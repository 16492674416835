import React from 'react';
import { useHistory } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { Skeleton } from '@material-ui/lab';
import { BackArrowIcon } from '../../shared/icons';
import { FormatText, NavigateTo } from '../../shared/ui/common';
import styles from './breadcrumb.styles';
import { shoppingExperienceColors } from '../../shared/constants';

const useStyles = makeStyles(styles);

const Breadcrumb = props => {
  const { routes, isLoading } = props;
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.gridContainer}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          color="inherit"
          href="/dashboard"
          onClick={() => NavigateTo(history, '/dashboard')}
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {isLoading ? (
            <Skeleton variant="circle" width={30} height={30} />
          ) : (
            <BackArrowIcon
              height="20px"
              width="20px"
              fontSize="18px"
              className={classes.imageIcon}
              onClick={() => history.goBack()}
            />
          )}
          {isLoading ? (
            <Skeleton variant="text" width={200} />
          ) : (
            <Typography
              variant="body3MediumInter"
              className={routes?.length === 0 ? classes.activeLink : classes.inactiveLink}
            >
              Home
            </Typography>
          )}
        </Link>
        {routes?.map((path, index) => {
          const _canRoute = index === routes.length - 1;
          if (path?.name === 'collections') {
            if (routes.length > 1) {
              return null;
            }
            return isLoading ? (
              <Skeleton variant="text" width={200} />
            ) : (
              <Typography
                variant="body3MediumInter"
                className={classes.inactiveLink}
                style={{ color: shoppingExperienceColors.info }}
              >
                Best Sellers
              </Typography>
            );
          }
          if (_canRoute) {
            return (
              <Link key={index} color="inherit">
                {isLoading ? (
                  <Skeleton variant="text" width={200} />
                ) : (
                  <Typography variant="body3MediumInter" className={classes.activeLink}>
                    {FormatText(path?.name)}
                  </Typography>
                )}
              </Link>
            );
          }
          return (
            <Link
              key={index}
              color="inherit"
              style={{ cursor: 'pointer' }}
              onClick={() => NavigateTo(history, path.to)}
            >
              {isLoading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <Typography variant="body3MediumInter" className={classes.inactiveLink}>
                  {FormatText(path?.name)}
                </Typography>
              )}
            </Link>
          );
        })}
      </Breadcrumbs>
    </Grid>
  );
};

export default Breadcrumb;
