import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CheckCircleRounded } from '@material-ui/icons';
import { debounce } from 'lodash';
import accountProducts from '../../../apis/swagup/accountProducts';
import { Pagination } from '../../shared';
import { CustomTooltip, SearchField } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import { StylessButton } from '../../buttons';
import { imageSrcSet } from '../../../helpers/utils';
import apiPaths from '../../../helpers/apiPaths';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';
import USShippingOnlyTag from '../../global/USShippingOnlyTag';

const useStyles = makeStyles(styles);

const getImage = image => image || '/images/public/nopic.jpg';
const productConvertion = product => {
  return {
    ...product,
    image: getImage(product.image),
    variants: product.stock.map(s => ({
      ...s,
      name: product.title,
      description: product.description,
      image: getImage(product.image)
    }))
  };
};

const perPageOptions = [8, 16, 24, 32];

const IntegrationItem = ({
  onNext,
  title,
  description,
  variant,
  icon,
  alReadyConnected,
  small,
  selected,
  product,
  ellipsisStylesStyle,
  long,
  html,
  usOnly
}) => {
  const classes = useStyles({ selected, product, alReadyConnected });
  const longSize = long ? 9 : 8;
  return (
    <StylessButton className={classes.integrationSection} onClick={onNext} disabled={alReadyConnected}>
      <Grid container justifyContent="center">
        <Grid item xs={small ? 6 : longSize}>
          <Box className={classes.integrationSelectionItem}>
            <Grid container alignItems="center" spacing={6}>
              <Grid item>{icon}</Grid>
              <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
                <CustomTooltip
                  title={title}
                  arrow
                  placement="top-start"
                  disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
                >
                  <Typography
                    variant="subtitle2SemiBoldInter"
                    className={classes.integrationItemTitle}
                    style={{ marginBottom: product ? 12 : 0 }}
                  >
                    {title}
                  </Typography>
                </CustomTooltip>

                {variant && (
                  <Grid container justifyContent="center" alignItems="center" className={classes.variant}>
                    <Grid item style={{ maxWidth: '100%' }}>
                      <Typography variant="body4RegularInter">{variant}</Typography>
                    </Grid>
                  </Grid>
                )}
                {description &&
                  (html ? (
                    <div
                      dangerouslySetInnerHTML={{ __html: description }}
                      className={classes.integrationItemDescription}
                    />
                  ) : (
                    <Typography variant="body3RegularInter" className={classes.integrationItemDescription}>
                      {description}
                    </Typography>
                  ))}
              </Grid>
              <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
                {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
              </Grid>
            </Grid>
            {product && usOnly && (
              <USShippingOnlyTag
                small
                infoIcon
                style={{ position: 'absolute', right: 8, top: 2, maxWidth: 128 }}
                infoIconStyle={{ marginBottom: -2 }}
              />
            )}
            {alReadyConnected && <CheckCircleRounded className={classes.connected} />}
          </Box>
        </Grid>
      </Grid>
    </StylessButton>
  );
};

const SendSwagConfigurationProduct = ({ action, onActionUpdate }) => {
  const [search, setSearch] = useState();
  const [total, setTotal] = useState(-1);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(12);

  const paginatedFlieds = useMemo(
    () => ({
      nextPage: () => setOffset(prev => (prev < total ? (pageIndex + 1) * limit : prev)),
      pageIndex: offset / limit,
      perPage: limit,
      sizeOptions: perPageOptions,
      previousPage: () => setOffset(prev => (prev > 0 ? (pageIndex - 1) * limit : prev)),
      changeSize: newSize => setLimit(newSize)
    }),
    [limit, pageIndex, total, offset]
  );

  const { nextPage, pageIndex, perPage, sizeOptions, previousPage, changeSize } = paginatedFlieds;

  const { data: integrationProductsData, isLoading: integrationProductsLoading } = useQuery(
    [apiPaths.integrationProducts, search, limit, offset],
    () => accountProducts.fetch({ search, limit, offset, visible_in_inventory: true, inventory: 'available' }),
    {
      enabled: !!action
    }
  );

  useEffect(() => {
    if (integrationProductsData) setTotal(integrationProductsData.count);
  }, [integrationProductsData]);

  const integrationProducts = integrationProductsData?.results.map(productConvertion) || [];

  const debouncedSearch = useCallback(
    debounce(value => {
      setSearch(value);
    }, 750),
    [setSearch]
  );

  const searchByName = value => {
    debouncedSearch(value);
  };

  const selectedProduct = action.settings?.product || {};
  // const queryClient = useQueryClient();

  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body3SemiBoldInter" className={classes.actionTitle}>
        Select the product you want for this automation
      </Typography>
      <Grid container justifyContent="center">
        <Grid item xs={12}>
          <Grid container alignItems="center" style={{ marginBottom: 12 }}>
            <Grid item xs={4}>
              <SearchField
                key="search"
                placeholder="Search By Name"
                onChange={searchByName}
                rounded
                lean
                inverseHover
              />
            </Grid>
            <Grid item xs />
            <Grid item>
              <Pagination
                count={total}
                endText="products"
                perPage={perPage}
                onNext={nextPage}
                pageIndex={pageIndex}
                onPrevious={previousPage}
                sizeOptions={sizeOptions}
                onPerPageChange={changeSize}
                buttonClass={classes.paginationButton}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box className={classes.integrationProductsWrapper}>
        <div>
          {integrationProducts.length ? (
            integrationProducts.map(product => (
              <IntegrationItem
                key={product.id}
                onNext={() =>
                  onActionUpdate({
                    ...action,
                    settings: {
                      ...action.settings,
                      product: {
                        account_product: product.id,
                        stocks: product.stock.map(st => ({
                          id: st.id,
                          size: st.size.id,
                          sizeName: st.size.name,
                          quantity: st.quantity
                        })),
                        name: product.name,
                        color: product.theme_color,
                        image: product.image,
                        usOnly: !product.can_ship_international
                      }
                    }
                  })
                }
                title={product.name}
                description={product.description}
                html
                icon={
                  <Box className={classes.integrationProductImageWrapper}>
                    <Img
                      src={product.image || '/images/public/nopic.jpg'}
                      alt={product.name}
                      className={classes.integrationImage}
                    />
                  </Box>
                }
                selected={selectedProduct?.account_product === product.id}
                ellipsisStylesStyle={ellipsisStyles}
                product
                long
                usOnly={!product.can_ship_international}
              />
            ))
          ) : (
            <Box>
              {integrationProductsLoading ? (
                <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
                  Loading...
                </Typography>
              ) : (
                <>
                  <Typography variant="body1MediumInter" className={classes.emptyProductsTitle}>
                    You don’t have products to connect yet!
                  </Typography>

                  <Grid container justifyContent="center">
                    <Grid item>
                      <Box className={classes.emptyProductsImageWrapper}>
                        <Img
                          srcSet={imageSrcSet('/images/integrations/empty-products.png')}
                          className={classes.integrationImage}
                          alt="empty-products"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Typography variant="body3RegularInter" className={classes.emptyProductsText}>
                        Go back to start creating new products into your store.
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container justifyContent="center">
                    <Grid item>
                      <Button size="small" variant="primary" onClick={() => alert('onBack')}>
                        Go Back
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          )}
        </div>
      </Box>
    </Box>
  );
};

export default SendSwagConfigurationProduct;
