/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react';
import { globalApi } from '../../apis/swagup';
import { useMembership } from '../../contexts/membershipContext';
import { useAuth } from '../../components/global/Authentication/AuthProvider';

const CheckoutContext = React.createContext();

const CheckoutContextProvider = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const [profile, setProfile] = useState({});
  const [relatedAccounts, setRelatedAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState({});
  const [switchingAccounts, setSwitchingAccounts] = useState(false);

  const { refetch } = useMembership();

  const onAccountSelect = async account => {
    setSwitchingAccounts(true);
    await globalApi.setAccount(profile.current_account, { current_account: account.id });
    setTimeout(async () => {
      await refetch();
      setSelectedAccount({ ...account });
      setSwitchingAccounts(false);
    }, 1500);
  };

  useEffect(() => {
    if (!isAuthenticated) return;
    (async () => {
      const resp = await globalApi.fetchProfile();
      if (resp?.related_accounts?.length) {
        const { related_accounts: _relatedAccounts, ...rest } = resp;
        setProfile({ ...rest });
        setRelatedAccounts([..._relatedAccounts.sort((x, y) => (x.name > y.name ? 1 : -1))]);
        setSelectedAccount({ ..._relatedAccounts.find(account => account.id === resp.current_account) });
      }
    })();
  }, [isAuthenticated]);

  return (
    <CheckoutContext.Provider
      value={{
        profile,
        relatedAccounts,
        selectedAccount,
        switchingAccounts,
        onAccountSelect
      }}
    >
      {children}
    </CheckoutContext.Provider>
  );
};

const useCheckoutContext = () => {
  return React.useContext(CheckoutContext);
};

export { CheckoutContextProvider, useCheckoutContext };
