import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { shoppingExperienceColors } from './constants';

export const RightArrowIcon = ({ fontSize = 32, ...props }) => (
  <SvgIcon {...props} style={{ fontSize: { fontSize }, marginTop: 8, color: props.color }}>
    <path
      style={{ fontSize: '24px', color: '#ffff' }}
      d="M14.6673 8.87675C14.6673 5.19675 11.6807 2.21008 8.00065 2.21008C4.32065 2.21008 1.33398 5.19675 1.33398 8.87675C1.33398 12.5568 4.32065 15.5434 8.00065 15.5434C11.6807 15.5434 14.6673 12.5568 14.6673 8.87675ZM8.00065 9.54342H5.33398V8.21008H8.00065V6.21008L10.6673 8.87675L8.00065 11.5434V9.54342Z"
      fill={props.color || shoppingExperienceColors.textPrimary}
    />
  </SvgIcon>
);

export const UpArrowIcon = props => (
  <SvgIcon {...props} style={{ fontSize: '32px' }}>
    <path
      style={{ fontSize: '24px' }}
      d="M12.5 2C6.98 2 2.5 6.48 2.5 12C2.5 17.52 6.98 22 12.5 22C18.02 22 22.5 17.52 22.5 12C22.5 6.48 18.02 2 12.5 2ZM13.5 12L13.5 16L11.5 16L11.5 12L8.5 12L12.5 8L16.5 12L13.5 12Z"
      fill={shoppingExperienceColors.textPrimary}
    />
  </SvgIcon>
);

export const DownArrowIcon = props => (
  <SvgIcon {...props} style={{ fontSize: '32px', marginTop: props.marginTop }}>
    <path
      style={{ fontSize: '24px' }}
      id="Vector"
      d="M11.06 5.53003L8 8.58336L4.94 5.53003L4 6.47003L8 10.47L12 6.47003L11.06 5.53003Z"
      fill="#131415"
    />
  </SvgIcon>
);

export const BackArrowIcon = ({ fontSize, height = '24px', width = '24px', onClick, className, style, ...props }) => (
  <SvgIcon {...props} onClick={onClick} className={className}>
    <path
      d="M0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0C4.48 0 0 4.48 0 10ZM10 9H14V11H10V14L6 10L10 6V9Z"
      fill={props.color || shoppingExperienceColors.textPrimary}
    />
  </SvgIcon>
);

export const SearchIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }) => (
  <SvgIcon {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0456 13.27L20.7756 19L19.2856 20.49L13.5556 14.76C12.4856 15.53 11.1956 16 9.78565 16C6.19564 16 3.28564 13.09 3.28564 9.5C3.28564 5.91 6.19564 3 9.78565 3C13.3756 3 16.2856 5.91 16.2856 9.5C16.2856 10.91 15.8156 12.2 15.0456 13.27ZM9.78565 5C7.29565 5 5.28564 7.01 5.28564 9.5C5.28564 11.99 7.29565 14 9.78565 14C12.2756 14 14.2856 11.99 14.2856 9.5C14.2856 7.01 12.2756 5 9.78565 5Z"
      fill="#131415"
    />
  </SvgIcon>
);

export const PersonIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }) => (
  <SvgIcon {...props}>
    <path
      d="M12 11.9995C14.21 11.9995 16 10.2095 16 7.99951C16 5.78951 14.21 3.99951 12 3.99951C9.79 3.99951 8 5.78951 8 7.99951C8 10.2095 9.79 11.9995 12 11.9995ZM12 13.9995C9.33 13.9995 4 15.3395 4 17.9995V19.9995H20V17.9995C20 15.3395 14.67 13.9995 12 13.9995Z"
      fill="#131415"
    />
  </SvgIcon>
);

export const ShoppingBagIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }) => (
  <SvgIcon {...props}>
    <path
      d="M18 6H16C16 3.79 14.21 2 12 2C9.79 2 8 3.79 8 6H6C4.9 6 4 6.9 4 8V20C4 21.1 4.9 22 6 22H18C19.1 22 20 21.1 20 20V8C20 6.9 19.1 6 18 6ZM10 10C10 10.55 9.55 11 9 11C8.45 11 8 10.55 8 10V8H10V10ZM12 4C13.1 4 14 4.9 14 6H10C10 4.9 10.9 4 12 4ZM16 10C16 10.55 15.55 11 15 11C14.45 11 14 10.55 14 10V8H16V10Z"
      fill="#131415"
    />
  </SvgIcon>
);

export const SwagUpLogoMobileIcon = ({ width = '40', height = '40', viewBox = '0 0 40 40', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5716 29.5141L23.6141 26.5323C23.9847 25.0537 24.4704 22.6228 24.822 20.0131L27.5716 21.775V29.5141ZM16.1571 26.4579C15.5 23.8359 14.4866 18.2321 14.4866 13.9635C14.4866 9.13924 15.9017 4.94712 18.3696 2.4623C18.7306 2.09708 19.1107 1.77283 19.5063 1.4984C22.6021 3.66785 24.5232 8.4327 24.5232 13.9635C24.5232 18.23 23.5098 23.8359 22.8521 26.4579H16.1571ZM11.4396 29.5128V21.775L14.1845 20.0131C14.5408 22.6242 15.0252 25.0544 15.3964 26.5323L11.4396 29.5128ZM19.2902 0C8.63709 0 0 8.7044 0 19.4397C0 27.1884 4.49671 33.8749 11.0081 37C17.0242 34.722 18.1033 28.4847 18.1033 28.4847L18.776 27.9079V27.2682L19.4846 27.5884L20.1424 27.2682V27.9079L20.8144 28.4847C20.8144 28.4847 21.901 34.5746 27.745 36.9167C34.1622 33.7581 38.5824 27.1215 38.5824 19.4397C38.5824 8.7044 29.9467 0 19.2902 0Z"
      fill="#131415"
    />
  </svg>
);

export const SwagUpLogoSmallIcon = ({ width = '20', height = '20', viewBox = '0 0 40 40', ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.5716 29.5141L23.6141 26.5323C23.9847 25.0537 24.4704 22.6228 24.822 20.0131L27.5716 21.775V29.5141ZM16.1571 26.4579C15.5 23.8359 14.4866 18.2321 14.4866 13.9635C14.4866 9.13924 15.9017 4.94712 18.3696 2.4623C18.7306 2.09708 19.1107 1.77283 19.5063 1.4984C22.6021 3.66785 24.5232 8.4327 24.5232 13.9635C24.5232 18.23 23.5098 23.8359 22.8521 26.4579H16.1571ZM11.4396 29.5128V21.775L14.1845 20.0131C14.5408 22.6242 15.0252 25.0544 15.3964 26.5323L11.4396 29.5128ZM19.2902 0C8.63709 0 0 8.7044 0 19.4397C0 27.1884 4.49671 33.8749 11.0081 37C17.0242 34.722 18.1033 28.4847 18.1033 28.4847L18.776 27.9079V27.2682L19.4846 27.5884L20.1424 27.2682V27.9079L20.8144 28.4847C20.8144 28.4847 21.901 34.5746 27.745 36.9167C34.1622 33.7581 38.5824 27.1215 38.5824 19.4397C38.5824 8.7044 29.9467 0 19.2902 0Z"
      fill="#131415"
    />
  </svg>
);

export const HamburgerMenuIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }) => (
  <SvgIcon {...props}>
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#131415" />
  </SvgIcon>
);

export const CheckMarkIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none', color }) => (
  <SvgIcon {...props}>
    <path
      d="M3.72667 7.05333L0.946667 4.27333L0 5.21333L3.72667 8.94L11.7267 0.94L10.7867 0L3.72667 7.05333Z"
      fill={props.color}
    />
  </SvgIcon>
);

export const MobileCloseIcon = (props = {}) => (
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="close">
      <path
        id="Vector"
        d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z"
        fill="#131415"
      />
    </g>
  </svg>
);

export const CloseIcon = (props = { width: '24', height: '24', viewBox: '0 0 24 24', fill: 'none' }) => (
  <SvgIcon {...props}>
    <path
      d="M19.25 7.16L17.84 5.75L12.25 11.34L6.66 5.75L5.25 7.16L10.84 12.75L5.25 18.34L6.66 19.75L12.25 14.16L17.84 19.75L19.25 18.34L13.66 12.75L19.25 7.16Z"
      fill="#4A4F54"
    />
  </SvgIcon>
);

export const SlickCarouselRightArrow = ({
  fontSize,
  height = '24px',
  width = '24px',
  onClick,
  className,
  style,
  ...props
}) => (
  <SvgIcon viewBox="0 0 40 40" {...props} onClick={onClick} className={className}>
    <path
      d="M40 20C40 8.96 31.04 0 20 0C8.96 0 0 8.96 0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20ZM20 22H12V18H20V12L28 20L20 28V22Z"
      fill={shoppingExperienceColors.textPrimary}
    />
  </SvgIcon>
);

export const SlickCarouselLeftArrow = ({
  fontSize,
  height = '24px',
  width = '24px',
  onClick,
  className,
  style,
  disabled,
  ...props
}) => (
  <SvgIcon viewBox="0 0 40 40" {...props} onClick={onClick} className={className}>
    <path
      d="M0 20C0 31.04 8.96 40 20 40C31.04 40 40 31.04 40 20C40 8.96 31.04 0 20 0C8.96 0 0 8.96 0 20ZM20 18H28V22H20V28L12 20L20 12V18Z"
      fill={shoppingExperienceColors.textPrimary}
    />
  </SvgIcon>
);

export const VectorIcon = ({
  width = 30,
  height = 32,
  viewBox = '0 0 30 32',
  fill = 'none',
  color = shoppingExperienceColors.primary,
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      viewBox={viewBox}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        fill,
        color
      }}
    >
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70703 18.2632L6.0263 19.0701C7.67954 13.363 10.4915 10.6667 14.6 10.6667C13.9281 10.6667 13.3833 10.0697 13.3833 9.33333C13.3833 8.59695 13.9281 8 14.6 8C15.2719 8 15.8167 8.59695 15.8167 9.33333C15.8167 10.0694 15.2725 10.6661 14.601 10.6667C18.709 10.6671 21.5206 13.3635 23.1737 19.0701L25.493 18.2632C23.5413 11.5259 19.8643 8 14.6 8C9.3357 8 5.65873 11.5259 3.70703 18.2632Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.6001 13.3334C16.1894 13.3334 17.5414 12.2203 18.0424 10.6667H22.2257C22.6464 11.4638 23.4328 12 24.3335 12C25.6774 12 26.7668 10.8061 26.7668 9.33337C26.7668 7.86061 25.6774 6.66671 24.3335 6.66671C23.4328 6.66671 22.6464 7.20297 22.2257 8.00004L18.0424 8.00004C17.5414 6.44645 16.1894 5.33337 14.6001 5.33337C13.0109 5.33337 11.6589 6.44645 11.1578 8.00004H6.97459C6.55386 7.20297 5.76747 6.66671 4.8668 6.66671C3.5229 6.66671 2.43346 7.86061 2.43346 9.33337C2.43346 10.8061 3.5229 12 4.8668 12C5.76747 12 6.55386 11.4638 6.97459 10.6667H11.1578C11.6589 12.2203 13.0109 13.3334 14.6001 13.3334ZM15.8168 9.33337C15.8168 10.0698 15.2721 10.6667 14.6001 10.6667C13.9282 10.6667 13.3835 10.0698 13.3835 9.33337C13.3835 8.59699 13.9282 8.00004 14.6001 8.00004C15.2721 8.00004 15.8168 8.59699 15.8168 9.33337ZM4.8668 25.3334C6.88264 25.3334 8.5168 23.5425 8.5168 21.3334C8.5168 19.1242 6.88264 17.3334 4.8668 17.3334C2.85096 17.3334 1.2168 19.1242 1.2168 21.3334C1.2168 23.5425 2.85096 25.3334 4.8668 25.3334ZM6.08346 21.3334C6.08346 22.0698 5.53874 22.6667 4.8668 22.6667C4.19485 22.6667 3.65013 22.0698 3.65013 21.3334C3.65013 20.597 4.19485 20 4.8668 20C5.53874 20 6.08346 20.597 6.08346 21.3334ZM24.3335 25.3334C26.3493 25.3334 27.9835 23.5425 27.9835 21.3334C27.9835 19.1242 26.3493 17.3334 24.3335 17.3334C22.3176 17.3334 20.6835 19.1242 20.6835 21.3334C20.6835 23.5425 22.3176 25.3334 24.3335 25.3334ZM25.5501 21.3334C25.5501 22.0698 25.0054 22.6667 24.3335 22.6667C23.6615 22.6667 23.1168 22.0698 23.1168 21.3334C23.1168 20.597 23.6615 20 24.3335 20C25.0054 20 25.5501 20.597 25.5501 21.3334Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export const UploadVectorIcon = ({
  width = 32,
  height = 32,
  viewBox = '0 0 30 32',
  fill = 'none',
  color,
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      viewBox={viewBox}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        fill,
        color
      }}
    >
      <path d="M28 22V28H4V22H0V28C0 30.2 1.8 32 4 32H28C30.2 32 32 30.2 32 28V22H28ZM6 10L8.82 12.82L14 7.66V24H18V7.66L23.18 12.82L26 10L16 0L6 10Z" />
    </SvgIcon>
  );
};

export const InfoIcon = ({ width = 24, height = 24, viewBox = '0 0 24 24', fill = 'none', color, ...props }) => {
  return (
    <SvgIcon
      {...props}
      viewBox={viewBox}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        fill,
        color
      }}
    >
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM13 17H11V11H13V17ZM13 9H11V7H13V9Z"
        fill="black"
      />
    </SvgIcon>
  );
};
