import React from 'react';
import { Typography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core';
import { Link } from '../../../components/shared';
import style from './PrivacyAndTermsSection.style';

const useStyles = makeStyles(style);

const PrivacyAndTermsSection = () => {
  const classes = useStyles();
  const PRIVACY_POLICY_URL = `${process.env.REACT_APP_PROD_DOMAIN}privacy-policy-dba`;
  const TERMS_OF_USE_URL = `${process.env.REACT_APP_PROD_DOMAIN}terms-of-use`;
  const FOOTER_LINK = [
    {
      label: 'Privacy Policy',
      path: PRIVACY_POLICY_URL
    },
    {
      label: 'Terms of Use',
      path: TERMS_OF_USE_URL
    }
  ];
  return (
    <Typography variant="body4RegularInter" style={{ textAlign: 'center', marginTop: '0px' }}>
      By continuing, I agree to SwagUp’s 
      {FOOTER_LINK.map((link, index) => (
        <>
          <Link key={link.label} absolute to={link.path} className={classes.privacyAndTermsOfUseLink} target="_blank">
            {link.label}
          </Link>
          {index === 0 ? ' and ' : '.'}
        </>
      ))}
    </Typography>
  );
};

export default PrivacyAndTermsSection;
