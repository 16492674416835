import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const OktaLogoutCallback = () => {
  const history = useHistory();
  useEffect(() => {
    const currentPath = localStorage.getItem('postLogoutRedirectUri');
    history.push(currentPath.replace('/dashboard', '') || '/');
  }, [history]);

  return <>&nbsp;</>;
};

export default OktaLogoutCallback;
