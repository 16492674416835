import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { shoppingExperienceColors } from '../../shared/constants';
import { useResponsive } from '../../hooks';
import { TextFieldWithButton } from '../../shared/ui/common';

const NewsLetter = () => {
  const { isMobileOrTabletOrLaptop } = useResponsive();

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ gap: '25px' }}>
      {!isMobileOrTabletOrLaptop && (
        <SwagupTypography variant="h6SemiBoldInter" style={{ color: shoppingExperienceColors.white, fontWeight: 500 }}>
          Be the first to know
        </SwagupTypography>
      )}
      <TextFieldWithButton />
    </Grid>
  );
};

export default NewsLetter;
