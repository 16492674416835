import React from 'react';
import { shoppingExperienceColors } from '../constants';
import { DollarPriceSymbol } from './common';

const StrikeOut = ({ value, surPlus = 0, fontSize = 14, color = shoppingExperienceColors.info }) =>
  value > 0 ? (
    <span
      style={{
        fontFamily: 'Inter',
        fontSize,
        color,
        textDecoration: 'line-through'
      }}
    >
      {DollarPriceSymbol(value + surPlus)}
    </span>
  ) : null;

export default StrikeOut;
