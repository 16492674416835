import { shoppingExperienceColors } from '../constants';

const commonStyles = theme => ({
  image: {
    cursor: 'pointer',
    width: '100%',
    height: 'auto',
    objectFit: 'contain'
  },
  backToTopButton: {
    height: '40px',
    display: 'none',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    color: shoppingExperienceColors.white
  },
  buttonGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  showButton: {
    display: 'flex'
  },
  textField: {
    width: 'auto',
    borderRadius: '100px',
    backgroundColor: shoppingExperienceColors.white
  },
  placeholder: {
    fontFamily: 'Inter !important',
    color: shoppingExperienceColors.textPrimary
  },
  button: {
    height: '36px',
    width: '125px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '20px',
    backgroundColor: `${shoppingExperienceColors.textPrimary} !important`,
    border: 'none',
    color: `${shoppingExperienceColors.white} !important`,
    '&:hover': {
      color: `${shoppingExperienceColors.white} !important`,
      backgroundColor: shoppingExperienceColors.ctaHover
    },
    '&:disabled': {
      color: `${shoppingExperienceColors.white} !important`,
      backgroundColor: `${shoppingExperienceColors.disabledCta} !important`,
      border: 'none'
    }
  },
  searchBarTextField: {
    width: 'auto',
    borderRadius: '100px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    alignSelf: 'stretch',
    '& .MuiInputBase-input': {
      backgroundColor: shoppingExperienceColors.secondaryButton,
      '&::placeholder': {
        color: shoppingExperienceColors.textPrimary,
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400'
      }
    },
    border: 'none',
    outline: '0',
    '& .MuiOutlinedInput-root': {
      border: 'none',
      '&:hover fieldset': {
        border: 'none'
      },
      '&.Mui-focused fieldset': {
        border: 'none'
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  },
  searchInput: {
    backgroundColor: shoppingExperienceColors.secondaryButton,
    alignSelf: 'stretch',
    border: 'none !important'
  },
  // Modal styles
  titleContainer: ({ title }) => ({
    padding: '16px 40px',
    display: 'flex',
    flexDirection: 'column',
    ...(title ? { borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}` } : {}),
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  }),
  title: {
    padding: 0
  },
  content: {
    padding: '20px 40px 20px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: 20
    }
  },
  closeButton: {
    position: 'absolute',
    right: 20,
    top: 12,
    [theme.breakpoints.down('md')]: {
      right: 20,
      top: 7
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 7
    }
  },
  buttonStyle: {
    borderRadius: 4,
    borderWidth: 1,
    color: shoppingExperienceColors.textPrimary,
    borderColor: shoppingExperienceColors.textPrimary
  },
  blueBanner: {
    width: '100%',
    height: '50px',
    gap: '20px',
    backgroundColor: shoppingExperienceColors.primary,
    textAlign: 'center'
  },
  blueBannerLink: {
    textDecoration: 'underline',
    textDecorationColor: shoppingExperienceColors.white,
    cursor: 'pointer',
    textUnderlineOffset: '5px',
    '&:hover': {
      textDecoration: 'underline !important',
      textDecorationColor: `${shoppingExperienceColors.white} !important`
    }
  },
  blueBannerLinkMobile: {
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none !important'
    }
  },
  noHover: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  listTextItem: {
    color: shoppingExperienceColors.ctaHover,
    paddingBottom: 16
  },
  rootCheckbox: {
    '&.MuiCheckbox-root': {
      padding: '0px 16px 13px 0px',
      color: shoppingExperienceColors.checkboxOutline
    },
    '&.MuiCheckbox-colorSecondary.Mui-checked': {
      color: shoppingExperienceColors.primary
    }
  },
  checkboxChecked: {},
  rootRadio: {
    '&.MuiRadio-root': {
      padding: '0px 16px 13px 0px'
    },
    '&.MuiIconButton-colorSecondary': {
      color: shoppingExperienceColors.checkboxOutline
    }
  },
  radioChecked: {
    '&.MuiIconButton-colorSecondary': {
      color: shoppingExperienceColors.primary
    }
  },
  listCard: {
    width: 78,
    height: 40,
    border: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    borderRadius: 4,
    marginRight: 12,
    marginBottom: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  // Header Search
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: shoppingExperienceColors.secondaryButton,
    width: '230px',
    height: '56px',
    [theme.breakpoints.up('sm')]: {
      width: 'auto'
    },
    padding: '5px',
    borderRadius: '28px'
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    width: '230px',
    paddingLeft: '20px',
    '&::placeholder': {
      color: shoppingExperienceColors.textPrimary,
      opacity: 1,
      fontFamily: 'Inter !important',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px'
    }
  },

  // TopSearchDrawer CSS
  drawerPaper: {
    height: '88px',
    top: 0
  },
  drawerContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '88px',
    borderBottom: `1px Solid ${shoppingExperienceColors.bannarBGColorMobile}`,
    padding: '0px 40px'
  },
  searchSuggestions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    padding: '0px 450px',
    height: '460px'
  },
  list: {
    paddingTop: theme.spacing(2)
  },
  listItem: {
    paddingLeft: 0,
    cursor: 'pointer'
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1)
  },
  authContent: {
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      padding: 20,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline'
    }
  },
  rootPaper: {
    display: 'inline-block',
    backgroundColor: shoppingExperienceColors.blueLightHovered,
    borderRadius: '5px',
    padding: '4px 8px'
  }
});

export default commonStyles;
