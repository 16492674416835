/* eslint-disable react/require-default-props */
import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@swagup-com/components';
import { shoppingExperienceColors } from '../constants';

const useStyles = makeStyles(() => ({
  baseButton: props => {
    let disabledStyles = {};
    if (props.disabled) {
      if (props.disabledVariant === 'outlined-disabled') {
        disabledStyles = {
          backgroundColor: 'transparent',
          color: shoppingExperienceColors.disabledCta,
          border: `1px solid ${shoppingExperienceColors.disabledCta}`,
          cursor: 'not-allowed'
        };
      } else if (props.disabledVariant === 'active-disabled') {
        disabledStyles = {
          backgroundColor: shoppingExperienceColors.disabledCta,
          color: shoppingExperienceColors.white
        };
      }
    }

    return {
      backgroundColor: props.disabled ? disabledStyles.backgroundColor : props.styles.backgroundColor,
      color: props.disabled ? disabledStyles.color : props.styles.color,
      border: props.disabled ? disabledStyles.border : props.styles.border,
      cursor: props.disabled ? disabledStyles.cursor : 'pointer',
      borderRadius: '4px',
      textTransform: 'none',
      paddingLeft: props.px || 20,
      paddingRight: props.px || 20,
      marginLeft: props.ml || 0,
      marginRight: props.mr || 10,
      marginTop: props.mt || 20,
      height: '45px',
      width: props.w || '100%',
      '&:hover': {
        backgroundColor: props.disabled
          ? disabledStyles.backgroundColor
          : props.styles.hoverColor || props.styles.backgroundColor,
        color: props.disabled ? disabledStyles.color : props.styles.hoverText || props.styles.color
      }
    };
  },
  baseButtonText: props => ({
    color: props.disabled ? shoppingExperienceColors.white : props.styles.color,
    '&:hover': {
      color: props.disabled ? shoppingExperienceColors.white : props.styles.hoverText || props.styles.color
    }
  })
}));

function BaseButton({
  text,
  onClick,
  px,
  mr,
  ml,
  mt,
  ht,
  styles,
  w,
  hasIcon,
  icon,
  v,
  id,
  children,
  disabled,
  disabledVariant,
  type,
  style = {},
  startIcon
}) {
  const classes = useStyles({ px, mr, ml, mt, styles, w, disabled, disabledVariant });

  const updatedIcon =
    hasIcon && icon
      ? React.cloneElement(icon, {
          color: disabled
            ? disabledVariant === 'outlined-disabled'
              ? shoppingExperienceColors.disabledCta
              : shoppingExperienceColors.white
            : icon.props.color
        })
      : null;

  return (
    <Button
      id={id}
      type={type && type}
      onClick={onClick}
      style={{ height: ht, background: disabled && shoppingExperienceColors.info, ...style }}
      className={classes.baseButton}
      {...(startIcon ? { startIcon: updatedIcon } : { endIcon: updatedIcon })}
      disabled={disabled}
    >
      <Typography
        variant={v || 'body3RegularInter'}
        style={{
          color: disabled
            ? disabledVariant === 'active-disabled'
              ? shoppingExperienceColors.white
              : shoppingExperienceColors.disabledCta
            : 'inherit'
        }}
      >
        {text}
      </Typography>
      {children && <div style={{ marginLeft: 5 }}>{children}</div>}
    </Button>
  );
}

BaseButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  px: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  mt: PropTypes.number,
  w: PropTypes.number,
  v: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  outlined: PropTypes.bool,
  hasIcon: PropTypes.bool,
  children: PropTypes.node,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    color: PropTypes.string,
    hoverColor: PropTypes.string,
    hoverText: PropTypes.string,
    border: PropTypes.string
  }).isRequired,
  disabled: PropTypes.bool,
  disabledVariant: PropTypes.oneOf(['outlined-disabled', 'active-disabled'])
};

export function CommonButton({
  text,
  onClick,
  px,
  mr,
  ml,
  mt,
  w,
  v,
  isActive,
  outlined = false,
  hasIcon = false,
  icon = null,
  id,
  children,
  disabled = false,
  disabledVariant = 'outlined-disabled',
  ht,
  type,
  style = {},
  startIcon = false
}) {
  const styles = outlined
    ? {
        backgroundColor: shoppingExperienceColors.white,
        color: shoppingExperienceColors.black,
        border: `1px solid`,
        height: ht
      }
    : isActive
    ? {
        backgroundColor: shoppingExperienceColors.black,
        color: shoppingExperienceColors.white,
        hoverColor: shoppingExperienceColors.cardCtaHover,
        hoverText: shoppingExperienceColors.white,
        height: ht
      }
    : {
        backgroundColor: shoppingExperienceColors.secondaryButton,
        color: shoppingExperienceColors.black,
        hoverColor: shoppingExperienceColors.black,
        hoverText: shoppingExperienceColors.white,
        height: ht
      };
  return (
    <BaseButton
      text={text}
      onClick={onClick}
      px={px}
      mr={mr}
      ml={ml}
      mt={mt}
      w={w}
      v={v}
      ht={ht}
      styles={styles}
      hasIcon={hasIcon}
      icon={icon}
      id={id}
      disabled={disabled}
      disabledVariant={disabledVariant}
      type={type}
      style={style}
      startIcon={startIcon}
    >
      {children}
    </BaseButton>
  );
}

CommonButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  px: PropTypes.number,
  mr: PropTypes.number,
  ml: PropTypes.number,
  mt: PropTypes.number,
  w: PropTypes.number,
  v: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool.isRequired,
  outlined: PropTypes.bool,
  hasIcon: PropTypes.bool,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  disabledVariant: PropTypes.oneOf(['outlined-disabled', 'active-disabled'])
};
