import React from 'react';
import { Grid, Link } from '@material-ui/core';
import { LocalShippingOutlined } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';

const UsOnlyWarningBox = ({ names, maxWidth }) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{ padding: '6px 24px', background: '#FFF3E0', marginBottom: 8, maxWidth }}
    >
      <Grid item>
        <LocalShippingOutlined style={{ color: '#131415', marginBottom: -5 }} />
      </Grid>
      <Grid item xs>
        <Typography variant="body3RegularInter" style={{ color: '#131415' }}>
          The product{`${names.length > 1 ? 's' : ''}`} "{names.join('", "')}" in this order has US only shipping
          restrictions.
        </Typography>
      </Grid>
      <Grid item>
        <Link
          href="https://support.swagup.com/en/articles/6952397-international-shipments-restricted-items"
          target="_blank"
          style={{ fontSize: 14, fontFamily: 'Inter' }}
        >
          Learn more
        </Link>
      </Grid>
    </Grid>
  );
};

export default UsOnlyWarningBox;
