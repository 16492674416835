import React from 'react';
import { Typography } from '@swagup-com/components';
import { Button, makeStyles } from '@material-ui/core';
import Styles from './common.styles';

const useStyles = makeStyles(Styles);
const PaginationButton = props => {
  const classes = useStyles();
  const { title, totalProducts, renderedProducts, showMoreHandler, showPreviousHandler, showMore = true } = props;
  return (
    <Button
      variant="outlined"
      size="small"
      disabled={totalProducts === renderedProducts && showMore}
      className={classes.buttonStyle}
      onClick={showMore ? showMoreHandler : showPreviousHandler}
    >
      <Typography variant="buttonSmallInter">{title}</Typography>
    </Button>
  );
};
export default PaginationButton;
