import React from 'react';
import { LoginCallback } from '@okta/okta-react';
import AccountNewHome from './pages/account/AccountNewHome';
import CatalogBulkHome from './pages/catalog/bulk/BulkHome';
import CatalogHome from './pages/catalog/CatalogHome';
import CatalogPackHome from './pages/catalog/pack/PackHome';
import CatalogThanks from './pages/catalog/thanks/Thanks';
import CreditConfirmation from './account/CreditConfirmation';
import DashboardHome from './dashboard/DashboardHome';
import ProductsHome from './products/ProductsHome';
import EmployeeAdd from './directory/employeesList/EmployeeAdd';
import EmployeeDetails from './directory/employeesList/EmployeeDetails';
import SendSwag from './pages/sendSwag/SendSwag';
import ErrorFound from './error/ErrorFound';
import ImportContacts from './pages/importContacts/ImportContacts';
import InventoryEmpty from './inventory/InventoryEmpty';
import InventoryHome from './inventory/InventoryHome';
import OnboardingHome from './onboarding/OnboardingHome';
import OnboardingMockupsHome from './pages/onboarding/mockups/OnboardingMockupsHome';
import RequestedDetailsRoot from './pages/orders/requested/details/RequestedDetailsRoot';
import OrderDetails from './pages/orders/completed/OrderDetails';
import OrdersHome from './pages/orders/OrdersHome';
import PresetHome from './preset/PresetHome';
import Contacts from './pages/contact/ContactsHome';
import Settings from './pages/settings/SettingsHome';
// This should be undone when new Concierge-checkout page will be finished
// import ProductOnboarding from './dashboard/ProductOnboarding';
// This should be undone when new Mockup page will be finished
// import ProofDetailsVersionSwitcher from './opportunities/ProofDetailsVersionSwitcher';
import MockUpApprovalVersionSwitcher from './opportunities/MockUpApprovalVersionSwitcher';
// import RequestedOrders from './pages/orders/requested/RequestedOrders';
import PublicInvoice from './account/PublicInvoice';
import SendSwagReview from './inventoryShipment/SendSwagReview';
import ShipmentHome from './pages/shipments/ShipmentsNewHome';
import ShipmentsReview from './directory/shipmentsReview/ShipmentsReview';
import ShippingOrderConfirmation from './shipping/ShippingOrderConfirmation';
import ShipSwag from './pages/shipSwag/ShipSwag';
import ShipSwagOverview from './pages/shipSwag/ShipSwagOverview';
import Reorder from './pages/reorder/Reorder';
import RedeemPagesRouter from './redeemPages/RedeemPagesRouter';
import StoresRouter from './storefronts/StoresRouter';
import DashboardVersionSwitcher from './dashboard/DashboardVersionSwitcher';
// import IntegrationsHome from './integrations/IntegrationsHome';
import StagnantInventory from './stagnantInventory/StagnantInventory';
import StoragePayment from './stagnantInventory/StoragePayment';
import StoragePaymentOverview from './stagnantInventory/StoragePaymentOverview';
import OrderSummaryPage from './pages/orders/requested/details/orderReview/OrderSummaryPage';
// import IntegrationFlowHome from './integrationsDev/integrationFlow/IntegrationFlowHome';
import IntegrationsRouter from './integrations/IntegrationsRouter';
import InventoryProductDetail from './inventory/InventoryProductDetail';
import OktaSignUp from './OktaSignup';
import OktaCallback from './OktaCallback';
import ShoppingExperienceRoutes from '../shoppingExperience/ShoppingExperienceRoutes';
import OktaLogoutCallback from './OktaLogoutCallback';

const CatalogBulk = () => <CatalogHome isBulk />;

const routes = [
  { path: '/', Component: DashboardHome, exact: true },
  { path: '/bulk', Component: CatalogBulkHome, exact: true },
  { path: '/bulk/catalog', Component: CatalogBulk },
  { path: '/custom-swag-packs', Component: CatalogPackHome, exact: true },
  { path: '/custom-swag-packs/catalog', Component: CatalogHome },
  { path: '/(custom-swag-packs|bulk)/thanks', Component: CatalogThanks, exact: true },
  { path: '/error', Component: ErrorFound, exact: true },
  { path: '/implicit/callback', Component: LoginCallback },
  { path: '/onboarding', Component: OnboardingHome, exact: true },
  { path: '/onboarding/mockups/thanks', Component: CatalogThanks, exact: true },
  {
    path: ['/onboarding/mockups/upload-logo', '/onboarding/mockups/:designId/:collageId?'],
    Component: OnboardingMockupsHome,
    exact: true
  },
  { path: '/onboarding/:step', Component: OnboardingHome, exact: true },
  { path: '/preset-packs', Component: PresetHome },
  // { path: ['/product-onboarding', '/paid-onboarding'], Component: ProductOnboarding, exact: true },
  { path: ['/product-onboarding', '/paid-onboarding'], Component: DashboardVersionSwitcher, exact: true },
  { path: '/public-invoice/:id', Component: PublicInvoice, exact: true },
  // secure ones
  {
    path: [
      '/billing/accounts',
      '/billing/invoices',
      '/billing/credits',
      '/billing/add-credits',
      '/billing/transactions'
    ],
    Component: AccountNewHome,
    secure: true
  },
  { path: '/add-employee', Component: EmployeeAdd, exact: true, secure: true },
  { path: '/credit-confirmation', Component: CreditConfirmation, exact: true, secure: true },
  { path: '/contact-details/:id', Component: EmployeeDetails, exact: true, secure: true },
  { path: ['/products', '/products/order'], Component: ProductsHome, exact: true, secure: true },
  { path: '/empty-inventory', Component: InventoryEmpty, exact: true, secure: true },
  { path: '/import-contacts/:step?/:fileId?', Component: ImportContacts, secure: true },
  { path: '/inventory', Component: InventoryHome, exact: true, secure: true },
  { path: '/inventory/inventory-histories/:id', Component: InventoryProductDetail, exact: true, secure: true },
  { path: '/order-details/:id', Component: OrderDetails, exact: true, secure: true },
  { path: '/order-completed-details/:id', Component: OrderSummaryPage, exact: true, secure: true },
  { path: ['/orders', '/orders-requested', '/orders-completed'], Component: OrdersHome, exact: true, secure: true },
  {
    path: ['/proof-details/:id/:proofId', '/proof-details/:id', '/product-details/:id'],
    Component: MockUpApprovalVersionSwitcher,
    exact: true,
    secure: true
  },
  // { path: '/orders-requested', Component: RequestedOrders, exact: true, secure: true },
  {
    path: [
      '/orders-requested/:id/review-order',
      '/orders-requested/:id/add-funds',
      '/orders-requested/:id/payment-method',
      '/orders-requested/:id/:step?/:shipmentGroupId?'
    ],
    Component: RequestedDetailsRoot,
    exact: true,
    secure: true
  },
  { path: '/order-requested-summary/:id', Component: OrderSummaryPage, secure: true },
  { path: '/reorder/:step?', Component: Reorder, secure: true },
  { path: '/send-swag-review', Component: SendSwagReview, exact: true, secure: true },
  { path: '/send-swag/:step?/:view?', Component: SendSwag, exact: true, secure: true },
  { path: '/shipments-review', Component: ShipmentsReview, exact: true, secure: true },
  { path: '/shipments/:view?', Component: ShipmentHome, exact: true, secure: true },
  { path: '/shipping-order-confirmation/:invoiceId', Component: ShippingOrderConfirmation, exact: true, secure: true },
  { path: '/ship-swag/:step?', Component: ShipSwag, secure: true },
  { path: '/ship-swag-overview', Component: ShipSwagOverview, secure: true },
  {
    path: ['/redeem-pages', '/redeem-pages-create', '/redeem-details/:id', '/redeem-history/:id'],
    Component: RedeemPagesRouter,
    exact: true,
    secure: true
  },
  {
    path: ['/integrations/', '/integrations/orders', '/integration-details/:id', '/integration-details/orders/:id'],
    Component: IntegrationsRouter,
    secure: true
  },
  { path: '/stagnant-inventory', Component: StagnantInventory, secure: true },
  { path: '/storage-payment/', Component: StoragePayment, secure: true },
  { path: '/storage-payment-overview/', Component: StoragePaymentOverview, secure: true },
  {
    path: ['/shops', '/shop-create', '/shop-customize/:id', '/shop-details/:id', '/shops/all-set'],
    Component: StoresRouter,
    exact: true,
    secure: true
  },
  { path: '/contacts', Component: Contacts, exact: true, secure: true },
  {
    path: [
      '/settings/profile',
      '/settings/company',
      '/settings/users',
      '/settings/your-membership',
      '/membership/manage-membership',
      '/membership/manage-membership-cancel',
      '/membership/manage-membership-switch',
      '/membership/manage-membership-confirmation',
      '/membership/change-membership'
    ],
    Component: Settings,
    exact: true,
    secure: true
  },
  { path: '/okta-signup', Component: OktaSignUp, exact: true, secure: false },
  { path: '/okta-callback', Component: OktaCallback, exact: true, secure: false },
  { path: '/okta/logout/callback', Component: OktaLogoutCallback, exact: true, secure: false },
  ...ShoppingExperienceRoutes
];

export default routes;
