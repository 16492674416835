const toCamelCase = str => {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => (index === 0 ? match.toLowerCase() : match.toUpperCase()))
    .replace(/\s+/g, '');
};

export const generateUniqueId = () => {
  return (
    Date.now().toString(36) +
    Math.random()
      .toString(36)
      .substr(2, 5)
  );
};

export const convertString = str => {
  const isSingleWord = !str.includes(' ');
  if (isSingleWord) {
    return str.toLowerCase();
  }
  return toCamelCase(str);
};
