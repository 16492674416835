import { shoppingExperienceColors } from '../../shared/constants';

const loginStyles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px'
  },
  form: {
    width: '324px',
    marginTop: '20px'
  },
  field: {
    '& input::placeholder': {
      color: shoppingExperienceColors.textPrimary,
      opacity: 1
    },
    '& .Mui-error input::placeholder': {
      color: shoppingExperienceColors.validationErrorText
    },
    '& .Mui-error input': {
      color: shoppingExperienceColors.validationErrorText
    },
    marginBottom: '16px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '& fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&.Mui-focused fieldset': {
        borderColor: shoppingExperienceColors.textPrimary
      },
      '&.Mui-error fieldset': {
        color: shoppingExperienceColors.validationErrorText
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: shoppingExperienceColors.borderLineGray,
      borderRadius: 5
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000'
    },
    '& .MuiInputLabel-root': {
      display: 'none'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: `${shoppingExperienceColors.validationErrorText} !important`,
      textAlign: 'left',
      marginLeft: 0
    },

    marginBottom: '16px'
  },

  button: {
    marginTop: '16px',
    width: '100%'
  },
  passwordLink: {
    textAlign: 'left',
    color: shoppingExperienceColors.primary
  },
  link: {
    textAlign: 'center',
    marginTop: '12px',
    fontSize: '14px'
  },
  errorMessage: {
    color: `${shoppingExperienceColors.validationErrorText} !important`,
    fontFamily: 'Gilroy',
    fontWeight: 400,
    marginBottom: 8,
    fontSize: 12
  },
  loadingIcon: { color: '#fff' },
  forgotPasswordText: {
    color: '#2663FF'
  }
});

export default loginStyles;
