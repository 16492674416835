export const setAppliedFilter = filter => ({
  type: 'APPLY_FILTER',
  payload: filter
});

const initialState = {
  appliedFilter: []
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'APPLY_FILTER': {
      return { ...state, appliedFilter: action.payload };
    }
    default:
      return state;
  }
};

export default filterReducer;
