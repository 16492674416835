import { useLocation } from 'react-router-dom';

export const getBreadCrumbs = () => {
  const location = useLocation();
  let pathSegments = location.pathname.split('/').filter(Boolean);
  const ignoredPath = ['product'];
  pathSegments = pathSegments.filter(item => !ignoredPath.includes(item));

  const breadcrumbs = pathSegments.map((segment, index) => {
    const to = `/${pathSegments.slice(0, index + 1).join('/')}`;
    return { name: segment, to };
  });
  return breadcrumbs;
};

export const formatNumbers = numericValue => {
  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const ensureHash = obj => {
  const updatedObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'string' && value[0] !== '#') {
        updatedObj[key] = `#${value}`;
      } else {
        updatedObj[key] = value;
      }
    }
  }

  return updatedObj;
};

export const getMediaItemByDevice = (mediaItems, isMobile, isTablet) => {
  let image = mediaItems.find(mediaItem => mediaItem.device === 'desktop')?.url;

  const device = isMobile ? 'mobile' : isTablet ? 'tablet' : 'desktop';
  const imageByDevice = mediaItems.find(mediaItem => mediaItem.device === device);

  if (imageByDevice?.url) image = imageByDevice.url;

  return image;
};
