import { shoppingExperienceColors } from '../../shared/constants';

const hoverActiveListItem = {
  background: '#3577D414'
};

const styles = {
  dropdownRoot: {
    border: '1px solid #E5E7E8',
    padding: '10px 16px',
    borderRadius: '4px',
    maxWidth: 350,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&.disabled': {
      opacity: 0.5,
      userSelect: 'none',
      pointerEvents: 'none'
    }
  },
  expandIcon: { color: '#131415' },
  listWrapper: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 44,
    zIndex: 12,
    userSelect: 'none',
    background: '#fff',
    marginTop: 4,
    boxShadow: '0px 8px 16px 2px #0000000A',
    border: '1px solid #E5E7E8',
    borderRadius: '5px',
    maxWidth: 350,
    height: 150
  },
  listItem: {
    display: 'flex',
    padding: '14px 20px',
    borderBottom: '1px solid #E5E7E8',
    cursor: 'pointer',
    '&:hover': {
      ...hoverActiveListItem
    },
    '&.active': { ...hoverActiveListItem }
  },

  scrollBar: {
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY': {
      width: '4px !important',
      height: 'calc(100% - 10px) !important',
      top: '0px !important',
      right: '5px !important',
      background: `${shoppingExperienceColors.bannarBGColorMobile} !important`
    },
    '& .ScrollbarsCustom-Thumb': {
      background: `${shoppingExperienceColors.black} !important`
    }
  }
};

export default styles;
