import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextLink as FooterLink } from '../../shared/ui/common';
import styles from './footer.styles';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(styles);

const Categories = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <>
      {currentDevice !== DEVICES_TYPE.MOBILE && (
        <FooterLink variant="h5SemiBoldInter" className={classes.footerSubHeading}>
          Categories
        </FooterLink>
      )}
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        All Swag
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Apparel
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Tech items
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Office
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Drinkware
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Bags
      </FooterLink>
    </>
  );
};

export default Categories;
