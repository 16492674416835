import * as React from 'react';
import { IntercomProvider } from 'react-use-intercom';
import { BrowserRouter, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { Provider as ReduxProvider, useDispatch } from 'react-redux';
import { SecureRoute } from '@okta/okta-react';
import { CssBaseline, Hidden, ThemeProvider, makeStyles } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { QueryClient, QueryClientProvider, useQueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import TagManager from 'react-gtm-module';
import { useFlags, useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import dayjsUtils from '@date-io/dayjs';
import { createTheme } from '@swagup-com/components';
import { useGlobalQuery } from '../hooks';
import AuthProvider, { useAuth } from './global/Authentication/AuthProvider';
import TokenInterceptor from './tokenInterceptor/TokenInterceptor';
import { HolidayContext } from '../contexts/holiday';
import { SavingContacts } from './shared';
import ScrollToTop from './global/ScrollToTop';
import NotFoundPage from './error/NotFoundPage';
import ErrorFound from './error/ErrorFound';
import Loader from './global/Loader';
import '../styles/App.css';
import store from '../reduxStore';
import routes from './routes';
import newRoutes from './Newroutes';
import apiPaths from '../helpers/apiPaths';
import log from '../logger';
import GlobalStyles from './GlobalStyles';
import GlobalNotification from './shared/GlobalNotification';
import { MembershipProvider } from '../contexts/membershipContext';

import { hiddenTopBarHeaderRoutes } from '../utils/constants';
import { HIDE_LEFT_NAV } from '../actions/types';
import { LeftNavbarProvider, useLeftNavbar } from '../contexts/leftNavbar';
import styles from './App.styles';
import { CartContextProvider } from '../shoppingExperience/context/cart.context';
import { FilterContextProvider } from '../shoppingExperience/contexts/filter.context';

const Navbar = React.lazy(() => import('./Navbar'));
const Header = React.lazy(() => import('./Header'));

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_CONTAINER,
  auth: process.env.REACT_APP_GTM_AUTH,
  preview: process.env.REACT_APP_GTM_PREVIEW
});
log.debug('process.env:', process.env);

const useStyles = makeStyles(styles);

const AppBody = ({ oktaAuthPending }) => {
  const [navbarOpen, setNavbarOpen] = React.useState(true);

  const history = useHistory();
  const location = useLocation();

  const { leftBarNavigation } = useFlags();

  const { toggleLeftNav, hideLeftNav } = useLeftNavbar();

  const { isPending, isAuthenticated } = useAuth();

  const classes = useStyles({ isAuthenticated });
  const dispatch = useDispatch();

  const { isLoading } = useGlobalQuery(!isPending && isAuthenticated);

  const queryClient = useQueryClient();

  React.useEffect(() => {
    log.debug('isPending:', isPending, 'isAuthenticated:', isAuthenticated);
    if (!isPending && !isAuthenticated) {
      queryClient.setQueryData(apiPaths.accounts, {});
      queryClient.setQueryData(apiPaths.profiles, {});
    }
  }, [isAuthenticated, isPending, queryClient]);

  const ldClient = useLDClient();
  const ldUser = ldClient?.getUser()?.key;
  const {
    enableRedeemPagesManagementFeatureOnDashboardTemp060322: enableRedeemPages,
    zapierEmbebedIntegrations: zapier
  } = useFlags();

  const filter = route => {
    return (
      ((!route.path.includes('/redeem-') || enableRedeemPages) && (!route.path.includes('/integrations') || zapier)) ||
      !ldUser ||
      ldUser === 'AnonymousUser'
    );
  };

  React.useEffect(() => {
    dispatch({ type: HIDE_LEFT_NAV, payload: { hideLeftNav } });
  }, [hideLeftNav]);

  React.useEffect(() => {
    toggleLeftNav(navbarOpen);
  }, [navbarOpen]);

  return (
    <>
      {leftBarNavigation && !hideLeftNav && (
        <Hidden implementation="css" xsDown>
          <React.Suspense fallback={<></>}>
            <Navbar navbarOpen={navbarOpen} setNavbarOpen={setNavbarOpen} />
          </React.Suspense>
        </Hidden>
      )}
      <div
        className={`${classes.root} ${
          leftBarNavigation && !hideLeftNav
            ? navbarOpen
              ? classes.navbarOpen
              : classes.navbarClosed
            : classes.topNavbar
        } ${window.location.pathname.includes('/project-submission') ? classes.rootShopingExp : ''}`}
      >
        <ErrorBoundary
          FallbackComponent={ErrorFound}
          onError={error => {
            log.debug('ErrorBoundary, error', error);
          }}
          onReset={() => (location.key ? history.goBack() : history.replace('/'))}
          resetKeys={[history, location]}
        >
          <MembershipProvider>
            <CartContextProvider>
              <ScrollToTop>
                <Switch>
                  {!leftBarNavigation
                    ? routes.filter(filter).map(({ path, Component, secure, exact }) => {
                        const RouteComponent = secure ? SecureRoute : Route;
                        return (
                          <RouteComponent path={path} exact={!!exact} key={path}>
                            <HolidayContext.Provider value={false}>
                              <React.Suspense fallback={<Loader />}>
                                <Header navbarOpen={navbarOpen} />
                              </React.Suspense>
                              {isLoading ? <Loader /> : <Component />}
                            </HolidayContext.Provider>
                          </RouteComponent>
                        );
                      })
                    : newRoutes.filter(filter).map(({ path, Component, secure, exact }) => {
                        const RouteComponent = secure ? SecureRoute : Route;
                        return (
                          <RouteComponent path={path} exact={!!exact} key={path}>
                            <HolidayContext.Provider value={false}>
                              {!hiddenTopBarHeaderRoutes.some(routeToHide =>
                                location.pathname.startsWith(routeToHide)
                              ) && (
                                <React.Suspense fallback={<Loader />}>
                                  <Header navbarOpen={navbarOpen} />
                                </React.Suspense>
                              )}
                              {isLoading ? <Loader /> : <Component />}
                            </HolidayContext.Provider>
                          </RouteComponent>
                        );
                      })}
                  {!isPending && !oktaAuthPending && (
                    <Route>
                      <NotFoundPage backToDashboard />
                    </Route>
                  )}
                </Switch>
              </ScrollToTop>
            </CartContextProvider>
          </MembershipProvider>
        </ErrorBoundary>
      </div>
    </>
  );
};

const queryClient = new QueryClient();
queryClient.setDefaultOptions({
  queries: {
    useErrorBoundary: true,
    refetchOnWindowFocus: false
  }
});

const mainTheme = createTheme();
const basename = '/dashboard';

const App = () => {
  const [oktaAuthPending, setOktaAuthPending] = React.useState(true);

  React.useEffect(() => {
    // Simulate checking Okta authentication state
    const checkOkta = async () => {
      // Perform Okta-specific logic to determine when authentication is complete
      // Example: Wait for an async call to verify login state
      await new Promise(resolve => setTimeout(resolve, 1000));
      setOktaAuthPending(false);
    };

    checkOkta();
  }, []);

  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={mainTheme}>
        <MuiPickersUtilsProvider utils={dayjsUtils}>
          <CssBaseline />
          <BrowserRouter basename={basename}>
            <IntercomProvider
              autoBoot
              appId={process.env.REACT_APP_INTERCOM_APP}
              onShow={() => log.debug("Showing Intercom's Messenger.")}
              onHide={() => log.debug("Hiding Intercom's Messenger.")}
            >
              <AuthProvider isFromDashboard>
                <LeftNavbarProvider>
                  <TokenInterceptor>
                    <QueryClientProvider client={queryClient}>
                      <FilterContextProvider>
                        <GlobalStyles />
                        <AppBody oktaAuthPending={oktaAuthPending} />
                        <SavingContacts /> {/* TODO remove this after FF contactsFeGroupingTemp011921 true */}
                        <GlobalNotification />
                        <ReactQueryDevtools initialIsOpen={false} />
                      </FilterContextProvider>
                    </QueryClientProvider>
                  </TokenInterceptor>
                </LeftNavbarProvider>
              </AuthProvider>
            </IntercomProvider>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export default withLDProvider({
  clientSideID: process.env.REACT_APP_LAUNCH_DARKLY_CLIENTID,
  user: {
    key: 'AnonymousUser',
    anonymous: true
  }
})(App);
