export const setPreviousPath = path => ({
  type: 'UPDATE_PATH',
  payload: path
});

const initialState = {
  previousPath: ''
};

const pathReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PATH': {
      return { ...state, previousPath: action.payload };
    }
    default:
      return state;
  }
};

export default pathReducer;
