import React, { useEffect, useState } from 'react';
import { makeStyles, Snackbar, Grid, IconButton, Dialog, LinearProgress } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { Close as CloseIcon } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import { useDropzone } from 'react-dropzone';
import { Document, Page, pdfjs } from 'react-pdf';
import { getDocument } from 'pdfjs-dist';
import InfoIcon from '@material-ui/icons/Info';
import { shoppingExperienceColors } from '../../shared/constants';
import PDFImage from '../../assets/images/fileTypes/pdf-file-type.png';
import AIImage from '../../assets/images/fileTypes/ai-file-type.png';
import { UploadVectorIcon } from '../../shared/icons';
import { generateUniqueId } from '../../utils';
import { s3 } from '../../../helpers/utils';

// ! DO NOT REMOVE BELOW pdfjs.GlobalWorkerOptions.workerSrc line of code
// Set the worker URL for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.entry');

const isPasswordProtectedPDF = async file => {
  try {
    const loadingTask = getDocument({
      data: await file.arrayBuffer()
    });

    return new Promise((resolve, reject) => {
      loadingTask.promise
        .then(() => {
          resolve(false);
        })
        .catch(error => {
          if (error.name === 'PasswordException') {
            resolve(true);
          } else {
            reject(error);
          }
        });
    });
  } catch (error) {
    console.error('Error while checking PDF protection:', error);
    return true;
  }
};

const useStyles = makeStyles(theme => ({
  dialogBox: {
    height: '100vh',
    width: '100vw',
    margin: 'auto',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  uploadContainer: {
    border: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    borderRadius: '8px',
    padding: theme.spacing(4),
    textAlign: 'center',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2)
    }
  },
  uploadIcon: {
    marginBottom: theme.spacing(1)
  },
  previewContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      height: 'auto'
    }
  },
  previewItem: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    gap: '20px',
    position: 'relative',
    height: '100px',
    padding: '20px',
    backgroundColor: shoppingExperienceColors.secondaryButton,
    marginBottom: '20px',
    borderRadius: '5px',
    [theme.breakpoints.down('xs')]: {
      marginBottom: '10px'
    }
  },
  previewImage: {
    width: '100px',
    height: '70px',
    objectFit: 'contain',
    borderRadius: '5px'
  },
  previewImgClose: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    cursor: 'pointer',
    color: shoppingExperienceColors.black,
    [theme.breakpoints.down('xs')]: {
      top: '10px',
      right: '10px'
    }
  },
  chooseFilesLink: {
    color: shoppingExperienceColors.primary,
    textDecoration: 'underline',
    fontWeight: 500,
    fontFamily: 'Inter',
    fontSize: '14px',
    lineHeight: '20px'
  },
  zoomImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
    cursor: 'zoom-in',
    transformOrigin: props => `${props.mouseX}px ${props.mouseY}px`
  },
  pdfIconPreview: {
    width: '100px'
  },
  aiIconPreview: {
    width: '100px'
  },
  pdfPreview: {
    width: '100%',
    textAlign: 'center'
  },
  snackbar: {
    position: 'fixed',
    transform: 'translate(10px, -70px)',
    '& .MuiSnackbarContent-root': {
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    '& .MuiSnackbarContent-message': {
      padding: 0
    },
    '& .MuiSnackbarContent-action': {
      padding: 0
    }
  },
  dialogCloseButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    color: shoppingExperienceColors.error,
    zIndex: 999
  }
}));

const acceptedFileTypes = [
  'image/svg+xml', // SVG
  'image/jpeg', // JPEG
  'image/jpg', // JPG
  'image/png', // PNG
  'application/pdf', // PDF
  'application/postscript', // EPS
  'application/vnd.adobe.illustrator' // AI
];

const acceptedExtensions = ['svg', 'jpeg', 'jpg', 'png', 'pdf', 'eps', 'ai'];
const maxFileSize = 20 * 1024 * 1024; // 20 MB
const maxFilesCount = 2;

const UploadImage = ({ onChange }) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [zoomedImage, setZoomedImage] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [mousePosition, setMousePosition] = useState({ mouseX: 0, mouseY: 0 });

  const isMaxLimitReached = uploadedFiles.length >= maxFilesCount;
  const classes = useStyles({ isMaxLimitReached, ...mousePosition });

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const onFileUploading = () => {
    onChange({ isFileUploading: true, data: null });
  };

  const onFileUploaded = data => {
    onChange({ isFileUploading: false, data });
  };

  const isFileTypeValid = file => {
    const fileType = file.type;
    const fileExtension = file.name
      .split('.')
      .pop()
      .toLowerCase();
    return acceptedFileTypes.includes(fileType) && acceptedExtensions.includes(fileExtension);
  };

  const onDrop = async (acceptedFiles, rejectedFiles) => {
    if (isMaxLimitReached) {
      setSnackbarMessage(
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.error }}>
          You've reached the maximum file capacity. Delete files in order to add new ones.
        </SwagupTypography>
      );
      setSnackbarOpen(true);
      return;
    }

    const newFiles = [];

    rejectedFiles.forEach(file => {
      if (!isFileTypeValid(file.file)) {
        setSnackbarMessage(
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
            File type is not correct
          </SwagupTypography>
        );
        setSnackbarOpen(true);
      } else if (file.file.size > maxFileSize) {
        setSnackbarMessage(
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
            File size exceeds 20 MB. Please upload a smaller file.
          </SwagupTypography>
        );
        setSnackbarOpen(true);
      }
    });

    for (const file of acceptedFiles) {
      if (isFileTypeValid(file) && file.size <= maxFileSize && uploadedFiles.length + newFiles.length < maxFilesCount) {
        if (file.type === 'application/pdf') {
          const isPasswordProtected = await isPasswordProtectedPDF(file);
          if (isPasswordProtected) {
            setSnackbarMessage(
              <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
                Password-protected PDFs are not allowed. Please upload a file without a password.
              </SwagupTypography>
            );
            setSnackbarOpen(true);
            continue;
          }
        }

        newFiles.push(Object.assign(file, { preview: URL.createObjectURL(file) }));
      } else if (!isFileTypeValid(file)) {
        setSnackbarMessage(
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
            File type is not correct
          </SwagupTypography>
        );
        setSnackbarOpen(true);
      } else if (file.size > maxFileSize) {
        setSnackbarMessage(
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
            File size exceeds 20 MB. Please upload a smaller file.
          </SwagupTypography>
        );
        setSnackbarOpen(true);
      } else if (uploadedFiles.length + newFiles.length >= maxFilesCount) {
        setSnackbarMessage(
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.black }}>
            You've reached the maximum file capacity. Delete files in order to add new ones.
          </SwagupTypography>
        );
        setSnackbarOpen(true);
      }
    }
    const uniqueId = generateUniqueId();
    s3.upload({
      Key: `${uniqueId}-logo-${newFiles[0].name.replace(/\s+/g, '-')}`,
      Body: newFiles[0],
      ContentType: newFiles[0].type
    })
      .on('httpUploadProgress', () => onFileUploading())
      .send((err, data) => {
        if (err) {
          onFileUploaded(uploadedFiles);
          return;
        }
        const _tempNewFile = {
          uniqueId,
          path: data?.Location || '',
          file: newFiles?.[0]
        };
        setUploadedFiles(prev => {
          const files = [...prev, _tempNewFile].slice(0, maxFilesCount);
          onFileUploaded(files);
          return files;
        });
      });
  };

  const handleRemoveFile = index => {
    setUploadedFiles(prev => {
      const files = prev.filter((_, i) => i !== index);
      onFileUploaded(files);
      return files;
    });
  };

  const handleZoomImage = file => {
    if (file.type !== 'application/postscript' && file.type !== 'application/vnd.adobe.illustrator') {
      setZoomedImage(file);
      setIsZoomed(false);
    }
  };

  const handleCloseZoom = () => {
    setZoomedImage(null);
    setIsZoomed(false);
  };

  const handleMouseMove = event => {
    const { offsetX, offsetY } = event.nativeEvent;
    setMousePosition({ mouseX: offsetX, mouseY: offsetY });
  };

  const handleImageClick = () => {
    setIsZoomed(!isZoomed);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFileTypes.join(','),
    maxSize: maxFileSize,
    disabled: isMaxLimitReached
  });

  return (
    <Grid container style={{ marginTop: '20px' }}>
      <Grid item xs={12} {...getRootProps()} className={classes.uploadContainer}>
        <input {...getInputProps()} />
        <UploadVectorIcon
          fill={isMaxLimitReached ? shoppingExperienceColors.borderLineGray : shoppingExperienceColors.primary}
        />
        <SwagupTypography variant="body3MediumInter" style={{ color: shoppingExperienceColors.info }}>
          Drag & Drop to Upload or{' '}
          <span className={classes.chooseFilesLink} style={{ cursor: isMaxLimitReached ? 'not-allowed' : 'pointer' }}>
            Choose files
          </span>
        </SwagupTypography>
        <SwagupTypography
          variant="body4RegularInter"
          style={{ marginTop: '8px', color: shoppingExperienceColors.info }}
        >
          Maximum file size: 20MB
          {isMaxLimitReached ? (
            <SwagupTypography
              variant="body4RegularInter"
              style={{ color: shoppingExperienceColors.error, marginTop: '8px' }}
            >
              You’ve reached maximum file capacity.
              <br />
              Delete files in order to add new ones.
            </SwagupTypography>
          ) : (
            <SwagupTypography
              variant="body4RegularInter"
              style={{ color: shoppingExperienceColors.info, marginTop: '8px' }}
            >
              Maximum file quantity: {maxFilesCount}
            </SwagupTypography>
          )}
        </SwagupTypography>
      </Grid>

      {uploadedFiles.length > 0 && (
        <Grid item xs={12} className={classes.previewContainer}>
          {uploadedFiles.map(({ file }, index) => {
            let PreviewComponent;
            switch (file.type) {
              case 'application/pdf':
                PreviewComponent = (
                  <div className={classes.pdfIconPreview}>
                    <img src={PDFImage} alt="pdf-file-type" className={classes.previewImage} />
                  </div>
                );
                break;
              case 'application/postscript':
              case 'application/vnd.adobe.illustrator':
                PreviewComponent = (
                  <div className={classes.aiIconPreview}>
                    <img src={AIImage} alt="ai-file-type" className={classes.previewImage} />
                  </div>
                );
                break;
              default:
                PreviewComponent = <img src={file.preview} alt={`preview ${index}`} className={classes.previewImage} />;
                break;
            }

            return (
              <div key={index} className={classes.previewItem}>
                {PreviewComponent}
                <div onClick={() => handleZoomImage(file)}>
                  <SwagupTypography
                    variant="body3SemiBoldInter"
                    style={{
                      color: shoppingExperienceColors.primary,
                      cursor:
                        file.type === 'application/postscript' || file.type === 'application/vnd.adobe.illustrator'
                          ? 'not-allowed'
                          : 'pointer'
                    }}
                  >
                    {file.name}
                  </SwagupTypography>
                  {file.type === 'application/postscript' || file.type === 'application/vnd.adobe.illustrator' ? (
                    <SwagupTypography
                      variant="body4RegularInter"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '4px',
                        color: shoppingExperienceColors.error
                      }}
                    >
                      <InfoIcon style={{ fontSize: '14px', marginRight: '4px' }} />
                      File cannot be Previewed
                    </SwagupTypography>
                  ) : (
                    <SwagupTypography
                      variant="body4RegularInter"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '4px',
                        color: shoppingExperienceColors.info
                      }}
                    >
                      <InfoIcon style={{ fontSize: '14px', marginRight: '4px' }} />
                      Click on the File Name to Preview the file
                    </SwagupTypography>
                  )}

                  {file.type === 'application/pdf' && (
                    <SwagupTypography
                      variant="body4RegularInter"
                      style={{ color: shoppingExperienceColors.black, marginTop: '4px' }}
                    >
                      Only one page PDF's can be previewed at this time;
                    </SwagupTypography>
                  )}
                </div>
                <IconButton size="small" onClick={() => handleRemoveFile(index)} className={classes.previewImgClose}>
                  <CloseIcon />
                </IconButton>
              </div>
            );
          })}
        </Grid>
      )}

      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={10000}
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        className={classes.snackbar}
      >
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseSnackbar} severity="error">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>

      <Dialog
        open={
          !!zoomedImage &&
          zoomedImage.type !== 'application/postscript' &&
          zoomedImage.type !== 'application/vnd.adobe.illustrator'
        }
        onClose={handleCloseZoom}
        maxWidth="md"
        className={classes.dialogBox}
      >
        <IconButton className={classes.dialogCloseButton} onClick={handleCloseZoom}>
          <CloseIcon />
        </IconButton>
        {zoomedImage && zoomedImage?.type === 'application/pdf' ? (
          <Document file={zoomedImage.preview} className={classes.pdfPreview}>
            <Page pageNumber={1} />
          </Document>
        ) : (
          <img
            src={zoomedImage?.preview}
            alt={zoomedImage?.name}
            className={classes.zoomImage}
            style={{
              transform: isZoomed ? 'scale(2)' : 'scale(1)',
              cursor: isZoomed ? 'zoom-out' : 'zoom-in'
            }}
            onClick={handleImageClick}
            onMouseMove={handleMouseMove}
          />
        )}
      </Dialog>
    </Grid>
  );
};

export default UploadImage;
