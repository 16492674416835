import * as React from 'react';
import { Grid, makeStyles, Tooltip, FilledInput, Divider } from '@material-ui/core';
import { useRouter } from 'next/router';
import debounce from 'lodash/debounce';
import { Button, Typography } from '@swagup-com/components';
import clsx from 'clsx';
import { Search } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import { imageSrcSet, moneyStr, joinFields } from '../../helpers/utils';
import { useCompany } from '../../hooks';
import NewBadge from '../shared/NewBadge';
import { Link } from '../shared';
import { scrollBar, ellipsisStyles } from '../shared/styles/commonStyles';
import UserAvatar from './UserAvatar';
import { LoginDropdownMenu } from './common';
import { StylessButton } from '../buttons';

const commonHover = {
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#00000014'
  }
};
const useStyles = makeStyles({
  closeIconContainer: { padding: '8px', display: 'flex', justifyContent: 'flex-end', width: '100%' },
  closeIcon: { color: '#787B80', cursor: 'pointer', fontSize: '18px' },
  profileName: { color: '#111827', fontSize: 20 },
  swapAccountItem: { ...commonHover, padding: '12px 16px', borderBottom: '1px solid #E5E7E8', zIndex: 99999 },
  accountControlItem: { ...commonHover, padding: '12px 16px', width: '100%', justifyContent: 'center' },
  accountSettingsControlItem: {
    ...commonHover,
    padding: '12px 16px',
    border: '1px solid #E5E7E8',
    margin: '12px 0px 12px',
    borderRadius: '24px'
  },
  labelItem: { marginLeft: 10, color: '#4A4F54' },
  profileEmail: { color: '#111827', fontSize: 14 },
  rowItem: { padding: '8px 16px', borderBottom: '1px solid #E5E7E8', width: '100%' },
  currentAccount: { padding: '8px 16px', borderBottom: '1px solid #E5E7E8' },
  accountsContainer: { ...scrollBar, maxHeight: 173 },
  selected: { backgroundColor: '#3577d414' },
  border: { marginTop: 8, borderTop: '1px solid #E5E7E8' },
  helpMargin: { marginTop: 8 },
  noMargin: { marginLeft: 0 },
  accountsEmptyContainer: { alignItems: 'center', justifyContent: 'center', padding: 12, minHeight: 90 },
  tooltip: {
    width: 220,
    borderRadius: '4px 0px 4px 4px',
    padding: '0px 0px 8px 0px',
    '&.MuiTooltip-tooltipPlacementLeft': { margin: 0 }
  },
  linkedAccountsContainer: { width: '100%' },
  linkedAccountsAccordion: { boxShadow: 'none' },
  expanded: { margin: 0 },
  content: { margin: 0, '&$expanded': { margin: 0, minHeight: '0px' } },
  linkedAccountsHeader: { fontSize: 14, color: '#131415', margin: '0px' },
  linkedAccountDetails: { padding: '0px' },
  accordionDetailsContainer: { padding: '0px' },
  swagupCardContainer: { background: '#EBF1FB', padding: '8px 16px' },
  swagCardBalance: { fontSize: 20 },
  swagCardBalanceText: { fontSize: 10, marginBottom: '8px' },
  addFundsWrapper: {
    display: 'flex',
    width: 44,
    height: 20,
    justifyContent: 'center',
    borderRadius: 10,
    backgroundColor: '#131415',
    padding: '4px 8px'
  },
  addFundsCTA: {
    fontSize: 10,
    letterSpacing: 0.4,
    color: '#ffffff',
    alignSelf: 'center'
  }
});

const useSearchFieldStyles = makeStyles({
  root: {
    fontFamily: 'Inter',
    background: 'transparent',
    height: 48,
    fontSize: 12,
    borderRadius: '50px',
    margin: '0px 16px',
    border: '1px solid #E5E7E8',
    '&:hover': { background: 'transparent' },
    '&:focus-within': { background: 'transparent' },
    '& .MuiSvgIcon-root': { color: '#4A4F54', width: 18, marginTop: 3 }
  },
  input: {
    ...ellipsisStyles,
    padding: '10px 10px 10px 8px',
    background: 'transparent',
    fontSize: 14
  }
});

const Profile = ({ profile, classes }) => {
  const name = profile.first_name;
  const { currentAccount } = profile;

  return (
    <Grid container className={classes.rowItem}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={2}>
          <UserAvatar name={name} nameForColorGeneration={currentAccount?.name} size={42} fontSize={16} />
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', padding: '8px' }}>
        <Typography variant="body4SemiBoldInter" className={classes.profileName}>
          Hi, {name}!
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <Typography variant="body4RegularInter" className={classes.profileEmail}>
          {profile.email}
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginLeft: '22px', marginRight: '22px' }}>
        <MenuItem itemName="Account Settings" to="/settings/profile" classes={classes} />
      </Grid>
    </Grid>
  );
};

const CurrentAccount = ({ accountName, classes, accountsCount }) => (
  <Grid container alignItems="center" className={classes.currentAccount}>
    <Grid item xs={12}>
      <Typography variant="body4RegularInter" style={{ color: '#131415', fontSize: 14 }}>
        {accountsCount > 0 ? 'Current Account' : 'Account'}
      </Typography>
    </Grid>
    <Grid item xs={12} style={{ marginTop: 4, display: 'inline-flex', alignItems: 'center' }}>
      <div style={{ width: '24px', height: '24px', borderRadius: '50%', backgroundColor: '#125CFF' }} />
      <Typography variant="body4SemiBoldInter" style={{ fontSize: '14px', marginLeft: '8px' }}>
        {accountName}
      </Typography>
    </Grid>
  </Grid>
);

const SwapAccountItem = ({ companyName, email, onSwapAccount, classes }) => (
  <StylessButton onClick={onSwapAccount} style={{ width: '100%' }}>
    <Grid container alignItems="center" className={classes.swapAccountItem}>
      <Grid item xs={2}>
        <UserAvatar name={companyName} nameForColorGeneration={companyName} size={32} />
      </Grid>
      <Grid item xs={10} style={{ paddingLeft: 8 }}>
        <Typography variant="body4SemiBoldInter" style={{ color: '#131415', fontSize: 14, textAlign: 'left' }}>
          {companyName}
        </Typography>
      </Grid>
    </Grid>
  </StylessButton>
);

const SearchField = ({ onChange }) => {
  const classes = useSearchFieldStyles();

  return (
    <FilledInput
      id="account-search-input"
      onChange={e => onChange(e.target.value)}
      placeholder="Search accounts"
      startAdornment={<Search />}
      fullWidth
      autoFocus
      disableUnderline
      classes={classes}
    />
  );
};

const SwapAccounts = ({ profile, onSwapAccount, classes }) => {
  const [searchValue, setSearchValue] = React.useState('');

  const debouncedSearch = React.useCallback(
    debounce(value => setSearchValue(value), 500),
    [setSearchValue]
  );

  const accounts = profile.related_accounts?.filter(a => a.id !== profile.current_account);

  const filteredAccounts = accounts?.filter(a => a.name.toLowerCase().includes(searchValue.toLowerCase()));

  const title = (
    <>
      <SearchField onChange={debouncedSearch} />
      <Grid container className={classes.accountsContainer} style={{ marginTop: '4px' }}>
        {filteredAccounts?.length === 0 ? (
          <Grid container className={classes.accountsEmptyContainer}>
            <Typography variant="body4SemiBoldInter">No Account Found</Typography>
            <Typography variant="body4RegularInter" style={{ textAlign: 'center', color: '#989EA4' }}>
              Remove filter to see all the accounts.
            </Typography>
          </Grid>
        ) : (
          filteredAccounts?.map(a => (
            <SwapAccountItem
              key={a.id}
              companyName={a.name}
              email={profile.email}
              onSwapAccount={e => {
                onSwapAccount(a.id);
                e.stopPropagation();
              }}
              classes={classes}
            />
          ))
        )}
      </Grid>
    </>
  );

  return (
    <Grid container alignItems="center" onClick={() => setOpen(true)} className={classes.accordionDetailsContainer}>
      {title}
    </Grid>
  );
};

const HelpCenter = ({ loggedOut, classes }) => (
  <a href="https://support.swagup.com" target="_blank" rel="noopener noreferrer">
    <Grid container className={clsx(loggedOut && classes.border)}>
      <Grid container alignItems="center" className={clsx(classes.accountControlItem, loggedOut && classes.helpMargin)}>
        <img src="/images/public/help-center.svg" alt="Help Center" />
        <Typography variant="body3RegularInter" className={classes.labelItem}>
          Help Center
        </Typography>
      </Grid>
    </Grid>
  </a>
);

const MenuItem = ({ itemName, to, classes }) => {
  const isSSR = useRouter();
  if (!isSSR && itemName === 'Dashboard') return null;

  const imageSrcName = itemName.toLowerCase().replace(/ /g, '-');

  return (
    <Link to={to}>
      <Grid container alignItems="center" className={classes.accountSettingsControlItem} justifyContent="center">
        <AccountCircleOutlinedIcon />
        <Typography variant="body3RegularInter" className={classes.labelItem}>
          {itemName}
        </Typography>
      </Grid>
    </Link>
  );
};

const MenuAuthItem = ({ itemName, onClick, classes }) => {
  const loggedOut = itemName === 'Log Out';

  return (
    <Grid container alignItems="center" onClick={() => onClick()} className={classes.accountControlItem}>
      {loggedOut && <img src="/images/public/log-out-blue.svg" alt="Log Out" />}
      <Typography variant="body3RegularInter" className={clsx(classes.labelItem, !loggedOut && classes.noMargin)}>
        {itemName}
      </Typography>
    </Grid>
  );
};

const LinkedAccounts = ({ profile, onSwapAccount }) => {
  const [showLinkedAccounts, setShowLinkedAccounts] = React.useState(false);
  const classes = useStyles();
  return (
    <div className={classes.linkedAccountsContainer}>
      <Accordion className={classes.linkedAccountsAccordion}>
        <AccordionSummary
          classes={{ content: classes.content, expanded: classes.expanded }}
          expandIcon={<ExpandMoreIcon />}
          style={{ minHeight: '40px', padding: '0px 16px' }}
          aria-controls="panel1a-content"
          id="panel1a-header"
          onClick={e => {
            setShowLinkedAccounts(!showLinkedAccounts);
            e.stopPropagation();
          }}
        >
          <Typography className={classes.linkedAccountsHeader}>
            {!showLinkedAccounts ? 'Show linked accounts' : 'Hide linked accounts'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.linkedAccountDetails} style={{ padding: '0px' }}>
          <SwapAccounts profile={profile} onSwapAccount={onSwapAccount} classes={classes} />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

const AddFunds = () => {
  const classes = useStyles();

  return (
    <Link to="/billing/add-credits" className={classes.addFundsWrapper}>
      <Typography variant="body4RegularInter" className={classes.addFundsCTA}>
        ADD
      </Typography>
    </Link>
  );
};

const SwagCardBalance = () => {
  const classes = useStyles();
  const company = useCompany();
  const balance = company?.data?.balance || 0;
  return (
    <Grid container className={classes.swagupCardContainer}>
      <Grid item style={{ display: 'inline-flex', alignItems: 'center', width: '100%' }}>
        <Grid style={{ display: 'flex' }}>
          <CreditCardIcon style={{ color: '#0065FF' }} />
        </Grid>
        <Grid style={{ marginLeft: '8px' }}>
          <Typography variant="body4RegularInter" className={classes.swagCardBalanceText}>
            Swag Card Balance
          </Typography>
          <Typography variant="body4SemiBoldInter" className={classes.swagCardBalance}>
            {moneyStr(balance)}
          </Typography>
        </Grid>
        <Grid item style={{ display: 'flex', justifyContent: 'flex-end', width: '30%' }}>
          <AddFunds />
        </Grid>
      </Grid>
    </Grid>
  );
};

const AccountProfileControl = ({ profile, onSwapAccount, logout, login, setOpen }) => {
  const classes = useStyles();
  const accounts = profile.related_accounts?.filter(a => a.id !== profile.current_account);
  const accountsCount = accounts?.length;
  return (
    <Grid container>
      <Grid className={classes.closeIconContainer}>
        <CloseIcon className={classes.closeIcon} onClick={() => setOpen(false)} />
      </Grid>
      {profile ? (
        <>
          <Profile profile={profile} classes={classes} />
          <CurrentAccount accountName={profile.currentAccount?.name} classes={classes} accountsCount={accountsCount} />
          {accountsCount > 0 ? <LinkedAccounts profile={profile} onSwapAccount={onSwapAccount} /> : null}
          <SwagCardBalance />
          <LoginDropdownMenu
            icon={MonetizationOnOutlinedIcon}
            text="Refer a Friend"
            color="#0065FF"
            link="https://launch.swagup.com/refer"
          />
          <MenuAuthItem itemName="Log Out" onClick={logout} classes={classes} />
        </>
      ) : (
        <>
          <MenuAuthItem itemName="Sign Up" onClick={login} classes={classes} />
          <MenuAuthItem itemName="Log In" onClick={login} classes={classes} />
          <HelpCenter loggedOut classes={classes} />
        </>
      )}
    </Grid>
  );
};

export default AccountProfileControl;
