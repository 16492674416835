/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Button, Divider, Grid, makeStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Typography } from '@swagup-com/components';
import { round } from 'lodash';
import styles from './cartQuantityBreakup.style';
import { defaultMinimumQtyForPacks, quantityBreaksArr } from '../../utils/constants';
import { useCartContext } from '../../context/cart.context';
import { DollarPriceSymbol } from '../../shared/ui/common';

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-unused-vars
const QuantityBreakItem = ({ quantity, price, isActive, setQtyForPack }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        className={`${classes.qtyBreakItem} ${isActive ? classes.selectedQtyBreakItem : ''}`}
        onClick={() => setQtyForPack(quantity)}
      >
        <Typography variant="body2RegularInter">{quantity}</Typography>
        <Typography variant="body2RegularInter">{DollarPriceSymbol(price)}</Typography>
      </Grid>
    </>
  );
};

const CustomQuantity = ({ qtyForPack = 100, setQtyForPack }) => {
  const classes = useStyles();
  const [currentQty, setCurrentQty] = useState(qtyForPack);

  useEffect(() => {
    setCurrentQty(qtyForPack);
  }, [qtyForPack]);

  const updatePackQuantity = value => {
    const numericValue = +value.toString();
    const updatedQty = numericValue >= defaultMinimumQtyForPacks ? numericValue : defaultMinimumQtyForPacks;
    setQtyForPack(updatedQty);
    if (updatedQty !== numericValue) {
      setCurrentQty(updatedQty);
    }
  };

  const handleInput = e => {
    const { value } = e.target;
    setCurrentQty(value);
  };

  return (
    <>
      <Grid container className={classes.customQtyContainer}>
        <Typography variant="body2RegularInter" style={{ minWidth: 'fit-content' }}>
          Custom quantity
        </Typography>
        <Grid container style={{ alignItems: 'center', justifyContent: 'end' }}>
          <Button
            className={`${classes.customQtyInputBtn} ${
              currentQty === defaultMinimumQtyForPacks ? classes.customQtyInputBtnDisabled : ''
            }`}
            onClick={() => updatePackQuantity(currentQty - 1)}
          >
            -
          </Button>
          <input
            onBlur={e => updatePackQuantity(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') updatePackQuantity(e.target.value);
            }}
            onInput={handleInput}
            value={currentQty}
            className={classes.customQtyInputTextbox}
          />
          <Button className={classes.customQtyInputBtn} onClick={() => updatePackQuantity(currentQty + 1)}>
            +
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

const PricePerPack = ({ totalPricePerPack = 96.21 }) => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.pricePerPackContainer}>
        <Typography variant="body3RegularInter">Price per pack</Typography>
        <Typography variant="body2SemiBoldInter">{`$${totalPricePerPack}`}</Typography>
      </Grid>
    </>
  );
};

const CartQuantityBreak = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => setExpanded(!expanded);

  const [quantityBreaks, setQuantityBreaks] = useState([]);

  const { qtyForPack, setQtyForPack, totalPricePerPackWithDiscount } = useCartContext();

  useEffect(() => {
    if (totalPricePerPackWithDiscount > 0 && qtyForPack) {
      const updatedQuantities = quantityBreaksArr.map((qty, index, array) => {
        const nextQty = array[index + 1] || Infinity; // Next value or Infinity if it's the last element
        return {
          quantity: qty,
          price: round(totalPricePerPackWithDiscount * qty, 2),
          isActive: qtyForPack >= qty && qtyForPack < nextQty
        };
      });
      setQuantityBreaks([...updatedQuantities]);
    }
  }, [totalPricePerPackWithDiscount, qtyForPack]);

  return (
    <>
      <Grid className={classes.container}>
        <Accordion expanded={expanded} onChange={handleToggle} className={classes.accordionContainer}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#131415' }} />}
            aria-controls={`panel-${1}-content`}
            id={`panel-${1}-header`}
            className={classes.accordionSummaryStyle}
          >
            <Grid container style={{ justifyContent: 'space-between' }}>
              <Typography variant="body3RegularInter">Pack quantity ({`Min ${defaultMinimumQtyForPacks}`})</Typography>
              <Typography variant="body2SemiBoldInter">{qtyForPack}</Typography>
            </Grid>
          </AccordionSummary>

          <Divider style={{ margin: '0px 10px 0px 10px' }} />

          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant="body2RegularInter">The more you order, the more you save</Typography>

            <Grid container className={classes.quantityBreaksContainer}>
              {quantityBreaks.map(quantityBreakItem => (
                <React.Fragment key={`quantityBreakItem-${quantityBreakItem.quantity}`}>
                  <QuantityBreakItem {...quantityBreakItem} qtyForPack={qtyForPack} setQtyForPack={setQtyForPack} />
                </React.Fragment>
              ))}

              <CustomQuantity qtyForPack={qtyForPack} setQtyForPack={setQtyForPack} />
            </Grid>
          </AccordionDetails>
        </Accordion>

        <PricePerPack totalPricePerPack={totalPricePerPackWithDiscount} />
      </Grid>
    </>
  );
};

export default CartQuantityBreak;
