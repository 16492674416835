import React from 'react';
import { Typography } from '@swagup-com/components';
import { Grid, makeStyles } from '@material-ui/core';
import FilterButton from '../FilterAndSortButton/filterAndSortButton';
import { FormatText } from '../../shared/ui/common';

const useStyles = makeStyles(theme => ({
  gridContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  titleContainer: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  subTitile: {
    color: '#989EA4',
    marginLeft: 8
  }
}));

const TitleHeader = props => {
  const classes = useStyles();
  const {
    title,
    showFilter,
    totalProducts,
    isMobile,
    productsRefetch,
    setGroupedFilter,
    groupedFilter,
    handleSortChange,
    sortItem,
    selectedCollectionId = null,
    isCollectionPage = false,
    categoryName = null,
    products = []
  } = props;

  const Heading = `Shop ${FormatText(decodeURIComponent(title))}`;

  return (
    <Grid container className={classes.gridContainer}>
      <Grid item className={classes.titleContainer}>
        <Typography variant="h4SemiBoldInter">{Heading}</Typography>
        <Typography variant="body2RegularInter" className={classes.subTitile}>
          ({totalProducts})
        </Typography>
      </Grid>
      {showFilter && (
        <Grid>
          <FilterButton
            isMobile={isMobile}
            productsRefetch={productsRefetch}
            groupedFilter={groupedFilter}
            setGroupedFilter={setGroupedFilter}
            handleSortChange={handleSortChange}
            sortItem={sortItem}
            selectedCollectionId={selectedCollectionId}
            isCollectionPage={isCollectionPage || false}
            categoryName={categoryName}
            products={products}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default TitleHeader;
