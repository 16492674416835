import { shoppingExperienceColors } from '../../shared/constants';

const cartPopupStyles = theme => ({
  productGrid: {
    maxHeight: '288px',
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '218px',
      overflowY: 'auto'
    }
  },
  productPriceContainer: {
    padding: '0px 0px 0px 20px',
    borderLeft: `1px solid ${shoppingExperienceColors.info}`,
    [theme.breakpoints.down('md')]: {
      padding: '0px 0px 20px 20px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 20px 0px',
      borderLeft: 'none',
      borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`
    }
  },
  productDetails: {
    marginBottom: 16,
    fontSize: 14
  },
  cartPriceDetails: {
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    padding: '0px 0px 20px 0px'
  }
});

export default cartPopupStyles;
