const catalogStyles = theme => ({
  gridContainer: {
    padding: '0px 40px 40px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 11px 11px 11px'
    }
  },
  gridItemStyle: {
    display: 'inline-grid',
    justifyContent: 'center'
  },
  dialog: {
    width: 1000,
    maxWidth: 1000,
    maxHeight: 506,
    height: 470,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      width: 800
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      position: 'absolute',
      margin: 0,
      bottom: 0,
      width: '100%',
      minHeight: 506
    }
  }
});

export default catalogStyles;
