export const normalizePricingMatrix = data => {
  return data.map(item => {
    return {
      media: item.media,
      color: item.color, // Assuming color is a direct property in the original object
      sizes: item.pricingOption.reduce((sizes, option) => {
        option.pricingMatrix.forEach(matrix => {
          const size = matrix.parameters.find(param => param.value).value;
          const quantityRange = {
            min: matrix.parameters.find(param => param.minimum !== null).minimum,
            max: matrix.parameters.find(param => param.maximum !== null).maximum,
            price: matrix.price[0].value
          };

          if (!sizes[size]) {
            sizes[size] = [];
          }
          sizes[size].push(quantityRange);
        });
        return sizes;
      }, {})
    };
  });
};

export const getDistinctSizesByColor = (selectedColorName, items) => {
  // Find the item that matches the selected color name
  const colorItem = items?.find(item => item.color.colorName === selectedColorName);

  // If color is found, return distinct sizes; otherwise, return an empty array
  if (colorItem) {
    const sizeOrder = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];

    return Object.keys(colorItem.sizes).sort((a, b) => {
      // Find the index of each size in the predefined order
      const indexA = sizeOrder.indexOf(a.toUpperCase());
      const indexB = sizeOrder.indexOf(b.toUpperCase());

      // If either size is not in the predefined order, move it to the end
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      // Sort based on the index
      return indexA - indexB;
    });
  }
  return [];
};

function getFirstElementFromObject(obj = {}) {
  const firstEntry = Object.entries(obj)?.[0];
  if (!firstEntry) return null;
  return firstEntry[1];
}

export const getApplicablePrice = (quantities = [25, 50, 100, 150, 250, 500, 1000], sizeArray) => {
  const priceRanges = getFirstElementFromObject(sizeArray?.[0]?.sizes);
  if (priceRanges) {
    // Find the minimum `min` in the price ranges
    const bestRange = priceRanges.reduce((minRange, currentRange) =>
      currentRange.min < minRange.min ? currentRange : minRange
    );

    return quantities.map(quantity => {
      const range = priceRanges.find(range => quantity >= range.min && quantity <= range.max);
      return {
        quantity,
        price: range ? range.price : bestRange.price // Use bestRange.price for quantities below the minimum
      };
    });
  }
  return [];
};
