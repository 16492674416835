import React from 'react';
import { Grid, InputBase, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import styles from './common.styles';
import { SearchIcon } from '../icons';
import TopSearchDrawer from './topSearchDrawer';
import { toggleDrawer } from '../../redux/searchReducer';

const useStyles = makeStyles(styles);

const SearchComponent = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleSearchClick = () => {
    dispatch(toggleDrawer(true));
  };

  return (
    <>
      <Grid className={classes.search} onClick={handleSearchClick} style={{ position: 'relative' }}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput
          }}
          inputProps={{
            placeholder: 'Search Products'
          }}
        />
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1,
            cursor: 'pointer'
          }}
        />
      </Grid>
      <TopSearchDrawer />
    </>
  );
};

export default SearchComponent;
