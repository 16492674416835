/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useMemo, useState } from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import styles from './forgotPassword.styles';
import { forgetPasswordApi } from '../../apis/auth';
import ForgetPasswordForm from '../../components/Auth/forgetPasswordForm';
import { CommonButton } from '../../shared/ui/commonButtons';
import { BackArrowIcon } from '../../shared/icons';

const useStyles = makeStyles(styles);

const Subtitle = ({ classes, isSubmitted }) => {
  return (
    <>
      <Typography variant="body3RegularInter" className={classes.subtitleText}>
        {isSubmitted
          ? 'If your email exists in our system, we just sent you an email. Please follow the instructions to reset your password.'
          : 'Don’t worry it happens to the best of us. Type in your email and we’ll send you a recovery link.'}
      </Typography>
    </>
  );
};

const PrimaryButton = ({ label, type, disabled = null, onClick = null, startIcon = false, Icon }) => {
  return (
    <CommonButton
      text={label}
      px={50}
      isActive
      mr={10}
      mt={0}
      hasIcon
      icon={Icon}
      ht={56}
      type={type}
      style={{ marginTop: '14px' }}
      startIcon={startIcon}
      disabledVariant="active-disabled"
      {...(disabled !== null && { disabled })}
      {...(onClick !== null && { onClick })}
    />
  );
};

const ForgotPassword = ({ onOpen, onClose }) => {
  const classes = useStyles();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);

  const handleSubmit = async formValues => {
    setSubmitting(true);
    try {
      const resp = await forgetPasswordApi(formValues);
      if (resp) {
        setSubmitted(true);
      }
      setSubmitting(false);
      console.log('Form Values', { formValues, resp });
    } catch (error) {
      setSubmitting(false);
      throw error;
    }
  };

  const toggleLoginModal = () => {
    onClose(false);
    onOpen();
  };

  const title = useMemo(() => (isSubmitted ? 'Thank you' : 'Reset password'), [isSubmitted]);

  useEffect(() => {
    setSubmitted(false);
  }, []);

  return (
    <Box className={classes.root}>
      <Typography variant="body1SemiBoldInter">{title}</Typography>
      <Subtitle classes={classes} isSubmitted={isSubmitted} />
      {isSubmitted === false ? (
        <ForgetPasswordForm
          classes={classes}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isSubmitted={isSubmitted}
          onClose={onClose}
          toggleLoginModal={toggleLoginModal}
          PrimaryButton={PrimaryButton}
        />
      ) : (
        <Grid item style={{ width: '100%', padding: '0px 40px' }}>
          <PrimaryButton
            label="Back to Sign In"
            type="button"
            onClick={toggleLoginModal}
            Icon={<BackArrowIcon color="#ffff" />}
            startIcon
          />
        </Grid>
      )}
    </Box>
  );
};

export default ForgotPassword;
