import { shoppingExperienceColors } from '../../shared/constants';

const confirmationStyles = theme => ({
  mainContainer: {
    padding: 40,
    [theme.breakpoints.down('md')]: {
      padding: '40px 60px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px'
    }
  },
  innerContainer: {
    padding: '0px 200px',
    display: 'inline-flex',
    [theme.breakpoints.down('md')]: {
      padding: '0px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
      display: 'block'
    }
  },
  detailsContainer: {
    marginRight: 40,
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      textAlign: 'center',
      padding: '40px 0px'
    }
  },
  productsContainer: {
    marginLeft: 40,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      borderTop: `1px solid ${shoppingExperienceColors.info}`,
      borderBottom: `1px solid ${shoppingExperienceColors.info}`,
      padding: 20
    }
  },
  accordionHeaderStyle: {
    margin: 0,
    padding: 0
  },
  accordionHeaderContentStyle: {
    display: 'block'
  },
  summaryTitle: {
    paddingBottom: 20,
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`
  },
  productGrid: {
    height: '110vh',
    overflowY: 'auto'
  },
  subTotalEstimate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  scrollBar: {
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY': {
      width: '4px !important',
      height: 'calc(100% - 10px) !important',
      top: '0px !important',
      right: '5px !important',
      background: `${shoppingExperienceColors.bannarBGColorMobile} !important`
    },
    '& .ScrollbarsCustom-Thumb': {
      background: `${shoppingExperienceColors.black} !important`
    }
  }
});

export default confirmationStyles;
