/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/display-name */
import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Grid, makeStyles, Divider } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { Typography as SwagupTypography } from '@swagup-com/components';
import PersonIcon from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import StyledTextField from './styledTextField';
import { shoppingExperienceColors } from '../constants';
import { PhoneField } from '../../../components/global/reactHookFormFields';
import useResponsive from '../../hooks/useResponsive';
import { setCartInfo } from '../../sevices/checkout.service';

const useStyles = makeStyles(() => ({
  accordionRoot: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    '&.Mui-disabled': {
      backgroundColor: shoppingExperienceColors.white,
      borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`
    }
  },
  accordionSummary: {
    backgroundColor: shoppingExperienceColors.lightGray,
    cursor: 'default',
    padding: 0
  },
  accordionSummaryDisabled: {
    backgroundColor: 'transparent'
  },
  accordionDetails: {
    padding: 0
  },
  noBorderRadiusTop: {
    '&.MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px'
    }
  },
  noBorderRadiusBottom: {
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px'
    }
  },
  labelStyle: {
    paddingBottom: '16px'
  },
  errorText: {
    color: shoppingExperienceColors.error,
    fontSize: '12px'
  },
  formInput: {
    '& > div > fieldset, & > div:nth-child(2) > div > fieldset': {
      borderRadius: '10px'
    }
  },
  editLink: {
    cursor: 'pointer',
    color: shoppingExperienceColors.primary,
    marginLeft: '16px',
    textDecoration: 'underline',
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Inter'
  }
}));

const ContactDetails = forwardRef(({ isVerified, onChange, prefetchedContactDetails = null }, ref) => {
  const classes = useStyles();
  const { control, setValue, trigger, getValues, watch } = useForm();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [showNormalText, setShowNormalText] = useState(!!prefetchedContactDetails);
  const transitionTimeout = useRef(null);
  const editTimeout = useRef(null);
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  useImperativeHandle(ref, () => ({
    resetTimeout: () => {
      if (transitionTimeout.current) {
        clearTimeout(transitionTimeout.current);
      }
      if (editTimeout.current) {
        clearTimeout(editTimeout.current);
      }
      setIsTransitioning(false);
      setShowNormalText(false);
    },
    submit: async () => {
      const valid = await trigger();
      if (valid) {
        return getValues();
      }
      return null;
    }
  }));

  useEffect(() => {
    const subscription = watch(values => {
      const { fullName, companyName, phone } = values;
      if (fullName && companyName) {
        if (transitionTimeout.current) {
          clearTimeout(transitionTimeout.current);
        }
        transitionTimeout.current = setTimeout(() => {
          setShowNormalText(true);
          setIsTransitioning(false);
        }, 15000);
      }
      setCartInfo({ phone });
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    if (prefetchedContactDetails) {
      for (const key in prefetchedContactDetails) {
        const value = prefetchedContactDetails[key];
        setValue(key, value);
      }
    }
  }, [prefetchedContactDetails, setValue]);

  const handleFieldChange = async (field, value) => {
    setValue(field, value);
    onChange(field, value);
    await trigger(field);

    const fullName = getValues('fullName');
    const companyName = getValues('companyName');
    if (fullName && companyName) {
      if (transitionTimeout.current) {
        clearTimeout(transitionTimeout.current);
      }
      transitionTimeout.current = setTimeout(() => {
        setShowNormalText(true);
        setIsTransitioning(false);
      }, 15000);
    }
  };

  const handleEditClick = () => {
    setShowNormalText(false);
    if (editTimeout.current) {
      clearTimeout(editTimeout.current);
    }
    editTimeout.current = setTimeout(() => {
      setShowNormalText(true);
    }, 15000);
  };

  return (
    <Accordion
      expanded={isVerified}
      disabled={!isVerified}
      classes={{
        root: classes.accordionRoot,
        disabled: classes.accordionSummaryDisabled
      }}
      className={`${classes.noBorderRadiusTop} ${classes.noBorderRadiusBottom}`}
    >
      <AccordionSummary className={classes.accordionSummary}>
        <SwagupTypography variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body1SemiBoldInter' : 'h5SemiBoldInter'}>
          Contact Details
        </SwagupTypography>
        {showNormalText && (
          <span className={classes.editLink} onClick={handleEditClick}>
            Edit
          </span>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        {!showNormalText ? (
          <Grid container spacing={2} className={isTransitioning ? classes.hidden : classes.transitionContainer}>
            <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
              <label htmlFor="full-name">
                <SwagupTypography variant="body3RegularInter" className={classes.labelStyle}>
                  Your full name*
                </SwagupTypography>
              </label>
              <Controller
                name="fullName"
                control={control}
                defaultValue=""
                rules={{ required: 'Please enter your full name.' }}
                render={({ field: { value }, fieldState: { error } }) => (
                  <StyledTextField
                    id="full-name"
                    placeholder="Your full name"
                    width="100%"
                    value={value}
                    onChange={e => handleFieldChange('fullName', e.target.value)}
                    error={!!error}
                    helperText={
                      error?.message && (
                        <SwagupTypography variant="body3RegularInter" className={classes.errorText}>
                          {error.message}
                        </SwagupTypography>
                      )
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6} style={{ marginBottom: '20px' }}>
              <label htmlFor="company-name">
                <SwagupTypography variant="body3RegularInter" className={classes.labelStyle}>
                  What's the name of your company?*
                </SwagupTypography>
              </label>
              <Controller
                name="companyName"
                control={control}
                defaultValue=""
                rules={{ required: 'Please enter your company name.' }}
                render={({ field: { value }, fieldState: { error } }) => (
                  <StyledTextField
                    id="company-name"
                    placeholder="What's the name of your company?"
                    width="100%"
                    value={value}
                    onChange={e => handleFieldChange('companyName', e.target.value)}
                    error={!!error}
                    helperText={
                      error?.message && (
                        <SwagupTypography variant="body3RegularInter" className={classes.errorText}>
                          {error.message}
                        </SwagupTypography>
                      )
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <label htmlFor="contact-number">
                <SwagupTypography variant="body3RegularInter" className={classes.labelStyle}>
                  Contact number
                </SwagupTypography>
              </label>
              <PhoneField
                className={classes.formInput}
                control={control}
                name="phone"
                variant="outlined"
                id="contact-number"
                defaultValue=""
                onChange={e => handleFieldChange('contactNumber', e.target.value)}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2} className={classes.transitionContainer}>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}
            >
              <PersonIcon style={{ color: shoppingExperienceColors.primary, fontSize: '20px' }} />
              <SwagupTypography variant="body2RegularInter">{getValues('fullName')}</SwagupTypography>
            </Grid>
            <Grid
              item
              xs={12}
              md={12}
              style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}
            >
              <BusinessIcon style={{ color: shoppingExperienceColors.primary, fontSize: '20px' }} />
              <SwagupTypography variant="body2RegularInter">{getValues('companyName')}</SwagupTypography>
            </Grid>
            {getValues('phone') && (
              <Grid
                item
                xs={12}
                md={12}
                style={{ display: 'flex', justifyContent: 'start', alignItems: 'center', gap: '10px' }}
              >
                <ContactPhoneIcon style={{ color: shoppingExperienceColors.primary, fontSize: '20px' }} />
                <SwagupTypography variant="body2RegularInter">{getValues('phone')}</SwagupTypography>
              </Grid>
            )}
          </Grid>
        )}
      </AccordionDetails>
      <Divider style={{ marginTop: '20px' }} />
    </Accordion>
  );
});

export default ContactDetails;
