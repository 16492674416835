import { createContext, React, useContext, useEffect, useState } from 'react';

const FilterContext = createContext();

const FilterContextProvider = ({ children }) => {
  const [appliedFilters, setAppliedFilter] = useState([]);
  const [filterData, setFilterData] = useState();
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [delayLoading, setDelayLoading] = useState(false);
  const [retainAppliedFilters, setRetainAppliedFilters] = useState(false);

  // useEffect(() => {
  //   if (retainAppliedFilters) setRetainAppliedFilters(false);
  // }, [retainAppliedFilters]);

  return (
    <FilterContext.Provider
      value={{
        appliedFilters,
        setAppliedFilter,
        filterData,
        setFilterData,
        openFilterDrawer,
        setOpenFilterDrawer,
        delayLoading,
        setDelayLoading,
        retainAppliedFilters,
        setRetainAppliedFilters
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

const useFilterContext = () => {
  return useContext(FilterContext);
};

export { FilterContextProvider, useFilterContext };
