import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, FormControlLabel, Grid, makeStyles } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { CustomTooltip } from '../../products/commonProductsElements';
import styles from './styles/sendSwagConfiguration';
import Img from '../../shared/Img';
import { ellipsisStyles } from '../../shared/styles/commonStyles';
import { SmallSwitch } from '../../pages/orders/requested/shipments/sendSwagFlow/common';
import UsOnlyWarningBox from '../../global/UsOnlyWarningBox';
import USShippingOnlyTag from '../../global/USShippingOnlyTag';

const useStyles = makeStyles(styles);

const IntegrationItem = ({ title, icon, small, selected, usOnly, product, ellipsisStylesStyle, long, color, size }) => {
  const classes = useStyles({ selected, product });
  const longSize = long ? 9 : 8;
  return (
    <Grid container justifyContent="center" className={classes.integrationSection}>
      <Grid item xs={small ? 6 : longSize}>
        <Box className={classes.integrationSelectionItem}>
          <Grid container alignItems="center" spacing={6}>
            <Grid item>{icon}</Grid>
            <Grid item xs={small ? 6 : 8} style={{ textAlign: 'left', position: 'relative' }}>
              <CustomTooltip
                title={title}
                arrow
                placement="top-start"
                disableHoverListener={!ellipsisStylesStyle || !title || title.length < 17}
              >
                <Typography
                  variant="subtitle2SemiBoldInter"
                  className={classes.integrationItemTitle}
                  style={{ marginBottom: product ? 12 : 0 }}
                >
                  {title}
                </Typography>
              </CustomTooltip>

              <Box className={classes.productTag}>
                {color} / {size}
              </Box>
            </Grid>
            {product && usOnly && (
              <USShippingOnlyTag
                small
                infoIcon
                style={{ position: 'absolute', right: 8, top: 2, maxWidth: 132 }}
                iconStyle={{ marginBottom: -3, marginRight: 3 }}
              />
            )}
            <Grid item style={{ paddingRight: 0, paddingLeft: 0 }}>
              {!product && <ChevronRight style={{ color: '#131415', marginTop: 4 }} />}
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

const ShippingMethodCard = ({ shippingMethod, deliveryMethods, handleChange }) => {
  const classes = useStyles();

  return (
    <Box className={classes.shippingMethod}>
      <Box>
        <Typography variant="h5SemiBoldInter">{shippingMethod.title}</Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item xs={6} style={{ paddingLeft: 12 }}>
            <FormControlLabel
              labelPlacement="end"
              control={
                <SmallSwitch
                  checked={deliveryMethods[shippingMethod.type] === 'Standard'}
                  onChange={() => handleChange('Standard')}
                  color="primary"
                  name="checkedBtn"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Box className={classes.shippingMethodLabel}>
                  <Typography variant="h6SemiBoldInter">Standard Shipping</Typography>
                  <Typography variant="body3RegularInter">{`${shippingMethod.standardBD} Business Days`}</Typography>
                </Box>
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControlLabel
              labelPlacement="end"
              control={
                <SmallSwitch
                  checked={deliveryMethods[shippingMethod.type] === 'Express'}
                  onChange={() => handleChange('Express')}
                  color="primary"
                  name="checkedBtn"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              }
              label={
                <Box className={classes.shippingMethodLabel}>
                  <Typography variant="h6SemiBoldInter">Rush Shipping</Typography>
                  <Typography variant="body3RegularInter">{`${shippingMethod.rushBD} Business Days`}</Typography>
                </Box>
              }
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const deliveryMethods = [
  { id: 1, title: 'US Shipments', type: 'domestic', standardBD: '2-7', rushBD: '1-2' },
  { id: 2, title: 'International', type: 'international', standardBD: '5-12', rushBD: '3-7' }
];

const SendSwagConfigurationDM = ({ action, onActionUpdate }) => {
  const selectedProduct = action.settings?.product || {};
  const selectedDeliveryMethods = action.settings?.deliveryMethods || {};

  const classes = useStyles();

  return (
    <Box>
      <Typography variant="body3SemiBoldInter" className={classes.actionTitleSmall}>
        Product selected for this automation
      </Typography>
      {selectedProduct.usOnly && (
        <Grid container justifyContent="center">
          <Grid item>
            <UsOnlyWarningBox names={[selectedProduct.name]} maxWidth={540} />
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center">
        <IntegrationItem
          key={selectedProduct.id}
          title={selectedProduct.name}
          icon={
            <Box className={classes.integrationProductImageWrapper}>
              <Img
                src={selectedProduct.image || '/images/public/nopic.jpg'}
                alt={selectedProduct.name}
                className={classes.integrationImage}
              />
            </Box>
          }
          ellipsisStylesStyle={ellipsisStyles}
          product
          long
          color={selectedProduct.color}
          size={selectedProduct.sizeName}
          usOnly={selectedProduct.usOnly}
        />
      </Grid>
      <Box>
        <Typography variant="body3SemiBoldInter" className={classes.actionSubTitle}>
          Now pick your Shipping Methods
        </Typography>
        <Grid container justifyContent="center">
          <Grid item xs={10}>
            <Box>
              {deliveryMethods.map(dm => (
                <ShippingMethodCard
                  key={dm.id}
                  shippingMethod={dm}
                  deliveryMethods={selectedDeliveryMethods}
                  handleChange={selectedDM =>
                    onActionUpdate({
                      ...action,
                      settings: {
                        ...action.settings,
                        deliveryMethods: { ...action.settings.deliveryMethods, [dm.type]: selectedDM }
                      }
                    })
                  }
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SendSwagConfigurationDM;
