import React from 'react';
import { Drawer, Grid } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import SearchBar from '../../shared/ui/searchBar';

const MobileSearch = ({ open, onClose }) => {
  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <Grid container style={{ padding: '20px 0px' }}>
        <Grid item container alignItems="center" style={{ paddingLeft: '20px', gap: '30px' }}>
          <SearchBar onClose={onClose} />
          <span onClick={onClose}>
            <Typography variant="body3SemiBoldInter" style={{ paddingRight: '20px' }}>
              Cancel
            </Typography>
          </span>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default MobileSearch;
