import React, { useEffect, useState } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Typography as SwagupTypography } from '@swagup-com/components';
import StyledTextField from './styledTextField';
import { shoppingExperienceColors } from '../constants';
import { shoppingExperienceEmailValidation } from '../../../actions';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';

const ContactEmail = ({ onVerify, onEmailChange, iconColor = shoppingExperienceColors.primary, onOpen }) => {
  const { isAuthenticated } = useAuth();
  const { control, setError, clearErrors } = useForm();
  const [isNewUser, setIsNewUser] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const [responseMessge, setResponseMessage] = useState('');

  const validateEmail = (email, isValid) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!regex.test(String(email).toLowerCase())) {
      return (
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.error }}>
          Incorrect email format.
        </SwagupTypography>
      );
    }
    if (isValid === false) {
      return (
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.error }}>
          Incorrect email format. Must use company email.
        </SwagupTypography>
      );
    }

    return true;
  };

  const handleBlur = async email => {
    if (email != '') {
      const response = await shoppingExperienceEmailValidation(email);
      setIsVerified(response?.data?.valid);
      setIsNewUser(!response?.data?.account_exists);
      const validationMessage = validateEmail(email, response?.data?.valid);
      if (!response?.data?.valid) {
        setResponseMessage(response?.data?.msg);
      }
      if (validationMessage !== true) {
        setError('email', { type: 'manual', message: validationMessage });
        onVerify(false);
      } else {
        clearErrors('email');
        onVerify(true);
      }
    }
  };

  const handleChange = value => {
    if (value === '') {
      setIsVerified(false);
      setIsNewUser(false);
      onVerify(false);
      clearErrors('email');
      setResponseMessage('');
    } else {
      const validationMessage = validateEmail(value, true);
      if (validationMessage !== true) {
        setError('email', { type: 'manual', message: validationMessage });
        onVerify(false);
      } else {
        clearErrors('email');
      }
    }
    onEmailChange(value);
  };

  const showMessage = email => {
    if (!isAuthenticated && email === '') {
      return null;
    }
    if (!isAuthenticated && email != '' && isVerified && isNewUser) {
      onVerify(true);
      return (
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.textPrimary }}>
          Looks like you are new here - Welcome!
        </SwagupTypography>
      );
    }
    if (!isAuthenticated && email !== '' && isVerified && !isNewUser) {
      onVerify(false);
      return (
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.textPrimary }}>
          Welcome back!{' '}
          <span style={{ color: shoppingExperienceColors.primary, cursor: 'pointer' }} onClick={onOpen}>
            {' '}
            Sign In
          </span>{' '}
          to see most accurate pricing & reward points.
        </SwagupTypography>
      );
    }
    if (!isAuthenticated && email !== '' && !isVerified) {
      <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.textPrimary }}>
        {responseMessge}
      </SwagupTypography>;
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <Controller
        name="email"
        control={control}
        defaultValue=""
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <StyledTextField
            width="100%"
            type="email"
            placeholder="Company Email*"
            value={value}
            onChange={e => {
              onChange(e);
              handleChange(e.target.value);
            }}
            onBlur={() => {
              onBlur();
              handleBlur(value);
            }}
            error={!!error}
            helperText={error?.message || showMessage(value)}
            InputProps={{
              endAdornment: isVerified && (
                <InputAdornment position="end">
                  <CheckCircleIcon style={{ color: iconColor }} />
                </InputAdornment>
              ),
              style: {
                borderColor: error ? shoppingExperienceColors.error : shoppingExperienceColors.borderLineGray
              }
            }}
          />
        )}
      />
    </Grid>
  );
};

export default ContactEmail;
