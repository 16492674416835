import { shoppingExperienceColors } from '../../shared/constants';

const filterStyles = theme => ({
  container: {
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    padding: '20px 16px 16px 20px',
    maxHeight: '300px',
    overflowY: 'auto'
  },
  showMore: {
    display: 'flex',
    marginTop: 12,
    alignItems: 'center',
    cursor: 'pointer'
  },
  filteringItemsRoot: {
    width: '100%',
    padding: 16,
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important'
    }
  },
  accordionSummaryStyle: {
    padding: 0,
    '& .MuiAccordionSummary-content': {
      margin: 0
    },
    '&.MuiAccordionSummary-content': {
      margin: 0
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      minHeight: 0
    }
  },
  accordionDetailsStyle: {
    display: 'contents'
  },
  accordionDetailsCardStyle: {
    display: 'ruby'
  },
  textField: {
    marginTop: 8,
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: 4
    }
  },
  priceHypher: {
    marginTop: 20
  }
});

export default filterStyles;
