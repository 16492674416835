/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { CheckMarkIcon } from '../icons';

const ColorSelection = props => {
  const { isLoading, selectedColor, setSelectedColor, color = 'white', extraData = {}, multiSelect = false } = props;
  const isWhite = ['#ffffff', '#FFFFFF', 'white', 'White', 'FFFFFF'];
  const isSelected =
    selectedColor === (extraData?.colorName || color) || (multiSelect && selectedColor.includes(color));

  const getHexCode = colorHex => {
    return colorHex.indexOf('#') !== 0 ? `#${colorHex}` : colorHex;
  };
  return isLoading ? (
    <Skeleton variant="circle" width={20} height={20} />
  ) : (
    <div
      key={color}
      onClick={() => !multiSelect && setSelectedColor(extraData?.colorName || color)}
      style={{
        marginRight: 30,
        width: 30,
        height: 30,
        borderRadius: '50%',
        padding: multiSelect
          ? selectedColor.includes(color)
            ? '4px'
            : '0'
          : selectedColor === (extraData?.colorName || color)
          ? '4px'
          : '0',
        border: `2px solid ${
          selectedColor.includes(color) || selectedColor === (extraData?.colorName || color)
            ? isWhite.includes(color) || isWhite.includes(extraData?.colorName || color)
              ? '#808080'
              : getHexCode(color)
            : getHexCode(color)
        }`,
        boxSizing: 'border-box',
        cursor: 'pointer',
        transition: 'border 0.3s, padding 0.3s'
      }}
    >
      <div
        style={{
          backgroundColor: getHexCode(color),
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          paddingTop: 15,
          paddingLeft: 10,
          border: isWhite.includes(color) && '1px solid #808080'
        }}
      >
        {isSelected && <CheckMarkIcon color={isWhite.includes(color) ? 'black' : 'white'} />}
      </div>
    </div>
  );
};

export default ColorSelection;
