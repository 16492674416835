import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Drawer } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styles from './common.styles';
import SearchBar from './searchBar';
import { SwagUpLogoMobileIcon } from '../icons';
import { NavigateTo } from './common';
import { toggleDrawer, setSearchTerm } from '../../redux/searchReducer';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(styles);

const TopSearchDrawer = () => {
  const classes = useStyles();
  const { isDesktop, isTablet, isLaptop } = useResponsive();
  const history = useHistory();
  const dispatch = useDispatch();
  const open = useSelector(state => state.search?.isDrawerOpen);

  const handleClose = () => {
    dispatch(toggleDrawer(false));
  };

  useEffect(() => {
    const unlisten = history.listen(newLocation => {
      if (newLocation.pathname !== '/search') {
        dispatch(setSearchTerm(''));
      }
    });
    return () => {
      unlisten();
    };
  }, [history, dispatch]);

  return (
    <Drawer anchor="top" open={open} onClose={handleClose} classes={{ paper: classes.drawerPaper }}>
      <Grid className={classes.drawerContent}>
        <Grid item onClick={() => NavigateTo(history, '/')} style={{ cursor: 'pointer' }}>
          <SwagUpLogoMobileIcon />
        </Grid>
        <Grid item style={{ width: isDesktop ? '864px' : isTablet ? '500px' : isLaptop ? '614px' : '100%' }}>
          <SearchBar onClose={handleClose} />
        </Grid>
        <Grid item onClick={handleClose} style={{ cursor: 'pointer' }}>
          <SwagupTypography variant="buttonMediumInter">Cancel</SwagupTypography>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default TopSearchDrawer;
