import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { useResponsive } from '../../hooks';
import DefaultBannerImageDesktop from '../../assets/images/collections/collections-banner-image.png';
import DefaultBannerImageTablet from '../../assets/images/collections/collections-banner-image-tablet.png';
import DefaultBannerImageMobile from '../../assets/images/collections/collections-banner-image-mobile.png';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: ({ isMobile, isTablet, isLaptop }) => {
      if (isMobile) return '0px';
      return '0px 40px';
    }
  },
  bannerImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: ({ isMobile }) => {
      if (isMobile) return '0px';
      return '5px';
    }
  }
}));

const CollectionsBannerImage = ({ imageUrl }) => {
  const { isMobile, isTablet, isLaptop } = useResponsive();
  const classes = useStyles({ isMobile, isTablet, isLaptop });

  return (
    <Grid container className={classes.container}>
      <img src={imageUrl} alt="Collections Banner" className={classes.bannerImage} />
    </Grid>
  );
};

export default CollectionsBannerImage;
