/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import sumBy from 'lodash/sumBy';
import { makeStyles, Grid, Box, IconButton } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import clsx from 'clsx';
import { round } from 'lodash';
import {
  AccountBalance,
  ArrowDropDown,
  ArrowDropUp,
  ExpandLess,
  ExpandMore,
  InfoOutlined,
  Receipt
} from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Skeleton } from '@material-ui/lab';
import { cardImg, getShipmentGroupsInfo, imageSrcSet, moneyStr, sumByQuantity } from '../../../../../../helpers/utils';
import { opportunityStatus, paymentMethods, productionTime as productionSpeed } from '../../../../../../apis/constants';
import { useOrder } from '../../OrderContext';
import { cannotContinueMessage } from '../../common/utilsOrder';
import { ellipsisStyles } from '../../../../../shared/styles/commonStyles';
import ArrowTooltip from '../../common/Tooltip';
import AppliedMembershipPanel from '../../../../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../../../global/StrikeOutCost';
import { SwagCardModal } from './common';
import useIsEnterpriseFlowActive from '../../../../../../hooks/useIsEnterpriseFlowActive';
import { useMembership } from '../../../../../../contexts/membershipContext';
import { changeColorLightness } from '../../../../../shared/styles/utils';
import { CustomTooltip364 } from '../../../../../products/commonProductsElements';

const commonSummaryText = { paddingTop: 10, color: '#4A4F54' };
const commonTotalItem = { marginTop: 20, borderTop: '1px solid #E5E7E8' };

const useStyles = makeStyles(() => ({
  summaryLabel: commonSummaryText,
  productLabel: { ...commonSummaryText, ...ellipsisStyles },
  rushLabel: { ...commonSummaryText },
  totalValue: { ...commonSummaryText, paddingTop: 20, color: '#000000' },
  totalLabel: { paddingTop: 20, paddingBottom: 20, color: '#000000' },
  subTotalItem: { ...commonTotalItem, paddingTop: 10 },
  totalItem: { ...commonTotalItem, borderBottom: '1px solid #E5E7E8', borderTop: 'none' },
  continueButton: { height: 56, fontSize: 16 },
  orderSummaryContainer: {
    border: '1px solid #EBEDF0',
    padding: '16px 24px',
    borderRadius: 15
  },
  paymentWrapper: {
    width: '100%',
    padding: '12px 0px'
  },
  heading: {
    padding: '24px 0px'
  },
  swagCard: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: 20
  },
  newFunds: {
    color: '#9846DD'
  },
  paymentMethodsHeader: {
    position: 'relative'
  },
  paymentMethodsHeaderContent: {
    position: 'absolute',
    width: '100%'
  },
  iconButton: {
    width: 24,
    height: 24,
    padding: 2
  },
  shippingInfoButton: {
    marginLeft: 5,
    color: '#3577d4',
    '&:hover': {
      color: changeColorLightness('#3577d4'),
      backgroundColor: 'unset'
    }
  },
  swagCards: { paddingTop: 32 }
}));

const ProductSummaryItem = ({ product, classes }) => {
  const showRushItem = product.production_time === productionSpeed.rush;
  const totalQty = sumByQuantity(product.sizes);
  const subtotal = totalQty * +(product.price ?? 0);
  const rushFee = totalQty * +(product.rush_fee ?? 0);
  const productTitle = product.product.name;

  const capitalizeFirstLetter = title => {
    return title.charAt(0).toUpperCase() + title.slice(1);
  };

  return (
    <Grid container>
      <Grid item xs={8} align="left">
        <Typography variant="body3RegularInter" className={classes.productLabel}>
          {capitalizeFirstLetter(productTitle)}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.rushLabel}>
            Rush Production
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} align="right">
        <Typography variant="body3RegularInter" className={classes.summaryLabel}>
          {moneyStr(subtotal)}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.summaryLabel}>
            {moneyStr(rushFee)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const OrderSummaryItem = ({ label, value, tooltip, isSubtotal, previous, isTotal, isFunds, classes, isLoading }) => (
  <Grid container className={clsx({ [classes.totalItem]: isTotal }, { [classes.subTotalItem]: isSubtotal })}>
    <Grid item xs align="left">
      <Typography
        variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
        className={clsx(classes.summaryLabel, { [classes.totalLabel]: isTotal }, { [classes.newFunds]: isFunds })}
      >
        <Grid container alignItems="center">
          <Grid item>
            <span>{label}</span>
          </Grid>
          <Grid item>
            {tooltip && (
              <CustomTooltip364 title="If you are a tax exempt company or know exactly where each product will ship, please contact us at billing@swagup.com for more accurate pricing">
                <InfoOutlined style={{ fontSize: 15, marginBottom: -3 }} className={classes.shippingInfoButton} />
              </CustomTooltip364>
            )}
          </Grid>
        </Grid>
      </Typography>
    </Grid>
    <Grid item align="right">
      {isLoading ? (
        <Skeleton style={{ width: 92, marginTop: 12, display: 'inline-block' }} />
      ) : (
        <Typography
          variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
          className={clsx(classes.summaryLabel, { [classes.totalValue]: isTotal }, { [classes.newFunds]: isFunds })}
        >
          <StrikeOutText value={previous} fontSize={isTotal ? 16 : 12} color="#898C91" marginRight={8} />
          {moneyStr(value)}
        </Typography>
      )}
    </Grid>
  </Grid>
);

const OrderReviewSummary = ({
  handleContinue,
  isPaymentMethod,
  inputValue,
  disableContinue,
  loading,
  isCreditsLoading
}) => {
  const classes = useStyles();
  const order = useOrder();
  const products = order?.products ?? [];

  const subtotal = sumBy(order.products, product => Number(product.price) * sumByQuantity(product.sizes));
  const subtotalBeforeDiscount = sumBy(
    order.products,
    product => Number(product.price_without_discount || 0) * sumByQuantity(product.sizes)
  );
  const shippingInfo = getShipmentGroupsInfo(order.shipmentGroups);
  const shipping = shippingInfo.international.price + shippingInfo.domestic.price;
  const shippingBeforeDiscount =
    shippingInfo.international.price_without_discount + shippingInfo.domestic.price_without_discount;
  const warehouse = order.total_warehouses;
  const warehouseBeforeDiscount = order.total_warehouses_before_discount;
  const estimatedTax = Number(order.tax);
  const rushFee = sumBy(order.products, product => Number(+product.rush_fee || 0) * sumByQuantity(product.sizes));
  const rushFeeBeforeDiscount = sumBy(
    order.products,
    product => Number(+product.rush_fee_without_discount || 0) * sumByQuantity(product.sizes)
  );
  // const total = subtotal + rushFee + shipping + warehouse + estimatedTax;
  const addNewSwagFunds = order.funds; // || Number(order?.inputFunds || 0) || shipping;
  const moreCreditsAdded = addNewSwagFunds > shipping;
  const shippingToAccount = addNewSwagFunds; // moreCreditsAdded ? addNewSwagFunds : shipping;
  const total = subtotal + rushFee + warehouse + estimatedTax + addNewSwagFunds;

  useEffect(() => {
    order.setCurrentTotal(total);
  }, [total]);

  // const shippingCutoffHour = useShippingCutoffHour();
  // const dispatch = useDispatch();
  // const { data: sizes } = useSizes();
  // const history = useHistory();
  // const { id } = useParams();
  // const handleContinue = () => {
  //   dispatch(setReorderProducts(order.products.map(buildProofAlikeObject(sizes))));
  //   dispatch(createShipment(sizes, getDirectoryOrders(order), shippingCutoffHour));
  //   history.push(`/orders-requested/${id}/order-storage`);
  // };

  const { productsWithInactiveSizes } = order;
  const orderHasInactiveSizes = productsWithInactiveSizes.length > 0;
  const orderHasProductsUnapproved = ![opportunityStatus.proofsApproved, opportunityStatus.proofsApprovedOld].includes(
    order.status
  );
  const orderInProductionRequest = [opportunityStatus.finalizingProofs, opportunityStatus.finalizingProofsOld].includes(
    order.status
  );

  const hasPricingError = order.pricingError?.hasError;

  const title = cannotContinueMessage(
    orderHasProductsUnapproved,
    orderInProductionRequest,
    orderHasInactiveSizes,
    hasPricingError
  );

  const multipleTotals = React.useMemo(
    () => ({
      shipping_discount: shipping || shippingBeforeDiscount,
      product_discount: subtotal || subtotalBeforeDiscount,
      storage_discount: warehouse || warehouseBeforeDiscount,
      rush_production_discount: rushFee || rushFeeBeforeDiscount
    }),
    [
      shipping,
      shippingBeforeDiscount,
      subtotal,
      subtotalBeforeDiscount,
      warehouse,
      warehouseBeforeDiscount,
      rushFee,
      rushFeeBeforeDiscount
    ]
  );
  const exceeds = React.useMemo(() => (moreCreditsAdded ? shippingToAccount - shipping : 0), [
    moreCreditsAdded,
    shipping,
    shippingToAccount
  ]);
  const { totalBeforeDiscount, multipleDiscounts } = useDiscountsAndRewards(
    0,
    '',
    multipleTotals,
    undefined,
    undefined,
    undefined,
    exceeds,
    'new'
  );

  const summaryRows = [
    { key: 1, label: 'Subtotal', value: subtotal, beforeDiscountValue: multipleDiscounts?.product_discount },
    {
      key: 2,
      label: 'Rush Production',
      value: rushFee,
      beforeDiscountValue: multipleDiscounts?.rush_production_discount
    },
    {
      key: 3,
      label: 'Funds for Future Shipments',
      value: shippingToAccount,
      beforeDiscountValue: moreCreditsAdded ? undefined : multipleDiscounts?.shipping_discount
    },
    { key: 4, label: 'Storage', value: warehouse, beforeDiscountValue: multipleDiscounts?.storage_discount },
    {
      key: 5,
      label: 'Estimated taxes & fees',
      value: estimatedTax,
      tooltip:
        'If you are a tax exempt company or know exactly where each product will ship, please contact us at billing@swagup.com for more accurate pricing'
    },
    // {
    //   key: 6,
    //   label: 'Funds added to Swag Card',
    //   value: addNewSwagFunds
    // },
    {
      key: 6,
      label: 'Total',
      value: total,
      beforeDiscountValue: totalBeforeDiscount > 0 ? totalBeforeDiscount + estimatedTax + addNewSwagFunds : 0
    }
  ];

  const swagCardPayment = round(parseFloat(inputValue || 0), 2);
  const creditCardPayment = round(parseFloat(total || 0) - swagCardPayment, 2);

  // * Below are the states for SwagCardModal component to call when the full payment is made thru Swag Card

  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => {
    setModalOpen(false);
  };

  const handlePayModal = () => {
    const maxCreditPayment = order?.currentTotal - order?.funds;
    const isSame = round(inputValue, 2) === round(maxCreditPayment, 2);

    if (isSame) {
      setModalOpen(true);
    }
  };

  const { allowToPayWithCredits } = useFlags();

  const FinalPaymentItem = () => {
    const isEnterpriseFlowActive = useIsEnterpriseFlowActive();

    const { company } = useMembership();
    const { payment_profile: defaultProfile } = company || {};
    const paymentProfileCard = defaultProfile?.credit_card || {};
    const currentPaymentMethod = isEnterpriseFlowActive ? paymentMethods.ach : order.paymentMethod;
    const [isOpen, setIsOpen] = useState(true);
    return (
      <Box className={classes.paymentWrapper}>
        <Box className={classes.paymentMethodsHeader}>
          <Grid container className={classes.paymentMethodsHeaderContent} justifyContent="flex-end" alignItems="center">
            <Grid item xs>
              <Typography variant="body4RegularInter" style={{ color: '#4A505C' }}>
                Payment Method{allowToPayWithCredits ? 's' : ''}
              </Typography>
            </Grid>
            <Grid item>
              <IconButton className={classes.iconButton} onClick={() => setIsOpen(!isOpen)}>
                {isOpen ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Grid>
          </Grid>
        </Box>
        {isOpen && (
          <Box className={classes.swagCards}>
            {allowToPayWithCredits && (
              <Grid container alignItems="center" className={classes.swagCard}>
                <Grid item xs={8}>
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="body3RegularInter" style={{ color: '#4A505C' }}>
                        Payment with Swag Card
                      </Typography>
                    </Grid>
                    <Grid item>
                      <img
                        srcSet={imageSrcSet('/images/orders/swag_credit_card.png')}
                        alt="credit-card"
                        style={{ width: 32, marginBottom: -6, marginRight: -4 }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs>
                  <Typography variant="body3SemiBoldInter" style={{ color: '#45AF5F', textAlign: 'right' }}>
                    {moneyStr(swagCardPayment > 0 ? swagCardPayment : 0)}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              alignItems="center"
              className={classes.swagCard}
              style={{ borderBottom: '1px solid #E5E7E8' }}
            >
              <Grid item xs={8}>
                <Grid container alignItems="center">
                  <Grid item xs>
                    <Typography variant="body3RegularInter" style={{ color: '#4A505C' }}>
                      Payment with {currentPaymentMethod}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {currentPaymentMethod === 'Credit Card' ? (
                      <img
                        src={cardImg(paymentProfileCard.card_type)}
                        alt={paymentProfileCard.Visa}
                        style={{ width: 24, height: 16, marginBottom: -2 }}
                      />
                    ) : (
                      <div>
                        {currentPaymentMethod === 'ACH' ? (
                          <AccountBalance
                            style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }}
                          />
                        ) : (
                          <Receipt style={{ width: 24, height: 16, marginBottom: -2, color: 'rgb(74, 80, 92)' }} />
                        )}
                      </div>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs>
                <Typography variant="body3SemiBoldInter" style={{ color: '#4A505C', textAlign: 'right' }}>
                  {moneyStr(creditCardPayment > 0 ? creditCardPayment : 0)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Grid container className={classes.orderSummaryContainer}>
      <Grid item>
        <Typography variant="h6SemiBoldInter">Order Summary</Typography>
        <Typography variant="body2MediumInter" style={{ marginTop: 12 }}>
          Products
        </Typography>
      </Grid>
      {products.map(product => (
        <ProductSummaryItem key={product.id} product={product} classes={classes} />
      ))}
      {summaryRows.map(row =>
        row.key === 6 ? (
          <>
            <AppliedMembershipPanel
              type="product"
              total={subtotal}
              multipleTotals={multipleTotals}
              swagCardPayment={swagCardPayment > 0 ? swagCardPayment - estimatedTax : 0}
              exceeds={exceeds}
              fullWidth
              style={{ marginTop: 24, width: '100%', paddingTop: 24, borderTop: '1px solid #E5E7E8' }}
            />
            <OrderSummaryItem
              key={row.key}
              label={row.label}
              value={row.value}
              previous={row.beforeDiscountValue}
              isSubtotal={row.label === 'Subtotal'}
              isTotal={row.label === 'Total'}
              classes={classes}
            />
          </>
        ) : (
          <OrderSummaryItem
            key={row.key}
            label={row.label}
            value={row.value}
            tooltip={row.tooltip}
            previous={row.beforeDiscountValue}
            isSubtotal={row.label === 'Subtotal'}
            isTotal={row.label === 'Total'}
            classes={classes}
            isLoading={row.key === 3 && isCreditsLoading}
            addNewSwagFunds={row.key === 6 ? addNewSwagFunds : 0}
          />
        )
      )}

      {isPaymentMethod ? (
        <>
          <FinalPaymentItem />
          <Grid container style={{ marginTop: 30 }}>
            <ArrowTooltip title={title} fullWidth variant="primary">
              <Button
                size="small"
                variant="primary"
                fullWidth
                onClick={
                  round(inputValue, 2) === round(order?.currentTotal - order?.funds, 2)
                    ? handlePayModal
                    : handleContinue
                }
                disabled={disableContinue || orderHasProductsUnapproved || orderHasInactiveSizes || hasPricingError}
                className={classes.continueButton}
                loading={loading}
                id="pay-order"
              >
                Pay and Place Order
              </Button>
              <SwagCardModal
                open={modalOpen}
                handleClose={handleClose}
                handleAccept={handleContinue}
                inputValue={inputValue}
              />
            </ArrowTooltip>
          </Grid>
        </>
      ) : (
        <>
          <Grid container style={{ marginTop: 30 }}>
            <ArrowTooltip title={title} fullWidth variant="primary">
              <Button
                size="small"
                variant="primary"
                fullWidth
                onClick={handleContinue}
                disabled={disableContinue || orderHasProductsUnapproved || orderHasInactiveSizes || hasPricingError}
                className={classes.continueButton}
              >
                Continue
              </Button>
            </ArrowTooltip>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default OrderReviewSummary;
