import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { TextField, makeStyles } from '@material-ui/core';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import dayjs from 'dayjs';

const useStyles = makeStyles(theme => ({
  dateInput: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100% !important'
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        borderRadius: '5px !important'
      }
    }
  }
}));

const CheckoutFormDatePicker = ({ setDateValue, initialDate }) => {
  const classes = useStyles();
  const { control, setValue } = useForm();

  useEffect(() => {
    if (!initialDate) {
      const defaultDate = dayjs()
        .add(30, 'day')
        .toDate();
      setValue('date', defaultDate);
      setDateValue(defaultDate);
    } else {
      setValue('date', initialDate);
      setDateValue(initialDate);
    }
  }, [setValue, initialDate]);

  const handleDateChange = date => {
    setDateValue(date);
    setValue('date', date);
  };

  return (
    <Controller
      name="date"
      control={control}
      render={({ field }) => (
        <MuiDatePicker
          {...field}
          value={field.value}
          onChange={handleDateChange}
          disablePast
          shouldDisableDate={date => {
            const today = dayjs().startOf('day');
            return dayjs(date).isSame(today, 'day');
          }}
          TextFieldComponent={props => (
            <TextField
              {...props}
              variant="outlined"
              fullWidth
              className={classes.dateInput}
              InputProps={{
                endAdornment: <CalendarTodayOutlinedIcon style={{ cursor: 'pointer' }} />,
                style: { fontFamily: 'Inter' }
              }}
            />
          )}
          format="MMMM Do, YYYY"
          autoOk
        />
      )}
    />
  );
};

export default CheckoutFormDatePicker;
