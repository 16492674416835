import React, { useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import MembershipHand from '../../assets/images/products/membership_hand.png';
import useResponsive from '../../hooks/useResponsive';
import { getBreadCrumbs } from '../../shared/helper';
import Banner from '../Banner/banner';
import Breadcrumb from '../Breadcrumb/breadcrumb';
import TitleHeader from '../TitleHeader/titleHeader';
import styles from './collections.styles';
import CollectionsCard from './collectionsCard';
import useMembershipCheck from '../../../hooks/useMembershipCheck';

const useStyles = makeStyles(styles);

const CollectionsLisiting = ({
  selectedCollectionId = '',
  pageTitle = '',
  currentPage,
  setCurrentPage,
  groupedFilter,
  setGroupedFilter
}) => {
  const classes = useStyles();
  const { isMobile } = useResponsive();
  const PATHS = getBreadCrumbs();
  const [totalProducts, setTotalProducts] = useState(0);
  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck?.isPlatinum;

  return (
    <Grid container className={classes.collectionsLayout}>
      <Breadcrumb routes={PATHS} />
      <TitleHeader
        title={pageTitle}
        showFilter
        totalProducts={totalProducts}
        groupedFilter={groupedFilter}
        setGroupedFilter={setGroupedFilter}
        selectedCollectionId={selectedCollectionId}
        isCollectionPage
      />
      <CollectionsCard
        selectedCollectionId={selectedCollectionId}
        setTotalProducts={setTotalProducts}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        groupedFilter={groupedFilter}
      />
      {!PlatinumMembershipTier && (
        <Banner
          image={`${MembershipHand}`}
          title="Members get free shipping on orders $50+"
          mobileTitle="Upgrade to Platinum today and get 15% OFF on your next order"
          subtitle="Become a SwagUp Platinum Member for fast free shipping on orders $50+ Join us or Sign-in"
          buttonLabel="Upgrade to Platinum"
          buttonLink="https://www.swagup.com/membership"
          highlightedWord="Platinum"
          isMobile={isMobile}
        />
      )}
    </Grid>
  );
};

export default CollectionsLisiting;
