/* eslint-disable no-param-reassign */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-underscore-dangle */
/* eslint-disable new-cap */
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { round } from 'lodash';
import {
  CART_TYPES,
  defaultQtyForBulk,
  defaultQtyForPack,
  MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK
} from '../utils/constants';
import { fetchDistributorCatalogProductsForCart } from '../apis';
import { getPriceForQuantity } from '../utils/getPriceForQuantity';
import useDiscountPrice from '../hooks/useDiscountPrice';
import { useAuth } from '../../components/global/Authentication/AuthProvider';
import { GetMinimumPrice } from '../shared/ui/common';

const CartContext = React.createContext();

const CartContextProvider = ({ children }) => {
  const [cartItems, setCartItems] = useState([]);
  const [apiData, setApiData] = useState([]);
  const [cartProducts, setProducts] = useState([]);
  const [confirmationCartItems, setConfirmationCartItems] = useState([]);
  const [qtyForPack, setQtyForPack] = useState(defaultQtyForPack);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPriceByTotalQtyPacksWithoutDiscount, setTotalPriceByTotalQtyPacksWithoutDiscount] = useState(0);
  const [totalPriceByTotalQtyPacksWithDiscount, setTotalPriceByTotalQtyPacksWithDiscount] = useState(0);
  const [totalPricePerPackWithDiscount, setTotalPricePerPackWithDiscount] = useState();
  const [totalPricePerPackWithoutDiscount, setTotalPricePerPackWithoutDiscount] = useState();
  const [totalEstimationWithDiscount, setTotalEstimationWithDiscount] = useState(0);
  const [totalEstimationWithoutDiscount, setTotalEstimationWithoutDiscount] = useState(0);
  const [confirmationTotalEstimation, setConfirmationTotalEstimation] = useState(0);

  const { isAuthenticated } = useAuth();

  const { getPriceByShippingAndMarkup, getDiscountPrice, productDiscount } = useDiscountPrice();

  const [isPack, setIsPack] = useState(false);

  const queryClient = useQueryClient();

  const productCodes = useMemo(() => cartItems.map(cart => cart.productCode), [cartItems]);

  const isHazmatExists = useMemo(() => {
    return isPack && cartProducts.findIndex(cartProduct => cartProduct.isHazmat === true) !== -1;
  }, [isPack, cartProducts]);

  const applicableQuantity = () => {
    const cartType = localStorage.getItem('cartType');
    return cartType === CART_TYPES.BULK ? defaultQtyForBulk : 1;
  };

  const updateLocalStorage = items => localStorage.setItem('cartItems', JSON.stringify(items));

  const updateCart = (cartId, updatedQuantity, decorationMethod = null) => {
    const cartItemIndex = cartItems.findIndex(cart => cart.cartId === cartId);

    if (cartItemIndex !== -1) {
      cartItems[cartItemIndex] = {
        ...cartItems[cartItemIndex],
        quantity: updatedQuantity,
        decorationMethod: decorationMethod || cartItems[cartItemIndex].decorationMethod
      };
      setCartItems([...cartItems]);
      setProducts(
        cartProducts.map(product => {
          return { ...product, quantity: product.cartId === cartId ? updatedQuantity : product.quantity };
        })
      );
      updateLocalStorage(cartItems);
    }
  };

  const isProductExistInCart = product => {
    return cartItems.find(cartItem => cartItem?._id === product._id && cartItem?.color?.hex === product.color.hex);
  };

  const getUpdatedQuantity = (existingCartItem, userInputQty, fromProductDetails) => {
    let qtyToUpdate;

    if (isPack) {
      if (existingCartItem.quantity >= MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK) {
        qtyToUpdate = MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK;
      } else if (isHazmatExists && existingCartItem.isHazmat) {
        qtyToUpdate = 1;
      } else {
        qtyToUpdate = existingCartItem.quantity + (fromProductDetails ? userInputQty : 1);
      }
    } else {
      qtyToUpdate = existingCartItem.quantity + (fromProductDetails ? userInputQty : applicableQuantity());
    }

    return qtyToUpdate;
  };

  const addToCart = (product, quantity, fromProductDetails = false) => {
    const { _id, productCode, pricingOption, color, name, isHazmat, decorationMethod = '' } = product;

    // check if cart type is set
    const cartType = localStorage.getItem('cartType');

    if (!cartType) {
      localStorage.setItem('cartType', CART_TYPES.BULK);
    }

    // existing cart items
    const existingCartItem = cartItems.find(cartItem => cartItem?._id === _id && cartItem?.color?.hex === color?.hex);

    if (existingCartItem) {
      const qtyToUpdate = getUpdatedQuantity(existingCartItem, quantity, fromProductDetails);

      console.log({ qtyToUpdate });

      updateCart(existingCartItem.cartId, qtyToUpdate, decorationMethod);
      return;
    }

    const cartItem = { _id, productCode, pricingOption, color, name, decorationMethod, isHazmat };
    const cartId = Date.now();

    cartItems.push({ ...cartItem, cartId, quantity });
    setCartItems([...cartItems]);
    updateLocalStorage(cartItems);

    // if cart type is pack, check if pack qty has been set
    const packQuantity = localStorage.getItem('pack_quantity');
    if (!packQuantity) {
      localStorage.setItem('pack_quantity', defaultQtyForPack);
    }
  };

  const clearCartItems = (clearCartType = false) => {
    setConfirmationCartItems(cartItems);
    setConfirmationTotalEstimation(totalEstimationWithDiscount);
    setCartItems([]);
    setProducts([]);
    localStorage.removeItem('cartItems');
    setQtyForPack(defaultQtyForPack);
    if (clearCartType) localStorage.removeItem('cartType');
  };

  const removeCartItem = cartId => {
    const updatedCartItems = cartItems.filter(cart => cart.cartId !== cartId);
    setCartItems([...updatedCartItems]);
    setProducts(cartProducts.filter(product => product.cartId !== cartId));
    updateLocalStorage(updatedCartItems);
  };

  const setCartTypeToPack = () => {
    const cartType = localStorage.getItem('cartType');
    if (cartType && cartType !== CART_TYPES.PACK) {
      clearCartItems(true);
    }

    localStorage.setItem('cartType', CART_TYPES.PACK);
    setIsPack(true);
    setQtyForPack(defaultQtyForPack);
  };

  const setCartTypeToBulk = () => {
    const cartType = localStorage.getItem('cartType');

    if (cartType && cartType !== CART_TYPES.BULK) {
      clearCartItems(true);
    }

    localStorage.setItem('cartType', CART_TYPES.BULK);
    setIsPack(false);
  };

  const getCartType = () => {
    return localStorage.getItem('cartType') || CART_TYPES.BULK;
  };

  const getColorCode = color => {
    if (color.hex) {
      return {
        color: color.hex,
        colorName: color.colorName
      };
    }
    return { color: '', colorName: '' };
  };

  const updateCartProducts = productsData => {
    if (!productsData.length) return;

    const existingCartItemsInStr = localStorage.getItem('cartItems');

    if (!existingCartItemsInStr) return;

    const _cartItems = JSON.parse(existingCartItemsInStr);

    const _products = _cartItems.map(cartItem => {
      const product = productsData.find(prod => prod.productCode === cartItem.productCode);

      if (!product) return { ...cartItem };

      const {
        name,
        brand,
        productCode,
        media: rootProductMedia,
        hazmatDetails: { isHazmat },
        defaultVariant: { pricingOption: defaultVariantPricingOption },
        variants,
        minimumOrderQuantity = 1,
        shipping,
        markupOption
      } = product;

      const variantByColor = variants.find(variant => variant?.color?.hex === cartItem?.color?.hex);

      const media = [...rootProductMedia, ...(variantByColor?.media || [])];

      const thumbnailURL = variantByColor?.media
        ? variantByColor?.media.find(item => item.mediaCategory === 'product')?.url || ''
        : rootProductMedia.find(item => item.mediaCategory === 'product')?.url || '';

      const pricingOption = variantByColor?.pricingOption || defaultVariantPricingOption;

      const pricePerItem = getPriceForQuantity(
        pricingOption[0]?.pricingMatrix,
        isPack ? cartItem?.quantity * qtyForPack : cartItem.quantity
      );

      return {
        name,
        brand,
        thumbnailURL,
        productCode,
        media,
        decorationMethod: cartItem?.decorationMethod || '',
        quantity: cartItem?.quantity || 0,
        cartId: cartItem.cartId,
        pricingOption: variantByColor?.pricingOption || pricingOption,
        isHazmat,
        pricePerItem,
        price: isPack ? pricePerItem * qtyForPack : pricePerItem,
        minimumOrderQuantity,
        shipping,
        markupOption,
        ...getColorCode(cartItem?.color || {})
      };
    });
    setProducts(_products);
  };

  const getWithAndWithoutDiscountedPricePerProduct = (product, forStartingAt = false) => {
    if (forStartingAt) {
      const priceWithoutDiscount = GetMinimumPrice(product.pricingOption || []);
      const priceWithDiscount = getDiscountPrice(priceWithoutDiscount);
      return {
        totalPriceWithDiscount: getPriceByShippingAndMarkup(product, 1, priceWithDiscount),
        totalPriceWithoutDiscount: getPriceByShippingAndMarkup(product, 1, priceWithoutDiscount)
      };
    }

    const quantity = isPack ? product?.quantity * qtyForPack : product?.quantity;

    const priceByQty = getPriceForQuantity(product?.pricingOption?.[0]?.pricingMatrix || [], quantity);

    const priceWithoutDiscount = priceByQty;
    const priceWithDiscount =
      isAuthenticated && productDiscount > 0 ? getDiscountPrice(priceByQty) : priceWithoutDiscount;

    return {
      totalPriceWithDiscount: getPriceByShippingAndMarkup(
        product,
        isPack ? qtyForPack : product?.quantity,
        priceWithDiscount
      ),
      totalPriceWithoutDiscount: getPriceByShippingAndMarkup(
        product,
        isPack ? qtyForPack : product?.quantity,
        priceWithoutDiscount
      )
    };
  };

  useEffect(() => {
    if (productCodes.length) {
      (async () => {
        // setIsLoading(true);

        try {
          const result = await queryClient.fetchQuery(['data', productCodes], () =>
            fetchDistributorCatalogProductsForCart(productCodes)
          );

          const productsData = [...result.distributorCatalogCartItems];
          setApiData([...productsData]);
          setIsLoading(false);
        } catch (error) {
          console.log('error in setting prods: ', error);
          setIsLoading(false);
          setProducts([]);
          setCartItems([]);
        }
      })();
    } else {
      setProducts([]);
    }
  }, [productCodes]);

  useEffect(() => {
    if (apiData.length) {
      updateCartProducts(apiData);
    }
  }, [qtyForPack, isPack, apiData]);

  useEffect(() => {
    if (cartProducts.length && cartItems.length) {
      const updatedCartItems = cartItems.map(cartItem => {
        const matchingProduct = cartProducts.find(product => product.productCode === cartItem.productCode);
        if (matchingProduct) {
          if (
            !cartItem.media ||
            cartItem.media.length !== matchingProduct.media.length ||
            cartItem.thumbnailURL !== matchingProduct.thumbnailURL
          ) {
            return {
              ...cartItem,
              media: matchingProduct.media || [],
              thumbnailURL: matchingProduct.thumbnailURL || ''
            };
          }
        }
        return cartItem;
      });

      const hasChanges = updatedCartItems.some(
        (item, index) => item.media !== cartItems[index]?.media || item.thumbnailURL !== cartItems[index]?.thumbnailURL
      );

      if (hasChanges) {
        setCartItems(updatedCartItems);
        updateLocalStorage(updatedCartItems);
      }
    }
  }, [cartProducts]);

  useEffect(() => {
    const existingCartItemsInStr = localStorage.getItem('cartItems');
    if (existingCartItemsInStr) {
      setCartItems(JSON.parse(existingCartItemsInStr));
    }

    const packQuantity = +localStorage.getItem('pack_quantity') || defaultQtyForPack;
    setQtyForPack(packQuantity || defaultQtyForPack);
    setIsPack(getCartType() === CART_TYPES.PACK);
  }, []);

  useEffect(() => {
    if (!qtyForPack || !cartProducts.length) return;

    const quantityPricingWithDiscount = [];
    const quantityPricingWithoutDiscount = [];

    cartProducts.forEach(product => {
      const { name } = product;

      const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(product);

      quantityPricingWithDiscount.push({
        name,
        price: totalPriceWithDiscount
      });
      quantityPricingWithoutDiscount.push({
        name,
        price: totalPriceWithoutDiscount
      });
    });

    const totalPriceWithDiscount = quantityPricingWithDiscount.reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result += item.price;
      return result;
    }, 0);

    const totalPriceWithoutDiscount = quantityPricingWithoutDiscount.reduce((result, item) => {
      // eslint-disable-next-line no-param-reassign
      result += item.price;
      return result;
    }, 0);

    console.log({ quantityPricingWithDiscount });

    setTotalPriceByTotalQtyPacksWithDiscount(round(totalPriceWithDiscount, 2));
    setTotalPriceByTotalQtyPacksWithoutDiscount(round(totalPriceWithoutDiscount, 2));

    setIsPack(getCartType() === CART_TYPES.PACK);
  }, [cartProducts, qtyForPack, cartItems, isAuthenticated, productDiscount]);

  useEffect(() => {
    setTotalPricePerPackWithDiscount(round(totalPriceByTotalQtyPacksWithDiscount / qtyForPack, 2));
    setTotalPricePerPackWithoutDiscount(round(totalPriceByTotalQtyPacksWithoutDiscount / qtyForPack, 2));
  }, [totalPriceByTotalQtyPacksWithDiscount, totalPriceByTotalQtyPacksWithoutDiscount, qtyForPack]);

  useEffect(() => {
    const localStorageQtyForPack = localStorage.getItem('pack_quantity');
    if (+localStorageQtyForPack !== qtyForPack) {
      localStorage.setItem('pack_quantity', qtyForPack);
    }
  }, [qtyForPack]);

  const { totalEstimateForBulkWithDiscount, totalEstimateForBulkWithoutDiscount } = useMemo(() => {
    return cartProducts.reduce(
      (acc, product) => {
        const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(
          product
        );
        return {
          ...acc,
          totalEstimateForBulkWithDiscount: acc.totalEstimateForBulkWithDiscount + totalPriceWithDiscount,
          totalEstimateForBulkWithoutDiscount: acc.totalEstimateForBulkWithoutDiscount + totalPriceWithoutDiscount
        };
      },
      { totalEstimateForBulkWithDiscount: 0, totalEstimateForBulkWithoutDiscount: 0 }
    );
  }, [cartProducts, isAuthenticated, productDiscount]);

  useEffect(() => {
    const cartType = localStorage.getItem('cartType');
    setTotalEstimationWithDiscount(
      cartType === CART_TYPES.BULK ? totalEstimateForBulkWithDiscount : totalPriceByTotalQtyPacksWithDiscount
    );
    setTotalEstimationWithoutDiscount(
      cartType === CART_TYPES.BULK ? totalEstimateForBulkWithoutDiscount : totalPriceByTotalQtyPacksWithoutDiscount
    );
  }, [
    totalEstimateForBulkWithDiscount,
    totalPriceByTotalQtyPacksWithDiscount,
    totalPriceByTotalQtyPacksWithoutDiscount,
    totalEstimateForBulkWithoutDiscount
  ]);

  return (
    <CartContext.Provider
      value={{
        cartItems,
        cartProducts,
        isLoading,
        qtyForPack,
        totalPriceByTotalQtyPacksWithDiscount,
        totalPriceByTotalQtyPacksWithoutDiscount,
        totalPricePerPackWithDiscount,
        totalPricePerPackWithoutDiscount,
        totalEstimateForBulkWithDiscount,
        totalEstimateForBulkWithoutDiscount,
        totalEstimationWithDiscount,
        totalEstimationWithoutDiscount,
        isPack,
        isHazmatExists,
        confirmationCartItems,
        confirmationTotalEstimation,
        setQtyForPack,
        addToCart,
        updateCart,
        removeCartItem,
        clearCartItems,
        setCartTypeToPack,
        setCartTypeToBulk,
        getCartType,
        applicableQuantity,
        isProductExistInCart,
        getUpdatedQuantity,
        getWithAndWithoutDiscountedPricePerProduct
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const useCartContext = () => {
  return React.useContext(CartContext);
};

export { CartContextProvider, useCartContext };
