import * as React from 'react';
import { Grid, Table, TableHead, TableRow, TableCell, TableBody, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import round from 'lodash/round';
import log from '../../logger';
import styles from './styles/checkoutSideBar';
import { getShipmentGroupsInfo, moneyStr } from '../../helpers/utils';
import { ListProducts, quantityPriceStorageShipping } from './common';
import ItemDetailsSidebar from './ItemDetailsSidebar';
import { setShippingOrderCost } from '../../actions';
import { useOrder } from '../pages/orders/requested/OrderContext';
import AppliedMembershipPanel from '../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../global/StrikeOutCost';

const useStyles = makeStyles(styles);

const productsTotals = (products, storagePrices, storageType, isInternational, individualPackPrices) =>
  products.reduce(
    (acc, proof) => {
      const p = quantityPriceStorageShipping(proof, storagePrices, storageType, isInternational, individualPackPrices);
      const {
        productQty,
        productPrice,
        productRushFee,
        storagePrice,
        estimatedShippingPrice,
        productPriceBeforeDiscount,
        productRushFeeBeforeDiscount
      } = p;
      return {
        productsQty: acc.productsQty + productQty,
        storage: round(acc.storage + productQty * storagePrice, 2),
        shipping:
          typeof estimatedShippingPrice === 'number'
            ? round(acc.shipping + productQty * estimatedShippingPrice, 2)
            : acc.shipping,
        subtotal: round(acc.subtotal + productQty * productPrice, 2),
        rushFee: round(acc.rushFee + productQty * productRushFee, 2),
        subtotalBeforeDiscount: round(acc.subtotalBeforeDiscount + productQty * productPriceBeforeDiscount, 2),
        rushFeeBeforeDiscount: round(acc.rushFeeBeforeDiscount + productQty * productRushFeeBeforeDiscount, 2),
        tax: round(acc.tax + parseFloat(proof.tax), 2)
      };
    },
    {
      productsQty: 0,
      storage: 0,
      shipping: 0,
      subtotal: 0,
      rushFee: 0,
      tax: 0,
      subtotalBeforeDiscount: 0,
      rushFeeBeforeDiscount: 0
    }
  );

const useShippingPrice = (order, priceField = 'price') => {
  const { id } = useParams();

  const shippingInfo = getShipmentGroupsInfo(order?.shipmentGroups ?? []);

  return id ? shippingInfo.international[priceField] + shippingInfo.domestic[priceField] : 0;
};

const CheckoutSidebar = ({
  individualPackPrices,
  isInternational,
  paymentMethod,
  products,
  selectedCredit,
  selectedStorage,
  shippingElements,
  showCredit,
  showItems = true,
  showStorage,
  storagePrices,
  storageType,
  tax,
  totalPlusTax
}) => {
  const classes = useStyles();

  const order = useOrder();
  const shippingPriceFromOrder = useShippingPrice(order);
  const shippingPriceFromOrderBeforeDiscount = useShippingPrice(order, 'price_without_discount');

  const prodTotals = React.useMemo(
    () => productsTotals(products, storagePrices, storageType, isInternational, individualPackPrices),
    [products, storagePrices, storageType, isInternational, individualPackPrices]
  );
  log.debug('CheckoutSidebar products:', products, 'prodTotals:', prodTotals, 'selectedCredit:', selectedCredit);
  const storage = showStorage ? (selectedStorage && Number(selectedStorage)) || prodTotals.storage : 0;
  const shippingCredits = showCredit ? (selectedCredit && Number(selectedCredit)) || prodTotals.shipping : 0;

  const currentTax = tax === undefined ? Number(order.tax) : tax;

  const shippingPrice = (showItems ? shippingPriceFromOrder : 0) + shippingCredits;
  const shippingPriceBeforeDiscount = (showItems ? shippingPriceFromOrderBeforeDiscount : 0) + shippingCredits;

  const orderCost = React.useMemo(
    () =>
      totalPlusTax === undefined
        ? currentTax + prodTotals.subtotal + prodTotals.rushFee + shippingPrice + storage
        : totalPlusTax,
    [totalPlusTax, shippingPrice, storage, prodTotals, currentTax]
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(setShippingOrderCost(orderCost));
  }, [orderCost, dispatch]);

  const multipleTotals = React.useMemo(
    () => ({
      shipping_discount: shippingPrice || shippingPriceBeforeDiscount,
      product_discount: prodTotals.subtotal || prodTotals.subtotalBeforeDiscount,
      storage_discount: storage,
      rush_production_discount: prodTotals.rushFee || prodTotals.rushFeeBeforeDiscount
    }),
    [
      prodTotals.rushFee,
      prodTotals.rushFeeBeforeDiscount,
      prodTotals.subtotal,
      prodTotals.subtotalBeforeDiscount,
      shippingPrice,
      shippingPriceBeforeDiscount,
      storage
    ]
  );

  const { totalBeforeDiscount, multipleDiscounts } = useDiscountsAndRewards(0, '', multipleTotals);

  return (
    <div className={classes.summaryContainer}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow className={classes.tableRow}>
            <TableCell className={classes.tableCell}>
              <Grid container spacing={0} alignItems="center">
                <Grid item xs={10}>
                  <p className={classes.tableHeader}>Order summary</p>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>
        {showItems && (
          <TableBody>
            {products.map(product => (
              <React.Fragment key={product.id}>
                {shippingElements ? (
                  <ItemDetailsSidebar proof={product} shippingElements={shippingElements} classes={classes} />
                ) : (
                  <ListProducts
                    proof={product}
                    storagePrices={storagePrices}
                    storageType={storageType}
                    isInternational={isInternational}
                    individualPackPrices={individualPackPrices}
                    classes={classes}
                  />
                )}
              </React.Fragment>
            ))}
          </TableBody>
        )}
      </Table>

      <Table className={classes.summaryTable}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2} className={clsx(classes.tableCell, classes.noBottomBproof)}>
              <Grid container>
                <Grid item xs={6}>
                  <p className={classes.packTotal}>{`Subtotal (${prodTotals.productsQty})`}</p>
                  {prodTotals.rushFee !== 0 && <p className={classes.packTotal}>Rush production </p>}
                  {showStorage && <p className={classes.packTotal}>Storage</p>}
                  <p className={classes.packTotal}>Shipping</p>
                  <p className={classes.packTotal}>Estimated taxes & fees</p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <p className={classes.itemDesc}>
                    <StrikeOutText value={multipleDiscounts?.product_discount} />
                    {moneyStr(prodTotals.subtotal)}
                  </p>
                  {prodTotals.rushFee !== 0 && (
                    <p className={classes.itemDesc}>
                      <StrikeOutText value={multipleDiscounts?.rush_production_discount} />
                      {moneyStr(prodTotals.rushFee)}
                    </p>
                  )}
                  {showStorage && (
                    <p className={classes.itemDesc}>
                      <StrikeOutText value={multipleDiscounts?.storage_discount} />
                      {moneyStr(storage)}
                    </p>
                  )}
                  <p className={classes.itemDesc}>
                    <StrikeOutText value={multipleDiscounts?.shipping_discount} />
                    {moneyStr(shippingPrice)}
                  </p>
                  <p className={classes.itemDesc}>{moneyStr(currentTax)}</p>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className={classes.tableCell} style={{ borderBottom: '0px' }}>
              <AppliedMembershipPanel
                type="product"
                total={prodTotals.subtotal}
                multipleTotals={multipleTotals}
                fullWidth
                style={{ marginTop: 16 }}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2} className={classes.tableCell} style={{ borderBottom: '0px' }}>
              <Grid container>
                <Grid item xs={6}>
                  <p className={classes.packTitle}>
                    <b>Total</b>
                  </p>
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right' }}>
                  <p className={classes.itemDesc}>
                    <StrikeOutText value={totalBeforeDiscount} surPlus={currentTax} />
                    <b>{moneyStr(orderCost)}</b>
                  </p>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          {!showItems && (
            <TableRow>
              <TableCell
                colSpan={2}
                className={classes.tableCell}
                style={{ borderTop: '1px solid #dddddd', borderBottom: '0px' }}
              >
                <Grid container>
                  <Grid item xs={6}>
                    <p className={classes.packTitle}>
                      <b>Payment method</b>
                    </p>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: 'right' }}>
                    <p className={classes.itemDesc}>
                      <b>{paymentMethod}</b>
                    </p>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default CheckoutSidebar;
