import { shoppingExperienceColors } from '../../shared/constants';

const breadcrumbStyles = () => ({
  gridContainer: {
    paddingTop: 20
  },
  imageIcon: {
    width: 20,
    height: 20,
    marginRight: 4
  },
  activeLink: {
    color: shoppingExperienceColors.info,
    textTransform: 'capitalize'
  },
  inactiveLink: {
    color: shoppingExperienceColors.textPrimary,
    textTransform: 'capitalize',
    '&:hover': {
      color: shoppingExperienceColors.info
    }
  }
});

export default breadcrumbStyles;
