import { shoppingExperienceColors } from '../../shared/constants';

const onHoverIsActiveQtyBreakItem = {
  backgroundColor: '#F5F5F6',
  borderRadius: '5px',
  cursor: 'pointer'
};

// eslint-disable-next-line no-unused-vars
const cartQuantityBreakStyles = theme => ({
  container: {
    width: '100%',
    padding: '12px 0px',
    boxShadow: 'none',
    borderRadius: 0,
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    '& .MuiPaper-elevation1': {
      boxShadow: 'none !important'
    },
    [theme.breakpoints.up('lg')]: {
      padding: '12px 0px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '12px 24px'
    }
  },
  qtyBreakItem: {
    justifyContent: 'space-between',
    padding: '6px 20px',
    '&:hover': { ...onHoverIsActiveQtyBreakItem }
  },
  selectedQtyBreakItem: { ...onHoverIsActiveQtyBreakItem },
  customQtyContainer: { justifyContent: 'space-between', flexWrap: 'nowrap', alignItems: 'center' },
  customQtyInputBtn: { minWidth: 'unset', color: '#131415', fontSize: '24px', fontWeight: 400 },
  customQtyInputBtnDisabled: { opacity: 0.2, pointerEvents: 'none', userSelect: 'none' },
  customQtyInputTextbox: {
    width: '65px',
    borderRadius: '4px',
    border: '1px solid #131415',
    padding: '0px 20px',
    height: '40px'
  },
  pricePerPackContainer: {
    backgroundColor: '#F5F5F6',
    justifyContent: 'space-between',
    alignItems: 'center',
    border: '1px solid #E5E7E8',
    padding: '0px 20px',
    height: '60px',
    borderBottomLeftRadius: '5px',
    borderBottomRightRadius: '5px'
  },
  accordionContainer: {
    border: '1px solid #E5E7E8',
    padding: '10px',
    borderTopLeftRadius: '4px !important',
    borderTopRightRadius: '4px !important',
    marginBottom: '0px !important',
    paddingBottom: '0px'
  },
  accordionDetails: { marginTop: 12, flexDirection: 'column', gap: 14 },
  quantityBreaksContainer: {
    flexDirection: 'column',
    gap: 18
  }
});

export default cartQuantityBreakStyles;
