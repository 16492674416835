import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { shoppingExperienceColors } from '../constants';
import CallUs from '../../assets/images/footer/CallUs@4x.png';

const CallUsComponent = ({ color, showEmoji }) => {
  return (
    <Grid style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '12px' }}>
      <Grid
        component="a"
        href="tel:+16464940520"
        style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '12px' }}
      >
        <img src={CallUs} alt="Contact Us" style={{ height: 40, width: 40 }} />
        <Grid style={{ display: 'block', justifyContent: 'space-evenly', alignItems: 'center' }}>
          <SwagupTypography variant="body3RegularInter" style={{ color: color || shoppingExperienceColors.white }}>
            {showEmoji ? (
              <>
                Call us <span> 👋 </span>
              </>
            ) : (
              'Call us'
            )}
          </SwagupTypography>

          <SwagupTypography variant="body3SemiBoldInter" style={{ color: color || shoppingExperienceColors.white }}>
            646-494-0520
          </SwagupTypography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CallUsComponent;
