import React, { useState } from 'react';
import {
  Accordion,
  Box,
  Grid,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Skeleton } from '@material-ui/lab';
import { shoppingExperienceColors } from '../constants.js';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '70%',
      marginLeft: '0'
    }
  },
  summary: {
    boxShadow: 'none'
  },
  icon: {
    color: shoppingExperienceColors.textPrimary
  }
}));

const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

const FAQList = ({ faq, isLoading }) => {
  // Filter items to show
  const itemsToShow = faq.filter(item => item.doShow);

  // Split items into chunks of 4
  const chunks = chunkArray(itemsToShow, 4);

  return (
    <Box>
      <Grid container spacing={2}>
        {chunks.map((chunk, index) => {
          if (isLoading) {
            return <Skeleton variant="rect" />;
          }
          return (
            <Grid item xs={12} sm={6} key={index}>
              <ul style={{ paddingLeft: '1.5em', listStyle: 'disc', margin: 0 }}>
                {chunk.map((item, i) => (
                  <li
                    key={i}
                    style={{
                      fontSize: '1rem',
                      fontWeight: 'bold',
                      color: '#333',
                      marginTop: '10px',
                      marginBottom: '10px'
                    }}
                  >
                    <SwagupTypography
                      variant="body2RegularInter"
                      style={{
                        fontWeight: 'normal',
                        marginLeft: '0.5em'
                      }}
                    >
                      {item.name}
                    </SwagupTypography>
                  </li>
                ))}
              </ul>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

// FAQ Accordion Component
export default function FAQAccordion({ faqData, isLoading, fullWidth = false }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMediumUp = useMediaQuery(theme.breakpoints.up('md'));

  const [expanded, setExpanded] = useState([]);

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(prev => (isExpanded ? [...prev, panel] : prev.filter(item => item !== panel)));
  };

  return (
    <div
      className={classes.root}
      style={{
        width: isMediumUp && !fullWidth ? '51%' : '100%',
        margin: isMediumUp ? '0' : '0 auto'
      }}
    >
      {faqData.map((faq, index) => (
        <Accordion
          key={index}
          style={{
            boxShadow: 'none',
            borderBottom: '1px solid',
            borderColor: shoppingExperienceColors.borderLineGray,
            borderRadius: 0
          }}
          expanded={expanded.includes(index)}
          onChange={handleChange(index)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className={classes.icon} />}
            className={classes.summary}
            style={{ boxShadow: 'none', border: 'none' }}
          >
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography variant="body2SemiBoldInter" style={{ marginBottom: 20 }}>
                {faq.title}
              </SwagupTypography>
            )}
          </AccordionSummary>
          {faq.type === 'text' ? (
            <AccordionDetails>
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <SwagupTypography variant="body2RegularInter">{faq.answer}</SwagupTypography>
              )}
            </AccordionDetails>
          ) : (
            <FAQList faq={faq.answer} />
          )}
        </Accordion>
      ))}
    </div>
  );
}
