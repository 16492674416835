const host = process.env.REACT_APP_PUBLIC_URL.replace('/dashboard/', '');

// Please, keep both "sections" sorted
const seoTags = {
  // section: the most important ones
  beOnDeck: {
    title: 'SwagUp x On Deck - Swag Management Made Simple',
    description:
      'Exclusive On Deck Benefits. Use SwagUp to manage all your swag needs. Create, automate and distribute swag all in one centralized platform. Reach out to a Swag Expert for details.',
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  blog: {
    title: 'SwagUp Blog - Swag Secrets, Startup Culture, Client Success',
    description:
      'Get the latest from Swag Experts on swag tips, popular swag items, office culture, behind-the-swag client success stories, product updates, and more!',
    'og:image': `${host}/static/images/seo/mailbox.png`
  },
  bulk: {
    title: 'SwagUp - Create High Quality Bulk Swag',
    description:
      'We help you design, pack, ship, and store effortlessly. Select from our catalog for bulk swag and take advantage of our volume discounts today!',
    'og:image': `${host}/static/images/seo/swagup-burst.png`
  },
  bulkCatalog: {
    title: 'SwagUp - Browse Our Curated Swag Catalog',
    description:
      'Explore the SwagUp Bulk Catalog for our most popular swag items. Our Swag Experts can also help you source high quality swag people will actually love. Customizable to your brand, free mockups included.',
    'og:image': `${host}/static/images/seo/swagup-sample-pack.png`
  },
  bulkCustomThanks: {
    title: 'Thank you for your SwagUp Order!',
    description:
      "Woohoo! We appreciate the partnership! Your swag mockup is in the works. Hang tight, you'll get results in 24 hours or less.",
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  carePackages: {
    title: 'SwagUp - Corporate Care Packs Made Simple',
    description:
      'What better way to stay connected than through some branded care packages? SwagUp makes creating, automating and distributing your branded care packages across the globe!'
  },
  company: {
    title: 'About SwagUp - The Story Behind the Swag',
    description:
      'In May 2017, we set out to create the swag company we would want to work with if we were running a growing company. Since then, we’ve accomplished so much and we’re not stopping anytime soon.',
    'og:image': `${host}/static/images/company/swagup-team@2x.png`
  },
  customPacks: {
    title: 'SwagUp - Build Your Custom Swag Pack',
    description:
      'Step 1: Choose the swag you like. Step 2: Watch the magic happen. We help you design, pack, ship, and store effortlessly. SwagUp is your swag solution for the modern workplace.',
    'og:image': `${host}/static/images/seo/swagup-burst.png`
  },
  customPacksCatalog: {
    title: 'SwagUp - Browse Our Curated Swag Pack Catalog',
    description:
      'Explore the SwagUp Swag Pack Catalog for our most popular swag items. Then get started on your custom swag pack today. Fully customizable to your brand, free mockups included.',
    'og:image': `${host}/static/images/seo/swagup-sample-pack.png`
  },
  dashboardLoggedOut: {
    title: 'Log in to the SwagUp Platform - Your One-Stop Shop to Create, Automate, Distribute Swag',
    description:
      'Your control tower for everything swag. Login to the SwagUp Platform to manage all your swag projects. Create, Automate, Ship, Distribute, Store Swag all at the click of a button.',
    'og:image': `${host}/static/images/seo/swagup-burst.png`
  },
  directMail: {
    title: 'SwagUp - Upgrade Your Direct Mail Campaigns with Swag',
    description:
      'Boost Engagement, Strengthen Relationships. Use swag to bring a human touch to your direct mail campaigns - without losing hours of your time to make it happen'
  },
  faq: {
    title: 'SwagUp - FAQ',
    description:
      "Swag can be complicated - but it doesn't have to be. If you have a few questions on your mind, don't worry you're not alone! Here are some of the top questions we get asked all of the time. Head to our help desk for a more detailed breakdown of the SwagUp Platform."
  },
  gallery: {
    title: 'SwagUp Gallery | First Class Swag Packs Launched with SwagUp',
    description:
      'Over 1000 teams use the SwagUp Platform to create, automate, and distribute swag packs globally. Check out some of the swag projects that made it #tothemoon!'
  },
  holidaySEMPage: {
    title: 'SwagUp - Holiday Swag Gifts Everyone Will Love!',
    description:
      "High-Quality Holiday Swag everyone will love! Let's cross that corporate gifting off this year's holiday list!"
  },
  firstRepublicBank: {
    title: 'SwagUp - Swag for First Republic Bank',
    description:
      'Create, automate and distribute high quality swag with exclusive benefits for all First Republic fellows, discounts and dedicated customer success rep'
  },
  home: {
    title: 'SwagUp - Create, Automate, Distribute High Quality Swag',
    description:
      "We've mastered swag packs, so you don't have to. SwagUp is your one stop shop for everything swag. Create, automate and distribute swag without the headaches.",
    'google-site-verification': 'XuGJhkTMPvVX1x19Okc4-40ofszrROpiSF-ozcZ3D2g',
    'og:image': `${host}/static/images/seo/swagup-burst.png`,
    'og:url': 'https://www.swagup.com',
    'og:site_name': 'swagup.com',
    keywords: 'branded, kits, merch, merchandise, printing, screen, startup, startups, swag, welcome'
  },
  homeOffice: {
    title: 'SwagUp - Send Swag from Your Home Office!',
    description: 'Send swag to remote teams and workers worldwide'
  },
  hopin: {
    title: 'SwagUp - Swag for Hopin events made simple',
    description: 'Create, automate, and distribute high quality swag to Hopin events attendees'
  },
  onboarding: {
    title: 'SwagUp - Grab a Free Sample Pack',
    description:
      'Want to try the SwagUp unboxing experience? Get a free sample pack and see how it works. SwagUp helps you onboard new employees, clients, and community members.',
    'og:image': `${host}/static/images/seo/3d-sample-pack.png`
  },
  pricing: {
    title: 'SwagUp - Pricing',
    description:
      'SwagUp Pricing details. Simple Pricing, no surprises. We pride ourselves on making swag simple, and our pricing even simpler'
  },
  privacyPolicy: {
    title: 'SwagUp - Privacy Policy',
    description:
      'This privacy policy (the “Policy”) describes how we collect and use your information. This Policy applies to data you provide us when you access or use the Site and purchase SwagUp products or services for yourself or others.',
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  presetPacks: {
    title: 'SwagUp - Branded Preset Swag Packs',
    description:
      'Launch your next swag project faster with the most popular swag packs created by our Swag Experts. Simply choose a pack, upload your brand logo, and ship!',
    'og:image': `${host}/static/images/seo/eco-preset-pack.png`
  },
  remoteSwag: {
    title: 'SwagUp - Connect with Remote Employees Using Swag',
    description:
      'Grow a thriving company culture with your remote team and send high quality swag they will love. SwagUp helps you create, automate and distribute swag to remote workers through one platform.'
  },
  returnRefundPolicy: {
    title: 'SwagUp - Return/Refund Policy',
    description:
      "SwagUp wants you to love your swag, however, if you are unhappy with your purchase we're here to help.",
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  services: {
    title: 'SwagUp - Services',
    description:
      'SwagUp does more than just offer swag. We help create, automate and distribute swag worldwide while using one platform to manage it all!'
  },
  termsOfUse: {
    title: 'SwagUp - Terms of Use',
    description:
      'Please review the following Terms that govern your use of the Site, and information and products obtained through the Site. Your use of the Site, and/or acceptance of information and products through the Site, constitutes your agreement to these Terms of Use (the “Terms”).',
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  membershipService: {
    title: 'SwagUp - Membership Terms and Conditions',
    description:
      'Please review the following Terms that govern your use of the Site, and information and products obtained through the Site. Your use of the Site, and/or acceptance of information and products through the Site, constitutes your agreement to these Membership Terms and Conditions.',
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  virtualSwag: {
    title: 'SwagUp - Virtual Conference Swag Made Simple',
    description:
      'Bring the fun back to virtual events. SwagUp helps you painlessly create and distribute swag at virtual events, boosting attendee engagement and brand awareness without the hassle.'
  },
  // section: less inspired/important ones?
  account: {
    title: 'SwagUp - Account',
    description: 'Account'
  },
  addContactOption: {
    title: 'SwagUp - Add Contact',
    description: 'Add Contact'
  },
  addCredits: {
    title: 'SwagUp - Add Credits',
    description: 'Add Credits'
  },
  addEmployee: {
    title: 'SwagUp - Add Employee',
    description: 'Add Employee'
  },
  buildLoyalty: {
    title: 'SwagUp - Build Loyalty with Swag Packs',
    description:
      'Score a culture win with swag packs. From welcoming new clients and community members to appreciating employees, personalized swag packs are the perfect way to build loyalty in an authentic, thoughtful gesture.',
    'og:image': `${host}/static/images/seo/swagup-sample-pack.png`
  },
  bulkExperience: {
    title: 'SwagUp - Custom Swag for Conferences',
    description: 'Custom Swag for Conferences'
  },
  contacts: {
    title: 'SwagUp - Contacts',
    description: 'SwagUp Contacts'
  },
  credits: {
    title: 'SwagUp - Credits',
    description: 'Credits'
  },
  customPacksExperience: {
    title: 'SwagUp - Custom Swag Packs Experience',
    description: 'Custom Swag Packs Experience'
  },
  dashboardLoggedIn: {
    title: 'SwagUp - Dashboard',
    description: 'SwagUp Dashboard'
  },
  products: {
    title: 'SwagUp - Products',
    description: 'SwagUp Products'
  },
  ecoSwagPacks: {
    title: 'SwagUp - Eco Swag Packs',
    description: 'Eco Swag Packs made simple'
  },
  employeeAnniversary: {
    title: 'SwagUp - Employee Anniversary Swag Packs',
    description: 'Employee Anniversary Swag Packs made simple!'
  },
  employeeDetails: {
    title: 'SwagUp - Employee Details',
    description: 'Employee Details'
  },
  employeeSwagPack: {
    title: 'SwagUp - Curated High Quality Employee Swag Packs',
    description:
      'Elevate your company culture with high quality swag for the talented people on your team. Use employee swag packs during onboarding, major milestones and achievements, employee appreciation, and more!',
    'og:image': `${host}/static/images/seo/swagup-sample-pack.png`
  },
  error: {
    title: 'SwagUp - Error',
    description: 'Error'
  },
  eventGeek: {
    title: 'SwagUp - Swag for Event Managers',
    description: 'Swag for Event Managers made simple'
  },
  inventory: {
    title: 'SwagUp - Inventory',
    description: 'Inventory'
  },
  inventoryHistories: {
    title: 'SwagUp - Inventory Histories',
    description: 'Inventory Histories'
  },
  integrations: {
    title: 'SwagUp - Zapier',
    description: 'SwagUp - Zapier Integrations'
  },
  integrationsFlow: integrationName => ({
    title: `SwagUp - ${integrationName}`,
    description: `SwagUp - ${integrationName} Integrations`
  }),
  invoices: {
    title: 'SwagUp - Invoices',
    description: 'Invoices'
  },
  multiShippingOverview: {
    title: 'SwagUp - Multi-Shipping Overview',
    description: 'Multi-Shipping Overview'
  },
  multiShippingPayment: {
    title: 'SwagUp - Multi-Shipping Payment',
    description: 'Multi-Shipping Payment'
  },
  multiShippingStorageOptions: {
    title: 'SwagUp - Multi-Shipping Storage Options',
    description: 'Multi-Shipping Storage Options'
  },
  newClientGifts: {
    title: 'SwagUp - Swag Packs For Your Clients',
    description: 'Swag Packs For Your Clients with love'
  },
  newHire: {
    title: 'SwagUp - New Hire Swag Packs for the Modern Workplace',
    description:
      'Get it right from Day One. Give your newest talent a warm welcome and onboard them with high quality swag to get them excited about joining the team. Automate all your new hire swag packs through the SwagUp Platform.',
    'og:image': `${host}/static/images/seo/swagup-sample-pack.png`
  },
  orderDetails: {
    title: 'SwagUp - Order Details',
    description: 'Order Details'
  },
  orderReview: {
    title: 'SwagUp - Order Review',
    description: 'Order Review'
  },
  ordersCompleted: {
    title: 'SwagUp - Completed Orders',
    description: 'SwagUp Completed Orders'
  },
  ordersRequested: {
    title: 'SwagUp - Requested Orders',
    description: 'SwagUp Requested Orders'
  },
  notFoundPage: {
    title: 'SwagUp - Page Not Found',
    description: 'Page Not Found'
  },
  paymentProfiles: {
    title: 'SwagUp - Payment Profiles',
    description: 'Payment Profiles'
  },
  productOnboarding: {
    title: 'SwagUp - Launch Your Swag Project',
    description:
      "Get started on your swag project today. From ready-made preset packs to high-quality custom swag packs and branded bulk swag items, you can launch your next swag project and get free mockups in a few minutes. Let's take this #tothemoon!",
    'og:image': `${host}/static/images/seo/swagup-burst.png`
  },
  profile: {
    title: 'SwagUp - Profile',
    description: 'Profile'
  },
  proofDetails: {
    title: 'SwagUp - Proof Details',
    description: 'Proof Details'
  },
  promoteYourBrand: {
    title: 'SwagUp - Promote your Brand',
    description: 'Promote your Brand with Swag Packs'
  },
  rebranding: {
    title: 'SwagUp - Rebranding',
    description: 'Swag Packs Made Simple! (Rebranding)'
  },
  recentShipments: {
    title: 'SwagUp - Recent Shipments',
    description: 'SwagUp Recent Shipments'
  },
  reorder: {
    title: 'SwagUp - Reorder Swag',
    description: 'SwagUp, Reorder Swag'
  },
  request: {
    title: 'SwagUp - Request Details',
    description: 'Request Details'
  },
  redeem: {
    title: 'Redeem',
    description: 'Redeem Pages Management'
  },
  rewardEmployee: {
    title: 'SwagUp - Reward Employees with Swag',
    description: 'Reward Employees with Swag!'
  },
  rippling: {
    title: 'SwagUp x Rippling - Automated Swag Onboarding',
    description:
      'Add the SwagUp app to your Rippling account in just one click. With SwagUp, you can automatically send swag to your newest hires all over the world. Onboarding made easy.',
    'og:image': `${host}/static/images/seo/hands-with-swagup-icon.png`
  },
  samplePack: {
    title: 'SwagUp - Swag Packs Made Simple',
    description:
      'Swag should be fun, not a headache. With SwagUp, you can create, automate and distribute high quality custom swag without the headache. Swag, as it should be.'
  },
  shipments: {
    title: 'SwagUp - Shipments',
    description: 'SwagUp Shipments'
  },
  shippingOrderConfirmation: {
    title: 'SwagUp - Shipping Order Confirmation',
    description: 'SwagUp Shipping Order Confirmation'
  },
  shopify: {
    title: 'SwagUp - Integrate SwagUp with Shopify',
    description: 'Integrate SwagUp with Shopify'
  },
  storefronts: {
    title: 'Shops',
    description: 'Shops Management'
  },
  swagManagment: {
    title: 'SwagUp - Swag Management for the Modern Workplace',
    description:
      'The all-in-one swag solution for modern teams. Upgrade your swag workflow with a centralized platform that helps you save time managing all your swag needs'
  },
  swagPackForClient: {
    title: 'SwagUp - Swag Packs for Clients',
    description: 'SwagUp Swag Packs for Clients'
  },
  swagPacksMadeSimple: {
    title: 'SwagUp - Swag Packs Made Simple',
    description:
      'Swag should be fun, not a headache. With SwagUp, you can create, automate and distribute high quality custom swag without the headache. Swag, as it should be.'
  },
  transactions: {
    title: 'SwagUp - Transactions',
    description: 'Transactions'
  },
  membership: {
    title: 'SwagUp - Membership',
    description: 'Membershipss'
  },
  settings: {
    title: 'SwagUp - Settings',
    description: 'Settings'
  },

  // New Shopping Experience seo Tags

  projectOnboarding: {
    title: 'Start a New Swag Project | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  },
  allSwagPage: {
    title: 'SwagUp Online Shop | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  },
  cart: {
    title: 'Cart | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  },
  shoppingExperienceCollections: {
    title: 'Collections | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  },
  CXOpages: {
    title: 'Project Submission | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  },
  confirmationPage: {
    title: 'Project Submission Confirmation | SwagUp',
    description:
      'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
  }
};

export default seoTags;
