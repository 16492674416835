const SwagupLogoStyles = theme => ({
  logowithName: {
    width: '170px',
    height: '40px',
    maxWidth: '170px',
    maxHeight: '40px'
  },
  logoRocket: {
    width: '70px',
    height: '70px',
    maxWidth: '70px',
    maxHeight: '70px'
  }
});

export default SwagupLogoStyles;
