import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextLink as FooterLink } from '../../shared/ui/common';
import styles from './footer.styles';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(styles);

const Support = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <>
      {currentDevice !== DEVICES_TYPE.MOBILE && (
        <FooterLink variant="h5SemiBoldInter" className={classes.footerSubHeading}>
          Support
        </FooterLink>
      )}
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Help
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Chat with us
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        FAQ
      </FooterLink>
      <FooterLink
        href="#"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Book a Demo
      </FooterLink>
    </>
  );
};

export default Support;
