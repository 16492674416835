import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import sumBy from 'lodash/sumBy';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import { useSetAuthCookies } from '../../hooks/globalQueries';

// from https://flatuicolors.com/
const defaultColors = [
  '#3c40c6', // free speech blue
  '#ff5e57', // sunset orange
  '#ffa801', // chrome yellow
  '#05c46b', // green teal
  '#1e272e', // black pearl
  '#575fcf', // dark periwinkle
  '#2c3e50' // midnight blue
];

const sumChars = str => sumBy(str, c => c.charCodeAt(0));

const useStyles = makeStyles({
  userAvatar: {
    color: 'white',
    cursor: 'pointer'
  },
  userAvatarInner: {
    boxSizing: 'border-box',
    border: '1px solid lightgray',
    fontSize: '14px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    ...ellipsisStyles
  }
});

const UserAvatar = ({ name, nameForColorGeneration, size, fontSize }) => {
  const classes = useStyles();

  const userInitial = name?.charAt(0) ?? '?';
  // pick a deterministic color from the list
  const colorGenName = nameForColorGeneration || name || '';
  const backgroundColor = defaultColors[sumChars(colorGenName) % defaultColors.length];

  useSetAuthCookies({ userInitial, backgroundColor });
  const innerStyle = {
    lineHeight: `${size - 2}px`,
    backgroundColor,
    width: size,
    maxWidth: size,
    height: size,
    maxHeight: size,
    textAlign: 'center',
    borderRadius: '100%',
    fontSize
  };

  return (
    <div aria-label={name} className={classes.userAvatar}>
      <div className={classes.userAvatarInner} style={innerStyle}>
        {userInitial}
      </div>
    </div>
  );
};

export default UserAvatar;
