import React, { useState, useEffect, useRef } from 'react';

const CustomCarousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [startX, setStartX] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const slideRef = useRef(null);

  // Navigate to the next image
  const nextSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
  };

  // Navigate to the previous image
  const prevSlide = () => {
    setCurrentIndex(prevIndex => (prevIndex - 1 + images.length) % images.length);
  };

  // Navigate to a specific slide
  const goToSlide = index => {
    setCurrentIndex(index);
  };

  // Handle touch start
  const handleTouchStart = e => {
    setStartX(e.touches[0].clientX);
    setIsSwiping(true);
  };

  // Handle touch move
  const handleTouchMove = e => {
    if (!startX) return;
    const touchX = e.touches[0].clientX;
    const diffX = startX - touchX;

    // Only change slides if the swipe is significant
    if (Math.abs(diffX) > 50) {
      if (diffX > 0) {
        nextSlide(); // Swipe left to go to the next slide
      } else {
        prevSlide(); // Swipe right to go to the previous slide
      }
      setStartX(null); // Reset start position after a successful swipe
      setIsSwiping(false);
    }
  };

  // Handle touch end
  const handleTouchEnd = () => {
    setStartX(null);
    setIsSwiping(false);
  };

  // Auto slide feature every 3 seconds
  useEffect(() => {
    if (!isSwiping) {
      const autoSlide = setInterval(nextSlide, 3000);
      return () => clearInterval(autoSlide);
    }
  }, [isSwiping, images.length]);

  const styles = {
    container: {
      position: 'relative',
      width: '100%',
      maxWidth: '800px',
      margin: 'auto',
      overflow: 'hidden'
    },
    slides: {
      display: 'flex',
      transition: 'transform 0.5s ease-in-out'
    },
    slide: {
      minWidth: '100%',
      height: '400px',
      opacity: 0,
      transition: 'opacity 1s ease-in-out'
    },
    slideActive: {
      opacity: 1
    },
    slideImage: {
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      objectFit: 'contain'
    },
    button: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      color: 'white',
      border: 'none',
      cursor: 'pointer',
      padding: '10px',
      borderRadius: '50%',
      zIndex: 1
    },
    dotsContainer: {
      textAlign: 'center',
      padding: '10px'
    },
    dot: {
      display: 'inline-block',
      margin: '2px',
      width: '30px',
      height: '5px',
      backgroundColor: '#bbb',
      borderRadius: '10%',
      cursor: 'pointer'
    },
    dotActive: {
      backgroundColor: '#000000'
    }
  };

  return (
    <div
      style={styles.container}
      ref={slideRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {/* Carousel images */}
      <div style={{ ...styles.slides, transform: `translateX(-${currentIndex * 100}%)` }}>
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              ...styles.slide,
              ...(index === currentIndex ? styles.slideActive : {})
            }}
          >
            <img src={image.url} alt={`Slide ${index}`} style={styles.slideImage} />
          </div>
        ))}
      </div>

      {/* Navigation Dots */}
      <div style={styles.dotsContainer}>
        {images.map((_, index) => (
          <span
            key={index}
            style={{
              ...styles.dot,
              ...(index === currentIndex ? styles.dotActive : {})
            }}
            onClick={() => goToSlide(index)}
            aria-label={`Go to Slide ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

export default CustomCarousel;
