import React from 'react';
import { Typography as SwagupTypography } from '@swagup-com/components';
import StrikeOut from './StrikeOut';
import { shoppingExperienceColors } from '../constants';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import { DollarPriceSymbol, MembershipDiscountTextBadge } from './common';
import { useResponsive } from '../../hooks';
import { useCartContext } from '../../context/cart.context';

const MembershipPricing = ({ isLoading = false, classes, price = null, product = null, forStartingAt = false }) => {
  const { isAuthenticated } = useAuth();
  const { productDiscount } = useDiscountPrice();
  const { getWithAndWithoutDiscountedPricePerProduct } = useCartContext();

  const { isMobile } = useResponsive();

  const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(
    product,
    forStartingAt
  );
  const getDifference = () => {
    return totalPriceWithoutDiscount - totalPriceWithDiscount;
  };

  return (
    <>
      <div
        style={{ display: (!price || isMobile) && 'flex', gap: (!price || isMobile) && '16px', alignItems: 'center' }}
      >
        <SwagupTypography
          variant="h5SemiBoldInter"
          style={{ fontSize: price ? '16px' : '20px' }}
          className={classes?.price}
        >
          {!isLoading ? DollarPriceSymbol(totalPriceWithDiscount) : 'N/A'}
        </SwagupTypography>
        {isAuthenticated && !isLoading && productDiscount !== 0 && (
          <StrikeOut value={totalPriceWithoutDiscount} fontSize={price ? '12px' : '16px'} />
        )}
      </div>
      {isAuthenticated && !isLoading && productDiscount !== 0 && (
        <div
          style={{
            display: 'flex',
            justifyContent: !isMobile && price && 'end',
            alignItems: 'center',
            padding: isMobile && '10px 0px',
            marginTop: price && !isMobile && '16px',
            gap: '8px'
          }}
        >
          <MembershipDiscountTextBadge text="You save" typographyVariant="body3SemiBoldInter" />
          <SwagupTypography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.primary }}>
            {DollarPriceSymbol(getDifference())}
          </SwagupTypography>
        </div>
      )}
    </>
  );
};

export default MembershipPricing;
