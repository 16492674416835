import { shoppingExperienceColors } from '../../shared/constants';

const cartProductStyles = theme => ({
  cardContainer: {
    position: 'relative',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    borderRadius: 0,
    transition: 'transform 0.3s',
    minHeight: 284,
    [theme.breakpoints.down('sm')]: {
      minHeight: 216
    }
  },
  cardMediaStyle: {
    height: 316,
    width: 180,
    height: 180,
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      height: 80,
      width: 80
    },
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: 80
    },
    backgroundSize: 'contain'
  },
  productDetails: {
    marginBottom: 16,
    fontSize: 14,
    color: shoppingExperienceColors.ctaHover
  },
  disableQtyBtn: { opacity: 0.5, pointerEvents: 'none', userSelect: 'none' },
  cardContent: {
    paddingBottom: '0px !important',
    [theme.breakpoints.down('sm')]: {
      padding: '4px'
    }
  }
});

export default cartProductStyles;
