import React, { useState } from 'react';

const TruncatedText = ({ text, maxLines = 2 }) => {
  const handleText = (str, maxLines) => {
    const words = str.split(' ');
    let truncated = '';
    let lineCount = 0;
    const tempLine = '';

    // eslint-disable-next-line no-restricted-syntax
    while (lineCount < maxLines) {
      truncated += text.slice(lineCount * 15, (lineCount + 1) * 15);
      lineCount += 1;
    }

    truncated += text.slice(lineCount * 15, (lineCount + 1) * 17);
    return truncated.length < text.length ? `${truncated}...` : text;
  };

  return (
    <div
      style={{
        paddingTop: 8,
        cursor: 'pointer',
        maxWidth: '100%',
        position: 'relative'
      }}
      title={text}
    >
      <span
        style={{
          display: 'inline-block',
          whiteSpace: 'normal'
        }}
      >
        {handleText(text, maxLines)}
      </span>
    </div>
  );
};

export default TruncatedText;
