/* eslint-disable no-console */
import * as React from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import { Button } from '@swagup-com/components';
import { Box, Divider, Grid, makeStyles, Snackbar } from '@material-ui/core';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import AddContact from './AddContact';
import EditRecipient from './EditRecipient';
import CreateShipmentDialog from './CreateShipmentDialog';
import Filter from '../common/Filter';
import SortBy from '../common/SortBy';
import AddRecipientButton from './AddRecipientButton';
import SelectFromExisting from './SelectFromExisting';
import SelectedRecipientsTable from './SelectedRecipientsTable';
import ShipmentSummaryOld from './ShipmentSummary';
import ShipmentSummaryNew from './sendSwagFlow/ShipmentSummary';
import { ProgressContent } from '../../../../shared/SavingContacts';
import { statuses } from '../../../../../reducers/processReducer';
import apiPaths from '../../../../../helpers/apiPaths';
import { imageSrcSet } from '../../../../../helpers/utils';
import {
  addRecipients,
  addShipmentGroupRecipientsToSelect,
  cleanShipmentGroup,
  createdDirectoryOrders,
  selectShipmentGroupProducts,
  toggleSelectFromExisting
} from '../../../../../actions/shipmentGroupActions';
import { PROCESS_SUCCESS_PROCESSED } from '../../../../../actions/types';
import { contactsApi } from '../../../../../apis/swagup';
import SelectRecipientsHeader from './SelectRecipientsHeader';
import styles from './SelectRecipients.styles';
import { useFetchContactsByQuery, useFetchEmployeesByIds, useInSendSwagPath } from '../hooks';
import { useFetchGroups } from '../../../../groups/Groups';
import { shipmentStatuses, fiveMinutes } from '../../../../../helpers/helperConstants';
import { useQueryParams, useShippingCutoffHour } from '../../../../../hooks';
import InactiveSizesAlert from '../common/InactiveSizesAlert';
import { doesDirectoryHasInactiveSizes, mapProductsToInactiveSizes, mapProductToProof } from '../common/utilsOrder';
import { SelectionProvider } from '../../../shipments/contacts/SelectionContext';
import accountProducts from '../../../../../apis/swagup/accountProducts';
import SelectedRecipientPanel from './sendSwagFlow/SelectedRecipientPanel';
import { PRODUCT_DUE_DATE_STATUSES } from '../../../../../utils/constants';
import { useMembership } from '../../../../../contexts/membershipContext';
import { CustomTooltip } from './sendSwagFlow/common';

const useStyles = makeStyles(styles);

const sortByOptions = [
  { value: 'full_name', label: 'Name' },
  { value: 'created_at', label: 'Most Recent' }
];

const getButtonCopy = (comesFromSendSwag, isEditingShipmentGroup) => {
  if (comesFromSendSwag) return 'Review Shipment';
  return `${isEditingShipmentGroup ? 'Save' : 'Create'} Shipment`;
};

const contactsPayload = { ordering: '-created_at', limit: 12, offset: 0 };

const SelectRecipients = ({
  step,
  isLoading,
  limitedStock,
  onDelete,
  onCreate,
  onCreateAnother,
  onSendToWarehouse,
  onSkip,
  products = [],
  preventFurtherActions = false
}) => {
  const [selectedRecipient, setSelectedRecipient] = React.useState();
  const [isEditDrawerOpen, toggleEditDrawer] = React.useReducer(prevOpen => !prevOpen, false);
  const [isAddDrawerOpen, setIsAddDrawerOpen] = React.useState(false);
  const toggleIsAddDrawerOpen = React.useCallback(() => setIsAddDrawerOpen(prev => !prev), []);
  const [filters, setFilters] = React.useState();

  const query = useQueryParams();
  const product = query.get('product') || '';
  const { data: directProduct, isLoading: isLoadingProduct } = useQuery(
    [apiPaths.accountProduct(product)],
    () => accountProducts.getProduct(product),
    {
      enabled: !isEmpty(product),
      select: p => mapProductToProof(p)
    }
  );

  const dispatch = useDispatch();
  const shipment = useSelector(state => state.shipmentGroup);
  const { recipients, isSelectingFromExisting, status: shipmentStatus } = shipment;
  const [isSelectDrawerOpen, toggleIsSelectDrawerOpen] = React.useReducer(
    prevOpen => !prevOpen,
    isSelectingFromExisting
  );

  const [search, setSearch] = React.useState('');
  const debouncedSetSearch = debounce(setSearch, 350);
  const [ordering, setOrdering] = React.useState('created_at');

  const process = useSelector(state => state.process);
  const queryClient = useQueryClient();

  const [idsToFetch, setIdsToFetch] = React.useState();
  const [fetchedEmployees, isFetchingEmployees] = useFetchEmployeesByIds(idsToFetch);

  const [disableSendSwag, setDisableSendSwag] = React.useState(false);

  const { updatedSendSwagFlow, leftBarNavigation, storageFeeInventory } = useFlags();

  React.useEffect(() => {
    const { data, status } = process;
    if (status !== statuses.success) return;

    const ids = data.saved_contact_list.split(',');
    if (isEmpty(ids)) return;

    if (isSelectingFromExisting) {
      dispatch({ type: PROCESS_SUCCESS_PROCESSED });
      dispatch(addShipmentGroupRecipientsToSelect(ids));
      queryClient.invalidateQueries(apiPaths.contacts);
      return;
    }

    setIdsToFetch(ids);
  }, [process, dispatch, isSelectingFromExisting, queryClient]);

  const comesFromSendSwag = useInSendSwagPath();
  const shippingCutoffHour = useShippingCutoffHour();

  const addRecipientsCb = React.useCallback(
    contacts => dispatch(addRecipients(contacts, comesFromSendSwag, shippingCutoffHour)),
    [comesFromSendSwag, shippingCutoffHour, dispatch]
  );

  const { isLoading: isQueryLoading } = useFetchContactsByQuery(addRecipientsCb);

  React.useEffect(() => {
    if (fetchedEmployees) addRecipientsCb(fetchedEmployees);
  }, [fetchedEmployees, addRecipientsCb]);

  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const { contacts, ...state } = location.state || {};
    if (contacts?.length > 0) {
      addRecipientsCb(contacts.filter(c => !shipment.recipients.find(r => r.id === c.id)));
      history.replace({ ...location, state });
    }
  }, []);

  React.useEffect(() => {
    if (shipmentStatus === shipmentStatuses.success) {
      setIsAddDrawerOpen(false);
    }
  }, [shipmentStatus]);

  const { id, shipmentGroupId } = useParams();
  const hasShipmentGroupId = !Number.isNaN(+shipmentGroupId);

  React.useLayoutEffect(() => {
    if (hasShipmentGroupId || shipment.products.length > 0) return;

    if (isEmpty(product))
      if (history.length > 2) {
        history.goBack();
      } else {
        history.replace(`/orders-requested/${id}/select-products`);
      }
  }, [shipment.products.length, hasShipmentGroupId, limitedStock, history, id, product]);

  const { setAlertMessage } = useMembership();

  React.useEffect(() => {
    if (!isEmpty(product) && !isEmpty(directProduct)) {
      dispatch(cleanShipmentGroup);
      dispatch(selectShipmentGroupProducts([directProduct]));

      if (products.length) {
        const item = products.find(_product => _product.id === directProduct.id);

        if (!item) {
          setDisableSendSwag(false);
          return;
        }

        const nextPaymentDate = dayjs(item.nextPaymentDate);

        const currentDate = dayjs().format('MM/DD/YYYY');

        setDisableSendSwag(
          storageFeeInventory &&
            item.inventoryDueStatus !== PRODUCT_DUE_DATE_STATUSES.normal &&
            nextPaymentDate.diff(currentDate, 'day') < 0
        );
      }
    }
  }, [directProduct, dispatch, product, storageFeeInventory, products]);

  React.useEffect(() => {
    if (disableSendSwag) {
      setAlertMessage({
        message: `Sending Swag is not available either due to a negative Swag Card Balance or if the product selected has a payment due.`,
        type: 'disabledSendSwag'
      });
      history.push('/inventory?inventory=available');
    }
  }, [disableSendSwag]);

  React.useLayoutEffect(() => {
    queryClient.invalidateQueries([apiPaths.contacts]);
  }, [queryClient]);

  const employeesQuery = useQuery([apiPaths.contacts, contactsPayload], () => contactsApi.fetch(contactsPayload), {
    keepPreviousData: true,
    staleTime: fiveMinutes
  });

  const hasContacts = employeesQuery.status === 'success' && employeesQuery.data?.results.length > 0;

  useFetchGroups();

  const handleAddNewFromSelect = () => {
    toggleIsSelectDrawerOpen();
    toggleIsAddDrawerOpen();
  };

  const handleAddNew = contact => {
    setIsAddDrawerOpen(false);
    queryClient.invalidateQueries(apiPaths.contacts);
    if (isSelectingFromExisting) {
      dispatch(addShipmentGroupRecipientsToSelect([contact.id]));
      toggleIsAddDrawerOpen();
      toggleIsSelectDrawerOpen();
      return;
    }

    addRecipientsCb([contact]);
  };

  const handleCloseAddNew = () => {
    toggleIsAddDrawerOpen();
    if (isSelectingFromExisting) {
      toggleIsSelectDrawerOpen();
    }
  };

  const handleEditRecipient = recipient => {
    setSelectedRecipient(recipient);
    toggleEditDrawer();
  };

  const handleToggleIsSelectDrawerOpen = React.useCallback(() => {
    toggleIsSelectDrawerOpen();
    dispatch(toggleSelectFromExisting());
    if (shipmentStatus === shipmentStatuses.success) dispatch(createdDirectoryOrders());
  }, [shipmentStatus, dispatch]);

  const errorCount = Object.values(shipment.errors).filter(error => error.type === 'error').length;

  const isAddingRecipients = shipmentStatus === shipmentStatuses.adding || isQueryLoading;

  const shipmentGroupHasInactiveSizes = React.useMemo(() => {
    const { products, directoryOrders } = shipment;
    const productsWithInactiveSizes = mapProductsToInactiveSizes(products);
    return directoryOrders.some(doesDirectoryHasInactiveSizes(productsWithInactiveSizes));
  }, [shipment]);

  const inconsitentDM = shipment?.directoryOrders?.some(dior => dior.deliveryMethods?.every(dm => !dm.selected));

  const isCTADisabled =
    process.status === statuses.pending ||
    isAddingRecipients ||
    Boolean(recipients?.length === 0) ||
    isLoading ||
    isFetchingEmployees ||
    errorCount > 0 ||
    inconsitentDM;

  const classes = useStyles({ slimHeader: updatedSendSwagFlow, leftBarNavigation });

  const [buttonVariant, buttonText, buttonHandler, buttonClassName, buttonDisabled] =
    shipment.recipients.length === 0 && hasShipmentGroupId
      ? ['alert', 'Delete Shipment', onDelete, classes.deleteButton, isLoading]
      : [
          'primary',
          getButtonCopy(comesFromSendSwag, hasShipmentGroupId),
          onCreate,
          classes.createButton,
          isCTADisabled
        ];

  const RecipientPanel = updatedSendSwagFlow ? SelectedRecipientPanel : SelectedRecipientsTable;
  const ShipmentSummary = updatedSendSwagFlow ? ShipmentSummaryNew : ShipmentSummaryOld;

  if (shipment.products.length === 0) return null;

  return (
    <Grid
      container
      style={{
        height: '100%'
      }}
    >
      <Snackbar
        open={(isAddingRecipients && !isAddDrawerOpen && !isSelectDrawerOpen) || isLoadingProduct}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        style={{ marginBottom: process.status === statuses.idle ? 58 : 108 }}
      >
        <ProgressContent text={isLoadingProduct ? 'Loading Selected Product' : 'Adding recipients'} />
      </Snackbar>
      <Grid item container xs direction="column">
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="center"
          className={classes.toolbar}
          style={{ padding: updatedSendSwagFlow ? '0 20px 0px 80px' : '0 80px' }}
        >
          <SelectRecipientsHeader
            debouncedSetSearch={debouncedSetSearch}
            addRecipientsButton={
              <AddRecipientButton
                onSelectExisting={handleToggleIsSelectDrawerOpen}
                onAddNew={toggleIsAddDrawerOpen}
                disabled={isAddingRecipients}
              />
            }
          >
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <Filter filters={filters} onFiltersSubmit={setFilters} />
            <Divider orientation="vertical" flexItem className={classes.verticalDivider} />
            <SortBy options={sortByOptions} selected={ordering} onChange={setOrdering} />
          </SelectRecipientsHeader>
        </Grid>
        {/* {shipmentGroupHasInactiveSizes && !comesFromSendSwag && (
          <InactiveSizesAlert style={{ margin: '0px 0px 25px', width: 'inherit' }} />
        )} */}
        {recipients.length ? (
          <RecipientPanel search={search} ordering={ordering} filters={filters} onEditRecipient={handleEditRecipient} />
        ) : (
          <Grid
            item
            xs
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            style={{ padding: '0 80px' }}
          >
            <img alt="Contacts" srcSet={imageSrcSet('/images/orders/empty-contacts.png')} style={{ marginTop: -50 }} />
            <p className={classes.emptyStateTitle}>You don&apos;t have any saved recipients</p>
            <p className={classes.subtitle}>Add recipients to start creating shipments</p>
            <Box mt="36px">
              <AddRecipientButton
                onSelectExisting={handleToggleIsSelectDrawerOpen}
                onAddNew={toggleIsAddDrawerOpen}
                disabled={isAddingRecipients || preventFurtherActions}
                disableSelect={!hasContacts}
              />
            </Box>
          </Grid>
        )}
        <AddContact open={isAddDrawerOpen} onClose={handleCloseAddNew} onSuccess={handleAddNew} />
        <SelectionProvider>
          <SelectFromExisting
            open={isSelectDrawerOpen}
            onClose={handleToggleIsSelectDrawerOpen}
            onAddNew={handleAddNewFromSelect}
          />
        </SelectionProvider>
      </Grid>
      <ShipmentSummary>
        <CustomTooltip
          title="Some recipient's delivery methods are not properly selected."
          disableHoverListener={!inconsitentDM}
          fullWidth
        >
          <div style={{ width: '100%' }}>
            <Button
              variant={buttonVariant}
              disabled={buttonDisabled || shipment?.isLoading}
              loading={isLoading || shipment?.isLoading}
              className={buttonClassName}
              onClick={buttonHandler}
            >
              {buttonText}
            </Button>
          </div>
        </CustomTooltip>
      </ShipmentSummary>

      <CreateShipmentDialog
        open={step === 'create-shipment'}
        disabled={isCTADisabled}
        onClose={history.goBack}
        onCreateAnother={onCreateAnother}
        onSendToWarehouse={onSendToWarehouse}
        onSkip={onSkip}
      />
      <EditRecipient
        limitedStock={limitedStock}
        recipientId={selectedRecipient}
        open={isEditDrawerOpen}
        onOpen={toggleEditDrawer}
        onClose={toggleEditDrawer}
      />
    </Grid>
  );
};

export default SelectRecipients;
