/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Skeleton } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shoppingExperienceColors } from '../../shared/constants';
import { useResponsive } from '../../hooks';
import CartQuantityBreak from './cartQuantityBreakup';
import { useCartContext } from '../../context/cart.context';
import { DollarPriceSymbol, MembershipDiscountTextBadge } from '../../shared/ui/common';
import StrikeOut from '../../shared/ui/StrikeOut';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';

const styles = {
  accordionRoot: {
    margin: '0px 16px',
    '&.MuiAccordion-rounded:first-child': {
      border: 'none',
      borderRadius: '0px',
      boxShadow: 'unset',
      '&.MuiAccordion-root.Mui-expanded': {
        margin: '0px 16px'
      }
    }
  }
};

const useStyles = makeStyles(styles);

const CartItems = ({ isLoading, emptyState, products }) => {
  const { getWithAndWithoutDiscountedPricePerProduct } = useCartContext();

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        {isLoading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography style={{ paddingBottom: '20px' }} variant="body3RegularInter">
            Number of Items:
          </Typography>
        )}{' '}
        {isLoading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography style={{ paddingBottom: '20px' }} variant="body3RegularInter">
            {emptyState ? '-' : products.length}
          </Typography>
        )}
      </Box>
      {products.map(product => {
        const { totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(product);
        return (
          <Box key={`pricing-info-${product.cartId}`} display="flex" justifyContent="space-between" marginBottom="20px">
            {isLoading ? (
              <Skeleton variant="text" width={100} height={40} />
            ) : (
              <Typography variant="body3RegularInter">{product?.name}</Typography>
            )}
            {isLoading ? (
              <Skeleton variant="text" width={100} height={40} />
            ) : (
              <Typography variant="body3SemiBoldInter">{DollarPriceSymbol(totalPriceWithoutDiscount)}</Typography>
            )}
          </Box>
        );
      })}
    </>
  );
};

const CartOrderSummary = ({ products, isLoading, IS_PACK = false, emptyState = false }) => {
  const { isMobile } = useResponsive();
  const { totalEstimationWithDiscount, totalEstimationWithoutDiscount } = useCartContext();

  const { isAuthenticated } = useAuth();

  const classes = useStyles();

  return (
    <>
      {!isMobile ? (
        <>
          {isLoading ? (
            <Skeleton variant="text" width={100} height={40} />
          ) : (
            <Typography variant="h3SemiBoldInter" style={{ paddingBottom: '20px' }}>
              Order summary
            </Typography>
          )}
          <CartItems isLoading={isLoading} emptyState={emptyState} products={products} />
        </>
      ) : (
        <>
          <Accordion className={classes.accordionRoot}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Typography variant="h3SemiBoldInter">Order summary</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid item style={{ padding: '0px 8px' }}>
                <CartItems isLoading={isLoading} emptyState={emptyState} products={products} />
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      <Divider sx={{ marginY: '10px' }} />

      {/* Quantity break */}
      {IS_PACK === true && <CartQuantityBreak products={products} />}
      {isAuthenticated && totalEstimationWithoutDiscount !== totalEstimationWithDiscount && (
        <>
          <Divider sx={{ marginY: '10px' }} />{' '}
          <Grid
            container
            style={{
              padding: '20px 0px',
              margin: isMobile && '0px 20px',
              display: 'flex',
              justifyContent: 'space-between',
              width: 'auto'
            }}
          >
            <Grid item>
              <Typography variant="body2SemiBoldInter">Subtotal Estimate</Typography>
            </Grid>
            <Grid item>
              <StrikeOut value={totalEstimationWithoutDiscount} />
            </Grid>
            <Grid
              container
              style={{ width: '100%', marginTop: '20px', alignItems: 'center', justifyContent: 'space-between' }}
            >
              <MembershipDiscountTextBadge text="Membership Discounts" typographyVariant="body3SemiBoldInter" />
              <Typography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.primary }}>
                -{DollarPriceSymbol(totalEstimationWithoutDiscount - totalEstimationWithDiscount)}
              </Typography>
            </Grid>
          </Grid>
          <Divider sx={{ marginY: '10px' }} />{' '}
        </>
      )}
      <Box
        display="flex"
        justifyContent="space-between"
        marginTop="20px"
        padding={isMobile && '0px 20px'}
        alignItems="center"
      >
        {isLoading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography variant="body2SemiBoldInter">Total Estimate</Typography>
        )}
        {isLoading ? (
          <Skeleton variant="text" width={100} height={40} />
        ) : (
          <Typography variant="h5SemiBoldInter">
            {emptyState ? '-' : DollarPriceSymbol(totalEstimationWithDiscount)}
          </Typography>
        )}
      </Box>
      {isLoading ? (
        <Skeleton variant="text" width={100} height={40} />
      ) : (
        <Typography
          variant="body3RegularInter"
          style={{ color: shoppingExperienceColors.ctaHover, padding: isMobile && '0px 20px' }}
        >
          (Not including taxes and fees)
        </Typography>
      )}
    </>
  );
};

export default CartOrderSummary;
