import { shoppingExperienceColors } from '../../shared/constants';

const FooterStyles = theme => ({
  footer: {
    backgroundColor: shoppingExperienceColors.black,
    color: shoppingExperienceColors.white,
    padding: '60px 40px',
    marginTop: '40px',
    width: '100%',
    [theme.breakpoints.between(0, 768)]: {
      padding: '40px',
      marginTop: '0px'
    }
  },
  socialMediaIcons: {
    width: '48px',
    height: '48px',
    objectFit: 'contain'
  },
  footerSubHeading: {
    color: shoppingExperienceColors.white,
    fontWeight: 500,
    paddingBottom: '12px'
  },
  footerSubText: {
    color: shoppingExperienceColors.white,
    padding: '12px 0px'
  },
  footerMediaLink: {
    marginTop: '70px',
    height: '70px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
      marginTop: '40px'
    }
  },
  accordion: {
    backgroundColor: 'transparent',
    boxShadow: 'none'
  },
  accordionSummary: {
    color: shoppingExperienceColors.white,
    '& .MuiAccordionSummary-content': {
      margin: 0
    }
  },
  accordionDetails: {
    backgroundColor: 'transparent',
    flexDirection: 'column'
  },
  divider: {
    backgroundColor: shoppingExperienceColors.white
  },
  backToTopContainer: {
    height: '100px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0px auto'
  }
});

export default FooterStyles;
