import React, { useEffect, useState } from 'react';
import { Box, TextField, InputAdornment, IconButton, makeStyles, CircularProgress, Grid } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Button, Typography } from '@swagup-com/components';
import { useMutation } from 'react-query';
import CryptoJS from 'crypto-js';
import styles from './login.styles';
import { CommonButton } from '../../shared/ui/commonButtons';
import { RightArrowIcon } from '../../shared/icons';
import { shoppingExperienceColors } from '../../shared/constants';
import { signinApi } from '../../apis/auth';
import PrivacyAndTermsSection from '../../components/Auth/PrivacyAndTermsSection';

const redirectUri = `${process.env.REACT_APP_PUBLIC_URL}okta-callback`;

const useStyles = makeStyles(styles);

const Login = ({ onClose, onOpen, defaultEmail }) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSubmitting, setSubmitting] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .email('Invalid email')
      .required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const { mutate, data } = useMutation(signinApi, {
    onSuccess: resp => {
      console.log('Signin successful:', resp);
    },
    onError: error => {
      setSubmitting(false);
      if (error.response.status === 401) {
        setErrorMessage('Invalid credentials');
      } else {
        setErrorMessage('An error occurred. Please try again.');
      }
    }
  });

  const handleSubmit = values => {
    setSubmitting(true);
    try {
      mutate({
        ...values,
        password: CryptoJS.AES.encrypt(values.password, process.env.REACT_APP_OKTA_LOGIN_PWD_SECRET).toString()
      });
    } catch (err) {
      console.log(err);
    }
  };

  const toggleForgotPasswordModal = () => {
    onClose(true);
    onOpen(false);
  };

  useEffect(() => {
    if (data) {
      const { sessionToken, nonce, state } = data.Signin;

      localStorage.setItem('okta_state', state);
      localStorage.setItem('okta_nonce', nonce);

      const url =
        `${process.env.REACT_APP_OKTA_URI}/oauth2/default/v1/authorize?` +
        `sessionToken=${sessionToken}&` +
        `client_id=${process.env.REACT_APP_OKTA_CLIENT_ID}&` +
        `response_type=token id_token&` +
        `scope=openid profile email&` +
        `redirect_uri=${encodeURIComponent(redirectUri)}&` +
        `nonce=${encodeURIComponent(nonce)}&` +
        `state=${encodeURIComponent(state)}`;
      window.location.href = url;
    }
  }, [data]);

  return (
    <Box className={classes.root}>
      <Formik
        initialValues={{ username: defaultEmail !== '' ? defaultEmail : '', password: '' }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched }) => (
          <Form className={classes.form}>
            <Field
              name="username"
              as={TextField}
              placeholder="Email*"
              variant="outlined"
              fullWidth
              className={`${classes.field} ${
                touched.username && errors.username ? classes.placeholderError : classes.placeholderNormal
              }`}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              InputProps={{
                style: { height: '50px' }
              }}
            />
            <Field
              name="password"
              as={TextField}
              placeholder="Password*"
              type={showPassword ? 'text' : 'password'}
              variant="outlined"
              fullWidth
              className={classes.field}
              error={touched.password && Boolean(errors.password)}
              helperText={touched.password && errors.password}
              onClick={() => setErrorMessage('')}
              InputProps={{
                style: { height: '50px' },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={togglePasswordVisibility} edge="end">
                      {showPassword ? (
                        <Visibility style={{ color: '#131415' }} />
                      ) : (
                        <VisibilityOff style={{ color: '#131415' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            {errorMessage && (
              <Typography variant="body3RegularInter" className={classes.errorMessage}>
                {errorMessage}
              </Typography>
            )}
            <Typography variant="body3RegularInter" className={classes.passwordLink}>
              <Button
                variant="text"
                type="button"
                onClick={toggleForgotPasswordModal}
                style={{ margin: 0, padding: 0, maxHeight: 'fit-content' }}
              >
                <Typography variant="body3RegularInter" className={classes.forgotPasswordText}>
                  Forgot password?
                </Typography>
              </Button>
            </Typography>
            <CommonButton
              text="Sign In"
              px={50}
              isActive
              mr={10}
              hasIcon
              icon={
                isSubmitting ? (
                  <CircularProgress size={14} className={classes.loadingIcon} />
                ) : (
                  <RightArrowIcon color="#ffff" />
                )
              }
              ht={56}
              type="submit"
              disabled={isSubmitting}
            />
            <Typography variant="body3RegularInter" className={classes.link}>
              Don't have an account?{' '}
              <a
                style={{ fontWeight: 'bold', color: shoppingExperienceColors.primary }}
                href="https://signin.swagup.com/signin/register"
              >
                Sign Up
              </a>
            </Typography>
            <Grid item style={{ marginTop: 10 }}>
              <PrivacyAndTermsSection />
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default Login;
