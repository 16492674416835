import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Modal } from '../../../components/shared/Modal';

const useStyles = makeStyles({
  container: { width: 'calc(100vw - 64px)', height: '75vh', margin: 0 },
  typeformContainer: { width: '100%', height: '100%', border: 0 },
  content: { padding: '0px' }
});

const TypeFormExpertModal = ({
  open,
  onClose,
  typeformSrc = 'https://swagup.typeform.com/to/GO3Flf4c',
  typeformId = 'typeform-dialog'
}) => {
  const classes = useStyles();
  return (
    <Modal open={open} onClose={onClose} typeform={classes.content}>
      <div className={classes.container}>
        <iframe
          id={typeformId}
          src={typeformSrc}
          title="typeform"
          allowFullScreen
          className={classes.typeformContainer}
        />
      </div>
    </Modal>
  );
};

export default TypeFormExpertModal;
