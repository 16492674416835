// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import { request, gql } from 'graphql-request';
import { apiRoutes } from './apiRoutes';

export const signinApi = async payload => {
  const variables = {
    payload
  };

  const SIGN_IN = gql`
    mutation Signin($payload: SigninInput!) {
      Signin(payload: $payload) {
        sessionToken
        state
        nonce
        user {
          id
          firstName
          lastName
          locale
          timeZone
        }
      }
    }
  `;

  return request(
    `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`,
    SIGN_IN,
    variables,
    {
      'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
    }
  ).then(data => {
    return data;
  });
};

export const forgetPasswordApi = async payload => {
  return axios.post(apiRoutes.forgotPasswordEndpoint, payload);
};
