import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import logo from '../../assets/images/logo/swagup_Logo_with_Name.svg';
import { shoppingExperienceColors } from '../../shared/constants';
import { TextLink as FooterLink } from '../../shared/ui/common';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(() => ({
  footerContainer: {
    height: '70px',
    width: '100%',
    backgroundColor: shoppingExperienceColors.black,
    padding: '10px 40px'
  },
  footerContainerMobile: {
    height: '120px',
    width: '100%',
    backgroundColor: shoppingExperienceColors.black,
    padding: '20px 40px',
    display: 'flex',
    alignItems: 'flex-end'
  },
  desktopHeaderLogo: {
    height: '25px',
    width: '100%'
  },
  textLinks: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px'
  },
  textLinksMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerSubText: {
    color: shoppingExperienceColors.white,
    paddingTop: '10px',
    cursor: 'pointer'
  }
}));

const SimpleFormFooter = () => {
  const classes = useStyles();
  const { isMobile } = useResponsive();

  return (
    <Grid container className={isMobile ? classes.footerContainerMobile : classes.footerContainer}>
      <img src={logo} alt="Logo" className={classes.desktopHeaderLogo} />
      <Grid container className={isMobile ? classes.textLinksMobile : classes.textLinks}>
        <Grid item>
          <FooterLink
            href="https://www.swagup.com/privacy-policy-dba"
            target="_blank"
            variant="body4RegularInter"
            className={classes.footerSubText}
          >
            Privacy Policy
          </FooterLink>
        </Grid>
        {!isMobile && (
          <Grid item className={classes.footerSubText}>
            |
          </Grid>
        )}
        <Grid item>
          <FooterLink
            href="https://www.swagup.com/terms-of-use"
            target="_blank"
            variant="body4RegularInter"
            className={classes.footerSubText}
          >
            Terms of Use
          </FooterLink>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SimpleFormFooter;
