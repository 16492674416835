import { shoppingExperienceColors } from '../../shared/constants';

const forgotPasswordStyles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    maxWidth: '400px'
  },
  form: {
    width: '324px',
    marginTop: '20px'
  },
  field: {
    '& input::placeholder': {
      color: shoppingExperienceColors.textPrimary,
      opacity: 1
    },
    '& .Mui-error input::placeholder': {
      color: shoppingExperienceColors.validationErrorText
    },
    '& .Mui-error input': {
      color: shoppingExperienceColors.validationErrorText
    },
    marginBottom: '0px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0',
      '& fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&.Mui-focused fieldset': {
        borderColor: shoppingExperienceColors.textPrimary
      },
      '&.Mui-error fieldset': {
        color: shoppingExperienceColors.validationErrorText
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: shoppingExperienceColors.borderLineGray,
      borderRadius: 5
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#000'
    },
    '& .MuiInputLabel-root': {
      display: 'none'
    },
    '& .MuiFormHelperText-root.Mui-error': {
      color: `${shoppingExperienceColors.validationErrorText} !important`,
      textAlign: 'left',
      marginLeft: 0
    }
  },

  button: {
    marginTop: '16px',
    width: '100%'
  },
  passwordLink: {
    textAlign: 'left',
    color: shoppingExperienceColors.primary
  },
  link: {
    textAlign: 'center',
    marginTop: '0px',
    fontSize: '14px',
    padding: '18px 0px'
  },
  loginBtn: {
    maxHeight: 'fit-content',
    padding: '0px',
    minWidth: 'unset',
    marginLeft: '4px',
    fontFamily: 'inter',
    fontWeight: 600,
    fontSize: '14px'
  },
  subtitleText: { margin: '8px 24px 0px 24px', textAlign: 'center' },
  privacyAndTermsOfUseLink: {
    color: '#4A4F54',
    textDecoration: 'underline',
    fontWeight: 600
  },
  loadingIcon: { color: '#fff' }
});

export default forgotPasswordStyles;
