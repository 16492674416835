import { makeStyles } from '@material-ui/core/styles';
import { ImageLink } from './common';
import { ReactComponent as swagupLogoWithName } from '../../assets/images/logo/swagup_Logo_with_Name.svg';
import { ReactComponent as swagupRocket } from '../../assets/images/logo/swagup_Logo.svg';
import useResponsive from '../../hooks/useResponsive';
import styles from './swagupLogo.styles';

const useStyles = makeStyles(styles);

export const SwagupLogo = () => {
  const { isMobile, isMobileOrTablet } = useResponsive();
  const classes = useStyles();
  const LogoURL = process.env.REACT_APP_MAIN_DOMAIN;

  return (
    <ImageLink
      IconComponent={isMobileOrTablet ? swagupRocket : swagupLogoWithName}
      linkUrl={LogoURL}
      alt="swagup-logo"
      className={isMobileOrTablet ? classes.logoRocket : classes.logowithName}
      style={{ width: isMobile && '55px', height: isMobile && '55px' }}
    />
  );
};
