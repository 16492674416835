import React from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Typography } from '@swagup-com/components';
import { CircularProgress, TextField } from '@material-ui/core';
import { RightArrowIcon } from '../../shared/icons';
import PrivacyAndTermsSection from './PrivacyAndTermsSection';

const ForgetPasswordForm = ({ handleSubmit, classes, isSubmitting, toggleLoginModal, PrimaryButton }) => {
  const validationSchema = Yup.object({
    username: Yup.string()
      .email('Invalid email')
      .required('Email is required')
  });

  return (
    <Formik
      initialValues={{ username: '', factorType: 'EMAIL' }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched }) => {
        return (
          <Form className={classes.form}>
            <Field
              name="username"
              as={TextField}
              placeholder="Email*"
              variant="outlined"
              fullWidth
              className={`${classes.field} ${
                touched.username && errors.username ? classes.placeholderError : classes.placeholderNormal
              }`}
              error={touched.username && Boolean(errors.username)}
              helperText={touched.username && errors.username}
              InputProps={{
                style: { height: '50px', marginBottom: '0px' }
              }}
            />
            <PrimaryButton
              label="Send recovery link"
              disabled={isSubmitting}
              type="submit"
              Icon={
                isSubmitting ? (
                  <CircularProgress size={14} className={classes.loadingIcon} />
                ) : (
                  <RightArrowIcon color="#fff" />
                )
              }
            />
            <Typography variant="body3RegularInter" className={classes.link}>
              Back to
              <Button variant="text" className={classes.loginBtn} onClick={toggleLoginModal}>
                Sign in
              </Button>
            </Typography>
            <PrivacyAndTermsSection />
            <input type="hidden" name="factorType" value="EMAIL" />
          </Form>
        );
      }}
    </Formik>
  );
};

export default ForgetPasswordForm;
