import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core/styles';
import { shoppingExperienceColors } from '../constants';
import { ImageLink } from './common';
import { ReactComponent as LinkedInLogo } from '../../assets/images/logo/LinkedIn.svg';
import { ReactComponent as InstagramLogo } from '../../assets/images/logo/Instagram.svg';
import { ReactComponent as FacebookLogo } from '../../assets/images/logo/Facebook.svg';
import { ReactComponent as TwitterLogo } from '../../assets/images/logo/Twitter.svg';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(() => ({
  socialMediaIcons: {
    width: '48px',
    height: '48px',
    objectFit: 'contain'
  }
}));

const SocialMedia = () => {
  const classes = useStyles();
  const { isMobile } = useResponsive();

  const LinkedInURL = 'https://www.linkedin.com/company/swagup/';
  const InstagramURL = 'https://www.instagram.com/swagup/';
  const FacebookURL = 'https://www.facebook.com/weareswagup/';
  const TwitterURL = 'https://x.com/swagup';

  return (
    <>
      <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.white }}>
        Follow us
      </SwagupTypography>
      <Grid container alignItems="center" justifyContent={isMobile && 'space-between'}>
        <ImageLink
          IconComponent={LinkedInLogo}
          linkUrl={LinkedInURL}
          target="_blank"
          alt="LinkedIn"
          className={classes.socialMediaIcons}
        />
        <ImageLink
          IconComponent={InstagramLogo}
          linkUrl={InstagramURL}
          target="_blank"
          alt="Instagram"
          className={classes.socialMediaIcons}
        />
        <ImageLink
          IconComponent={FacebookLogo}
          linkUrl={FacebookURL}
          target="_blank"
          alt="Facebook"
          className={classes.socialMediaIcons}
        />
        <ImageLink
          IconComponent={TwitterLogo}
          linkUrl={TwitterURL}
          target="_blank"
          alt="Twitter"
          className={classes.socialMediaIcons}
        />
      </Grid>
    </>
  );
};

export default SocialMedia;
