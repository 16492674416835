import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { Info, LocalShipping } from '@material-ui/icons';

const useStyles = makeStyles({
  tagText: {
    color: '#EF6C00',
    fontSize: ({ small }) => (small ? '12px' : undefined),
    display: ({ hide }) => (hide ? 'none' : undefined)
  },
  tagIcon: {
    color: '#EF6C00',
    fontSize: ({ small }) => (small ? '12px' : 16),
    marginBottom: ({ small }) => (small ? -2 : -5),
    display: ({ hide }) => (hide ? 'none' : undefined)
  },
  tagBox: { height: 28, display: ({ hideAll, hide }) => (hide && hideAll ? 'none' : undefined) },
  infoIcon: {
    color: '#EF6C00',
    fontSize: ({ small }) => (small ? '12px' : 16),
    marginBottom: ({ small }) => (small ? -3 : -5),
    display: ({ hide }) => (hide ? 'none' : undefined)
  }
});

const USShippingOnlyTag = ({ product, hideAll, style, small, infoIcon, iconStyle, infoIconStyle }) => {
  const classes = useStyles({ hide: product?.can_ship_international, hideAll, small });

  return (
    <Grid container alignItems="center" spacing={small ? 0 : 2} className={classes.tagBox} style={style}>
      <Grid item>
        <LocalShipping className={classes.tagIcon} style={iconStyle} />
      </Grid>
      <Grid item xs>
        <Typography variant="body3RegularInter" className={classes.tagText}>
          US Shipping Only
        </Typography>
      </Grid>
      {infoIcon && (
        <Grid item>
          <Info className={classes.infoIcon} style={infoIconStyle} />
        </Grid>
      )}
    </Grid>
  );
};

export default USShippingOnlyTag;
