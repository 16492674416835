import React from 'react';
import ShoppingExperience from '.';
import Catalog from './pages/catalog';
import ProductDetails from './pages/ProductPage/ProductDetails';
import ShoppingCart from './pages/cart';
import Collections from './pages/collections';
import SearchResults from './pages/searchResults';
import Confirmation from './pages/ConfirmationPage/confirmation';
import ProjectOnboarding from './pages/projectOnboarding';
import GuestCheckout from './pages/CheckOutPage/guestCheckout';

const ProjectSubmissionComponent = props => {
  return <GuestCheckout {...props} />;
};

const routes = [
  {
    path: '/start-project',
    exact: true,
    Component: props => <ShoppingExperience {...props} isCategoryPage={false} component={ProjectOnboarding} />
  },
  {
    path: '/catalog',
    exact: true,
    Component: props => <ShoppingExperience {...props} isCategoryPage={false} component={Catalog} />
  },
  {
    path: '/catalog/collections',
    exact: true,
    Component: props => <ShoppingExperience {...props} isCategoryPage={false} component={Collections} />
  },
  {
    path: '/catalog/collections/:selectedCollections',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={Collections} />
  },
  {
    path: '/catalog/:category',
    exact: true,
    Component: props => <ShoppingExperience {...props} isCategoryPage component={Catalog} />
  },
  {
    path: '/catalog/:category/:subCategory',
    exact: true,
    Component: props => <ShoppingExperience {...props} isCategoryPage component={Catalog} />
  },
  {
    path: '/catalog/product/:productName/:productId',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={ProductDetails} />
  },
  {
    path: '/catalog/product/:collection/:productName/:productId',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={ProductDetails} />
  },
  {
    path: '/catalog/product/:collection/:category/:productName/:productId',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={ProductDetails} />
  },
  {
    path: '/cart',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={ShoppingCart} />
  },
  {
    path: '/search',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={SearchResults} />
  },
  {
    path: '/project-submission',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={ProjectSubmissionComponent} />
  },
  {
    path: '/project-submission-confirmation',
    exact: true,
    Component: props => <ShoppingExperience {...props} component={Confirmation} />
  }
];

export default routes;
