/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Box, IconButton, Modal, Hidden, Tooltip, Button } from '@material-ui/core';
import { Info, Remove, Add, Close } from '@material-ui/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { makeStyles } from '@material-ui/core/styles';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { useQuery } from 'react-query';
import { Skeleton } from '@material-ui/lab';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import { round } from 'lodash';
import { Helmet } from '../../../components/shared';
import Breadcrumb from '../../components/Breadcrumb/breadcrumb';
import { CommonButton } from '../../shared/ui/commonButtons';
import { SwagUpLogoSmallIcon, InfoIcon } from '../../shared/icons';
import FAQAccordion from '../../shared/ui/FAQ';
import { shoppingExperienceColors } from '../../shared/constants';
import CustomCarousel from './ProductCarousal';
import { useResponsive } from '../../hooks';
import { getBreadCrumbs } from '../../shared/helper';
import ColorSelection from '../../shared/ui/colorSelection';
import { useCartContext } from '../../context/cart.context';
import { fetchCatalogProductDetails } from '../../apis/fetchProductDetail';
import { normalizePricingMatrix, getDistinctSizesByColor } from '../../utils/productUtil';
import RightSidebar from './SideBar';
import Styles from './productPage.styles';
import CartPopup from '../../components/CartPopup/cartPopup';
import CustomModal from '../../shared/ui/modal';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import { CapitalizeWords, DollarPriceSymbol } from '../../shared/ui/common';
import { getPriceForQuantity } from '../../utils/getPriceForQuantity';
import { MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK, toSnakeCase } from '../../utils/constants';
import MembershipPricing from '../../shared/ui/membershipPricing';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import { useMembership } from '../../../contexts/membershipContext';
import { getPlatinumProductDiscount, getPlatinumProductDiscountPrice } from '../../utils/getPlatinumProductDiscount';

const useItemsQuery = productId => {
  return useQuery(['data', productId], () => fetchCatalogProductDetails(productId));
};

const useStyles = makeStyles(Styles);

const SeeHowToSaveMore = ({ toggleSidebar, isLoading, variant }) => {
  return (
    <div onClick={toggleSidebar} style={{ cursor: 'pointer' }}>
      {isLoading ? (
        <Skeleton variant={variant} />
      ) : (
        <SwagupTypography
          variant="body2RegularInter"
          color={shoppingExperienceColors.black}
          style={{ color: shoppingExperienceColors.primary, textDecoration: 'underline' }}
        >
          See how to save more
        </SwagupTypography>
      )}
    </div>
  );
};

const ProductDetails = ({ isTouchDevice, isMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const queryStrings = new URLSearchParams(location.search);

  const {
    addToCart: addToCartFn,
    isHazmatExists,
    isPack,
    qtyForPack,
    getWithAndWithoutDiscountedPricePerProduct
  } = useCartContext();
  const { currentDevice, DEVICES_TYPE } = useResponsive();
  const { isAuthenticated } = useAuth();
  const { productDiscount } = useDiscountPrice();

  const PATHS = getBreadCrumbs().slice(0, -1);
  const { data, isLoading } = useItemsQuery(getBreadCrumbs().slice(-1)[0].name);
  const [selectedColor, setSelectedColor] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [decoration, setDecoration] = useState('');
  const [currIndex, setCurrIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [modalImageSrc, setModalImageSrc] = useState('');
  const isMobileUp = [DEVICES_TYPE.TABLET, DEVICES_TYPE.LAPTOP, DEVICES_TYPE.DESKTOP];

  const [faqData, setFaqData] = useState([]);
  const [bagPrice, setBagPrice] = useState(0);
  const [media, setMedia] = useState([]);
  const [prettifiedData, setPrettifiedData] = useState(null);
  const [pricingMatrix, setPricingMatrix] = useState({});
  const [productData, setProductData] = useState({});
  const [productDataForAddToCartModal, setProductDataForAddToCartModal] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => setShowAll(!showAll);
  const membershipCheck = useMembershipCheck();
  const { memberships } = useMembership();
  const PlatinumMembershipTier = membershipCheck?.isPlatinum;
  const PlatinumProductDiscount = getPlatinumProductDiscount(memberships);

  const displayedColors = showAll
    ? data?.distributorCatalogProductDetails?.variants || []
    : data?.distributorCatalogProductDetails?.variants.slice(0, 13) || [];
  const remainingCount = data?.distributorCatalogProductDetails?.variants.length - 13;

  const defaultApplicableQty = useMemo(() => {
    return isPack ? 1 : data?.distributorCatalogProductDetails?.minimumOrderQuantity;
  }, [isPack, data?.distributorCatalogProductDetails?.minimumOrderQuantity]);

  // sidebar
  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const updateTheApplicablePrice = qty => {
    let selectedVariant = { ...data?.distributorCatalogProductDetails?.variants[0] };
    if (selectedColor) {
      selectedVariant =
        data?.distributorCatalogProductDetails?.variants?.find(variant => variant.color.colorName === selectedColor) ||
        selectedVariant;
    }
    const pricingOption = [...selectedVariant.pricingOption];
    const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct({
      ...productData,
      quantity: qty,
      pricingOption
    });
    const applicablePrice =
      isAuthenticated && productDiscount !== 0 ? totalPriceWithDiscount : totalPriceWithoutDiscount;
    setBagPrice(applicablePrice || 0);
  };

  const realImages = useMemo(() => {
    if (!data?.distributorCatalogProductDetails) return [];
    const { media: rootMedia = [] } = data?.distributorCatalogProductDetails;
    return rootMedia.filter(mediaItem => mediaItem.mediaCategory === 'lifestyle');
  }, [data?.distributorCatalogProductDetails]);

  const nextImage = () => {
    if (currIndex + 1 < media.length) setCurrIndex(prev => prev + 1);
    else setCurrIndex(0);
  };

  const prevImage = () => {
    if (currIndex - 1 < 0) setCurrIndex(media.length - 1);
    else setCurrIndex(prev => prev - 1);
  };

  const toggleModal = (src = '') => {
    setIsModalOpen(!isModalOpen);
    setModalImageSrc(src);
  };

  const onClose = () => setOpenModal(false);

  const addToCart = (product, qty) => {
    const selectedVariant = productData.variants.find(variant => variant.color.colorName === selectedColor) || {};
    const color = { ...selectedVariant?.color } || {};
    const pricingOption = [...selectedVariant?.pricingOption] || [];
    addToCartFn(
      {
        ...productData,
        color,
        pricingOption,
        decorationMethod: decoration
      },
      qty,
      true
    );
    const price = getPriceForQuantity(
      product?.pricingOption?.[0]?.pricingMatrix,
      isPack ? quantity * qtyForPack : quantity
    );
    setProductDataForAddToCartModal({
      name: product?.name,
      category: product?.categories?.length ? product.categories[0]?.name : '',
      thumbnailURL: selectedVariant?.media?.length
        ? selectedVariant.media.find(mediaItem => mediaItem.mediaCategory === 'product')?.url
        : '',
      price,
      pricingOption,
      quantity,
      color: color?.hex,
      colorName: color?.colorName,
      decorationMethod: decoration || '',
      isHazmat: product?.hazmatDetails?.isHazmat || false,
      shipping: product.shipping,
      markupOption: product.markupOption
    });
    setOpenModal(true);
  };

  const prepareFAQ = (_productData, shipping) => {
    return [
      { title: 'Description', answer: _productData?.description, type: 'text' },
      {
        title: 'Details',
        answer: [
          { name: _productData.brand, doShow: !!_productData.brand },
          { name: _productData.line, doShow: !!_productData.line },
          { name: _productData.material, doShow: !!_productData.material },
          { name: 'Eco-Friendly', doShow: _productData.ecoFriendly },
          { name: 'Recyclable', doShow: _productData.recyclable },
          { name: 'Recyclable', doShow: _productData.recyclable },
          { name: `Min Order - ${data?.distributorCatalogProductDetails?.minimumOrderQuantity}`, doShow: true },
          { name: 'Hazmat', doShow: !!_productData.hazmatDetails?.isHazmat },
          {
            name: shipping,
            doShow: shipping
          }
        ],
        type: 'bullets'
      }
    ];
  };

  const isHazmat = useMemo(() => {
    return data?.distributorCatalogProductDetails?.hazmatDetails?.isHazmat && isHazmatExists;
  }, [data, isHazmatExists]);

  const updateQuantity = (updatedQty, checkForMinimum = false) => {
    let applicableQuantity = updatedQty;

    if (checkForMinimum) {
      if (isPack && updatedQty > MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK)
        applicableQuantity = MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK;
      else if (!isPack && updatedQty < defaultApplicableQty) {
        applicableQuantity = defaultApplicableQty;
      }
    }

    setQuantity(applicableQuantity < 1 ? 1 : applicableQuantity);
  };

  useEffect(() => {
    if (data?.distributorCatalogProductDetails?.minimumOrderQuantity) {
      setQuantity(isPack ? 1 : data?.distributorCatalogProductDetails?.minimumOrderQuantity);
    }
  }, [isPack, data?.distributorCatalogProductDetails?.minimumOrderQuantity]);

  useEffect(() => {
    if (data) {
      const _productData = {
        ...data?.distributorCatalogProductDetails,
        colorName: selectedColor,
        quantity,
        price: round(bagPrice / quantity, 2)
      };

      const processedData = normalizePricingMatrix(_productData?.variants);
      setPrettifiedData(processedData);

      const pricingMatrixData = processedData?.filter(item => item.color.colorName === selectedColor);
      setPricingMatrix(pricingMatrixData);

      const filteredMedia =
        processedData
          ?.filter(item => item.color.colorName === selectedColor)
          .map(item => item.media)
          .flat() || [];
      setMedia(filteredMedia);

      updateTheApplicablePrice(quantity, pricingMatrixData);

      if (selectedColor === '') {
        if (queryStrings.get('selectedColor')) {
          const selectedColorHex = queryStrings.get('selectedColor');
          const variantByColor = _productData.variants.find(
            _variant => _variant.color.hex === selectedColorHex || `#${_variant.color.hex}` === selectedColorHex
          );
          if (variantByColor && selectedColor === '') {
            setSelectedColor(variantByColor.color.colorName);
          } else {
            setSelectedColor(_productData?.variants[0].color.colorName);
          }
        } else {
          setSelectedColor(_productData?.variants[0].color.colorName);
        }
      }

      const variant = _productData.variants.find(_variant => _variant.color.colorName === selectedColor);

      const shipping = _productData?.canBeExported ? 'Global Shipping' : 'US Shipping Only';

      setFaqData(prepareFAQ(_productData, shipping));

      const thumbnailURL = filteredMedia?.find(mediaItem => mediaItem.mediaCategory === 'product')?.url || '';

      if (decoration === '') {
        setDecoration(data?.distributorCatalogProductDetails?.decorationOptions?.[0]);
      }

      setProductData({
        ..._productData,
        decorationMethod: data?.distributorCatalogProductDetails?.decorationOptions?.[0] || '',
        pricingOption: variant?.pricingOption ? [...variant.pricingOption] : [],
        thumbnailURL
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bagPrice, data, quantity, selectedColor]);

  const disableAddCTAForQty = useMemo(() => {
    return isPack && quantity >= MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK;
  }, [isPack, quantity]);

  const disableDecreaseQtyCTA = useMemo(() => {
    return quantity === 1 || (!isPack && quantity === defaultApplicableQty);
  }, [quantity, isPack, defaultApplicableQty]);

  useEffect(() => {
    setProductData({ ...productData, decorationMethod: decoration });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decoration]);

  const platinumMembershipProductDiscountDifference = getPlatinumProductDiscountPrice(
    bagPrice,
    PlatinumProductDiscount
  );

  // TODO Hard-coding Platinum membership Platinum discount, only for Logged-Out users.
  const PlatinumMembershipProductDiscount = 5;

  const ProductDiscountForLoggedOutUsers = getPlatinumProductDiscountPrice(
    bagPrice,
    Number(PlatinumMembershipProductDiscount)
  );

  const PlatinumMembershipMessage = ({ message }) => (
    <div style={{ padding: 5, marginTop: '10px', display: 'flex', alignItems: 'center' }}>
      <div>{isLoading ? <Skeleton variant="circle" width={20} height={20} /> : <SwagUpLogoSmallIcon />}</div>
      {isLoading ? (
        <Skeleton variant="text" />
      ) : (
        <SwagupTypography
          variant="body3RegularInter"
          style={{ color: shoppingExperienceColors.black, textDecoration: 'underline', paddingLeft: 10 }}
        >
          <a
            href="https://www.swagup.com/membership"
            target="_blank"
            style={{ color: shoppingExperienceColors.black, textDecoration: 'underline' }}
            rel="noreferrer"
          >
            {message}
          </a>
        </SwagupTypography>
      )}
    </div>
  );

  const seoTags = data?.distributorCatalogProductDetails?.name
    ? {
        title: `${CapitalizeWords(data?.distributorCatalogProductDetails?.name)} - ${selectedColor} | SwagUp`,
        description:
          'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand'
      }
    : {
        title: `${data?.distributorCatalogProductDetails?.name} - ${selectedColor} | SwagUp`,
        description:
          'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.'
      };

  return (
    <Box maxWidth="lg" px={isMobileUp.includes(currentDevice) ? 20 : 5} py={0}>
      <Helmet tags={seoTags} />
      <Breadcrumb routes={PATHS} isLoading={isLoading} />
      <Grid container justifyContent="space-between">
        {/* Product image section */}
        <Hidden xsDown>
          <Grid
            item
            xs={12}
            md={6}
            style={{ display: 'flex', flexDirection: 'column', justifyContent: 'top', alignItems: 'center' }}
          >
            {isLoading ? (
              <Skeleton variant="rect" className={classes.productImageSkeleton} />
            ) : (
              <div
                style={{
                  maxWidth: '600px',
                  maxHeight: '600px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  overflow: 'hidden',
                  padding: '30px'
                }}
              >
                <img
                  src={media[currIndex]?.url}
                  alt="Product"
                  style={{
                    width: '100%',
                    height: '75%',
                    objectFit: 'contain',
                    cursor: 'pointer'
                  }}
                  onClick={() => toggleModal(media[currIndex])}
                />
                <Hidden xsDown>
                  <Box
                    className={classes.referenceImages}
                    style={{
                      left: currentDevice === DEVICES_TYPE.TABLET ? 40 : 90,
                      marginTop: 20,
                      width: '20%',
                      maxHeight: '520px',
                      overflowY: 'scroll',
                      scrollbarWidth: 'none',
                      msOverflowStyle: 'none'
                    }}
                  >
                    {media.map((image, index) => {
                      if (isLoading) {
                        return <Skeleton variant="rect" className={classes.productThumbnailSkeleton} />;
                      }
                      return (
                        <img
                          // eslint-disable-next-line react/no-array-index-key
                          key={index + 1}
                          src={image?.url}
                          alt={`Small ${index + 1}`}
                          style={{
                            width: 50,
                            height: 50,
                            border: media[currIndex]?.url === image?.url ? '1px solid' : 'none',
                            borderColor: shoppingExperienceColors.solidborderLineGray,
                            borderRadius: '2px',
                            cursor: 'pointer',
                            objectFit: 'contain',
                            padding: 1,
                            marginRight: 8,
                            marginBottom: 8,
                            marginTop: 8
                          }}
                          onClick={() => setCurrIndex(index)}
                        />
                      );
                    })}
                  </Box>
                </Hidden>
              </div>
            )}
            <Hidden xsDown>
              {media?.length > 1 && (
                <div style={{ display: 'flex', alignSelf: 'end' }}>
                  <button
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      backgroundColor: shoppingExperienceColors.borderLineGray,
                      bottom: 20,
                      borderRadius: 50,
                      height: 40,
                      width: 40,
                      paddingLeft: 12,
                      paddingTop: 5,
                      border: 'none'
                    }}
                    onClick={prevImage}
                  >
                    <ArrowBackIosIcon />
                  </button>
                  <button
                    style={{
                      position: 'relative',
                      cursor: 'pointer',
                      backgroundColor: shoppingExperienceColors.borderLineGray,
                      bottom: 20,
                      borderRadius: 50,
                      height: 40,
                      width: 40,
                      paddingTop: 5,
                      paddingLeft: 8,
                      border: 'none',
                      marginLeft: 8
                    }}
                    onClick={nextImage}
                  >
                    <ArrowForwardIosIcon />
                  </button>
                </div>
              )}
              <div style={{ alignSelf: 'start', padding: 10 }}>
                {realImages.length > 0 && (
                  <>
                    <SwagupTypography variant="body2MediumInter"> See it in real life</SwagupTypography>
                    <br />
                    <Box
                      style={{
                        height: '100px',
                        display: 'flex',
                        maxWidth: '650px',
                        overflow: 'scroll',
                        scrollbarWidth: 'none',
                        msOverflowStyle: 'none'
                      }}
                    >
                      {realImages.map((image, index) => (
                        <img
                          // eslint-disable-next-line react/no-array-index-key
                          key={index + 1}
                          src={image?.url}
                          alt={`Small ${index + 1}`}
                          style={{
                            cursor: 'pointer',
                            marginRight: 12,
                            objectFit: 'contain'
                          }}
                          onClick={() => toggleModal(image)}
                        />
                      ))}
                    </Box>
                  </>
                )}
              </div>
            </Hidden>
            <Hidden smDown>
              <FAQAccordion faqData={faqData} isLoading={isLoading} fullWidth />
            </Hidden>
          </Grid>
        </Hidden>
        {/* Product details section */}
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            <SwagupTypography variant="body2RegularInter" className={classes.productTitles} gutterBottom>
              {!isLoading ? data?.distributorCatalogProductDetails?.brand : ''}
            </SwagupTypography>
          )}
          <Box display="flex" justifyContent="space-between" alignItems="start">
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography variant="h3SemiBoldInter" component="h1" style={{ paddingRight: 20 }}>
                {!isLoading ? data?.distributorCatalogProductDetails?.name : ''}
              </SwagupTypography>
            )}
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography
                variant="body3MediumInter"
                style={{
                  width: 'fit-content',
                  whiteSpace: 'nowrap',
                  color: shoppingExperienceColors.primary,
                  paddingTop: 10
                }}
              >
                In stock
              </SwagupTypography>
            )}
          </Box>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            <SwagupTypography variant="body2RegularInter" mb={8} className={classes.productTitles}>
              {!isLoading ? data?.distributorCatalogProductDetails?.productCode : ''}
            </SwagupTypography>
          )}
          <div style={{ display: 'flex', alignItems: 'center', gap: '4px', marginTop: '8px' }}>
            <div>
              {isLoading ? (
                <Skeleton variant="rect" width={20} height={20} />
              ) : (
                <LocalShippingOutlinedIcon style={{ color: shoppingExperienceColors.textPrimary }} />
              )}
            </div>
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography
                variant="body2MediumInter"
                style={{ color: shoppingExperienceColors.black, paddingLeft: 2 }}
              >
                {data?.distributorCatalogProductDetails?.canBeExported ? 'Global Shipping' : 'US Shipping Only'}
              </SwagupTypography>
            )}
          </div>
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            <SwagupTypography
              variant="body3SemiBoldInter"
              style={{ marginTop: '15px' }}
              className={classes.smallHeadings}
            >
              Starting at price
            </SwagupTypography>
          )}
          {isLoading ? (
            <Skeleton variant="text" />
          ) : (
            <MembershipPricing
              isLoading={isLoading}
              pricingOptions={data?.distributorCatalogProductDetails?.variants[0]?.pricingOption}
              classes={classes}
              product={{
                ...data.distributorCatalogProductDetails,
                quantity,
                pricingOption: data?.distributorCatalogProductDetails?.variants[0]?.pricingOption
              }}
              forStartingAt
            />
          )}
          <Hidden smUp>
            <CustomCarousel images={media} isLoading={isLoading} />
          </Hidden>
          <Hidden smUp>
            {isHazmat === true ? (
              <Box className={classes.hazmatWarningContainer}>
                <div style={{ display: 'inline-flex' }}>
                  <InfoIcon />
                  <SwagupTypography variant="body2SemiBoldInter" style={{ marginLeft: 8 }}>
                    Hazmat restriction
                  </SwagupTypography>
                </div>
                <div>
                  <SwagupTypography variant="body2RegularInter">
                    {quantity === 1
                      ? `You cannot create a pack with more than 1 hazmat product.`
                      : `You cannot add to your cart more than 1 hazmat product. ${data?.distributorCatalogProductDetails?.name}`}
                  </SwagupTypography>
                </div>
              </Box>
            ) : null}
          </Hidden>
          <Hidden mdUp>
            {isLoading ? (
              <Skeleton variant="rect" height={40} width={400} />
            ) : (
              <CommonButton
                text={`Add to cart - ${DollarPriceSymbol(bagPrice)}`}
                px={40}
                isActive
                mr={10}
                onClick={() => addToCart(productData, quantity)}
                disabled={isHazmat}
              />
            )}
          </Hidden>
          {/* Colors */}
          <Box mt={8}>
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography variant="body2SemiBoldInter" className={classes.smallHeadings}>
                Colors
              </SwagupTypography>
            )}
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography variant="body4RegularInter" color={shoppingExperienceColors.black}>
                {toSnakeCase(selectedColor)}
              </SwagupTypography>
            )}
            <Box
              display="grid"
              style={{
                gap: '15px',
                gridTemplateColumns: 'repeat(auto-fill, minmax(50px, 1fr))'
              }}
              mt={1}
            >
              {displayedColors.map(color => (
                <ColorSelection
                  key={color.color.hex}
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  color={`#${color.color.hex}`}
                  extraData={color.color}
                  isLoading={isLoading}
                />
              ))}
              {!showAll && remainingCount > 0 && (
                <Button
                  onClick={toggleShowAll}
                  style={{
                    width: 50,
                    height: 50,
                    marginTop: '-10px',
                    marginLeft: '-22px',
                    color: shoppingExperienceColors.primary
                  }}
                >
                  +{remainingCount}
                </Button>
              )}
              {showAll && (
                <Button
                  onClick={toggleShowAll}
                  style={{
                    textAlign: 'center',
                    lineHeight: '20px',
                    whiteSpace: 'nowrap',
                    marginTop: '-12px',
                    color: shoppingExperienceColors.primary
                  }}
                >
                  Show Less
                </Button>
              )}
            </Box>
          </Box>
          {/* Decoration options */}
          {/* // TODO Unhide the below decoration design, once we have the data on API & SF */}
          {/* {data?.distributorCatalogProductDetails?.decorationOptions && (
            <Box mt={4}>
              {isLoading ? (
                <Skeleton variant="text" />
              ) : (
                <SwagupTypography variant="body2SemiBoldInter" className={classes.smallHeadings}>
                  Decoration options
                </SwagupTypography>
              )}
              <Box mt={-3}>
                {isLoading === true && (
                  <Grid container style={{ gap: 4 }}>
                    <Skeleton variant="rect" />
                    <Skeleton variant="rect" />
                    <Skeleton variant="rect" />
                  </Grid>
                )}

                {data.distributorCatalogProductDetails.decorationOptions.map(decorationOption => {
                  return !decorationOption ? (
                    <></>
                  ) : (
                    <React.Fragment key={decorationOption}>
                      <CommonButton
                        text={decorationOption}
                        px={10}
                        w={150}
                        isActive={decoration === decorationOption}
                        mr={20}
                        onClick={() => setDecoration(decorationOption)}
                      />
                    </React.Fragment>
                  );
                })}

                <CommonButton
                  text="Other Option"
                  px={10}
                  w={150}
                  isActive={decoration === 'Other Option'}
                  mr={20}
                  onClick={() => setDecoration('Other Option')}
                >
                  <div className={classes.designPriceInfo} style={{ marginLeft: 5 }}>
                    <Tooltip
                      placement="right"
                      title="Have something specific in mind? Add to your cart and let us know at checkout. We'll see what's possible!"
                      arrow
                    >
                      <Info className={classes.infoIcon} style={{ width: 16, marginTop: 5 }} />
                    </Tooltip>
                  </div>
                </CommonButton>
              </Box>
            </Box>
          )} */}
          {/* Sizes */}
          <Box mt={4}>
            {isLoading ? (
              <Skeleton variant="text" />
            ) : (
              <SwagupTypography variant="body2SemiBoldInter" className={classes.smallHeadings}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  Available sizes
                  <div className={classes.designPriceInfo} style={{ marginLeft: 5 }}>
                    <Tooltip
                      placement="right"
                      title="You'll be able to specify how many units for each size after submitting your project details."
                      arrow
                    >
                      <Info className={classes.infoIcon} style={{ width: 16, marginTop: 5 }} />
                    </Tooltip>
                  </div>
                </div>
              </SwagupTypography>
            )}

            <Box mt={1} style={{ display: 'flex' }}>
              {getDistinctSizesByColor(selectedColor, prettifiedData).map(size => {
                if (isLoading) {
                  return <Skeleton variant="text" />;
                }
                return (
                  <SwagupTypography variant="body3RegularInter" key={size} style={{ marginRight: 15, fontSize: 15 }}>
                    {size}
                  </SwagupTypography>
                );
              })}
            </Box>
          </Box>
          {/* Quantity */}
          <Box mt={4} mb={2}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
              {isLoading ? (
                <Skeleton variant="rect" />
              ) : (
                <SwagupTypography variant="body2SemiBoldInter" className={classes.smallHeadings}>
                  Quantity {isPack ? 'per Pack' : ''} (
                  {isPack ? `max ${MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK}` : `min ${defaultApplicableQty}`})
                </SwagupTypography>
              )}

              {isPack === false && (
                <Hidden xsDown>
                  <SeeHowToSaveMore toggleSidebar={toggleSidebar} isLoading={isLoading} variant="rect" />
                </Hidden>
              )}
            </div>
            {isHazmat ? (
              <Tooltip placement="right" title="You can't add another hazmat product to your pack." arrow>
                <Box display="flex" alignItems="center" mt={1} mb={4} style={{ maxWidth: 150 }}>
                  {isLoading ? (
                    <Skeleton variant="rect" width={60} height={40} />
                  ) : (
                    <button
                      variant="body2SemiBoldInter"
                      style={{
                        fontSize: 30,
                        cursor: disableDecreaseQtyCTA ? 'default' : 'pointer',
                        backgroundColor: 'transparent',
                        border: 'none'
                      }}
                      onClick={() => updateQuantity(+quantity - 1, true)}
                    >
                      <Remove
                        className={`${classes.infoIcon} ${disableDecreaseQtyCTA ? classes.disableQuantityCTA : ''}`}
                        style={{ width: 20 }}
                      />
                    </button>
                  )}
                  {isLoading ? (
                    <Skeleton variant="rect" width={60} height={40} />
                  ) : (
                    <input
                      type="text"
                      value={quantity}
                      min={defaultApplicableQty}
                      onChange={e => setQuantity(e.target.value)}
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onBlur={e => {
                        updateQuantity(+e.target.value, true);
                      }}
                      style={{
                        width: 60,
                        textAlign: 'center',
                        padding: '8px',
                        border: '1px solid gray',
                        borderRadius: 4,
                        margin: '0 16px'
                      }}
                      disabled={isHazmat}
                    />
                  )}
                  {isLoading ? (
                    <Skeleton variant="rect" width={60} height={40} />
                  ) : (
                    <button
                      variant="body2SemiBoldInter"
                      style={{
                        fontSize: 30,
                        cursor: disableAddCTAForQty ? 'default' : 'pointer',
                        backgroundColor: 'transparent',
                        border: 'none'
                      }}
                      onClick={() => updateQuantity(+quantity + 1, true)}
                      disabled={data?.distributorCatalogProductDetails?.hazmatDetails?.isHazmat}
                    >
                      <Add
                        className={`${classes.infoIcon} ${disableAddCTAForQty ? classes.disableQuantityCTA : ''}`}
                        style={{ width: 20 }}
                      />
                    </button>
                  )}
                  <br />
                </Box>
              </Tooltip>
            ) : (
              <Box display="flex" alignItems="center" mt={1} mb={4} style={{ maxWidth: 150 }}>
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={40} />
                ) : (
                  <button
                    variant="body2SemiBoldInter"
                    style={{
                      fontSize: 30,
                      cursor: disableDecreaseQtyCTA ? 'default' : 'pointer',
                      backgroundColor: 'transparent',
                      border: 'none'
                    }}
                    onClick={() => updateQuantity(+quantity - 1, true)}
                  >
                    <Remove
                      className={`${classes.infoIcon} ${disableDecreaseQtyCTA ? classes.disableQuantityCTA : ''}`}
                      style={{ width: 20 }}
                    />
                  </button>
                )}
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={40} />
                ) : (
                  <input
                    type="text"
                    value={quantity}
                    min={defaultApplicableQty}
                    onChange={e => setQuantity(e.target.value)}
                    onInput={e => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    onBlur={e => {
                      updateQuantity(+e.target.value || defaultApplicableQty, true);
                    }}
                    style={{
                      width: 60,
                      textAlign: 'center',
                      padding: '8px',
                      border: '1px solid gray',
                      borderRadius: 4,
                      margin: '0 16px'
                    }}
                  />
                )}
                {isLoading ? (
                  <Skeleton variant="rect" width={60} height={40} />
                ) : (
                  <button
                    variant="body2SemiBoldInter"
                    style={{
                      fontSize: 30,
                      cursor: disableAddCTAForQty ? 'default' : 'pointer',
                      backgroundColor: 'transparent',
                      border: 'none'
                    }}
                    onClick={() => updateQuantity(+quantity + 1, true)}
                  >
                    <Add
                      className={`${classes.infoIcon} ${disableAddCTAForQty ? classes.disableQuantityCTA : ''}`}
                      style={{ width: 20 }}
                    />
                  </button>
                )}
                <br />
              </Box>
            )}

            {isPack === false && (
              <Hidden smUp>
                <SeeHowToSaveMore variant="text" isLoading={isLoading} toggleSidebar={toggleSidebar} />
              </Hidden>
            )}
          </Box>
          <Hidden smDown>
            {isHazmat === true ? (
              <Box className={classes.hazmatWarningContainer}>
                <div style={{ display: 'inline-flex' }}>
                  <InfoIcon />
                  <SwagupTypography variant="body2SemiBoldInter" style={{ marginLeft: 8 }}>
                    Hazmat restriction
                  </SwagupTypography>
                </div>
                <div>
                  <SwagupTypography variant="body2RegularInter">
                    {quantity === 1
                      ? `You cannot create a pack with more than 1 hazmat product.`
                      : `You cannot add to your cart more than 1 hazmat product. ${data?.distributorCatalogProductDetails?.name}`}
                  </SwagupTypography>
                </div>
              </Box>
            ) : null}
          </Hidden>
          {/* Add to cart */}
          <Hidden smDown>
            {isLoading ? (
              <Skeleton variant="rect" height={40} width={400} />
            ) : (
              <CommonButton
                text={`Add to cart - ${DollarPriceSymbol(bagPrice)}`}
                px={40}
                isActive
                mr={10}
                onClick={() => addToCart(productData, quantity)}
                disabled={isHazmat}
              />
            )}
            {!PlatinumMembershipTier && isAuthenticated && (
              <PlatinumMembershipMessage
                message={`Platinum members get an estimated ${DollarPriceSymbol(
                  platinumMembershipProductDiscountDifference
                )} (${PlatinumProductDiscount}%) back on this item`}
              />
            )}
            {!isAuthenticated && (
              <PlatinumMembershipMessage
                message={`Platinum members get an estimated ${DollarPriceSymbol(
                  ProductDiscountForLoggedOutUsers
                )} (${PlatinumMembershipProductDiscount}%) back on this item`}
              />
            )}
          </Hidden>
        </Grid>
      </Grid>
      <RightSidebar
        isOpen={isOpen}
        toggleSidebar={toggleSidebar}
        pricingMatrix={pricingMatrix}
        quantity={quantity}
        setQuantity={setQuantity}
        defaultApplicableQty={defaultApplicableQty}
        classes={classes}
        product={productData}
      />
      <Hidden mdUp>
        <FAQAccordion faqData={faqData} isLoading={isLoading} />
      </Hidden>
      {/* Image Modal */}
      <Modal open={isModalOpen} onClose={toggleModal}>
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
          <Box position="relative" style={{ backgroundColor: 'white', padding: 15, borderRadius: 5 }}>
            <IconButton
              style={{
                position: 'absolute',
                top: 2,
                right: 2,
                color: 'black',
                backgroundColor: 'transparent'
              }}
              onClick={toggleModal}
            >
              <Close className={classes.infoIcon} style={{ width: 20 }} />
            </IconButton>
            <img src={modalImageSrc?.url} alt="Zoomed product" style={{ maxHeight: '400px' }} />
          </Box>
        </Box>
      </Modal>
      {!PlatinumMembershipTier && isAuthenticated && (
        <Hidden smUp>
          <PlatinumMembershipMessage
            message={`Platinum members get an estimated ${DollarPriceSymbol(
              platinumMembershipProductDiscountDifference
            )} (${PlatinumProductDiscount}%) back on this item`}
          />
        </Hidden>
      )}
      {!isAuthenticated && (
        <Hidden smUp>
          <PlatinumMembershipMessage
            message={`Platinum members get an estimated ${DollarPriceSymbol(
              ProductDiscountForLoggedOutUsers
            )} (${PlatinumMembershipProductDiscount}%) back on this item`}
          />
        </Hidden>
      )}
      {/* Add to cart modal */}
      <CustomModal
        title="Product added to cart"
        open={openModal}
        onClose={onClose}
        innerClasses={{ paper: classes.dialog }}
      >
        <CartPopup
          details={productDataForAddToCartModal}
          isTouchDevice={isTouchDevice}
          isMobile={isMobile}
          addedProductsToCartCount={1}
          onClose={onClose}
        />
      </CustomModal>
    </Box>
  );
};

export default ProductDetails;
