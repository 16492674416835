import { shoppingExperienceColors } from '../../shared/constants';

const confirmationStyles = theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px'
  },
  imageContainer: {
    maxWidth: 150,
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    textAlign: 'left',
    padding: '0 20px',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center'
    }
  },
  image: {
    width: '100%',
    height: 'auto'
  }
});

export default confirmationStyles;
