const SEARCH_ACTIONS = Object.freeze({
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  TOGGLE_DRAWER: 'TOGGLE_DRAWER',
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_INITIAL_URL: 'SET_INITIAL_URL',
  SET_SEARCH_TRIGGERED: 'SET_SEARCH_TRIGGERED'
});

export const setSearchTerm = searchTerm => ({
  type: SEARCH_ACTIONS.SET_SEARCH_TERM,
  payload: searchTerm
});

export const toggleDrawer = isOpen => ({
  type: SEARCH_ACTIONS.TOGGLE_DRAWER,
  payload: isOpen
});

export const setSearchResults = results => ({
  type: SEARCH_ACTIONS.SET_SEARCH_RESULTS,
  payload: results
});

export const setInitialUrl = url => ({
  type: SEARCH_ACTIONS.SET_INITIAL_URL,
  payload: url
});

export const setSearchTriggered = isTriggered => ({
  type: SEARCH_ACTIONS.SET_SEARCH_TRIGGERED,
  payload: isTriggered
});

export const triggerSearch = (searchTerm, onClose, history = null) => async dispatch => {
  const trimmedSearchTerm = searchTerm.trim();
  if (!trimmedSearchTerm) {
    return;
  }

  const formattedSearchTerm = trimmedSearchTerm.replace(/\s+/g, '+');
  dispatch(setSearchTerm(trimmedSearchTerm));

  if (history) {
    history.push(`/search?q=${formattedSearchTerm}`);
  }

  if (onClose) onClose();
};

const initialState = {
  searchTerm: '',
  initialUrl: '',
  isDrawerOpen: false,
  searchResults: [],
  isSearchTriggered: false
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SEARCH_ACTIONS.SET_SEARCH_TERM:
      return { ...state, searchTerm: action.payload };
    case SEARCH_ACTIONS.TOGGLE_DRAWER:
      const isDrawerOpen = action.payload;
      const isSearchTriggered = !isDrawerOpen;
      return { ...state, isDrawerOpen, isSearchTriggered };
    case SEARCH_ACTIONS.SET_SEARCH_RESULTS:
      return { ...state, searchResults: action.payload };
    case SEARCH_ACTIONS.SET_INITIAL_URL: {
      return { ...state, initialUrl: action.payload };
    }
    case SEARCH_ACTIONS.SET_SEARCH_TRIGGERED: {
      return { ...state, isSearchTriggered: action.payload };
    }
    default:
      return state;
  }
};

export default searchReducer;
