import { round } from 'lodash';

export const getPlatinumProductDiscount = memberships => {
  if (!Array.isArray(memberships)) {
    console.error('Input is not a valid array.');
    return null;
  }
  const platinumProgram = memberships.find(program => program?.name === 'Platinum');
  if (platinumProgram) {
    return platinumProgram?.product_discount;
  }
  console.log('No Platinum membership found.');
  return null;
};

export const getPlatinumProductDiscountPrice = (price, productDiscount) => {
  const discountValue = (price * productDiscount) / 100;
  return round(discountValue, 2);
};

export const getLastPathSegment = () => {
  const path = window.location.pathname;
  const segments = path.split('/').filter(Boolean);
  return segments[segments.length - 1];
};
