import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import Grid from '@material-ui/core/Grid';
import { Button, Typography } from '@swagup-com/components';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Link } from '../shared';
import { openModal } from '../../actions/types';
import useMembershipCheck from '../../hooks/useMembershipCheck';
import { isPack } from '../../helpers/utils';
import { useQueryParams } from '../../hooks';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    padding: '20px 16px',
    borderBottom: '2px solid #E5E7E8'
  },
  icon: {
    marginRight: '10px',
    color: props => props.color || theme.palette.text.primary,
    display: 'flex',
    alignItems: 'center'
  }
}));

export const showDashboard = pathname => !/^\/(onboarding)/.test(pathname);

export const createMenuItemProps = (title, description, path, absolute, imageSrc, onClick) => ({
  title,
  description,
  path,
  absolute,
  imageSrc,
  onClick
});

export const useTabsPathsMap = () => {
  const {
    showDesignTabFeTemp102521: showProductsTab,
    enableRedeemPagesManagementFeatureOnDashboardTemp060322: redeemPages,
    zapierEmbebedIntegrations: zapier,
    storefronts,
    leftBarNavigation
  } = useFlags();

  const productsTab = showProductsTab ? { Products: '/products' } : undefined;
  const redeemPagesTab = redeemPages ? { Redeem: '/redeem-pages' } : undefined;
  const zapierTab = zapier ? { Integrations: '/integrations' } : undefined;
  const storefrontsTab = storefronts ? { Shops: '/shops' } : undefined;
  return {
    ...(leftBarNavigation ? { Dashboard: '/' } : { Home: '/' }),
    ...productsTab,
    Orders: '/orders',
    Inventory: '/inventory?inventory=available',
    Shipments: '/shipments',
    ...(leftBarNavigation
      ? {
          Contacts: '/shipments/contacts'
        }
      : {}),
    ...redeemPagesTab,
    ...storefrontsTab,
    ...zapierTab,
    ...(leftBarNavigation
      ? {
          Billing: '/billing/accounts',
          Settings: '/account/company',
          'Help Center': 'https://support.swagup.com'
        }
      : {})
  };
};

export const packsMenuItemList = [
  createMenuItemProps(
    'Build A Pack',
    'Create your own custom pack',
    '/custom-swag-packs/catalog',
    false,
    '/images/top-navbar/build-pack.svg'
  ),
  createMenuItemProps(
    'Preset Packs',
    'Select one of our preset packs',
    '/preset-packs/',
    false,
    '/images/top-navbar/presets-pack.svg'
  )
];

export const productMenuItemList = [
  ...packsMenuItemList,
  createMenuItemProps('Bulk Swag', 'Get products in bulk', '/bulk/catalog', false, '/images/top-navbar/bulk-swag.svg')
];

export const companyMenuItemList = [
  createMenuItemProps(
    'About Us',
    'Get to know SwagUp',
    `${process.env.REACT_APP_PROD_DOMAIN}company`,
    true,
    '/images/top-navbar/about-us.svg'
  ),
  createMenuItemProps(
    'Blog',
    'Read SwagUp related articles',
    `${process.env.REACT_APP_PROD_DOMAIN}blog`,
    true,
    '/images/top-navbar/blog.svg'
  ),
  createMenuItemProps(
    'FAQ',
    'Frequently Asked Questions',
    `${process.env.REACT_APP_PROD_DOMAIN}faq`,
    true,
    '/images/top-navbar/faq.svg'
  )
];

export const collectionMenuItemList = [
  createMenuItemProps(
    'Eco',
    'Building sustainable swag for over 10,000 brands',
    `${process.env.REACT_APP_PROD_DOMAIN}collections/eco-swag`,
    true,
    '/images/top-navbar/eco.svg'
  ),
  createMenuItemProps(
    'Pride',
    'Wear your colors with pride swag!',
    `${process.env.REACT_APP_PROD_DOMAIN}collections/pride-swag`,
    true,
    '/images/top-navbar/pride.svg'
  ),
  createMenuItemProps(
    'New Hires',
    'Leave a lasting impression on your new hires',
    `${process.env.REACT_APP_PROD_DOMAIN}collections/new-hire-swag`,
    true,
    '/images/top-navbar/new-hires.svg'
  ),
  createMenuItemProps(
    'All Collections',
    'Curated list of all the swag collections for you!',
    `${process.env.REACT_APP_PROD_DOMAIN}collections`,
    true,
    '/images/top-navbar/all-collections.svg'
  )
];

export const platformMenuItemList = [
  createMenuItemProps(
    'Shops',
    'Native self-service shops',
    `${process.env.REACT_APP_PROD_DOMAIN}shops`,
    true,
    '/images/top-navbar/shop.svg'
  ),
  createMenuItemProps(
    'Services',
    'The SwagUp process',
    `${process.env.REACT_APP_PROD_DOMAIN}services`,
    true,
    '/images/top-navbar/services.svg'
  ),
  createMenuItemProps(
    'Redeem Pages',
    'Automate swag fulfillment',
    `${process.env.REACT_APP_PROD_DOMAIN}blog/redeem-pages-guide`,
    true,
    '/images/top-navbar/redeem-pages.svg'
  ),
  createMenuItemProps(
    'Integrations',
    '50+ HRIS Integrations',
    `${process.env.REACT_APP_PROD_DOMAIN}integrations`,
    true,
    '/images/top-navbar/integrations.svg'
  )
];

export const pricingMenuItemList = [
  createMenuItemProps(
    'Pricing',
    'Simple pricing, no surprises',
    `${process.env.REACT_APP_PROD_DOMAIN}pricing`,
    true,
    '/images/top-navbar/pricing.svg'
  ),
  createMenuItemProps(
    'Memberships',
    'SwagUp membership plans',
    `${process.env.REACT_APP_PROD_DOMAIN}membership`,
    true,
    '/images/top-navbar/membership.svg'
  )
];

export const TitleTopHeader = ({ title }) => {
  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter" style={{ fontWeight: 600 }}>
          {title}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CatalogTopHeaderSignedIn = () => {
  return (
    <Grid container alignItems="center" justifyContent="flex-end" style={{ paddingRight: '20px' }}>
      <Grid item>
        <Button variant="primary" component={Link} to={{ pathname: '/' }} style={{ height: 48, background: '#131415' }}>
          <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
            Dashboard
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const TopHeaderTitleWithButton = ({
  title,
  buttonText,
  buttonLink,
  buttonLinkState,
  withState = false,
  search
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const query = useQueryParams();
  const handleClick = () => {
    if (withState) {
      setOpenDrawer(true);
    }
  };

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter">{title}</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="primary"
          component={Link}
          to={{
            pathname: `${buttonLink}`,
            state: buttonLinkState,
            search: `${query.toString()}${search ? `&${search}` : ''}`
          }}
          style={{ height: 48, background: '#131415' }}
          onClick={handleClick}
        >
          <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
            {buttonText}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const RedeemTopHeaderWithButton = ({ title, buttonText, buttonLink }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    if (title == 'Redeem') {
      dispatch({ type: 'Redeem-toggle' });
    }
  };

  const buttonProps = buttonLink
    ? {
        component: { Link },
        to: { pathname: `${buttonLink}` }
      }
    : {
        onClick: handleClick
      };

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter">{title}</Typography>
      </Grid>
      <Grid item>
        <Button variant="primary" {...buttonProps} style={{ height: 48, background: '#131415' }}>
          <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
            {buttonText}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const IntegrationHeader = ({ title }) => {
  const dispatch = useDispatch();
  const tabValue = useSelector(state => state.integrationNavBarReducer.tabValue);
  const buttonTexts = useSelector(state => state.integrationNavBarReducer.buttonTexts);

  const handleOpenModal = () => dispatch(openModal());
  const commonBtnClick = {
    onClick: handleOpenModal
  };

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter">{title}</Typography>
      </Grid>
      <Grid item>
        {tabValue === 'native' && (
          <Button variant="primary" style={{ height: 48, background: '#131415' }} {...commonBtnClick}>
            <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
              {buttonTexts.native}
            </Typography>
          </Button>
        )}
        {tabValue === 'zapier' && (
          <Button variant="primary" style={{ height: 48, background: '#131415' }} {...commonBtnClick}>
            <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
              {buttonTexts.zapier}
            </Typography>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

export const ModalTopHeader = ({ title, buttonText }) => {
  const dispatch = useDispatch();

  const handleButtonClick = () => {
    if (title == 'Billing') {
      dispatch({ type: 'Add-Credit-Card' });
    }
  };

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter">{title}</Typography>
      </Grid>
      <Grid item>
        <Button variant="primary" style={{ height: 48, background: '#131415' }} onClick={handleButtonClick}>
          <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
            {buttonText}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const MembershipHeader = ({ title, buttonLink }) => {
  const membershipCheck = useMembershipCheck();
  const buttonStyle = {
    height: 48,
    background: membershipCheck.isBasic ? '#131415' : '#131415'
  };

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h2BoldInter">{title}</Typography>
      </Grid>
      <Grid item>
        <Button variant="primary" component={Link} to={{ pathname: `${buttonLink}` }} style={buttonStyle}>
          <Typography variant="buttonMediumInter" style={{ color: '#FFFFFF', fontWeight: 500 }}>
            {membershipCheck.isBasic ? 'Upgrade Now' : 'Manage Membership'}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export const InventoryHistoriesHeader = () => {
  const location = useLocation();
  const item = location.state?.item || {};
  const isPackOrProduct = isPack(item?.record_type) ? 'Pack' : 'Product';

  return (
    <Grid container alignItems="center" style={{ paddingRight: '20px' }}>
      <Grid item xs>
        <Typography variant="h3BoldInter">{isPackOrProduct} Details</Typography>
      </Grid>
    </Grid>
  );
};

export const LoginDropdownMenu = ({ icon: Icon, text, color, link }) => {
  const classes = useStyles({ color });

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={classes.root}>
      <Box className={classes.icon}>
        <Icon />
      </Box>
      <Typography variant="body3RegularInter" className={classes.text}>
        {text}
      </Typography>
    </a>
  );
};
