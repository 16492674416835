import * as React from 'react';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import stripeAPI from '../apis/stripe';
import { useAuth } from '../components/global/Authentication/AuthProvider';
import apiPaths from '../helpers/apiPaths';
import { globalApi } from '../apis/swagup';
import { joinFields, moneyStr } from '../helpers/utils';
import useQueryParams from '../hooks/useQueryParamsExtended';

const sortedNames = ['Basic', 'Silver', 'Gold', 'Platinum'];

const sortMembershipsByNames = (memberships, names) => {
  const nameIndexMap = new Map();
  for (let i = 0; i < names.length; i++) {
    nameIndexMap.set(names[i], i);
  }

  memberships.sort((a, b) => {
    const aIndex = nameIndexMap.get(a.name);
    const bIndex = nameIndexMap.get(b.name);
    return aIndex - bIndex;
  });

  return memberships;
};

const MembershipContext = React.createContext();
MembershipContext.displayName = 'MembershipContext';

const MembershipProvider = ({ children }) => {
  const [memberships, setMemberships] = useState([]);
  const [rewards, setRewards] = useState([]);
  const [totalRewards, setTotalRewards] = useState(0);
  const [loadingMembership, setLoadingMembership] = useState(false);
  const [currentMembership, setCurrentMembership] = useState({});
  const { isAuthenticated } = useAuth();

  const [alertMessage, setAlertMessage] = useState({
    type: '',
    message: ''
  });

  const payPendingStorageFees = useMutation(params => stripeAPI.payPendingStorageFees(params), {
    onSuccess: response => {
      return response;
    },
    onError: () => {
      throw new Error('Payment failed, please contact your AE.');
    }
  });

  const useDiscardInventories = () => {
    return useMutation(stripeAPI.discardInventories, {
      onSuccess: data => {
        // Handle successful response
        return data;
      },
      onError: error => {
        // Handle error response
        return error;
      }
    });
  };

  const { data: profile, isLoading: isProfileLoading } = useQuery(apiPaths.profiles, () => globalApi.fetchProfile(), {
    select: data => {
      const currentAccount = data?.related_accounts?.find(a => a.id === data.current_account);
      return { ...data, currentAccount };
    },
    enabled: !!isAuthenticated
  });

  const { data: company, isLoading: isCompanyLoading, refetch } = useQuery(
    apiPaths.accounts,
    () => globalApi.fetchAccount(),
    {
      enabled: !!isAuthenticated
    }
  );

  const { showingMembershipMonthlyPrice } = useFlags();

  const isSSR = useRouter();
  const query = useQueryParams();

  const rplimit = query.get('rplimit');
  const rpoffset = query.get('rpoffset');

  const getPaginatedRewards = React.useCallback(async () => {
    if (!isSSR && isAuthenticated) {
      const rewardQuery = `&limit=${isEmpty(rplimit) ? 12 : rplimit}&offset=${isEmpty(rpoffset) ? 0 : rpoffset}`;
      const rewardsFromApi = await stripeAPI.getAccountMembershipRewards(rewardQuery);
      setRewards(rewardsFromApi.results);
      setTotalRewards(rewardsFromApi.total);
    }
  }, [isAuthenticated, isSSR, rplimit, rpoffset]);

  useEffect(() => {
    const getMemberships = async () => {
      setLoadingMembership(true);
      const data = await stripeAPI.fetchMemberships();
      const programs = await stripeAPI.fetchMembershipsPrograms();
      const membershipsPrograms = programs.map(p => {
        const product = data.find(m => p.external_id === m.id);
        const stripePerks = product
          ? Object.keys(product.metadata).map(key => ({
              id: key,
              name: key,
              value: product.metadata[key]
            }))
          : [];
        let benefits = product
          ? [
              {
                id: 1,
                name: 'Reward Points Rate',
                value: `${p.reward_points_rate}X`
              },
              {
                id: 2,
                name: 'Product Discount',
                value: `${p.product_discount}%`
              },
              {
                id: 3,
                name: 'Rush Production Discount',
                value: `${p.rush_production_discount}%`
              },
              {
                id: 4,
                name: 'Shipping Discount',
                value: `${p.shipping_discount}%`
              },
              {
                id: 5,
                name: 'Storage Discount',
                value: `${p.storage_discount}%`
              },
              {
                id: 6,
                name: 'Swagup Shops',
                value: p.swagup_shops
              },
              {
                id: 7,
                name: 'Shop Credit Gifting',
                value: p.shop_credit_gifting
              },
              {
                id: 8,
                name: 'Shop Transaction Fee',
                value: p.swagup_shops ? `${p.shop_transaction_fee}%` : false
              },
              {
                id: 9,
                name: 'Integrations',
                value: p.integrations
              },
              ...stripePerks
            ]
          : [];
        benefits = benefits.filter(b => b.value);

        const { default_price: defaultPrice, images } = product || {};
        const price = defaultPrice || { id: 'none', unit_amount: 0, recurring: { interval: 'month' }, currency: 'usd' };
        const image = images?.[0] || '/images/membership/starter-membership.png';
        const isFreeTier = price.unit_amount ? price.unit_amount / 100 === 0 : true;
        const name = programs.some(pr => p.external_id === pr.external_id && pr.id !== p.id)
          ? 'Custom Membership'
          : p.name;
        return {
          ...product,
          priceId: price.id,
          benefits,
          image,
          isFreeTier,
          default_price: {
            ...price,
            unit_amount:
              showingMembershipMonthlyPrice && price.recurring?.interval === 'year'
                ? price.unit_amount / 12
                : price.unit_amount,
            recurring: {
              ...price.recurring,
              interval: showingMembershipMonthlyPrice ? 'month' : price.recurring?.interval
            }
          },
          actualInterval: price?.recurring?.interval,
          priceText: `${moneyStr(
            (showingMembershipMonthlyPrice && price.recurring?.interval === 'year'
              ? price.unit_amount / 12
              : price.unit_amount) / 100
          )} / ${showingMembershipMonthlyPrice ? 'month' : price.recurring?.interval}`,
          price: price.unit_amount / 100,
          kind: price.recurring?.interval,
          ...p,
          name
        };
      });
      const sortedMemberships = sortMembershipsByNames(membershipsPrograms, sortedNames);
      console.log('memberships: ', membershipsPrograms, sortedMemberships);
      setMemberships(sortedMemberships);
      getPaginatedRewards();

      setLoadingMembership(false);
    };
    if (isAuthenticated) getMemberships();
  }, [getPaginatedRewards, isAuthenticated, showingMembershipMonthlyPrice]);

  useEffect(() => {
    getPaginatedRewards();
  }, [getPaginatedRewards, rplimit, rpoffset]);

  useEffect(() => {
    let currentMem =
      memberships.find(m => m.id === company?.membership) ||
      memberships.find(m => m.default_price.unit_amount === 0) ||
      memberships.find(m => m.name === 'Free Starter Plan' || 'Basic') ||
      memberships[0] ||
      {};
    currentMem = isEmpty(currentMem)
      ? currentMem
      : {
          ...currentMem,
          default_price: {
            unit_amount:
              showingMembershipMonthlyPrice && currentMem.default_price?.recurring?.interval === 'year'
                ? currentMem.default_price.unit_amount / 12
                : currentMem?.default_price?.unit_amount,
            recurring: {
              ...currentMem?.default_price?.recurring,
              interval: showingMembershipMonthlyPrice ? 'month' : currentMem.default_price?.recurring?.interval
            }
          },
          actualInverval: currentMem.default_price?.recurring?.interval
        };
    setCurrentMembership(currentMem);
  }, [company, memberships, showingMembershipMonthlyPrice]);

  useEffect(() => {
    if (alertMessage !== null) {
      setTimeout(() => {
        setAlertMessage(null);
      }, 15000);
    }
  }, [alertMessage]);

  const isLoading = loadingMembership || isCompanyLoading || isProfileLoading;
  return (
    <MembershipContext.Provider
      value={{
        isLoading,
        company,
        rewards,
        totalRewards,
        currentEmail: profile?.email,
        currentName: profile?.first_name ? joinFields([profile?.first_name, profile?.last_name]) : '',
        currentAccount: profile?.current_account,
        memberships,
        currentMembership,
        setCurrentMembership,
        setLoadingMembership,
        payPendingStorageFees,
        useDiscardInventories,
        alertMessage,
        setAlertMessage,
        refetch
      }}
    >
      {children}
    </MembershipContext.Provider>
  );
};

const useMembership = () => {
  const membership = React.useContext(MembershipContext);
  return membership;
};

export { MembershipProvider, useMembership };
