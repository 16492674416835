import React, { useState, useEffect } from 'react';
import { Grid, makeStyles, Box, Accordion, AccordionDetails, AccordionSummary, Divider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Typography } from '@swagup-com/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { round } from 'lodash';
import Scrollable from 'react-scrollbars-custom';
import Styles from './confirmation.styles';
import { shoppingExperienceColors } from '../../shared/constants';
import Checklist from '../../components/Confirmation/checklist';
import { CONFIRMATION_STEPS } from '../../../utils/constants';
import { Helmet } from '../../../components/shared';
import seoTags from '../../../apis/seoTags';
import { useCartContext } from '../../context/cart.context';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { DollarPriceSymbol, MembershipDiscountTextBadge, NavigateTo } from '../../shared/ui/common';
import StrikeOut from '../../shared/ui/StrikeOut';
import { useProfile } from '../../../hooks';
import CartAccordionItem from '../CheckOutPage/cartAccordionItem';
import { CART_TYPES } from '../../utils/constants';
import { CommonButton } from '../../shared/ui/commonButtons';
import { RightArrowIcon } from '../../shared/icons';

const useStyles = makeStyles(Styles);

const ConfirmationPage = props => {
  const { isMobile, isTouchDevice } = props;
  const history = useHistory();
  const { confirmationCartItems: cartItems, confirmationTotalEstimation: totalEstimation } = useCartContext();
  const { isAuthenticated } = useAuth();
  const { productDiscount, getDiscountPrice } = useDiscountPrice();
  const MembershipDiscountAmount = round(totalEstimation - getDiscountPrice(totalEstimation), 2);
  const [expanded, setExpanded] = useState(true);
  const handleChange = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };
  const { data: profile } = useProfile();
  const contactEmail = localStorage.getItem('contactEmail');
  const userEmail = isAuthenticated ? profile?.email : contactEmail;
  const updatedConfirmationSteps = isAuthenticated ? CONFIRMATION_STEPS.slice(1) : CONFIRMATION_STEPS;
  const [cartType, setCartType] = useState('');
  const isPack = cartType === CART_TYPES.PACK;
  const classes = useStyles();
  const AccordionTitle = cartItems?.length === 1;
  const AccordionDynamicHeight = cartItems?.length;

  useEffect(() => {
    const storedCartType = localStorage.getItem('cartType');
    if (storedCartType) {
      setCartType(storedCartType);
    }
  }, []);

  return (
    <>
      <Helmet tags={seoTags.confirmationPage} />
      <Grid container className={classes.mainContainer}>
        <Grid className={classes.innerContainer}>
          <Grid item lg={7} md={7} sm={12} xs={12} className={classes.detailsContainer} style={{}}>
            <Typography variant="h2SemiBoldInter">Thank you for your project request!</Typography>
            <Box style={{ padding: isMobile ? '20px' : '20px 0px' }}>
              <Typography variant="body1RegularInter">
                We just sent you an email to{' '}
                <span style={{ color: shoppingExperienceColors.primary }}>{userEmail}</span> confirming this
                information.
              </Typography>
            </Box>
            <Box style={{ padding: '20px 0px' }}>
              <Typography variant="h4SemiBoldInter">What happens now?</Typography>
            </Box>
            {updatedConfirmationSteps.map((item, index) => {
              return (
                <Checklist key={index} image={item?.imagePath} title={item?.title} description={item?.description} />
              );
            })}
          </Grid>
          <Grid item lg={5} md={5} className={classes.productsContainer}>
            {isMobile ? (
              <>
                <Accordion expanded={expanded} style={{ boxShadow: 'none' }} onChange={handleChange}>
                  <AccordionSummary
                    className={classes.accordionHeaderStyle}
                    classes={{
                      content: classes.accordionHeaderContentStyle
                    }}
                    expandIcon={<ExpandMoreIcon style={{ color: shoppingExperienceColors.textPrimary }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Box>
                      <Typography variant="h4SemiBoldInter">Project summary</Typography>
                    </Box>
                  </AccordionSummary>
                  <>
                    {isAuthenticated && productDiscount !== 0 && (
                      <Grid item style={{ marginBottom: '20px' }}>
                        <Grid container className={classes.subTotalEstimate}>
                          <Grid item>
                            <Typography variant="body2SemiBoldInter">Subtotal Estimate</Typography>
                          </Grid>
                          <Grid item>
                            <StrikeOut value={totalEstimation} />
                          </Grid>
                          <Grid
                            container
                            className={classes.subTotalEstimate}
                            style={{ width: '100%', marginTop: '20px' }}
                          >
                            <MembershipDiscountTextBadge
                              text="Membership Discounts"
                              typographyVariant="body3SemiBoldInter"
                            />
                            <Typography
                              variant="body3SemiBoldInter"
                              style={{ color: shoppingExperienceColors.primary }}
                            >
                              -{DollarPriceSymbol(MembershipDiscountAmount)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                    <Divider />
                    <Box style={{ padding: '20px 0px' }}>
                      <div
                        style={{
                          display: 'inline-flex',
                          justifyContent: 'space-between',
                          width: '100%',
                          alignItems: 'center'
                        }}
                      >
                        <div>
                          <Typography variant="body2SemiBoldInter">Total Estimate</Typography>
                          <Typography variant="body3RegularInter">(Not including taxes and fees)</Typography>
                        </div>
                        <div>
                          <Typography variant="h5SemiBoldInter">
                            {DollarPriceSymbol(getDiscountPrice(totalEstimation))}
                          </Typography>
                        </div>
                      </div>
                    </Box>
                    <Divider style={{ marginBottom: 20 }} />
                    <Box style={{ padding: '0px 0px 20px 0px' }}>
                      {isPack ? (
                        <Typography variant="body2MediumInter">
                          {AccordionTitle
                            ? `Swag Pack: ${cartItems?.length} item`
                            : `Swag Pack: ${cartItems?.length} items`}
                        </Typography>
                      ) : (
                        <Typography variant="body2MediumInter">
                          {cartItems?.length} {AccordionTitle ? 'Product' : 'Products'}
                        </Typography>
                      )}
                    </Box>
                  </>
                  <AccordionDetails style={{ flexDirection: 'column' }}>
                    <Scrollable
                      style={{
                        height:
                          AccordionDynamicHeight > 5
                            ? '500px'
                            : AccordionDynamicHeight === 1
                            ? '120px'
                            : `${AccordionDynamicHeight * 100}px`,
                        width: '100%'
                      }}
                      className={classes.scrollBar}
                    >
                      {cartItems.map(item => (
                        <CartAccordionItem key={item?.name} item={item} />
                      ))}
                    </Scrollable>
                    {isAuthenticated && (
                      <Grid container justifyContent="center" alignItems="center" style={{ rowGap: '36px' }}>
                        <Grid item style={{ width: '100%' }}>
                          <CommonButton
                            text="Go to project"
                            px={40}
                            isActive
                            mr={10}
                            hasIcon
                            icon={<RightArrowIcon color={shoppingExperienceColors.white} />}
                            onClick={() => NavigateTo(history, '/orders-requested')}
                          />
                        </Grid>
                        <Grid item onClick={() => NavigateTo(history, '/start-project')}>
                          <Typography
                            variant="buttonMediumInter"
                            style={{
                              color: shoppingExperienceColors.primary,
                              cursor: 'pointer'
                            }}
                          >
                            Continue shopping
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </AccordionDetails>
                </Accordion>
              </>
            ) : (
              <>
                <Box style={{ paddingBottom: '20px' }}>
                  <Typography variant="h4SemiBoldInter">Project summary</Typography>
                  {isAuthenticated && productDiscount !== 0 && (
                    <Grid item style={{ marginTop: '20px' }}>
                      <Grid container className={classes.subTotalEstimate}>
                        <Grid item>
                          <Typography variant="body2SemiBoldInter">Subtotal Estimate</Typography>
                        </Grid>
                        <Grid item>
                          <StrikeOut value={totalEstimation} />
                        </Grid>
                        <Grid
                          container
                          className={classes.subTotalEstimate}
                          style={{ width: '100%', marginTop: '20px' }}
                        >
                          <MembershipDiscountTextBadge
                            text="Membership Discounts"
                            typographyVariant="body3SemiBoldInter"
                          />
                          <Typography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.primary }}>
                            -{DollarPriceSymbol(MembershipDiscountAmount)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Box>
                <Divider />
                <Box style={{ padding: '20px 0px' }}>
                  <div
                    style={{
                      display: 'inline-flex',
                      justifyContent: 'space-between',
                      width: '100%',
                      alignItems: 'center'
                    }}
                  >
                    <div>
                      <Typography variant="body2SemiBoldInter">Total Estimate</Typography>
                      <Typography variant="body3RegularInter">(Not including taxes and fees)</Typography>
                    </div>
                    <div>
                      <Typography variant="h5SemiBoldInter">
                        {DollarPriceSymbol(getDiscountPrice(totalEstimation))}
                      </Typography>
                    </div>
                  </div>
                </Box>
                <Divider style={{ marginBottom: 20 }} />
                <Box style={{ padding: '0px 0px 20px 0px' }}>
                  {isPack ? (
                    <Typography variant="body2MediumInter">
                      {AccordionTitle
                        ? `Swag Pack: ${cartItems?.length} item`
                        : `Swag Pack: ${cartItems?.length} items`}
                    </Typography>
                  ) : (
                    <Typography variant="body2MediumInter">
                      {cartItems?.length} {AccordionTitle ? 'Product' : 'Products'}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Grid>
                    <Scrollable
                      style={{
                        height:
                          AccordionDynamicHeight > 5
                            ? '500px'
                            : AccordionDynamicHeight === 1
                            ? '120px'
                            : `${AccordionDynamicHeight * 100}px`,
                        width: '100%'
                      }}
                      className={classes.scrollBar}
                    >
                      {cartItems.map(item => (
                        <CartAccordionItem key={item?.name} item={item} />
                      ))}
                    </Scrollable>
                  </Grid>
                </Box>
                {isAuthenticated && (
                  <Grid container justifyContent="center" alignItems="center" style={{ rowGap: '36px' }}>
                    <Grid item style={{ width: '100%' }}>
                      <CommonButton
                        text="Go to project"
                        px={40}
                        isActive
                        mr={10}
                        hasIcon
                        icon={<RightArrowIcon color={shoppingExperienceColors.white} />}
                        onClick={() => NavigateTo(history, '/orders-requested')}
                      />
                    </Grid>
                    <Grid item onClick={() => NavigateTo(history, '/start-project')}>
                      <Typography
                        variant="buttonMediumInter"
                        style={{
                          color: shoppingExperienceColors.primary,
                          cursor: 'pointer'
                        }}
                      >
                        Continue shopping
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ConfirmationPage;
