/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-key */
import React from 'react';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import CartProduct from '../Cart/cartProduct';
import { CommonButton } from '../../shared/ui/commonButtons';
import { RightArrowIcon } from '../../shared/icons';
import styles from './cartPopup.styles';
import { DollarPriceSymbol, MembershipDiscountTextBadge, NavigateTo } from '../../shared/ui/common';
import { useCartContext } from '../../context/cart.context';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import { shoppingExperienceColors } from '../../shared/constants';

const useStyles = makeStyles(styles);

const CartPopup = props => {
  const classes = useStyles();
  const history = useHistory();
  const { onClose, isMobile, addedProductsToCartCount } = props;
  const { totalEstimationWithDiscount, totalEstimationWithoutDiscount, cartItems } = useCartContext();
  const { getDiscountPrice, productDiscount, getPriceByShippingAndMarkup } = useDiscountPrice();
  const { isAuthenticated } = useAuth();

  const getMembershipDiscount = totalEstimationWithoutDiscount - totalEstimationWithDiscount;

  return (
    <Grid container>
      <Grid item lg={8} md={7} xs={12} className={classes.productGrid}>
        {new Array(addedProductsToCartCount).fill('').map((itm, ind) => {
          return <CartProduct {...props} />;
        })}
      </Grid>
      <Grid item lg={4} md={5} className={classes.productPriceContainer}>
        {!isMobile && (
          <div className={classes.cartPriceDetails}>
            <Typography variant="body3SemiBoldInter" style={{ marginBottom: 20 }}>
              Your cart
            </Typography>
            <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant="body3RegularInter" className={classes.productDetails}>
                Number of Items
              </Typography>
              <Typography variant="body3RegularInter" className={classes.productDetails}>
                {cartItems.length}{' '}
              </Typography>
            </div>
            {isAuthenticated && productDiscount !== 0 && (
              <>
                <Divider style={{ backgroundColor: shoppingExperienceColors.borderLineGray }} />
                <Grid container style={{ marginTop: '16px' }} justifyContent="space-between" alignItems="center">
                  <Typography variant="body3SemiBoldInter">Subtotal Estimate</Typography>
                  <Typography variant="body3SemiBoldInter">
                    {DollarPriceSymbol(totalEstimationWithoutDiscount)}
                  </Typography>
                </Grid>
                <Grid container style={{ marginTop: '16px' }} justifyContent="space-between" alignItems="center">
                  <MembershipDiscountTextBadge text="Membership Discounts" typographyVariant="body3SemiBoldInter" />
                  <Typography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.primary }}>
                    -{DollarPriceSymbol(getMembershipDiscount)}
                  </Typography>
                </Grid>
              </>
            )}
            {isAuthenticated && productDiscount !== 0 ? (
              <>
                <Divider style={{ margin: '16px 0px', backgroundColor: shoppingExperienceColors.borderLineGray }} />
                <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
                  <Typography variant="body3SemiBoldInter">Total Estimate</Typography>
                  <Typography variant="body3SemiBoldInter">{DollarPriceSymbol(totalEstimationWithDiscount)}</Typography>
                </div>
              </>
            ) : (
              <div style={{ display: 'inline-flex', justifyContent: 'space-between', width: '100%' }}>
                <Typography variant="body3SemiBoldInter">Total Estimate</Typography>
                <Typography variant="body3SemiBoldInter">{DollarPriceSymbol(totalEstimationWithDiscount)}</Typography>
              </div>
            )}
          </div>
        )}
        <CommonButton
          text="See cart"
          px={40}
          isActive
          mr={10}
          hasIcon
          icon={<RightArrowIcon color="#ffff" />}
          onClick={() => NavigateTo(history, '/cart')}
        />
        <CommonButton
          text="Continue shopping"
          px={40}
          isActive
          mr={10}
          onClick={onClose}
          outlined
          hasIcon
          icon={<RightArrowIcon />}
        />
      </Grid>
    </Grid>
  );
};
export default CartPopup;
