import UserIcon from '@material-ui/icons/AccountCircleOutlined';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@swagup-com/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useBreakpointDown } from '../../../hooks';
import useDiscountsAndRewards from '../../../hooks/useDiscountsAndRewards';
import UserAvatar from '../../../components/AppTopBar/UserAvatar';
import AccountProfileControl from '../../../components/AppTopBar/AccountProfileControl';
import ClickableDrawer from '../../../components/AppTopBar/ClickableDrawer';

const AccountMenuDropdown = ({ open, logout, login, handleAccountSwap, setOpen, profile, currentAccount, classes }) => {
  const isXs = useBreakpointDown('xs');
  const { currentMembership } = useDiscountsAndRewards();
  const avatar = profile ? (
    <UserAvatar name={profile.first_name} nameForColorGeneration={currentAccount?.name} size={24} />
  ) : (
    <img src="/images/public/log-in.svg" alt="Log In" />
  );

  const title = (
    <AccountProfileControl
      profile={profile}
      onSwapAccount={handleAccountSwap}
      logout={logout}
      login={login}
      setOpen={setOpen}
    />
  );

  return (
    <ClickableDrawer title={title} placement="bottom-end" isAccountDropdown open={open} setOpen={setOpen}>
      <Grid
        container
        alignItems="center"
        justifyContent="space-around"
        className={!isXs ? classes.accountMenuContainer : undefined}
      >
        {avatar}
        <ExpandMoreIcon />
      </Grid>
    </ClickableDrawer>
  );
};

export default AccountMenuDropdown;
