import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import Footer from './components/Footer/footer';
import Header from './components/Header/header';
import { TitleFormatter, CalculateSkipAndTake } from './shared/ui/common';
import { useResponsive } from './hooks';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getSearchParamsObject } from '../helpers/utils';
import { store } from './redux/store';
import { useItemsQuery } from './sevices/service';
import {
  differentHeaderPaths,
  formFooterPaths,
  formHeaderPaths,
  hideFooterPaths,
  removeMarginTopOnIndex
} from './utils/constants';
import SimpleFormHeader from './components/Header/simpleFormHeader';
import SimpleFormFooter from './components/Footer/simpleFormFooter';
// eslint-disable-next-line import/no-named-as-default
import Modal from './shared/ui/modal';
import Login from './pages/Login/login';
import ForgotPassword from './pages/ForgotPassword/forgotPassword';
import { CheckoutContextProvider } from './context/checkout.context';
import { useFilterContext } from './contexts/filter.context';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 400,
    maxWidth: 400,
    borderRadius: 5,
    paddingBottom: 24,
    [theme.breakpoints.down('md')]: {
      width: 800
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      left: 0,
      position: 'absolute',
      margin: 0,
      bottom: 0,
      minHeight: '100vh'
    }
  }
}));

const DEFAULT_RECORDS_PER_PAGE = 20;

const ShoppingExperience = ({ component: Component = () => <></>, ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const [width] = useState(window.innerWidth);
  const isTouchDevice = Math.min(width) < 1025 && Math.min(width) > 786;
  const isMobile = Math.min(width) < 786;
  const queryParams = new URLSearchParams(location.search);
  const navigationSource = location.state?.source || null;
  const [openLoginModal, setLoginOpenModal] = useState(false);
  const [openForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [skip, setSkip] = useState(0);
  const [take, setTake] = useState(DEFAULT_RECORDS_PER_PAGE);
  const [totalRecordsToFetch, setTotalRecordsToFetch] = useState(0);
  const [loadProductsByPageNumber, setLoadProductsByPageNumber] = useState();
  const [groupedFilter, setGroupedFilter] = useState(null);
  const queryString = getSearchParamsObject(location.search);
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('page'), 10) || 1);
  const [directPageNumber] = useState(parseInt(queryParams.get('page'), 10) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const categoryName = props.isCategoryPage ? params.category : '';
  const subCategoryName = props.isCategoryPage ? params.subCategory : '';
  const [sortItem, setSortItem] = useState(null);
  const [defaultEmail, setDefultEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const { isCategoryPage = false } = props;

  const { appliedFilters = [], delayLoading, setDelayLoading } = useFilterContext();

  const { data, refetch: productsRefetch, isLoading: apiLoading, isFetched } = useItemsQuery(
    navigationSource === null || location.pathname ? take : totalRecordsToFetch,
    skip,
    groupedFilter || null,
    sortItem,
    [],
    isCategoryPage ? appliedFilters?.length > 0 : true
  );

  const handleSortChange = field => {
    setSortItem(field);
    productsRefetch();
  };

  const { currentDevice, DEVICES_TYPE } = useResponsive();
  const onClose = (isLoginModal = true) => (isLoginModal ? setLoginOpenModal(false) : setForgotPasswordModal(false));
  const onOpen = (isLoginModal = true) => (isLoginModal ? setLoginOpenModal(true) : setForgotPasswordModal(true));
  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data?.distributorCatalogProducts?.totalProducts / DEFAULT_RECORDS_PER_PAGE));
    }
  }, [data]);

  useEffect(() => {
    let source = sessionStorage.getItem('source');
    if (!source) {
      source = 'page' in queryString ? 'direct' : 'route';
      sessionStorage.setItem('source', source);
    }
    if (loadProductsByPageNumber === undefined)
      setLoadProductsByPageNumber(source === 'direct' && navigationSource === null);
  }, [loadProductsByPageNumber, navigationSource, queryString]);

  useEffect(() => {
    if (loadProductsByPageNumber === undefined) return;
    CalculateSkipAndTake(
      currentPage,
      DEFAULT_RECORDS_PER_PAGE,
      loadProductsByPageNumber,
      setTotalRecordsToFetch,
      setTake,
      setSkip,
      directPageNumber,
      totalPages
    );
  }, [queryString, loadProductsByPageNumber, currentPage, directPageNumber, totalPages]);

  const renderHeader = () => {
    if (differentHeaderPaths.includes(location.pathname)) {
      return <Header onOpen={onOpen} hideMenu />;
    }
    if (formHeaderPaths.includes(location.pathname)) {
      return <SimpleFormHeader onOpen={onOpen} />;
    }
    return <Header onOpen={onOpen} hideMenu={false} />;
  };

  const renderFooter = () => {
    if (hideFooterPaths.includes(location.pathname)) {
      return null;
    }
    if (formFooterPaths.includes(location.pathname)) {
      return <SimpleFormFooter />;
    }
    return <Footer />;
  };

  // const renderBlueBanner = () => {
  //   return !hideBlueBannerPaths.includes(location.pathname);
  // };

  const isMarginTopRemoved = removeMarginTopOnIndex.includes(location.pathname);

  useEffect(() => {
    if (openLoginModal) {
      localStorage.setItem('currentRoute', location.pathname);
    }
  }, [location.pathname, openLoginModal]);

  useEffect(() => {
    if (apiLoading === false && delayLoading) {
      setTimeout(() => {
        setIsLoading(apiLoading);
        setDelayLoading(false);
      }, 2000);
      return;
    }
    setIsLoading(apiLoading);
  }, [apiLoading, delayLoading, setDelayLoading]);

  return (
    <Provider store={store}>
      <Grid container style={{ alignItems: 'flex-end' }}>
        <Grid item xs={12}>
          {renderHeader()}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginTop: isMarginTopRemoved ? '0px' : currentDevice === DEVICES_TYPE.MOBILE ? '57px' : '80px'
          }}
        >
          {/* // TODO HIDING THE BLUE BANNER CODE */}
          {/* {renderBlueBanner() && (
            <BlueBanner
              bannerText="Looking to build a kit? Add products to your bag"
              bannerLinkText="Learn More"
              bannerLinkURL
            />
          )} */}
          <CheckoutContextProvider>
            <Component
              {...props}
              isTouchDevice={isTouchDevice}
              isMobile={isMobile}
              productList={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              directPageNumber={directPageNumber}
              totalPages={totalPages}
              skip={skip}
              navigationSource={navigationSource}
              loadProductsByPageNumber={loadProductsByPageNumber}
              setLoadProductsByPageNumber={setLoadProductsByPageNumber}
              categoryName={categoryName}
              subCategoryName={subCategoryName}
              productsRefetch={productsRefetch}
              sortItem={sortItem}
              groupedFilter={groupedFilter}
              setGroupedFilter={setGroupedFilter}
              handleSortChange={handleSortChange}
              isLoading={isLoading}
              onOpen={onOpen}
              setDefultEmail={setDefultEmail}
              isFetched={isFetched}
            />
          </CheckoutContextProvider>
        </Grid>
        {renderFooter() && <>{renderFooter()}</>}
      </Grid>
      <Modal title="" open={openLoginModal} onClose={onClose} innerClasses={{ paper: classes.dialog }}>
        <Login onClose={onClose} onOpen={onOpen} defaultEmail={defaultEmail} />
      </Modal>
      <Modal
        title=""
        open={openForgotPasswordModal}
        onClose={() => onClose(false)}
        innerClasses={{ paper: classes.dialog }}
      >
        <ForgotPassword onClose={onClose} onOpen={onOpen} />
      </Modal>
    </Provider>
  );
};

export default ShoppingExperience;
