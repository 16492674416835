import React from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles, Grid } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { CloseIcon } from '../icons';
import styles from './common.styles';
import logo from '../../assets/images/logo/su-logo-dark.svg';

const useStyles = makeStyles(styles);

export const Modal = ({
  title = null,
  subtitle,
  actions,
  children,
  onClose,
  disableTypography,
  innerClasses,
  classes: overrideClasses,
  titleClass,
  subtitleClass,
  closeButtonClass,
  ...props
}) => {
  const classes = useStyles({ disableTypography, classes: overrideClasses, title });

  return (
    <Dialog onClose={onClose} maxWidth={false} {...props} classes={innerClasses}>
      <Grid className={title != '' && classes.titleContainer}>
        <IconButton aria-label="close" className={closeButtonClass || classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <DialogTitle className={classes.title}>
          {title === '' && (
            <div style={{ padding: '32px 20px 0px 16px', textAlign: 'center' }}>
              <img src={logo} alt="logo-image" />
            </div>
          )}
          <Typography variant="h5SemiBoldInter">{title || ''}</Typography>
        </DialogTitle>
      </Grid>
      <DialogContent className={title != '' ? classes.content : classes.authContent}>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
