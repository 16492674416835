/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import Scrollable from 'react-scrollbars-custom';
import { Skeleton } from '@material-ui/lab';
import CartOrderSummary from '../components/Cart/cartOrderSummary';
import CartProductItem from '../components/Cart/cartProductItem';
import { useResponsive } from '../hooks';
import { shoppingExperienceColors } from '../shared/constants';
import HandIcon from '../assets/images/cart/delete-confirmation.png';
import { NavigateTo } from '../shared/ui/common';
import { CommonButton } from '../shared/ui/commonButtons';
// eslint-disable-next-line import/no-named-as-default
import Modal from '../shared/ui/modal';

import { useCartContext } from '../context/cart.context';
import { CART_TYPES } from '../utils/constants';
import CartEmptyState from './cartEmptyState';
import CartOrderSummaryCTA from '../components/Cart/cartOrderSummaryCTA';
import { Helmet } from '../../components/shared';
import seoTags from '../../apis/seoTags';

const styles = theme => ({
  modalContainer: {
    '&>.MuiDialog-container': {
      '&>.MuiPaper-rounded': {
        borderRadius: '5px',
        maxHeight: 'unset'
      }
    }
  },
  gridContainer: {
    width: '508px',
    flexDirection: 'column',
    gap: '18px',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%'
    }
  },
  scrollBar: {
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY': {
      width: '4px !important',
      height: 'calc(100% - 10px) !important',
      top: '0px !important',
      right: '5px !important',
      background: `${shoppingExperienceColors.bannarBGColorMobile} !important`
    },
    '& .ScrollbarsCustom-Thumb': {
      background: `${shoppingExperienceColors.black} !important`
    },
    '& .ScrollbarsCustom-Content': {
      overflowX: 'hidden'
    }
  }
});

const useStyles = makeStyles(styles);

const ShoppingCart = () => {
  const classes = useStyles();
  const history = useHistory();

  const { isTablet, isMobile, isLaptop } = useResponsive();

  const { cartItems, clearCartItems, getCartType, isLoading, cartProducts } = useCartContext();

  const [isPack, setIsPack] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setIsPack(getCartType() === CART_TYPES.PACK);
  }, []);

  const toggleModal = () => setOpen(!open);

  return (
    <>
      <Helmet tags={seoTags.cart} />
      <Box sx={{ padding: isMobile ? '0px' : isTablet ? '20px' : isLaptop ? '30px' : '40px 100px' }}>
        <>
          {!cartItems.length ? (
            <CartEmptyState isMobile={isMobile} history={history} />
          ) : (
            <>
              <Grid container spacing={8}>
                <Grid item xs={12} md={8}>
                  <Grid
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ display: !isMobile && 'flex', padding: isMobile && '20px 0px 0px 20px' }}
                  >
                    {isLoading ? (
                      <Skeleton variant="text" width={100} height={40} />
                    ) : (
                      <Typography variant="h3SemiBoldInter" gutterBottom>
                        Your cart
                      </Typography>
                    )}
                  </Grid>
                  {isLoading ? (
                    <Skeleton variant="text" width={100} height={20} />
                  ) : (
                    <Typography
                      variant="body3RegularInter"
                      style={{ paddingBottom: '20px', paddingLeft: isMobile && '20px' }}
                    >
                      Total ({cartProducts.length} items)
                    </Typography>
                  )}
                  <Box
                    style={{
                      border: !isMobile && `1px solid ${shoppingExperienceColors.bannarBGColorMobile}`,
                      borderRadius: '5px'
                    }}
                  >
                    <Scrollable className={classes.scrollBar} style={{ height: '800px', overflowX: 'hidden' }}>
                      <Grid
                        direction="row"
                        justifyContent="space-between"
                        style={{ display: 'flex', alignItems: 'center', padding: '20px', background: '#72a0e114' }}
                      >
                        {isLoading ? (
                          <Skeleton variant="text" width={100} height={40} />
                        ) : (
                          <Typography
                            variant="h6SemiBoldInter"
                            style={{
                              fontSize: '20px',
                              fontWeight: 600,
                              lineHeight: '28px'
                            }}
                          >
                            {isPack ? 'Your Swag Pack' : 'Bulk items'}
                          </Typography>
                        )}
                        {isLoading ? (
                          <Skeleton variant="text" width={100} height={40} />
                        ) : (
                          <Typography
                            style={{
                              cursor: 'pointer',
                              color: shoppingExperienceColors.textPrimary,
                              fontFamily: 'Inter',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 500,
                              lineHeight: '14px'
                            }}
                            onClick={toggleModal}
                          >
                            Remove all
                          </Typography>
                        )}
                      </Grid>
                      <Grid container direction="column">
                        {isLoading
                          ? Array(5)
                              .fill()
                              .map((_, index) => (
                                <Grid item key={index} style={{ marginBottom: '16px' }}>
                                  <Skeleton variant="rectangular" width="100%" height={80} />
                                </Grid>
                              ))
                          : cartProducts.map(product => (
                              <>
                                <CartProductItem key={`cart-product-cart-${product.cartId}`} product={product} />
                              </>
                            ))}
                      </Grid>
                    </Scrollable>
                  </Box>
                  {!isMobile && (
                    <div
                      style={{ display: 'flex', marginTop: '40px', justifyContent: 'center' }}
                      onClick={() => NavigateTo(history, '/catalog')}
                    >
                      <Typography
                        style={{ color: shoppingExperienceColors.primary, cursor: 'pointer' }}
                        variant="body3RegularInter"
                      >
                        Continue shopping
                      </Typography>
                    </div>
                  )}
                </Grid>

                <Grid item xs={12} md={4}>
                  <CartOrderSummary products={cartProducts} isLoading={isLoading} IS_PACK={isPack} />
                  <CartOrderSummaryCTA history={history} isLoading={isLoading} isMobile={isMobile} />
                </Grid>
              </Grid>

              {isMobile && <Divider style={{ marginTop: '30px' }} />}

              <Modal open={open} onClose={toggleModal} className={classes.modalContainer}>
                <Grid container className={classes.gridContainer}>
                  <Grid container style={{ justifyContent: 'center' }}>
                    <img src={HandIcon} alt="delete-confirmation" />
                  </Grid>
                  <Typography variant="h5SemiBoldInter">Just a minute!</Typography>
                  <Typography variant="body2RegularInter" style={{ textAlign: 'center' }}>
                    Are you sure you want to delete all items from your cart?
                  </Typography>
                  <CommonButton
                    text="Yes, continue"
                    w="130px"
                    mt="0px"
                    mr="0px"
                    onClick={() => {
                      clearCartItems();
                      toggleModal();
                    }}
                    isActive
                  />
                  <Button variant="text" onClick={toggleModal}>
                    <Typography variant="body1RegularInter" style={{ color: '#2663FF' }}>
                      Cancel
                    </Typography>
                  </Button>
                </Grid>
              </Modal>
            </>
          )}
        </>
      </Box>
    </>
  );
};

export default ShoppingCart;
