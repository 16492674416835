import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useQuery } from 'react-query';
import seoTags from '../../apis/seoTags';
import { Helmet } from '../../components/shared';
import { fetchCollectionsDetails } from '../apis';
import CollectionTopGutter from '../components/Collections/collectionTopGutter';
import CollectionsBannerImage from '../components/Collections/collectionsBannerImage';
import CollectionsLisiting from '../components/Collections/collectionsLisiting';
import { getRouteMetadata } from '../utils/getRouteMetadata';
import { useResponsive } from '../hooks';
import { getMediaItemByDevice } from '../shared/helper';

const Collections = props => {
  const [selectedCollectionId, setSelectedCollectionId] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [matchingCollection, setMatchingCollection] = useState();
  const [bannerImage, setBannerImage] = useState('');
  const { data, isFetched } = useQuery(['collectionsData'], () => fetchCollectionsDetails());
  const collections = data?.productCollections?.collections ?? [];
  const selectedCollection = decodeURIComponent(location.pathname.split('/').pop());

  const { isMobile, isTablet } = useResponsive();

  useEffect(() => {
    setMatchingCollection(collections.find(collection => decodeURIComponent(collection?.slug) === selectedCollection));
  }, [collections, selectedCollection, selectedCollectionId]);

  useEffect(() => {
    if (collections.length) {
      if (selectedCollection === 'collections') {
        const firstCollection = { ...collections[0] };
        setSelectedCollectionId(firstCollection._id);
        setPageTitle(`Our ${firstCollection?.name}`);
        const templateImages =
          firstCollection?.media?.filter(mediaItem => mediaItem?.mediaCategory === 'template') || [];
        if (templateImages.length) {
          const image = getMediaItemByDevice(templateImages, isMobile, isTablet);
          setBannerImage(image);
        }
      } else if (matchingCollection) {
        setSelectedCollectionId(matchingCollection._id);
        setPageTitle(`Our ${matchingCollection.name}`);
        const images = matchingCollection.media.filter(mediaItem => mediaItem?.mediaCategory === 'template') || [];
        if (images.length) {
          const image = getMediaItemByDevice(images, isMobile, isTablet);
          setBannerImage(image);
        }
      }
    }
  }, [selectedCollection, collections, matchingCollection, isMobile, isTablet]);

  const metadata = getRouteMetadata();

  return (
    <Grid container>
      {metadata ? (
        <Helmet
          tags={{
            title: metadata?.title,
            description: metadata?.description
          }}
        />
      ) : (
        <Helmet tags={seoTags.shoppingExperienceCollections} />
      )}
      <CollectionTopGutter
        collectionsData={collections}
        isFetched={isFetched}
        setBannerImage={setBannerImage}
        setMatchingCollection={setMatchingCollection}
      />
      {bannerImage && <CollectionsBannerImage imageUrl={bannerImage} />}
      <CollectionsLisiting selectedCollectionId={selectedCollectionId} pageTitle={pageTitle} {...props} />
    </Grid>
  );
};

export default Collections;
