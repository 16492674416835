import { legacy_createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import searchReducer from './searchReducer';
import filterReducer from './filterReducer';
import pathReducer from './pathReducer';

const rootReducer = combineReducers({
  search: searchReducer,
  filter: filterReducer,
  path: pathReducer
});

export const store = legacy_createStore(rootReducer, applyMiddleware(thunk));
