import { shoppingExperienceColors } from '../../shared/constants';

const styles = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 14,
    '& > :nth-child(3)': {
      gap: '0px'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  contactDetailsWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 14,
    margin: '24px 0px'
  },
  contactDetailsTitle: {
    maxWidth: 'fit-content'
  },
  editCTA: {
    padding: '0px',
    maxHeight: 'fit-content',
    maxWidth: 'fit-content',
    minWidth: 'unset',
    textDecoration: 'underline',
    '&>span>p': {
      color: shoppingExperienceColors.primary
    }
  }
});

export default styles;
