import { makeStyles } from '@material-ui/core';
import { shoppingExperienceColors } from '../../shared/constants';

export const useHeaderStyles = makeStyles(theme => ({
  appBar: ({ isDesktop }) => ({
    position: 'fixed',
    display: 'flex',
    paddingRight: '0 !important',
    backgroundColor: shoppingExperienceColors.white,
    display: 'flex',
    alignItems: 'center',
    '&.MuiAppBar-root .MuiLink-root img': {
      height: '40px',
      width: isDesktop ? '170px !important' : '40px',
      objectFit: 'contain'
    }
  }),
  upperBar: {
    margin: 'auto',
    alignItems: 'center !important'
  },
  mainBar: {
    width: '100%',
    minHeight: 80,
    padding: '0px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px'
    },
    '&.MuiLink-root, .MuiLink-underlineHover': {
      alignItems: 'center !important'
    }
  },
  content: {
    width: '66%'
  },
  textField: {
    width: 'auto',
    borderRadius: '100px',
    backgroundColor: shoppingExperienceColors.white,
    display: 'flex',
    padding: '0px 20px',
    alignItems: 'center',
    gap: '10px',
    flex: '1 0 0',
    alignSelf: 'stretch'
  },
  img: {
    width: '36px'
  },
  mobileHeader: {
    padding: '0px 20px',
    height: '60px',
    display: 'flex',
    justifyContent: 'center',
    boxShadow: 'none'
  },
  menuIcon: { color: '#4A4F54', width: 16 },
  menuContainer: {
    cursor: 'pointer',
    width: ({ isAuthenticated }) => (isAuthenticated ? 110 : 72),
    height: 40,
    padding: 8,
    paddingRight: ({ isAuthenticated }) => (isAuthenticated ? 4 : 8),
    border: '1px solid #E5E7E8',
    borderRadius: 8,
    '&:hover': {
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.12), 0px 12px 24px 2px rgba(0, 0, 0, 0.06)'
    }
  },
  accountMenuContainer: {
    marginLeft: 8,
    cursor: 'pointer',
    paddingRight: ({ isAuthenticated }) => (isAuthenticated ? 4 : 8)
  },
  membershipBox: {
    background: '#EAF1FA',
    borderRadius: 4,
    position: 'relative',
    height: '30px',
    width: '30px',
    cursor: 'pointer'
  },
  membershipImage: { objectFit: 'contain', height: '30px', width: '30px' }
}));
