import ImageThumbnail from './images/products/product_thumb.png';
import ProductImage from './images/products/product_url.png';

export const PRODUCT_DETAILS = {
  name: 'Comfort Colors ® Heavyweight Ring Spun Tee',
  category: 'Bella + Canvas',
  color: '#B3D7E8',
  colorName: 'Cyan',
  decorationMethod: 'Screen print',
  quantity: 2,
  price: '2.35',
  thumbnailURL: `${ImageThumbnail}`,
  url: `${ProductImage}`
};

export const Collections_Data = {
  BestSellers: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Best-Sellers)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  StaffPicks: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Staff-Picks)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  HolidaySwag: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Holiday-Swag)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  AwardsMilestones: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Awards & Milestones)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  NewHires: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (New Hires)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  RealEstate: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Real Estate)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  LowBudget: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Low Budget)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  Pets: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Pets)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ],
  BabyKids: [
    {
      name: 'Comfort Colors ® Heavyweight Ring Spun Tee (Baby & Kids)',
      category: 'Bella + Canvas',
      color: '#B3D7E8',
      colorName: 'Cyan',
      decorationMethod: 'Screen print',
      quantity: 2,
      price: '2.35',
      thumbnailURL: `${ImageThumbnail}`,
      url: `${ProductImage}`
    }
  ]
};
