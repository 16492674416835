import React from 'react';
import sumBy from 'lodash/sumBy';
import { makeStyles, Grid } from '@material-ui/core';
import { Button, Typography } from '@swagup-com/components';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { getShipmentGroupsInfo, moneyStr, sumByQuantity } from '../../../../../helpers/utils';
import { opportunityStatus, productionTime as productionSpeed } from '../../../../../apis/constants';
import { useOrder } from '../OrderContext';
import { createShipment, setReorderProducts } from '../../../../../actions';
import { buildProofAlikeObject, cannotContinueMessage, getDirectoryOrders } from '../common/utilsOrder';
import { useShippingCutoffHour, useSizes } from '../../../../../hooks';
import { ellipsisStyles } from '../../../../shared/styles/commonStyles';
import ArrowTooltip from '../common/Tooltip';
import AppliedMembershipPanel from '../../../../global/AppliedMembershipPanel';
import useDiscountsAndRewards from '../../../../../hooks/useDiscountsAndRewards';
import StrikeOutText from '../../../../global/StrikeOutCost';

const commonSummaryText = { paddingTop: 10, color: '#4A4F54' };
const commonTotalItem = { marginTop: 20, borderTop: '1px solid #E5E7E8' };

const useStyles = makeStyles(theme => ({
  summaryLabel: commonSummaryText,
  productLabel: { ...commonSummaryText, ...ellipsisStyles },
  rushLabel: { ...commonSummaryText, marginLeft: 15 },
  totalValue: { ...commonSummaryText, paddingTop: 20, color: '#000000' },
  totalLabel: { paddingTop: 20, paddingBottom: 20, color: '#000000' },
  subTotalItem: { ...commonTotalItem, paddingTop: 10 },
  totalItem: { ...commonTotalItem, borderBottom: '1px solid #E5E7E8' },
  continueButton: { height: 56, fontSize: 16 },
  orderSummaryContainer: {
    paddingLeft: 50,
    [theme.breakpoints.down('sm')]: { paddingLeft: 0 }
  }
}));

const ProductSummaryItem = ({ product, classes }) => {
  const showRushItem = product.production_time === productionSpeed.rush;
  const totalQty = sumByQuantity(product.sizes);
  const subtotal = totalQty * +(product.price ?? 0);
  const rushFee = totalQty * +(product.rush_fee ?? 0);

  return (
    <Grid container>
      <Grid item xs={8} align="left">
        <Typography variant="body3RegularInter" className={classes.productLabel}>
          {product.product.name}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.rushLabel}>
            Rush Production
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} align="right">
        <Typography variant="body3RegularInter" className={classes.summaryLabel}>
          {moneyStr(subtotal)}
        </Typography>
        {showRushItem && (
          <Typography variant="body3RegularInter" className={classes.summaryLabel}>
            {moneyStr(rushFee)}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

const OrderSummaryItem = ({ label, value, isSubtotal, previous, isTotal, classes }) => (
  <Grid container className={clsx({ [classes.totalItem]: isTotal }, { [classes.subTotalItem]: isSubtotal })}>
    <Grid item xs align="left">
      <Typography
        variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
        className={clsx(classes.summaryLabel, { [classes.totalLabel]: isTotal })}
      >
        {label}
      </Typography>
    </Grid>
    <Grid item align="right">
      <Typography
        variant={isTotal ? 'h5SemiBoldInter' : 'body3RegularInter'}
        className={clsx(classes.summaryLabel, { [classes.totalValue]: isTotal })}
      >
        <StrikeOutText value={previous} fontSize={isTotal ? 16 : 12} color="#898C91" marginRight={8} />
        {moneyStr(value)}
      </Typography>
    </Grid>
  </Grid>
);

const OrderSummary = () => {
  const classes = useStyles();
  const order = useOrder();
  const products = order?.products ?? [];

  const subtotal = sumBy(order.products, product => Number(product.price) * sumByQuantity(product.sizes));
  const subtotalBeforeDiscount = sumBy(
    order.products,
    product => Number(product.price_without_discount || 0) * sumByQuantity(product.sizes)
  );
  const shippingInfo = getShipmentGroupsInfo(order.shipmentGroups);
  const shipping = shippingInfo.international.price + shippingInfo.domestic.price;
  const shippingBeforeDiscount =
    shippingInfo.international.price_without_discount + shippingInfo.domestic.price_without_discount;
  const warehouse = order.total_warehouses;
  const warehouseBeforeDiscount = order.total_warehouses_before_discount;
  const estimatedTax = Number(order.tax);
  const rushFee = sumBy(order.products, product => Number(+product.rush_fee || 0) * sumByQuantity(product.sizes));
  const rushFeeBeforeDiscount = sumBy(
    order.products,
    product => Number(+product.rush_fee_without_discount || 0) * sumByQuantity(product.sizes)
  );
  const total = subtotal + rushFee + shipping + warehouse + estimatedTax;

  const shippingCutoffHour = useShippingCutoffHour();
  const dispatch = useDispatch();
  const { data: sizes } = useSizes();
  const history = useHistory();
  const { id } = useParams();
  const handleContinue = () => {
    dispatch(setReorderProducts(order.products.map(buildProofAlikeObject(sizes))));
    dispatch(createShipment(sizes, getDirectoryOrders(order), shippingCutoffHour));
    history.push(`/orders-requested/${id}/order-storage`);
  };

  const { productsWithInactiveSizes } = order;
  const orderHasInactiveSizes = productsWithInactiveSizes.length > 0;
  const orderHasProductsUnapproved = ![opportunityStatus.proofsApproved, opportunityStatus.proofsApprovedOld].includes(
    order.status
  );
  const orderInProductionRequest = [opportunityStatus.finalizingProofs, opportunityStatus.finalizingProofsOld].includes(
    order.status
  );
  const title = cannotContinueMessage(orderHasProductsUnapproved, orderInProductionRequest, orderHasInactiveSizes);

  const multipleTotals = React.useMemo(
    () => ({
      shipping_discount: shipping || shippingBeforeDiscount,
      product_discount: subtotal || subtotalBeforeDiscount,
      storage_discount: warehouse || warehouseBeforeDiscount,
      rush_production_discount: rushFee || rushFeeBeforeDiscount
    }),
    [
      shipping,
      shippingBeforeDiscount,
      subtotal,
      subtotalBeforeDiscount,
      warehouse,
      warehouseBeforeDiscount,
      rushFee,
      rushFeeBeforeDiscount
    ]
  );

  const { totalBeforeDiscount, multipleDiscounts } = useDiscountsAndRewards(0, '', multipleTotals);

  const summaryRows = [
    { key: 1, label: 'Subtotal', value: subtotal, beforeDiscountValue: multipleDiscounts?.product_discount },
    {
      key: 2,
      label: 'Rush Production',
      value: rushFee,
      beforeDiscountValue: multipleDiscounts?.rush_production_discount
    },
    { key: 3, label: 'Storage', value: warehouse, beforeDiscountValue: multipleDiscounts?.storage_discount },
    {
      key: 4,
      label: 'Shipping and Handling',
      value: shipping,
      beforeDiscountValue: multipleDiscounts?.shipping_discount
    },
    { key: 5, label: 'Estimated taxes & fees', value: estimatedTax },
    {
      key: 6,
      label: 'Total',
      value: total,
      beforeDiscountValue: totalBeforeDiscount > 0 ? totalBeforeDiscount + estimatedTax : 0
    }
  ];

  return (
    <Grid container className={classes.orderSummaryContainer}>
      <Grid item>
        <Typography variant="h6SemiBoldInter">Order Summary</Typography>
        <Typography variant="body2MediumInter" style={{ marginTop: 12 }}>
          Products
        </Typography>
      </Grid>
      {products.map(product => (
        <ProductSummaryItem key={product.id} product={product} classes={classes} />
      ))}
      {summaryRows.map(row =>
        row.key === 5 ? (
          <>
            <OrderSummaryItem
              key={row.key}
              label={row.label}
              value={row.value}
              previous={row.beforeDiscountValue}
              isSubtotal={row.label === 'Subtotal'}
              isTotal={row.label === 'Total'}
              classes={classes}
            />
            <AppliedMembershipPanel
              type="product"
              total={subtotal}
              multipleTotals={multipleTotals}
              fullWidth
              style={{ marginTop: 16, width: '100%' }}
            />
          </>
        ) : (
          <OrderSummaryItem
            key={row.key}
            label={row.label}
            value={row.value}
            previous={row.beforeDiscountValue}
            isSubtotal={row.label === 'Subtotal'}
            isTotal={row.label === 'Total'}
            classes={classes}
          />
        )
      )}
      <Grid container style={{ marginTop: 30 }}>
        <ArrowTooltip title={title} fullWidth variant="primary">
          <Button
            size="small"
            variant="primary"
            fullWidth
            onClick={handleContinue}
            disabled={orderHasProductsUnapproved || orderHasInactiveSizes}
            className={classes.continueButton}
          >
            Checkout
          </Button>
        </ArrowTooltip>
      </Grid>
    </Grid>
  );
};

export default OrderSummary;
