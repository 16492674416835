import { makeStyles } from '@material-ui/core';
import { shoppingExperienceColors } from '../../shared/constants';

export const useMobileHeaderStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    height: '37px',
    padding: '0px 28px 0px 168px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '104.418px',
    alignSelf: 'stretch'
  },
  content: {
    display: 'flex',
    padding: '40px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '40px',
    alignSelf: 'stretch',
    width: '100%'
  },
  footer: {
    display: 'flex',
    padding: '20px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '20px',
    alignSelf: 'stretch',
    background: shoppingExperienceColors.borderLineGray
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    background: shoppingExperienceColors.white
  },
  menuDiv: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between'
  },
  menuText: {
    color: shoppingExperienceColors.textPrimary,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '32px' /* 133.333% */,
    letterSpacing: '-0.72px'
  }
}));
