import React from 'react';
import { Skeleton } from '@material-ui/lab';
import { Typography } from '@swagup-com/components';
import { CommonButton } from '../../shared/ui/commonButtons';
import { RightArrowIcon } from '../../shared/icons';
import { NavigateTo } from '../../shared/ui/common';

const CartOrderSummaryCTA = ({ isMobile, isLoading, history }) => {
  return (
    <>
      <div style={{ padding: isMobile && '0px 20px' }}>
        {isLoading ? (
          <Skeleton variant="rect" width="100%" height={60} />
        ) : (
          <CommonButton
            text="Next: Add project details"
            px={40}
            isActive
            mr={10}
            hasIcon
            v="buttonMediumInter"
            icon={<RightArrowIcon color="#ffff" />}
            disabled={history === null}
            disabledVariant="active-disabled"
            onClick={() => {
              if (history) {
                NavigateTo(history, '/project-submission');
              }
            }}
          />
        )}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
        {isLoading ? (
          <Skeleton variant="text" width={300} height={40} />
        ) : (
          <Typography variant="body3RegularInter">No credit card info required</Typography>
        )}
      </div>
    </>
  );
};

export default CartOrderSummaryCTA;
