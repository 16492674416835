import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { useResponsive } from '../../hooks';
import Styles from './confirmation.styles';

const useStyles = makeStyles(Styles);

const Checklist = ({ image, title, description }) => {
  const classes = useStyles();
  const { isMobile } = useResponsive();

  return (
    <Grid container className={classes.container} direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 2 : 4}>
      <Grid item xs={12} sm={4} md={3} className={classes.imageContainer}>
        <img src={image} alt={title} className={classes.image} />
      </Grid>
      <Grid item xs={12} sm={8} md={9} className={classes.textContainer}>
        <SwagupTypography variant="subtitle2SemiBoldInter" style={{ marginBottom: '8px' }}>
          {title}
        </SwagupTypography>
        <SwagupTypography variant="body2RegularInter">{description}</SwagupTypography>
      </Grid>
    </Grid>
  );
};

export default Checklist;
