import { shoppingExperienceColors } from '../../shared/constants';

const productStyles = theme => ({
  cardContainer: {
    position: 'relative',
    boxShadow: 'none',
    height: 440,
    width: 316,
    borderRadius: 0,
    cursor: 'pointer',
    marginBottom: 40,
    padding: 5,
    transition: 'transform 0.3s',
    [theme.breakpoints.down('md')]: {
      height: 360,
      width: 239
    },
    [theme.breakpoints.down('sm')]: {
      height: 330,
      width: 185.5
    }
  },
  cardMediaStyle: {
    height: 316,
    [theme.breakpoints.down('md')]: {
      height: 239
    },
    [theme.breakpoints.down('sm')]: {
      height: 185.5
    }
  },
  buttonStyle: {
    position: 'absolute',
    bottom: theme.spacing(30),
    maxWidth: 264,
    minWidth: 264,
    height: 56,
    color: 'white',
    borderRadius: 0,
    backgroundColor: shoppingExperienceColors.textPrimary,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.textPrimary
    }
  },
  disabledButtonStyle: {
    position: 'absolute',
    bottom: theme.spacing(30),
    maxWidth: 264,
    minWidth: 264,
    height: 56,
    color: 'white',
    borderRadius: 0,
    cursor: 'default',
    backgroundColor: shoppingExperienceColors.info,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.info
    },
    '&.MuiButton-disableElevation.Mui-disabled': {
      backgroundColor: shoppingExperienceColors.info,
      color: 'white',
      '&:hover': {
        backgroundColor: shoppingExperienceColors.info,
        cursor: 'not-allowed'
      }
    }
  },
  customIconStyle: {
    position: 'absolute',
    bottom: '37%',
    right: '8%',
    width: '40px',
    height: '40px',
    cursor: 'pointer'
  },
  contentStyle: {
    padding: 12,
    display: 'flex'
  },
  hazmatRestrictionWarningContent: {
    position: 'absolute',
    top: 20,
    right: 10,
    background: shoppingExperienceColors.textPrimary,
    textAlign: 'center',
    width: 150,
    borderRadius: 20,
    padding: '4px 0px'
  }
});

export default productStyles;
