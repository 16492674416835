import * as React from 'react';
import clsx from 'clsx';
import sumBy from 'lodash/sumBy';
import { Button } from '@swagup-com/components';
import { Grid, Box, Divider, makeStyles } from '@material-ui/core';
import { ChevronRight, InfoOutlined, Info } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { useIsFetching } from 'react-query';
import { useHistory, Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import apiPaths from '../../../../../helpers/apiPaths';
import {
  buildUrlWithParam,
  getShipmentGroupsInfo,
  moneyStr,
  reorderDateWithSlicing,
  sumByQuantity
} from '../../../../../helpers/utils';
import { useOrder } from '../OrderContext';
import ProductBreakdown from '../common/ProductBreakdown';
import ShipmentBreakdown from './ShipmentBreakdown';
import InactiveSizesModal from './InactiveSizesModal';
import { ProductProvider, useProduct } from '../common/ProductContext';
import { doesProductHasInactiveSizes } from '../common/utilsOrder';
import { useQueryParams } from '../../../../../hooks';
import styles from './OrderSummary.styles';

const useStyles = makeStyles(styles);

const Money = ({ amount, isLoading, width }) =>
  isLoading ? <Skeleton style={{ width, display: 'inline-block' }} /> : moneyStr(amount);

const ProductDetails = ({ onClose }) => {
  const { product } = useProduct();
  const productHasInactiveSizes = doesProductHasInactiveSizes(product);
  const classes = useStyles({ showWarning: productHasInactiveSizes });

  const order = useOrder();
  const { hasRefetchStarted } = order;
  const isFetchingOrder = useIsFetching([apiPaths.opportunities, order.id]);

  const [hovering, toggleHovering] = React.useReducer(prevHovering => !prevHovering, false);

  const history = useHistory();
  const query = useQueryParams();
  const open = +query.get('productBreakdown') === product.id;

  const urlToOpen = buildUrlWithParam(history.location, 'productBreakdown', product.id);

  return (
    <Grid item container style={{ paddingLeft: 32, paddingRight: 18 }}>
      <Grid container item direction="column" style={{ padding: '18px 0 15px' }}>
        <Grid item container>
          <p className={classes.itemName}>{product.product.name}</p>
        </Grid>
        <Grid onMouseEnter={toggleHovering} onMouseLeave={toggleHovering}>
          <Grid container item justifyContent="space-between" style={{ marginTop: 12 }}>
            <p className={clsx(classes.pricePerItem, { [classes.itemPriceHovered]: hovering })}>
              <Money
                amount={product.price}
                isLoading={isFetchingOrder || (hasRefetchStarted && +product.price === 0)}
                width={36}
              />
              <span style={{ marginLeft: 6 }}>x{sumByQuantity(product.sizes)}</span>
            </p>
            <p className={classes.totalPriceItem}>
              <Money
                amount={product.total}
                isLoading={isFetchingOrder || (hasRefetchStarted && product.total === 0)}
                width={58}
              />
            </p>
          </Grid>
          <Button variant="text" className={classes.editButton} component={Link} to={urlToOpen} replace>
            Update Quantities {productHasInactiveSizes && <Info style={{ fontSize: 11, marginLeft: 5 }} />}
            {hovering && <ChevronRight style={{ fontSize: 16 }} />}
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.itemDivider} />
      <ProductBreakdown open={open} onClose={onClose} />
    </Grid>
  );
};

const SummaryField = ({ amount, isLoading, classes }) => (
  <p className={clsx(classes.costSummaryValue, { [classes.greyedOutText]: amount === 0 })}>
    <Money amount={amount} isLoading={isLoading} width={58} />
  </p>
);

const OrderSummary = ({ children }) => {
  const classes = useStyles();
  const order = useOrder();
  const [isShipmentBreakdownOpen, toggleShipmentBreakdown] = React.useReducer(prev => !prev, false);
  const isFetchingOrder = useIsFetching([apiPaths.opportunities, order.id]);
  const isFetchingShipments = useIsFetching([apiPaths.shipmentGroups(order.id)]);
  const isFetchingWarehouse = useIsFetching([apiPaths.warehouseProofs(order.id)]);
  const isFetchingTotal = isFetchingOrder || isFetchingShipments || isFetchingWarehouse;

  const subtotal = sumBy(order.products, product => Number(product.price) * sumByQuantity(product.sizes));
  const shippingInfo = getShipmentGroupsInfo(order.shipmentGroups);
  const shipping = shippingInfo.international.price + shippingInfo.domestic.price;
  const warehouse = order.total_warehouses;
  const estimatedTax = Number(order.tax);
  const rushFee = sumBy(order.products, product => Number(product.rush_fee) * sumByQuantity(product.sizes));
  const total = subtotal + shipping + rushFee + warehouse + estimatedTax;

  const history = useHistory();
  const query = useQueryParams();
  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('productBreakdown');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const productIdToOpen = +query.get('productBreakdown');

  const { products, hasRefetchStarted } = order;
  React.useEffect(() => {
    const product = products.find(p => p.id === productIdToOpen);
    if (!product) removeOpenQueryParam();
  }, [products, productIdToOpen, removeOpenQueryParam]);

  const { billingGlobalUseTaxJarForTaxCollectionTemp012022: showTaxes } = useFlags();

  return (
    <Box
      boxShadow="0 12px 24px 0 rgba(0, 0, 0, 0.04)"
      bgcolor="white"
      height="calc(100vh - 100px)"
      width="100%"
      zIndex={1}
    >
      <div className={classes.container}>
        <Grid>
          <h2 className={classes.title}>Request #{order.id} Summary</h2>
          <p className={classes.createdDate}>Order Requested: {reorderDateWithSlicing(order.date)}</p>
          <Divider className={classes.itemDivider} />
        </Grid>
        <Grid item className={classes.itemListContainer} xs>
          <Grid container direction="column">
            {order.products.map(product => (
              <ProductProvider key={product.id} product={product}>
                <ProductDetails onClose={removeOpenQueryParam} />
              </ProductProvider>
            ))}
          </Grid>
        </Grid>

        <Grid container>
          <Grid container alignItems="center" style={{ padding: '0 32px' }}>
            <Grid
              container
              item
              justifyContent="space-between"
              xs={12}
              style={{ paddingTop: 18, borderTop: 'solid 1px #ced1d6' }}
            >
              <p className={classes.costSummaryText}>Subtotal</p>
              <p className={classes.costSummaryValue}>
                <Money
                  amount={subtotal}
                  isLoading={isFetchingOrder || (hasRefetchStarted && subtotal === 0)}
                  width={58}
                />
              </p>
            </Grid>
            <Grid container justifyContent="space-between" item xs={12} style={{ paddingTop: 18 }}>
              <p className={classes.costSummaryText}>Rush production</p>
              <SummaryField amount={rushFee} isLoading={isFetchingOrder} classes={classes} />
            </Grid>
            <Grid container justifyContent="space-between" item xs={12} style={{ paddingTop: 18 }}>
              <p className={classes.costSummaryText}>Storage</p>
              <SummaryField amount={warehouse} isLoading={isFetchingWarehouse} classes={classes} />
            </Grid>
            <Grid container item justifyContent="space-between" xs={12} style={{ paddingTop: 18 }}>
              <Grid container item alignItems="center" xs={6}>
                {order.shipmentGroups.length > 0 ? (
                  <Button
                    variant="text"
                    onClick={toggleShipmentBreakdown}
                    disabled={Boolean(isFetchingShipments)}
                    style={{ height: 16, minWidth: 16, padding: 0 }}
                  >
                    <span className={classes.costSummaryText}>Shipping</span>
                    <InfoOutlined style={{ fontSize: 15 }} className={classes.shippingInfoButton} />
                  </Button>
                ) : (
                  <a
                    href={`${process.env.REACT_APP_MAIN_DOMAIN}pricing#fulfillment`}
                    target="_blank"
                    tabIndex="-1"
                    className={classes.shippingLink}
                    rel="noreferrer"
                  >
                    <span className={classes.costSummaryText}>Shipping</span>
                    <InfoOutlined style={{ fontSize: 15 }} className={classes.shippingInfoButton} />
                  </a>
                )}
              </Grid>
              <SummaryField amount={shipping} isLoading={isFetchingShipments} classes={classes} />
            </Grid>
            {showTaxes && (
              <Grid container justifyContent="space-between" item xs={12} style={{ paddingTop: 18 }}>
                <p className={classes.costSummaryText}>Estimated taxes & fees</p>
                <SummaryField amount={estimatedTax} isLoading={isFetchingOrder} classes={classes} />
              </Grid>
            )}
            <Divider className={classes.itemDivider} style={{ backgroundColor: '#ced1d6', marginTop: 20 }} />
            <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 20 }}>
              <p className={classes.totalText}>Total</p>
              <p className={classes.totalValue}>
                <Money amount={total} isLoading={isFetchingTotal || (hasRefetchStarted && total === 0)} width={86} />
              </p>
            </Grid>
          </Grid>

          <Grid item style={{ margin: '24px auto' }}>
            {children}
          </Grid>
          <ShipmentBreakdown open={isShipmentBreakdownOpen} onClose={toggleShipmentBreakdown} />
        </Grid>
      </div>
      <InactiveSizesModal />
    </Box>
  );
};

export default OrderSummary;
