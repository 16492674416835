import React, { useCallback } from 'react';
import { round } from 'lodash';
import { useMembership } from '../../contexts/membershipContext';

const useDiscountPrice = () => {
  const { currentMembership } = useMembership();
  const productDiscount = currentMembership?.product_discount;

  const getDiscountPrice = useCallback(
    (price, discount = 'product') => {
      const currentDicount = currentMembership[`${discount}_discount`];
      if (!currentDicount) return price;
      return round(price - (price * currentDicount) / 100, 2);
    },
    [currentMembership]
  );

  const getPriceByShippingAndMarkup = useCallback((product, quantity, currentPrice) => {
    if (!product || !('shipping' in product) || !('markupOption' in product)) return currentPrice;
    const { shipping = null, markupOption = null } = product;

    if (!shipping && !markupOption) return currentPrice;

    const markupPercentage =
      markupOption.markupMatrix.find(item => {
        const { minimum, maximum } = item.parameters[0];
        return quantity >= minimum && quantity <= maximum;
      })?.markup[0].value || 1;

    if (markupPercentage <= 0) return currentPrice;

    return (currentPrice + shipping.value + (currentPrice + shipping.value) * (markupPercentage / 100)) * quantity;
  }, []);

  return {
    getDiscountPrice,
    getPriceByShippingAndMarkup,
    productDiscount
  };
};

export default useDiscountPrice;
