import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@swagup-com/components';
import { Grid } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Img } from '../global/ImgUtils';
import handleImg from '../../helpers/handleImgHelper';

const sections = {
  orders: {
    title: 'Orders',
    label: 'Start new order',
    to: '/product-onboarding',
    toViewAll: '/orders-completed',
    image: '/images/dashboard/empty-orders.png',
    width: 'auto'
  },
  shipments: {
    title: 'Shipments',
    label: 'Send Swag',
    to: '/send-swag/select-products',
    toViewAll: '/shipments',
    image: '/images/dashboard/empty-shipments.png',
    width: 'auto'
  },
  proofs: {
    title: 'Proofs',
    toViewAll: '/orders-requested',
    image: '/images/dashboard/empty-proofs.png'
  },
  representative: {
    title: 'Your SwagUp Rep',
    toViewAll: '/accounts',
    image: '/images/dashboard/empty-proofs.png'
  }
};

const SendSwagButton = ({ classes }) => {
  const location = useLocation();
  return (
    <div className={classes.separateSendSwagButton}>
      <Button
        component={Link}
        to={{ pathname: '/send-swag/select-products', state: { from: location } }}
        style={{ height: 56 }}
      >
        Send Swag
      </Button>
    </div>
  );
};

const CreateNewButton = ({ sectionName, variant }) => {
  const history = useHistory();
  const { newShoppingExperience } = useFlags();
  return (
    <>
      {newShoppingExperience ? (
        <Button
          id="Start-Order-Nav"
          variant={variant ?? 'primary'}
          onClick={() => history.push('/start-project')}
          style={{ width: 186, height: 56 }}
        >
          <Typography variant="body3RegularInter" style={{ color: '#3577D4', fontWeight: 600 }}>
            Start new order
          </Typography>
        </Button>
      ) : (
        <Button
          variant={variant ?? 'primary'}
          component={Link}
          to={sections[sectionName].to}
          style={{ height: 56, width: sections[sectionName].width }}
        >
          {sections[sectionName].label}
        </Button>
      )}
    </>
  );
};

const ViewMoreLink = ({ to, classes }) => (
  <Link to={to} className={classes.viewAll}>
    View all
    <ArrowForward viewBox="0 -13 30 32" style={{ marginLeft: 10 }} />
  </Link>
);

const HeaderSection = ({ classes, sectionName, hideViewAll = false }) => (
  <Grid item container xs={12} className={classes.subtitleContainer}>
    <Grid item xs={sectionName === 'proofs' ? 4 : 8}>
      <p className={classes.textSubtitle}>
        {hideViewAll && sectionName === 'orders' ? 'Orders' : sections[sectionName].title}
      </p>
    </Grid>
    {!hideViewAll && (
      <Grid item xs={sectionName === 'proofs' ? 8 : 4} align="right">
        <p className={classes.link}>
          <ViewMoreLink classes={classes} to={sections[sectionName].toViewAll} />
        </p>
      </Grid>
    )}
  </Grid>
);

const Header = ({ classes }) => (
  <Grid container alignItems="center">
    <Grid item lg={4} md={4} sm={4} xs={12}>
      <Typography variant="h2BoldInter" className={classes.textTitle}>
        Dashboard
      </Typography>
    </Grid>
    <Grid item container lg={8} md={8} sm={8} xs={12} className={classes.buttonsContainer}>
      <SendSwagButton classes={classes} />
      <CreateNewButton sectionName="orders" />
    </Grid>
  </Grid>
);

const ProductImage60x60 = ({ product, classes }) => (
  <Img
    src={handleImg(product && product.image, 78, 78)}
    width={60}
    height={60}
    className={classes.orderImg}
    alt={product && product.name}
  />
);

export { CreateNewButton, SendSwagButton, sections, Header, HeaderSection, ProductImage60x60 };
