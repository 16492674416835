/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Typography } from '@swagup-com/components';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import Styles from './common.styles';
import { shoppingExperienceColors } from '../constants';
import ColorSelection from './colorSelection';
import { useFilterContext } from '../../contexts/filter.context';
import { FILTER_FIELD_NAMES } from '../../utils/constants';

const useStyles = makeStyles(Styles);

const CustomList = props => {
  const {
    ind,
    isMultiSelect,
    data,
    checkbox = false,
    radio = false,
    card = false,
    color = false,
    sort = false,
    sortItem,
    handleSortChange,
    elementIndex
  } = props;

  const classes = useStyles();

  const history = useHistory();

  const { appliedFilters, setAppliedFilter, filterData, setRetainAppliedFilters } = useFilterContext();

  const handleChangeCheckbox = (event, filterItem) => {
    if (sort) {
      handleSortChange(data.parameter);
    } else {
      const _appliedFilter = [...appliedFilters];
      if (!isMultiSelect) {
        const currentFilterItem = filterData.find(filter => filter._id === ind);

        if (currentFilterItem.fieldName === FILTER_FIELD_NAMES.CATEGORY) {
          setRetainAppliedFilters(true);
          history.push(`/catalog/${filterItem.value}`);
          return;
        }

        if (currentFilterItem.fieldName === FILTER_FIELD_NAMES.SUB_CATEGORY && filterItem?.parentFieldValue) {
          setRetainAppliedFilters(true);
          history.push(`/catalog/${filterItem.parentFieldValue}/${filterItem.value}`);
          return;
        }

        const index = _appliedFilter.findIndex(item => item._id === ind);
        if (index !== -1) {
          _appliedFilter.splice(index, 1);
        }
        _appliedFilter.push({ ...filterItem, _id: ind });
      } else {
        const index = _appliedFilter.findIndex(item => item.value === filterItem?.value);
        if (index !== -1) {
          _appliedFilter.splice(index, 1);
        } else {
          _appliedFilter.push({ ...filterItem, _id: ind });
        }
      }
      setAppliedFilter(_appliedFilter);
    }
  };

  const isSortSelected = () => {
    return JSON.stringify(sortItem) === JSON.stringify(data.parameter);
  };

  return (
    <List
      style={
        (card && elementIndex === 0) || (color && elementIndex === 0)
          ? { padding: 0, cursor: 'pointer', marginLeft: -10 }
          : { padding: 0, cursor: 'pointer' }
      }
    >
      <ListItem className={classes.noHover} style={{ padding: 0 }} onClick={event => handleChangeCheckbox(event, data)}>
        {checkbox && (
          <Checkbox
            checked={appliedFilters.findIndex(item => item.value === data?.value) > -1}
            onChange={event => handleChangeCheckbox(event, data)}
            inputProps={{ 'aria-label': 'primary checkbox' }}
            classes={{
              root: classes.rootCheckbox,
              checked: classes.checkboxChecked
            }}
          />
        )}
        {radio && (
          <Radio
            checked={!!appliedFilters.find(item => item.value === data?.value)}
            value="a"
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
            classes={{
              root: classes.rootRadio,
              checked: classes.radioChecked
            }}
          />
        )}
        {card && (
          <Box
            className={classes.listCard}
            style={
              appliedFilters.findIndex(item => item.value === data?.value) > -1
                ? { borderColor: shoppingExperienceColors.textPrimary }
                : {}
            }
          >
            <Typography variant="body4RegularInter">{data?.label}</Typography>
          </Box>
        )}
        {color && (
          <ColorSelection
            className={classes.listCard}
            color={data.value}
            multiSelect
            selectedColor={appliedFilters.map(item => item.value)}
          />
        )}
        {!card && !color && !sort && (
          <Typography
            variant="body3RegularInter"
            className={classes.listTextItem}
            style={
              appliedFilters.findIndex(item => item.value === data?.value) > -1 && !checkbox && !radio
                ? { fontWeight: 'bold', color: `${shoppingExperienceColors.primary}` }
                : (appliedFilters.findIndex(item => item.value === data?.value) > -1 && checkbox) ||
                  (appliedFilters.find(item => item.value === data?.value) && radio)
                ? { color: `${shoppingExperienceColors.ctaHover}`, fontWeight: 'bold' }
                : {}
            }
          >
            {data?.label}
            <span style={{ color: `${shoppingExperienceColors.info}` }}> ({data.totalProducts})</span>
          </Typography>
        )}
        {sort && (
          <Typography
            variant="body3RegularInter"
            className={classes.listTextItem}
            style={isSortSelected() ? { fontWeight: 'bold', color: `${shoppingExperienceColors.primary}` } : {}}
          >
            {data?.label}
          </Typography>
        )}
      </ListItem>
    </List>
  );
};

export default CustomList;
