import { shoppingExperienceColors } from '../../shared/constants';

export const bannerStyles = theme => ({
  bannerContainer: {
    padding: '0px 40px',
    marginBottom: '48px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      margin: 0
    }
  },
  banner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: shoppingExperienceColors.bannarBGColor,
    width: '100%',
    height: 116,
    padding: 16,
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      height: 165,
      flexDirection: 'column'
    }
  },
  image: {
    width: 70,
    height: 80,
    objectFit: 'contain'
  },
  imageModal: {
    width: 40,
    height: 50,
    objectFit: 'contain'
  },
  bannerContent: {
    display: 'flex',
    alignItems: 'center',
    gap: 25,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row-reverse'
    }
  },
  highlighted: {
    color: '#125CFF'
  },
  upgradeButtonStyle: {
    borderRadius: 0,
    backgroundColor: shoppingExperienceColors.white,
    color: shoppingExperienceColors.textPrimary,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.white
    }
  }
});
