/* eslint-disable no-prototype-builtins */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles, Grid, TextField, InputAdornment } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { Typography } from '@swagup-com/components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useDispatch } from 'react-redux';
import { DownArrowIcon } from '../../shared/icons';
import Chip from '../../shared/ui/chip';
import Styles from './filter.styles';
import CustomList from '../../shared/ui/list';
import { shoppingExperienceColors } from '../../shared/constants';
import { useFilterContext } from '../../contexts/filter.context';
import { FILTER_FIELD_NAMES } from '../../utils/constants';

const useStyles = makeStyles(Styles);
const MINIMUM_CHIPS_DISPLAY = 6;
const Filter = props => {
  const { setAppliedFilters, appliedFilters } = useFilterContext();
  const [showMinimum, setShowMinimum] = useState(MINIMUM_CHIPS_DISPLAY);
  const {
    filterData,
    clearAll,
    openAccordions,
    setOpenAccordions,
    handleSortChange,
    sortItem,
    setAppliedFilter,
    categoryName = null,
    products = []
  } = props;

  const classes = useStyles();

  const elementType = (type, element, id, handleChangePrice, minPrice, maxPrice, handleApplyPrice) => {
    switch (type) {
      case 'sort':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
              sort
              sortItem={sortItem}
              handleSortChange={handleSortChange}
            />
          );
        });
      case 'list':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
            />
          );
        });
      case 'checkbox':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              checkbox
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
            />
          );
        });
      case 'radio':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              radio
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
            />
          );
        });
      case 'card':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              card
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
            />
          );
        });
      case 'color':
        return element?.values?.map((itm, index) => {
          return (
            <CustomList
              products={products}
              isMultiSelect={element?.multiselect}
              data={itm}
              ind={id}
              color
              setAppliedFilters={setAppliedFilters}
              elementIndex={index}
            />
          );
        });
      case 'number':
        return (
          <Grid container style={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <Grid item style={{ maxWidth: 160 }}>
              <Typography variant="body3RegularInter">Min</Typography>
              <TextField
                value={minPrice}
                type="number"
                onChange={e => handleChangePrice(e, 'min', id)}
                onBlur={handleApplyPrice}
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                variant="outlined"
                className={classes.textField}
              />
            </Grid>
            <Typography className={classes.priceHypher}>-</Typography>
            <Grid item style={{ maxWidth: 160 }}>
              <Typography variant="body3RegularInter">Max</Typography>
              <TextField
                value={maxPrice}
                type="number"
                onChange={e => handleChangePrice(e, 'max', id)}
                onBlur={handleApplyPrice}
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  startAdornment: <InputAdornment position="start">$</InputAdornment>
                }}
                variant="outlined"
                className={classes.textField}
              />
            </Grid>
          </Grid>
        );
      default:
        return <div />;
    }
  };

  useEffect(() => {
    setShowMinimum(MINIMUM_CHIPS_DISPLAY);
  }, [appliedFilters]);

  const FilteringItems = ({ item, id, totalItems }) => {
    const [minMaxId, setMinMaxId] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');

    const dispatch = useDispatch();

    const selectedLegth = appliedFilters.filter(i => i.id === id).length;

    useEffect(() => {
      const appliedPriceFilter = appliedFilters.find(filter => filter.id === id);
      if (appliedPriceFilter && appliedPriceFilter?.values?.length === 2) {
        setMinPrice(appliedPriceFilter.values[0]);
        setMaxPrice(appliedPriceFilter.values[1]);
      }
    }, [appliedFilters, id]);

    const handleChangePrice = (event, type, selectedId) => {
      if (minMaxId === '' || minMaxId === null) setMinMaxId(selectedId);
      const value = event.target.value.replace(/[^0-9]/g, '');
      if (/^\$?\d*\.?\d*$/.test(value)) {
        if (type === 'min') {
          setMinPrice(value ? Number(value) : '');
        } else {
          setMaxPrice(value ? Number(value) : '');
        }
      }
    };

    const handleApplyPrice = () => {
      if (minPrice !== '' && maxPrice !== '') {
        const index = appliedFilters.findIndex(appliedFilterItem => appliedFilterItem.id === minMaxId);
        if (index !== -1) {
          appliedFilters.splice(index, 1);
        }
        const updatedItems = [
          ...appliedFilters,
          { id: minMaxId, label: `$${minPrice} - $${maxPrice}`, values: [String(minPrice), String(maxPrice)] }
        ];
        const filteredArray = updatedItems.filter(filterItem => filterItem.id !== '');
        dispatch(setAppliedFilter(filteredArray));
      } else if (minPrice === '' && maxPrice === '') {
        setMinMaxId('');
      }
    };

    const handleToggle = index => {
      setOpenAccordions(prev => ({
        ...prev,
        [index]: !prev[index]
      }));
    };

    const selectAllHandler = _id => {
      const data = [...item?.values];
      const _allItems = data.map(dt => {
        const updatedDt = { ...dt };
        return {
          ...updatedDt,
          id
        };
      });
      const unselectedItems = appliedFilters.filter(selectedItem => {
        return selectedItem?.id === _id && selectedItem?._id === _id;
      });
      const updatedItems = [...unselectedItems, ..._allItems];
      dispatch(setAppliedFilter(updatedItems));
    };

    const unSelectAllHandler = _id => {
      const _selectedFilters = [...appliedFilters];
      const unselectedItems = _selectedFilters.filter(selectedItem => {
        return selectedItem?.id !== _id && selectedItem?._id !== _id;
      });

      dispatch(setAppliedFilter(unselectedItems));
    };

    return (
      <Grid className={classes.filteringItemsRoot}>
        <Accordion expanded={openAccordions[id]} onChange={() => handleToggle(id)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: '#131415' }} />}
            aria-controls={`panel-${id}-content`}
            id={`panel-${id}-header`}
            className={classes.accordionSummaryStyle}
          >
            <Typography variant="body3SemiBoldInter">{item?.name}</Typography>
            {item?.multiselect && (
              <div
                style={{ marginLeft: 16 }}
                onClick={() => {
                  // eslint-disable-next-line no-unused-expressions
                  totalItems === selectedLegth ? unSelectAllHandler(id) : selectAllHandler(id);
                }}
              >
                <Typography variant="body3RegularInter" style={{ color: shoppingExperienceColors.primary }}>
                  {totalItems === selectedLegth ? 'Unselect all' : 'Select all'}
                </Typography>
              </div>
            )}
          </AccordionSummary>
          <AccordionDetails
            className={
              item?.inputType === 'card' || item?.inputType === 'color'
                ? classes.accordionDetailsCardStyle
                : classes.accordionDetailsStyle
            }
          >
            {elementType(item?.inputType, item, id, handleChangePrice, minPrice, maxPrice, handleApplyPrice)}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  const filtersToDisplay = useMemo(() => {
    return filterData.map(filter => {
      let hidden = false;

      if (categoryName) {
        hidden =
          filter.fieldName === FILTER_FIELD_NAMES.CATEGORY || filter.fieldName === FILTER_FIELD_NAMES.SUB_CATEGORY;
      }

      return { ...filter, hidden };
    });
  }, [categoryName, filterData]);

  return (
    <>
      <Box className={classes.container}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="body3SemiBoldInter">Applied filters</Typography>
          <div
            onClick={() => {
              clearAll(true);
              setShowMinimum(MINIMUM_CHIPS_DISPLAY);
            }}
          >
            <Typography variant="body3RegularInter" style={{ color: '#3577D4', cursor: 'pointer' }}>
              Clear all
            </Typography>
          </div>
        </div>
        <div style={{ paddingTop: 16, display: 'ruby' }}>
          {appliedFilters.map((i, index) => {
            if (index < showMinimum) {
              return (
                <Chip
                  label={i.label}
                  value={i.value}
                  setAppliedFilters={setAppliedFilters}
                  isPrice={!!i.hasOwnProperty(i.totalProducts)}
                  categoryName={categoryName}
                />
              );
            }
          })}
        </div>
        {showMinimum < appliedFilters.length && showMinimum > 0 && (
          <div
            className={classes.showMore}
            onClick={() => {
              setShowMinimum(appliedFilters.length);
            }}
          >
            <DownArrowIcon marginTop={12} />
            <Typography variant="body4MediumInter">{`${appliedFilters.length - showMinimum} Show more`}</Typography>
          </div>
        )}
      </Box>
      {filtersToDisplay?.map((item, index) => {
        if (!item?.hidden && (item?.values?.length > 1 || item?.inputType === 'number')) {
          return (
            <React.Fragment key={`filter-item-${index}`}>
              <FilteringItems item={item} id={item?._id} totalItems={item?.values?.length} />
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

export default Filter;
