import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import RelatedAccountsDropdown from './RelatedAccountsDropdown';
import styles from './checkoutPageAssociatedAccounts.styles';
import { useCheckoutContext } from '../../context/checkout.context';
import ContactDetails from '../../shared/ui/contactDetails';

const useStyles = makeStyles(styles);

const CheckoutPageAssociatedAccounts = ({ isVerified, onChange, ref }) => {
  const classes = useStyles();

  const { profile, selectedAccount } = useCheckoutContext();

  return (
    <>
      <Grid container className={classes.root}>
        {profile?.first_name ? (
          <>
            <Typography variant="h5SemiBoldInter">Hi, {profile.first_name}!</Typography>
            <Typography variant="body3RegularInter">{profile.email}</Typography>
            <RelatedAccountsDropdown />
          </>
        ) : (
          <></>
        )}
      </Grid>
      <ContactDetails
        isVerified={isVerified}
        onChange={onChange}
        ref={ref}
        prefetchedContactDetails={{
          fullName: `${profile?.first_name} ${profile?.last_name}`,
          companyName: selectedAccount?.name || '',
          ...(profile?.mobile_phone ? { phone: profile.mobile_phone } : {})
        }}
      />
    </>
  );
};

export default CheckoutPageAssociatedAccounts;
