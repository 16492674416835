import React, { useEffect } from 'react';
import { Tooltip } from '@swagup-com/components';
import { ClickAwayListener, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  tooltip: {
    borderRadius: 8,
    padding: '0px 0px',
    width: 288,
    marginTop: 8
  }
});

const ClickableDrawer = ({
  title,
  children,
  placement = 'top',
  disabled,
  isAccountDropdown = false,
  setOpen,
  open
}) => {
  const classes = useStyles();

  const handleTooltipClose = ({ target: { id, innerText } }) => {
    if (id !== 'account-search-input' && innerText !== 'Switch Accounts' && innerText !== 'Hide linked accounts')
      setOpen(false);
  };

  const handleTooltipOpen = () => setOpen(true);

  return (
    <Tooltip
      disableTouchListener
      disableHoverListener={isAccountDropdown}
      interactive
      open={open && !disabled}
      onClose={handleTooltipClose}
      arrow={!isAccountDropdown}
      title={title}
      placement={placement}
      classes={{ tooltip: isAccountDropdown ? classes.tooltip : undefined }}
    >
      <div
        role="presentation"
        onMouseEnter={isAccountDropdown ? undefined : handleTooltipOpen}
        onClick={handleTooltipOpen}
      >
        {children}
      </div>
    </Tooltip>
  );
};

export default ClickableDrawer;
