import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@material-ui/styles';
import { Typography as SwagupTypography } from '@swagup-com/components';
import Tooltip from '@material-ui/core/Tooltip';
import { shoppingExperienceColors } from '../../shared/constants';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { DollarPriceSymbol } from '../../shared/ui/common';
import { CART_TYPES } from '../../utils/constants';
import styles from './checkoutPage.styles';
import StrikeOut from '../../shared/ui/StrikeOut';
import { useResponsive } from '../../hooks';
import { useCartContext } from '../../context/cart.context';

const useStyles = makeStyles(styles);

const CartAccordionItem = ({ item }) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth();
  const [cartType, setCartType] = useState('');
  const { cartProducts, getWithAndWithoutDiscountedPricePerProduct } = useCartContext();
  const { currentDevice, DEVICES_TYPE, customRange } = useResponsive({ min: 768, max: 959 });

  const { productDiscount } = useDiscountPrice();

  const isPack = cartType === CART_TYPES.PACK;

  const productImage = item?.thumbnailURL
    ? item?.thumbnailURL
    : item?.media?.find(mediaItem => mediaItem?.mediaCategory === 'product')?.url || '';

  const product = cartProducts.find(cartProduct => cartProduct.cartId === item.cartId);

  const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(product);

  const itemNameLength = currentDevice === DEVICES_TYPE.DESKTOP ? 35 : 25;

  useEffect(() => {
    const storedCartType = localStorage.getItem('cartType');
    if (storedCartType) {
      setCartType(storedCartType);
    }
  }, []);

  return (
    <Grid container alignItems="center" className={classes.itemContainer}>
      <Grid item style={{ width: '60px', height: '60px' }}>
        <img src={productImage} alt={item.name} className={classes.image} width={60} height={60} />
      </Grid>
      <Grid item>
        <Tooltip
          title={item?.name || ''}
          placement="top"
          arrow
          classes={{
            tooltip: classes.tooltip
          }}
        >
          <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {customRange ? (
              <SwagupTypography variant="body3RegularInter">{item?.name}</SwagupTypography>
            ) : (
              <SwagupTypography variant="body3RegularInter">
                {item?.name?.length > itemNameLength ? `${item?.name?.substring(0, 25)}...` : item?.name}
              </SwagupTypography>
            )}
          </div>
        </Tooltip>
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.info }}>
          Color:{' '}
          {item?.color?.colorName?.length > 20
            ? `${item?.color?.colorName.substring(0, 15)}...`
            : item?.color?.colorName}
        </SwagupTypography>
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.info }}>
          Qty {isPack ? ' per pack' : ''}: {item.quantity} -{' '}
          {DollarPriceSymbol(totalPriceWithoutDiscount / item.quantity)} / item
        </SwagupTypography>
        {isAuthenticated && productDiscount !== 0 ? (
          <Grid container style={{ gap: 14, marginTop: 10 }}>
            <SwagupTypography variant="body3RegularInter">{DollarPriceSymbol(totalPriceWithDiscount)}</SwagupTypography>
            <StrikeOut value={totalPriceWithoutDiscount} />
          </Grid>
        ) : (
          <SwagupTypography variant="body3RegularInter">
            {DollarPriceSymbol(totalPriceWithoutDiscount)}
          </SwagupTypography>
        )}
      </Grid>
    </Grid>
  );
};

export default CartAccordionItem;
