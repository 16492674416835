import dayjs from 'dayjs';
import Cookie from 'js-cookie';
import { round } from 'lodash';
import axiosInstance from '../../apis/DashBoard';
import { CATALOG_PRODUCT_TYPE, PRODUCTION_TIME } from '../shared/constants';
import { apiRoutes } from '../apis/apiRoutes';

let cartInfos = {
  date: null,
  firm_ihd: false,
  comments: '',
  coupon_code: '',
  primaryLogo: null,
  secondaryLogo: null,
  cartQuantity: 0,
  name: '',
  email: '',
  companyName: '',
  phone: '',
  quantity: '',
  budget: 0,
  numberOfColors: 1,
  qtyForPack: 0,
  totalEstimate: 0
};

export const setCartInfo = values => {
  cartInfos = { ...cartInfos, ...values };
};

export const getCartInfo = () => cartInfos;

const mergeDataWithCookie = (data, cookieName = 'swagup') => {
  const cookieValue = Cookie.get(cookieName);
  const customUtmParameters = localStorage.getItem('custom_utm_parameter') || null;

  try {
    const mergedData = {
      ...data,
      ...(customUtmParameters && JSON.parse(customUtmParameters)),
      ...(cookieValue && JSON.parse(cookieValue))
    };
    log.debug(`merged data with cookie ${cookieName}:`, JSON.stringify(mergedData));
    return mergedData;
  } catch {
    return data;
  }
};

const mergeCampaignIdsWithData = data => {
  const msclkid = localStorage.getItem('_uetmsclkid');
  const gacid = Cookie.get('_ga');

  return mergeDataWithCookie({
    ...data,
    msclkid,
    gacid
  });
};

const buildAccountProductsQty = (cart, cartInfo, isBulk) => {
  if (isBulk) {
    const bulk = cart.map(({ productCode, quantity, color }) => ({
      account_product_data: {
        source_product_code: productCode,
        theme_color: color?.colorName || '',
        theme_color_hex: color?.hex || '',
        primary_logo_path: cartInfo.primaryLogo,
        secondary_logo_path: cartInfo.secondaryLogo
      },
      quantity
    }));

    return bulk.length === 1 ? bulk[0] : bulk;
  }

  const pack = {
    account_product_data: {
      pack_items: cart.map(({ productCode, quantity, color }) => ({
        source_product_code: productCode,
        units_per_pack: quantity,
        theme_color: color?.colorName || '',
        theme_color_hex: color?.hex || ''
      })),
      primary_logo_path: cartInfo.primaryLogo,
      secondary_logo_path: cartInfo.secondaryLogo
    },
    production_time: PRODUCTION_TIME.standard,
    quantity: cartInfo.qtyForPack
  };

  return pack;
};

const buildOpportunityPayload = (cart, cartInfo, isBulk) => {
  const accountProductData = buildAccountProductsQty(cart, cartInfo, isBulk);
  return {
    account_products: accountProductData.length > 1 ? accountProductData : [accountProductData],
    lead_source: 'Start Order',
    client_ihd: cartInfo.date,
    firm_ihd: cartInfo.firm_ihd,
    notes: cartInfo.comments,
    coupon_code: cartInfo.coupon_code
  };
};

const buildFormOrderPayload = (products, info, isBulk) => {
  return mergeCampaignIdsWithData({
    form_type: isBulk ? CATALOG_PRODUCT_TYPE.PRODUCT : CATALOG_PRODUCT_TYPE.PACK,
    type: 2,
    full_name: info.fullName,
    work_email: info.email,
    company: info.companyName,
    coupon_code: info?.coupon_code || '',
    inhands_date: dayjs(info.date).format('YYYY-MM-DD'),
    firm_ihd: info?.firm_ihd || false,
    comments: info.comments,
    resources: [info.primaryLogo, info.secondaryLogo].filter(Boolean).map(p => ({ resource_path: p })),
    phone: info.phone,
    pack_quantity: isBulk ? 1 : +info.swagPacks,
    cart_quantity: +info.qtyForPack,
    pack_budget: round(isBulk ? +info.totalEstimate : +info.budget, 2),
    number_of_colors: info.numberOfColors,
    items: products.map(({ productCode, quantity, color }) => ({
      source_product_code: productCode,
      quantity,
      selected_color: color?.colorName || '',
      selected_color_hex: color?.hex || ''
    }))
  });
};

export const createCheckoutOrder = async (isAuthenticated, cart, cartInfo, isBulk) => {
  const url = isAuthenticated ? apiRoutes.opportunities : apiRoutes.orders;
  let payload = {};
  if (isAuthenticated) {
    payload = buildOpportunityPayload(cart, cartInfo, isBulk);
  } else {
    payload = buildFormOrderPayload(cart, cartInfo, isBulk);
  }
  return axiosInstance.post(url, payload);
};
