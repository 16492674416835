import { useQuery } from 'react-query';
import { matchPath, useLocation } from 'react-router-dom';
import { fetchCollectionsDetails } from '../apis';
import { getLastPathSegment } from './getPlatinumProductDiscount';

const getDescription = () => {
  switch (getLastPathSegment() || getLastPathSegment().toLowerCase()) {
    case 'bonusItems':
      return `Explore SwagUp's Bonus category for exclusive swag products that elevate your brand. Discover unique, high-quality items perfect for surprises, rewards, and giveaways that leave a lasting impression!`;
    case 'electronics':
      return `Discover SwagUp's Electronics category featuring cutting-edge tech swag. From custom headphones to branded chargers, find premium gadgets that impress and keep your brand top of mind!`;
    case 'packaging':
      return `Enhance your unboxing experience with SwagUp's Packaging category. Explore custom-designed boxes, eco-friendly materials, and premium packaging options that make your swag unforgettable!`;
    case 'office':
      return `Upgrade workspaces with SwagUp's Office category. Explore branded notebooks, pens, desk accessories, and more to keep your brand front and center in any professional setting!`;
    case 'apparel':
      return `Show off your style with SwagUp's Apparel category. Discover custom-branded clothing, from t-shirts to hoodies, that combines comfort, quality, and your brand's unique identity!`;
    case 'bags':
      return `Carry your brand further with SwagUp's Bags category. Explore custom tote bags, backpacks, and more—perfect for events, giveaways, and everyday use!`;
    case 'drinkware':
      return `Raise a toast to your brand with SwagUp's Drinkware category. Discover custom mugs, water bottles, tumblers, and more—designed to keep your logo in hand and on display!`;
    case 'homeAndDIY':
      return `Make your brand a part of everyday life with SwagUp's Home & DIY category. Explore custom kitchenware, decor, tools, and more—perfect for adding a personal touch to any space!`;
    case 'foodAndBeverage':
      return `Delight taste buds with SwagUp's Food & Beverage category. Explore custom snacks, gourmet treats, and branded drink options that leave a lasting impression on any occasion!`;
    case 'accessories':
      return `Complete the look with SwagUp's Accessories category. Discover custom hats, socks, scarves, and more—stylish add-ons that showcase your brand in everyday fashion!`;
    case 'sweatshirts':
      return `Stay cozy and stylish with SwagUp's Sweatshirts category. Explore custom-branded hoodies and sweatshirts that offer comfort, quality, and a bold display of your brand!`;
    case 'tops':
      return `Refresh your wardrobe with SwagUp's Tops category. Discover custom-branded t-shirts, polos, and button-ups that combine style and comfort while showcasing your brand!`;
    case 'outerwear':
      return `Stay warm and stylish with SwagUp's Outerwear category. Explore custom-branded jackets, fleece, and coats that offer protection from the elements while promoting your brand!`;
    case 'mugs':
      return `Enjoy your favorite drinks in style with SwagUp's Mugs category. Explore custom-branded mugs that add a personal touch to every sip, perfect for giveaways or daily use!`;
    case 'backpacks':
      return `Carry your essentials with flair using SwagUp's Backpacks category. Discover custom-branded backpacks designed for style, functionality, and to showcase your brand on the go!`;
    case 'construction':
      return `Build your brand with SwagUp's Construction category. Explore durable, custom-branded tools, gear, and safety equipment designed for the hardworking professional and to showcase your logo`;
    case 'holidaySwag':
      return `Celebrate the season with SwagUp's Holiday Swag collection. Discover festive, custom-branded gifts and products perfect for spreading holiday cheer and promoting your brand in style!`;
    case 'conferenceAndEvents':
      return `Make a lasting impression at your next event with SwagUp's Conference & Events category. Discover custom-branded swag like lanyards, notebooks, and giveaways designed to elevate your brand presence!`;
    case 'babyAndKids':
      return `Delight the little ones with SwagUp's Baby & Kids category. Explore custom-branded toys, apparel, and accessories that make a fun and memorable impression on families and young audiences!`;
    case 'newHires':
      return `Welcome new team members with SwagUp's New Hires category. Discover custom-branded welcome kits, apparel, and office essentials that make a great first impression and foster a sense of belonging!`;
    case 'premium':
      return `Elevate your brand with SwagUp's Premium category. Explore high-quality, luxury swag items like custom tech, exclusive apparel, and sophisticated gifts that make a lasting impact and stand out at any occasion!`;
    case 'staffPicks':
      return `Discover the best of the best with SwagUp's Staff Picks. Explore hand-selected, top-rated swag items chosen by our team to deliver quality, style, and a memorable brand experience!`;
    case 'medicalSwag':
      return `Show care and professionalism with SwagUp's Medical Swag category. Explore custom-branded healthcare essentials like scrubs, masks, and tools designed to make a positive impact in medical environments!`;
    case 'awardsAndMilestones':
      return `Celebrate achievements with SwagUp's Awards & Milestones category. Explore custom-branded trophies, plaques, and recognition gifts that honor success and create lasting memories for your team or clients!`;
    case 'lowMinimum':
      return `Get the perfect swag, no matter the size, with SwagUp's Low Minimum category. Explore customizable products available in small quantities, ideal for businesses and events with specific needs!`;
    case 'uniqueSwag':
      return `Stand out with SwagUp's Unique Swag category. Discover one-of-a-kind, custom-branded items that set your brand apart and leave a lasting impression at any event or giveaway!`;
    case 'womenOrMinorityOwned':
      return `Support diversity with SwagUp's Women/Minority Owned category. Discover custom-branded products from women and minority-owned businesses that showcase your commitment to inclusivity and empower diverse entrepreneurs!`;
    case 'pride':
      return `Celebrate equality and inclusion with SwagUp's Pride category. Explore custom-branded products that proudly support the LGBTQ+ community, perfect for events, giveaways, and promoting diversity!`;
    case 'lowBudget':
      return `Make an impact without breaking the bank with SwagUp's Low Budget category. Discover affordable, custom-branded swag that delivers quality and value, perfect for any event or promotion!`;
    case 'fast':
      return `Need swag in a hurry? SwagUp's Fast category offers quick turnaround on custom-branded products, ensuring you get high-quality swag on time for your event or promotion!`;
    case 'ecoOrSustainability':
      return `Promote your brand with a green touch using SwagUp's Eco/Sustainability category. Explore custom-branded products made from eco-friendly materials, designed to support your commitment to sustainability!`;
    case 'realEstateSwag':
      return `Make a lasting impression in the real estate world with SwagUp's Real Estate Swag category. Explore custom-branded items like keychains, tote bags, and more, perfect for open houses, client gifts, and marketing materials!`;
    case 'uniforms':
      return `Create a professional look with SwagUp's Uniforms category. Discover custom-branded apparel and workwear that ensures your team stands out with style, comfort, and a unified brand identity!`;
    case 'pets':
      return `Show some love for furry friends with SwagUp's Pets category. Explore custom-branded pet accessories, toys, and essentials that make perfect gifts for pet owners and promote your brand in a fun way!`;
    case 'hospitalitySwag':
      return `Enhance guest experiences with SwagUp's Hospitality Swag category. Discover custom-branded items like towels, tote bags, and amenities that make a lasting impression on visitors and elevate your brand’s presence in the hospitality industry!`;
    case 'swagPacks':
      return `Curate the perfect brand experience with SwagUp's Swag Packs. Discover custom-designed bundles of high-quality swag items that make great gifts, giveaways, and promotional tools for any occasion!`;
    case 'bestSellers':
      return `Discover the top-rated items with SwagUp's Best Sellers category. Explore the most popular custom-branded swag, from tech gadgets to apparel, chosen by brands for their quality, style, and impact!`;
    case 'printonDemand':
      return `Create custom swag on your terms with SwagUp's Print on Demand category. Design and order branded products as needed, with no minimums and fast delivery, perfect for on-the-spot marketing and special promotions!"`;
    default:
      return 'Shop custom branded merchandise, corporate gifts, and promo products at SwagUp. Discover quality swag, apparel, and accessories to elevate your brand.';
  }
};

const seoTags = {
  '/dashboard/catalog/collections/:selectedCollections': {
    title: ':selectedCollections | SwagUp',
    description: getDescription()
  },
  '/dashboard/catalog/:category': {
    title: ':category | SwagUp',
    description: getDescription()
  },
  '/dashboard/catalog/:category/:subCategory': {
    title: ':category - :subCategory | SwagUp',
    description: getDescription()
  }
};

export const getRouteMetadata = () => {
  const { data } = useQuery(['collectionsData'], () => fetchCollectionsDetails());
  const collections = data?.productCollections?.collections ?? [];
  const selectedCollection = decodeURIComponent(location.pathname.split('/').pop());
  const collectionTitle = collections.find(collection => decodeURIComponent(collection?.slug) === selectedCollection);

  const currentPath = window.location.pathname;
  const matchedRoute = Object.keys(seoTags).find(route => matchPath(currentPath, { path: route, exact: true }));

  if (!matchedRoute) return null;

  const { title: rawTitle, description: rawDescription } = seoTags[matchedRoute];
  const match = matchPath(currentPath, { path: matchedRoute, exact: true });

  const dynamicTitle =
    matchedRoute === '/dashboard/catalog/collections/:selectedCollections' && collectionTitle?.name
      ? `${collectionTitle?.name} | SwagUp`
      : rawTitle;

  const replaceDynamicValues = (template, params) =>
    Object.keys(params).reduce(
      (result, key) =>
        result.replace(`:${key}`, params[key].charAt(0).toUpperCase() + params[key].slice(1).toLowerCase()),
      template
    );

  const title = replaceDynamicValues(dynamicTitle, match.params);
  const description = replaceDynamicValues(rawDescription, match.params);

  return { title, description };
};
