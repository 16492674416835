import React, { useMemo, useState } from 'react';
import { CardContent, Grid, makeStyles, Card, Button, CardMedia } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { useHistory } from 'react-router-dom';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import Styles from './product.styles';
import { GetMinimumPrice, GetMediaUrls, DollarPriceSymbol } from '../../shared/ui/common';
import { useCartContext } from '../../context/cart.context';
import ProductLoader from '../../shared/ui/productLoader';
import { shoppingExperienceColors } from '../../shared/constants';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import StrikeOut from '../../shared/ui/StrikeOut';
import TruncatedText from '../../shared/ui/truncateText';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(Styles);

const Product = props => {
  const classes = useStyles();
  const { details, isTouchDevice, isMobile, onOpen } = props;
  const [hovered, setHovered] = useState(false);
  const history = useHistory();
  const { isHazmatExists } = useCartContext();
  const { getDiscountPrice, productDiscount, getPriceByShippingAndMarkup } = useDiscountPrice();
  const { isAuthenticated } = useAuth();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  const isHazmat = useMemo(() => {
    return details?.hazmatDetails?.isHazmat && isHazmatExists;
  }, [details, isHazmatExists]);

  const { addToCart: addToCartFn, applicableQuantity, isProductExistInCart } = useCartContext();

  const handleClick = () => {
    const { category } = props;
    const { subCategory } = props;
    const newPath = `/catalog/product${category ? `/${category}` : ''}${subCategory ? `/${subCategory}` : ''}/${
      encodeURIComponent(details.name)
      // eslint-disable-next-line no-underscore-dangle
    }/${details.productCode}`;

    history.push(newPath);
  };

  const handleMouseEnter = () => {
    if (!isTouchDevice && !isMobile) setHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isTouchDevice && !isMobile) setHovered(false);
  };

  const addToCart = event => {
    event.stopPropagation();
    const existingProductInCart = isProductExistInCart(details);
    addToCartFn(
      {
        ...details,
        decorationMethod: details?.decorationOptions?.[0] || '',
        isHazmat: details?.hazmatDetails?.isHazmat
      },
      existingProductInCart ? existingProductInCart.quantity : applicableQuantity()
    );
    const updatedQuantity = applicableQuantity();
    details.quantity = updatedQuantity;
    onOpen({ ...details });
  };

  return (
    <Grid container>
      <Card
        className={classes.cardContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleClick}
      >
        {' '}
        {details ? (
          <>
            {isHazmat === true && (
              <div className={classes.hazmatRestrictionWarningContent}>
                <Typography variant="body3RegularInter" style={{ color: shoppingExperienceColors.white }}>
                  Hazmat restriction
                </Typography>
              </div>
            )}
            <CardMedia
              className={classes.cardMediaStyle}
              image={hovered ? GetMediaUrls(details?.media)?.thumbnailImage : GetMediaUrls(details?.media)?.realImage}
              style={{
                objectFit: 'contain',
                backgroundSize: 'contain', // Ensure compatibility
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center'
              }}
            />

            {hovered && (
              <Grid item style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className={isHazmat ? classes.disabledButtonStyle : classes.buttonStyle}
                  variant="contained"
                  onClick={e => {
                    isHazmat ? e.stopPropagation() : addToCart(e);
                  }}
                >
                  <Typography variant="body2RegularInter" style={{ color: '#ffff' }}>
                    Add{' '}
                  </Typography>
                </Button>
              </Grid>
            )}
            {isMobile && (
              <AddCircleIcon
                className={classes.customIconStyle}
                style={isHazmat ? { color: shoppingExperienceColors.info, cursor: 'default' } : {}}
                onClick={e => {
                  isHazmat ? e.stopPropagation() : addToCart(e);
                }}
              />
            )}
            <CardContent className={classes.contentStyle} style={{ flexDirection: 'column' }}>
              <Grid item style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                {isAuthenticated && productDiscount !== 0 ? (
                  <Grid
                    container
                    alignItems={currentDevice !== DEVICES_TYPE?.MOBILE ? 'center' : undefined}
                    style={{
                      gap: currentDevice !== DEVICES_TYPE?.MOBILE ? !isMobile && 8 : undefined,
                      rowGap:
                        currentDevice === DEVICES_TYPE?.MOBILE || currentDevice === DEVICES_TYPE?.TABLET
                          ? '2px'
                          : undefined
                    }}
                  >
                    {currentDevice !== DEVICES_TYPE?.MOBILE && currentDevice !== DEVICES_TYPE?.TABLET ? (
                      <>
                        <Typography variant="body3RegularInter">
                          Starting at{' '}
                          <span style={{ fontWeight: 600 }}>
                            {DollarPriceSymbol(
                              getPriceByShippingAndMarkup(
                                details,
                                1,
                                getDiscountPrice(GetMinimumPrice(details?.pricingOption))
                              )
                            )}
                          </span>
                        </Typography>
                        <StrikeOut
                          value={getPriceByShippingAndMarkup(details, 1, GetMinimumPrice(details?.pricingOption))}
                        />
                      </>
                    ) : (
                      <>
                        <Grid item>
                          <Typography variant="body3RegularInter">Starting at</Typography>
                        </Grid>
                        <Grid item style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                          <Typography variant="body3RegularInter" style={{ fontWeight: 600 }}>
                            {DollarPriceSymbol(
                              getPriceByShippingAndMarkup(
                                details,
                                1,
                                getDiscountPrice(GetMinimumPrice(details?.pricingOption))
                              )
                            )}
                          </Typography>
                          <StrikeOut
                            value={getPriceByShippingAndMarkup(details, 1, GetMinimumPrice(details?.pricingOption))}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : (
                  <Typography variant="body3RegularInter" style={{ paddingTop: isMobile ? 8 : 0 }}>
                    Starting at{' '}
                    {DollarPriceSymbol(
                      getPriceByShippingAndMarkup(details, 1, GetMinimumPrice(details?.pricingOption))
                    )}
                  </Typography>
                )}

                {isTouchDevice && (
                  <AddCircleIcon
                    style={
                      isHazmat ? { color: shoppingExperienceColors.info, cursor: 'default' } : { cursor: 'pointer' }
                    }
                    onClick={e => {
                      isHazmat ? e.stopPropagation() : addToCart(e);
                    }}
                  />
                )}
              </Grid>
              <Typography variant="body3SemiBoldInter">
                <TruncatedText text={details?.name} maxLines={1} />
              </Typography>
              {!details.canBeExported && (
                <div
                  style={{
                    display: 'flex',
                    marginTop: 10,
                    gap: '10px'
                  }}
                >
                  <LocalShippingOutlinedIcon
                    style={{
                      fontSize: '20px',
                      color: shoppingExperienceColors.primary
                    }}
                  />
                  <Typography
                    variant="body3SemiBoldInter"
                    style={{
                      color: shoppingExperienceColors.primary
                    }}
                  >
                    US Shipping Only
                  </Typography>
                </div>
              )}
            </CardContent>
          </>
        ) : (
          <ProductLoader />
        )}
      </Card>
    </Grid>
  );
};
export default Product;
