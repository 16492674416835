/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { useAuth } from './global/Authentication/AuthProvider';
import Loader from './global/Loader';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENTID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECTURI,
  pkce: false,
  devMode: process.env.NODE_ENV !== 'production'
});

const OktaCallback = () => {
  const history = useHistory();
  const [addedTokens, setAddedTokens] = useState(false);
  const { login } = useAuth();

  const jwtDecode = token => {
    if (!token) {
      throw new Error('Token is required');
    }

    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid token format');
    }

    // Decode the payload (second part of the token)
    const payload = parts[1];

    // Base64URL decoding
    const decodedPayload = JSON.parse(atob(payload.replace(/-/g, '+').replace(/_/g, '/')));

    return decodedPayload;
  };

  const setTokens = async (idToken, accessToken) => {
    try {
      // Decode the ID token to get the expiration time and scopes (if necessary)
      const decodedIdToken = jwtDecode(idToken); // You'll need to use a JWT decode library like 'jwt-decode'
      const decodedAccessToken = jwtDecode(accessToken);

      // Construct the token objects with necessary metadata
      const idTokenObject = {
        idToken,
        expiresAt: decodedIdToken.exp * 1000, // Expiry time in milliseconds
        scopes: decodedIdToken.scope || [] // Scopes (can be an empty array)
      };

      const accessTokenObject = {
        accessToken,
        expiresAt: decodedAccessToken.exp * 1000, // Expiry time in milliseconds
        scopes: decodedAccessToken.scope || [] // Scopes (can be an empty array)
      };

      // Add the tokens to Okta's tokenManager
      await oktaAuth.tokenManager.add('idToken', idTokenObject);
      await oktaAuth.tokenManager.add('accessToken', accessTokenObject);
      await oktaAuth.tokenManager.setTokens({ idToken: idTokenObject, accessToken: accessTokenObject });

      setAddedTokens(true);
    } catch (error) {
      console.error('Error setting tokens:', error);
    }
  };

  useEffect(() => {
    const { hash } = window.location; // Get the URL fragment
    const params = new URLSearchParams(hash.slice(1)); // Parse the fragment
    const accessToken = params.get('access_token');
    const idToken = params.get('id_token');
    const state = params.get('state');

    // Retrieve and clear the stored state and nonce
    const storedState = localStorage.getItem('okta_state');
    const storedNonce = localStorage.getItem('okta_nonce');

    // Validate the state
    if (state !== storedState) {
      console.error('Invalid state parameter');
      history.push('/');
    }

    // Validate the nonce in the ID token
    if (idToken) {
      try {
        const decodedIdToken = jwtDecode(idToken);

        if (decodedIdToken.nonce !== storedNonce) {
          throw new Error('Invalid nonce');
        }

        console.log('ID Token validated:', decodedIdToken);
      } catch (err) {
        console.error('Error validating ID Token:', err);
        history.push('/');
      }
    }

    if (accessToken && idToken) {
      // Set tokens and authenticate user
      (async () => {
        await setTokens(idToken, accessToken, '/dashboard');
      })();
    } else {
      console.error('No tokens found in URL fragment');
      history.push('/');
    }
  }, [history]);

  useEffect(() => {
    const checkTokens = async () => {
      if (addedTokens) {
        try {
          // Retrieve the tokens from Okta's token manager
          const idTokenFromManager = await oktaAuth.tokenManager.get('idToken');
          const accessTokenFromManager = await oktaAuth.tokenManager.get('accessToken');
          if (idTokenFromManager && accessTokenFromManager) {
            const url = localStorage.getItem('currentRoute');
            localStorage.removeItem('currentRoute');
            login(url ? `${process.env.REACT_APP_PUBLIC_URL}${url.substring(1)}` : process.env.REACT_APP_PUBLIC_URL);
          } else {
            console.error('Tokens were not set correctly');
          }
        } catch (error) {
          console.error('Error retrieving tokens:', error);
        }
      }
    };

    checkTokens(); // Call the async function
  }, [addedTokens]);

  return <Loader />;
};

export default OktaCallback;
