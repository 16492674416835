import { useEffect, useState, useMemo } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';

const DEVICES_TYPE = {
  MOBILE: 'Mobile',
  TABLET: 'Tablet',
  LAPTOP: 'Laptop',
  DESKTOP: 'Desktop'
};

const useResponsive = (customBreakpoint = null) => {
  const [currentDevice, setCurrentDevice] = useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between(0, 768));
  const isTablet = useMediaQuery(theme.breakpoints.between(768, 1024));
  const isLaptop = useMediaQuery(theme.breakpoints.between(1024, 1280));
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280));

  const isMobileOrTablet = useMemo(() => isMobile || isTablet, [isMobile, isTablet]);
  const isMobileOrTabletOrLaptop = useMemo(() => isMobile || isTablet || isLaptop, [isMobile, isTablet, isLaptop]);

  const customRange = customBreakpoint
    ? useMediaQuery(`(min-width:${customBreakpoint.min}px) and (max-width:${customBreakpoint.max}px)`)
    : false;

  useEffect(() => {
    if (isMobile) {
      setCurrentDevice(DEVICES_TYPE.MOBILE);
    } else if (isTablet) {
      setCurrentDevice(DEVICES_TYPE.TABLET);
    } else if (isLaptop) {
      setCurrentDevice(DEVICES_TYPE.LAPTOP);
    } else if (isDesktop) {
      setCurrentDevice(DEVICES_TYPE.DESKTOP);
    }
  }, [isMobile, isTablet, isLaptop, isDesktop]);

  return {
    isMobile,
    isTablet,
    isLaptop,
    isDesktop,
    isMobileOrTabletOrLaptop,
    isMobileOrTablet,
    currentDevice,
    DEVICES_TYPE,
    customRange
  };
};

export default useResponsive;
