import _ from 'lodash';
import { FETCH_SIZES, FETCH_COUNTRIES, FETCH_METADATA } from './types';
import DashBoard from '../apis/DashBoard';
import globalApi from '../apis/swagup/global';
import { okAndLog, errorAndLog } from '../helpers/utils';
import apiPaths from '../helpers/apiPaths';
import log from '../logger';

export const fetchSizes = () => async dispatch => {
  log.debug('fetchSizes Action - Entering');
  try {
    const sizesAPI = await DashBoard.get(apiPaths.sizes);
    if (sizesAPI.status === 200) {
      const sizes = sizesAPI.data.results;
      dispatch({
        type: FETCH_SIZES,
        payload: sizes
      });
      return okAndLog('fetchSizes', sizesAPI.status, sizes);
    }
    return errorAndLog('fetchSizes', sizesAPI.status, sizesAPI.data);
  } catch (e) {
    return errorAndLog('fetchSizes', e.status, e.data);
  }
};

export const getOnboardingOnlySizes = async () => {
  log.debug('samplePackSizes Action - Entering');

  const getSizeId = (s, sizes) => {
    const size = sizes.find(sz => sz.name === s.label);
    return size?.id || -1;
  };

  try {
    const samplePackSizesAPI = await DashBoard.get(apiPaths.samplePacksInventory);
    const sizesAPI = await DashBoard.get(apiPaths.sizes);
    if (samplePackSizesAPI.status === 200 && sizesAPI.status === 200) {
      const sizesIventory = samplePackSizesAPI.data;
      const allSizes = sizesAPI.data.results;
      const sizes = sizesIventory.map(s => ({ ...s, id: getSizeId(s, allSizes) }));
      return okAndLog('samplePackSizes', samplePackSizesAPI.status, sizes);
    }
    return errorAndLog('fetcsamplePackSizeshSizes', samplePackSizesAPI.status, samplePackSizesAPI.data);
  } catch (e) {
    return errorAndLog('samplePackSizes', e.status, e.data);
  }
};

export const loadCountries = (active = true) => async dispatch =>
  DashBoard.get(apiPaths.countries, { params: { active }, validateStatus: status => status === 200 })
    .then(({ status, data }) => {
      dispatch({
        type: FETCH_COUNTRIES,
        payload: data.results
      });
      return okAndLog('loadCountries', status, data.results);
    })
    .catch(({ response }) => errorAndLog('loadCountries', response?.status, response?.data));

export const clearbitEmailValidation = async email => {
  log.debug('clearbitEmailValidation Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.samplePacksEmails, { email });
    if (apiCall.status === 201) {
      return okAndLog('clearbitEmailValidation', apiCall.status, apiCall.data);
    }
    return errorAndLog('clearbitEmailValidation', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('clearbitEmailValidation', e.status, e.data);
  }
};

export const shoppingExperienceEmailValidation = async email => {
  log.debug('shoppingExperienceEmailValidation Action - Entering');
  try {
    const apiCall = await DashBoard.post(apiPaths.samplePacksEmails, { email, have_sample_pack: false });
    if (apiCall.status === 201) {
      return okAndLog('shoppingExperienceEmailValidation', apiCall.status, apiCall.data);
    }
    return errorAndLog('shoppingExperienceEmailValidation', apiCall.status, apiCall.data);
  } catch (e) {
    return errorAndLog('shoppingExperienceEmailValidation', e.status, e.data);
  }
};

export const handleClearbitEmailValidation = _.debounce(async email => {
  if (email) {
    const response = await clearbitEmailValidation(email);
    if (response.result !== 'ok') return 'Email verification failed, please try again';
    const { valid, msg } = response.data;
    return valid || msg?.includes('sample pack') ? undefined : msg;
  }
  return 'This field may not be blank.';
}, 750);

export const loadMetadata = () => async dispatch =>
  globalApi
    .fetchMetadata()
    .then(results => {
      const metadata = {};
      results.forEach(r => {
        metadata[r.field] = r.value;
      });

      dispatch({
        type: FETCH_METADATA,
        payload: metadata
      });
      return okAndLog('loadMetadata', 200, results);
    })
    .catch(({ response }) => errorAndLog('loadMetadata', response?.status, response?.data));
