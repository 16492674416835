/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-console */
import React, { useState } from 'react';
import axios from 'axios';

const OKTA_URL = 'https://signin-staging.swagup.com';
const TOKEN = '00K-6FT3oSauk3VzG6URgEkY4OjvhXhPG6PwMiJO5t';

const OktaSignUp = () => {
  const [payload, setPayload] = useState({
    email: '',
    password: '',
    firstName: '',
    lastName: ''
  });

  const [message, setMessage] = useState('');

  const handleSignUp = async e => {
    e.preventDefault();
    try {
      const { firstName, lastName, email, password } = payload;
      const response = await axios.post(
        `${OKTA_URL}/api/v1/users?activate=true`, // Replace with your Okta domain
        {
          profile: {
            firstName,
            lastName,
            email,
            login: email // 'login' is typically the same as email
          },
          credentials: {
            password: { value: password }
          }
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `SSWS ${TOKEN}` // Replace with your Okta API token
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        setMessage('Sign-up successful! You can now log in.');
      }
    } catch (error) {
      console.error('Error during sign-up:', error);
      setMessage('Sign-up failed. Please try again.');
    }
  };

  const handleInputChange = e => {
    const { id, value } = e.target;
    setPayload(prev => ({
      ...prev,
      [id]: value
    }));
  };

  return (
    <div style={{ margin: '24px 300px' }}>
      <h2>Sign Up</h2>
      <form onSubmit={handleSignUp}>
        <div>
          <label>First Name:</label>
          <input type="text" id="firstName" onChange={handleInputChange} required />
        </div>
        <div>
          <label>Last Name:</label>
          <input type="text" id="lastName" onChange={handleInputChange} required />
        </div>
        <div>
          <label>Email:</label>
          <input type="email" id="email" onChange={handleInputChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" id="password" onChange={handleInputChange} required />
        </div>
        <button type="submit">Sign Up</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default OktaSignUp;
