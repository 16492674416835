import React, { useState } from 'react';
import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Scrollable from 'react-scrollbars-custom';
import UserAvatar from '../../../components/AppTopBar/UserAvatar';
import { useCheckoutContext } from '../../context/checkout.context';
import styles from './RelatedAccountsDropdown.style';

const useStyles = makeStyles(styles);

const RelatedAccountsDropdown = () => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const { relatedAccounts, selectedAccount, onAccountSelect, switchingAccounts } = useCheckoutContext();

  const toggleExpanded = () => setExpanded(!expanded);

  return (
    <>
      {relatedAccounts.length > 0 && (
        <Grid container style={{ flexDirection: 'column', position: 'relative' }}>
          <Grid className={`${classes.dropdownRoot} ${switchingAccounts ? 'disabled' : ''}`} onClick={toggleExpanded}>
            <Typography variant="body2RegularInter">{selectedAccount.name}</Typography>
            {switchingAccounts ? (
              <CircularProgress size="30px" />
            ) : (
              <>
                {expanded ? (
                  <ExpandLessIcon className={classes.expandIcon} />
                ) : (
                  <ExpandMoreIcon className={classes.expandIcon} />
                )}
              </>
            )}
          </Grid>
          {expanded === true && (
            <Grid className={classes.listWrapper}>
              <Scrollable className={classes.scrollBar}>
                {relatedAccounts.map(account => (
                  <Grid
                    item
                    key={account}
                    className={`${classes.listItem} ${selectedAccount.id === account.id ? 'active' : ''}`}
                    onClick={() => {
                      onAccountSelect(account);
                      toggleExpanded();
                    }}
                  >
                    <Grid item style={{ width: '20px', height: '20px', marginRight: 14 }}>
                      <UserAvatar name={account.name} />
                    </Grid>
                    <Typography variant="body3SemiBoldInter">{account.name}</Typography>
                  </Grid>
                ))}
              </Scrollable>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default RelatedAccountsDropdown;
