import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography } from '@swagup-com/components';

import EmptyCartIcon from '../assets/images/cart/empty-cart.png';
import { CommonButton } from '../shared/ui/commonButtons';
import CartOrderSummary from '../components/Cart/cartOrderSummary';
import CartOrderSummaryCTA from '../components/Cart/cartOrderSummaryCTA';

const CartEmptyState = ({ isMobile, history }) => {
  const handleStartShopping = () => history.push('/catalog');
  return (
    <>
      <Grid container>
        <Grid item xs={12} md={12}>
          <Grid
            md={12}
            justifyContent="space-between"
            alignItems="center"
            style={{ display: !isMobile && 'flex', padding: isMobile && '20px 0px 0px 20px' }}
          >
            <Grid item style={{ display: 'block', flexDirection: 'column' }} xs={12} md={10}>
              <Typography variant="h3SemiBoldInter" gutterBottom>
                Your cart
              </Typography>

              <Grid container style={{ flexDirection: 'column', alignItems: 'center', gap: '18px' }}>
                <Grid item>
                  <img src={EmptyCartIcon} alt="empty-cart-item" />
                </Grid>
                <Typography variant="subtitle2SemiBoldInter">Your cart is empty</Typography>
                <CommonButton
                  text="Start shopping"
                  w="fit-content"
                  mt="0px"
                  mr="0px"
                  onClick={handleStartShopping}
                  isActive
                />
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid container style={{ flexDirection: 'column' }} md={4}>
                <CartOrderSummary products={[]} emptyState />
                <CartOrderSummaryCTA history={null} isLoading={false} isMobile={isMobile} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CartEmptyState;
