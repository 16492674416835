import React from 'react';
import { Typography } from '@swagup-com/components';
import { Grid, Button, makeStyles } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import PaginationButton from '../../shared/ui/paginationButton';
import Styles from './pagination.styles';

const useStyles = makeStyles(Styles);
const Pagination = props => {
  const classes = useStyles();
  const { progress, totalProducts, renderedProducts } = props;
  return (
    <Grid container className={classes.gridContainer}>
      <div className={classes.root}>
        <LinearProgress variant="determinate" value={progress} className={classes.linearProgressStyle} />
      </div>
      <Grid item className={classes.gridItemStyle}>
        <Typography variant="body4RegularInter">
          Showing {renderedProducts} of {totalProducts} results
        </Typography>
      </Grid>
      <Grid item className={classes.gridItemStyle}>
        <PaginationButton {...props} title="Show more" />
      </Grid>
    </Grid>
  );
};
export default Pagination;
