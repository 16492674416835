import { shoppingExperienceColors } from '../../shared/constants';

const PaginationStyles = theme => ({
  gridContainer: {
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: 16,
    marginBottom: 16,
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  root: {
    width: '20%',
    marginBottom: 16,
    [theme.breakpoints.down('sm')]: {
      width: '50%'
    }
  },
  linearProgressStyle: {
    background: shoppingExperienceColors.borderLineGray,
    '& .MuiLinearProgress-bar': {
      backgroundColor: shoppingExperienceColors.textPrimary
    }
  },
  gridItemStyle: {
    marginBottom: 16
  },
  buttonStyle: {
    borderRadius: 4,
    borderWidth: 1,
    color: shoppingExperienceColors.textPrimary,
    borderColor: shoppingExperienceColors.textPrimary
  }
});

export default PaginationStyles;
