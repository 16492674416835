/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-key */
/* eslint-disable react/button-has-type */
import React from 'react';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { Box } from '@material-ui/core';
import { Remove, Add, Close } from '@material-ui/icons';
import { CommonButton } from '../../shared/ui/commonButtons';
import { getApplicablePrice } from '../../utils/productUtil';
import StrikeOut from '../../shared/ui/StrikeOut';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import { DollarPriceSymbol } from '../../shared/ui/common';
import { useResponsive } from '../../hooks';

const RightSidebar = ({
  isOpen,
  toggleSidebar,
  pricingMatrix,
  quantity,
  setQuantity,
  defaultApplicableQty,
  classes,
  product = null
}) => {
  const availableQuantities = getApplicablePrice([25, 50, 100, 150, 250, 500, 1000], pricingMatrix);
  const { isAuthenticated } = useAuth();
  const { getDiscountPrice, getPriceByShippingAndMarkup } = useDiscountPrice();
  const { isMobile } = useResponsive();

  const cellStyle = {
    padding: isMobile ? '5px' : '10px'
  };

  const rowStyle = {
    marginBottom: isMobile ? '5px' : '10px'
  };

  const cellRightStyle = {
    padding: isMobile ? '5px' : '10px',
    paddingRight: 15,
    textAlign: 'right'
  };

  return (
    <div>
      {/* Sidebar */}
      <div
        style={{
          position: 'fixed',
          top: '60px',
          right: isOpen ? 0 : '-100%',
          height: 'calc(100vh - 60px)',
          width: '100%',
          maxWidth: '420px',
          background: 'white',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          transition: 'right 0.3s ease',
          zIndex: 1000,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingBottom: 30
        }}
      >
        {/* Close Button */}
        <button
          onClick={toggleSidebar}
          style={{
            alignSelf: 'flex-end',
            padding: '5px 5px',
            color: 'black',
            border: 'none',
            backgroundColor: 'white',
            cursor: 'pointer'
          }}
        >
          <SwagupTypography variant="body2SemiBoldInter" style={cellRightStyle}>
            <Close className={classes.infoIcon} style={{ width: 20 }} />
          </SwagupTypography>
        </button>

        {/* Sidebar Content */}
        <div style={{ padding: '20px', paddingTop: 0 }}>
          <SwagupTypography
            variant="h6SemiBoldInter"
            style={{ borderBottom: '1px solid #E5E7E8', paddingBottom: '16px' }}
          >
            See how to save more
          </SwagupTypography>
          <SwagupTypography variant="body2RegularInter" style={{ paddingTop: '20px' }}>
            The more you order, the more you save
          </SwagupTypography>
        </div>
        {/* table */}
        <div style={{ padding: '0 20px' }}>
          <table style={{ width: '100%', borderCollapse: 'separate', borderSpacing: '0 10px' }}>
            <tbody>
              {availableQuantities &&
                availableQuantities.map(item => (
                  <tr style={rowStyle}>
                    <td>
                      <SwagupTypography variant="body2SemiBoldInter" style={cellStyle}>
                        {item.quantity}
                      </SwagupTypography>
                    </td>
                    <td>
                      <SwagupTypography variant="body2SemiBoldInter" style={cellRightStyle}>
                        {!isAuthenticated ? (
                          DollarPriceSymbol(product ? getPriceByShippingAndMarkup(product, 1, item.price) : item.price)
                        ) : (
                          <div style={{ display: 'flex', gap: 10, justifyContent: 'end' }}>
                            {DollarPriceSymbol(
                              product
                                ? getPriceByShippingAndMarkup(product, 1, getDiscountPrice(item.price))
                                : getDiscountPrice(item.price)
                            )}
                            <StrikeOut
                              value={product ? getPriceByShippingAndMarkup(product, 1, item.price) : item.price}
                              fontSize={item.price ? '12px' : '16px'}
                            />
                          </div>
                        )}
                      </SwagupTypography>
                    </td>
                  </tr>
                ))}
              <tr style={rowStyle}>
                <td>
                  <SwagupTypography variant="body2SemiBoldInter" style={cellStyle}>
                    Custom Quantity
                  </SwagupTypography>
                </td>
                <td>
                  <Box display="flex" style={{ marginLeft: '60px' }} mt={1} mb={4}>
                    <button
                      variant="body2SemiBoldInter"
                      style={{ fontSize: 30, cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}
                      onClick={() => setQuantity(prev => Math.max(10, parseInt(prev, 10) - 1))}
                    >
                      <Remove className={classes.infoIcon} style={{ width: 20 }} />
                    </button>
                    <input
                      type="text"
                      value={quantity}
                      min={defaultApplicableQty}
                      onChange={e => {
                        setQuantity(e.target.value);
                      }}
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onBlur={e => {
                        const value = parseInt(e.target.value, 10);
                        if (value < defaultApplicableQty || isNaN(value)) {
                          setQuantity(defaultApplicableQty);
                        }
                      }}
                      style={{
                        width: 60,
                        textAlign: 'center',
                        padding: '8px',
                        border: '1px solid gray',
                        borderRadius: 4,
                        margin: '0 16px'
                      }}
                    />
                    <button
                      variant="body2SemiBoldInter"
                      style={{ fontSize: 30, cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }}
                      onClick={() => setQuantity(prev => prev + 1)}
                    >
                      <Add className={classes.infoIcon} style={{ width: 20 }} />
                    </button>
                    <br />
                  </Box>
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ position: 'relative', bottom: 5 }}>
            <CommonButton text="Update product quantity" px={40} isActive mr={10} onClick={toggleSidebar} />
          </div>
        </div>
      </div>

      {/* Overlay for Mobile */}
      {isOpen && (
        <div
          onClick={toggleSidebar}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999
          }}
        />
      )}

      {/* Responsive Styles */}
      <style>
        {`
          @media (max-width: 768px) {
            div[style*="width: 20%"] {
              width: 100%;
            }
          }
        `}
      </style>
    </div>
  );
};

export default RightSidebar;
