import { Skeleton } from '@material-ui/lab';
import { Grid } from '@material-ui/core';
import { useResponsive } from '../../hooks';

const ProductLoader = () => {
  const { isMobileOrTablet, isTablet } = useResponsive();

  return (
    <Grid direction="column" style={{ display: 'flex', gap: 20 }}>
      <Skeleton
        variant="rounded"
        height={isMobileOrTablet ? (isTablet ? 190 : 150) : 273}
        style={{ borderRadius: '17.5px', alignSelf: 'stretch' }}
      />
      <Grid xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid item xs={6}>
          <Skeleton variant="rounded" height={12.5} style={{ borderRadius: '17.5px' }} />
        </Grid>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {isTablet && <Skeleton variant="circular" width={36} height={36} style={{ borderRadius: '50%' }} />}
        </Grid>
      </Grid>
      <Skeleton variant="rounded" height={13} style={{ borderRadius: '17.5px' }} />
    </Grid>
  );
};

export default ProductLoader;
