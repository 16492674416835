import React from 'react';
import { AppBar, Toolbar, Grid, Hidden, Badge } from '@material-ui/core';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import { makeStyles } from '@material-ui/styles';
import { useHistory } from 'react-router-dom';
import { ShoppingCart } from '@material-ui/icons';
import logo from '../../assets/images/logo/black-logo-with-name.svg';
import { SwagUpLogoMobileIcon } from '../../shared/icons';
import { NavigateTo } from '../../shared/ui/common';
import { shoppingExperienceColors } from '../../shared/constants';
import CallUsComponent from '../../shared/ui/callUs';

const useStyles = makeStyles(() => ({
  customToolbar: {
    padding: '0px !important'
  },
  desktopHeader: {
    padding: '30px 40px',
    height: '100px'
  },
  desktopHeaderLogo: {
    height: '38px',
    width: '160px',
    objectFit: 'contain'
  },
  mobileHeader: { padding: '12px 20px' },
  badge: {
    backgroundColor: shoppingExperienceColors.primary,
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 500,
    color: shoppingExperienceColors.white
  }
}));

const SimpleFormHeader = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <AppBar position="static" color="default" elevation={0}>
      <Toolbar className={classes.customToolbar}>
        {/* Mobile View */}
        <Hidden mdUp>
          <Grid container alignItems="center" justify="space-between" className={classes.mobileHeader}>
            <Grid item onClick={() => NavigateTo(history, '/')}>
              <SwagUpLogoMobileIcon />
            </Grid>
            <Grid item onClick={() => NavigateTo(history, '/cart')} style={{ marginRight: '10px' }}>
              <Badge badgeContent={4} classes={{ badge: classes.badge }}>
                <ShoppingCart style={{ color: shoppingExperienceColors.black, cursor: 'pointer' }} />
              </Badge>
            </Grid>
          </Grid>
        </Hidden>

        {/* Tablet, Laptop, and Desktop View */}
        <Hidden smDown>
          <Grid container alignItems="center" justify="space-between" className={classes.desktopHeader}>
            <Grid item onClick={() => NavigateTo(history, '/')} style={{ cursor: 'pointer' }}>
              <img src={logo} alt="Logo" className={classes.desktopHeaderLogo} />
            </Grid>
            <Grid item>
              {/* <PersonSharpIcon style={{ color: shoppingExperienceColors.black, cursor: 'pointer' }} /> */}
              <CallUsComponent color={shoppingExperienceColors.black} />
            </Grid>
          </Grid>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default SimpleFormHeader;
