import React, { useEffect, useCallback, useState } from 'react';
import { IconButton, InputAdornment, makeStyles } from '@material-ui/core';
import { TextField } from '@swagup-com/components';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { shoppingExperienceColors } from '../constants';
import styles from './common.styles';
import { setSearchTerm, triggerSearch } from '../../redux/searchReducer';

const useStyles = makeStyles(styles);

const SearchBar = ({ onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const searchTerm = useSelector(state => state.search.searchTerm);
  const [searchWord, setSearchWord] = useState('' || searchTerm);
  const doNotDisableSearch = searchWord.trim().length >= 2;
  const disableSearch = searchWord.trim().length < 2;

  const debouncedTriggerSearch = useCallback(
    debounce(schWord => {
      dispatch(triggerSearch(schWord, onClose, history));
    }, 300),
    [dispatch, onClose, history]
  );

  const handleSearchChange = e => {
    setSearchWord(e.target.value);
  };

  const handleSearchWithClose = () => {
    if (doNotDisableSearch) {
      debouncedTriggerSearch(searchWord);
      if (onClose) onClose();
    }
  };

  const handleClearSearch = () => {
    setSearchWord('');
    // dispatch(setSearchTerm(''));
    // history.push('/search');
  };

  return (
    <TextField
      className={classes.searchBarTextField}
      variant="outlined"
      value={searchWord}
      onChange={handleSearchChange}
      onKeyPress={e => {
        if (e.key === 'Enter' && doNotDisableSearch) {
          handleSearchWithClose();
        }
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {searchWord ? (
              <IconButton onClick={handleClearSearch}>
                <CancelIcon style={{ color: shoppingExperienceColors.textPrimary, fontSize: '30px' }} />
              </IconButton>
            ) : (
              <IconButton onClick={handleSearchWithClose} disabled={disableSearch}>
                <SearchIcon
                  style={{
                    color: disableSearch ? shoppingExperienceColors.disabledCta : shoppingExperienceColors.textPrimary
                  }}
                />
              </IconButton>
            )}
          </InputAdornment>
        ),
        className: classes.searchInput,
        placeholder: 'Search products'
      }}
    />
  );
};

export default SearchBar;
