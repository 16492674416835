import { shoppingExperienceColors } from '../../shared/constants';

const collectionTopGutterStyles = (theme, currentDevice, DEVICES_TYPE) => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    overflow: 'hidden',
    padding: '0px 30px',
    margin: '20px'
  },
  carousel: {
    display: 'flex',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    width: '100%',
    height: '150px !important',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  collectionItem: {
    display: 'inline-flex !important',
    flexDirection: 'column !important',
    alignItems: 'center !important',
    width: 'auto',
    height: '100%',
    minWidth: '150px',
    cursor: 'pointer',
    textAlign: 'center',
    outline: 0,
    '&:hover': {
      transform: currentDevice !== DEVICES_TYPE.MOBILE && 'translate(0px, -15px)',
      '& p': {
        backgroundColor: shoppingExperienceColors.white,
        color: shoppingExperienceColors.black,
        fontWeight: 'bold',
        borderBottomRightRadius: '0px',
        borderBottomLeftRadius: '0px'
      }
    }
  },
  active: {
    boxShadow: 'rgba(0, 0, 0, 0.08) 0px 4px 12px',
    color: shoppingExperienceColors.white,
    transform: currentDevice !== DEVICES_TYPE.MOBILE && 'translate(0px, -15px) !important',
    '& p': {
      backgroundColor: shoppingExperienceColors.black,
      color: shoppingExperienceColors.white,
      borderBottomRightRadius: '5px',
      borderBottomLeftRadius: '5px'
    },
    '&:hover': {
      '& p': {
        backgroundColor: shoppingExperienceColors.black,
        color: shoppingExperienceColors.white,
        borderBottomRightRadius: '5px',
        borderBottomLeftRadius: '5px',
        textDecoration: 'none !importatn',
        fontWeight: 400,
        borderBottom: 'none'
      }
    }
  },
  imageCollections: {
    width: '150px !important',
    height: '80px !important',
    objectFit: 'contain !important',
    borderRadius: '8px',
    outline: 0,
    transform: 'scale(0.85)',
    transition: 'transform 0.3s ease-in-out'
  },
  collectionName: {
    height: '50px',
    width: '150px',
    color: shoppingExperienceColors.textPrimary,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  slider: {
    width: '100%',
    '& .MuiSvgIcon-root': {
      width: '40px',
      height: '40px',
      zIndex: 999,
      '&.slick-disabled': {
        pointerEvents: 'none',
        display: 'none',
        '& path': {
          fill: shoppingExperienceColors.disabledCta
        }
      }
    }
  }
});

export default collectionTopGutterStyles;
