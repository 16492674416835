/* eslint-disable no-underscore-dangle */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Typography } from '@swagup-com/components';
import { CloseIcon } from '../icons';
import '../../index.css';
import { useFilterContext } from '../../contexts/filter.context';
import { FILTER_FIELD_NAMES } from '../../utils/constants';

const Chips = props => {
  const history = useHistory();
  const { label, value, setMinPrice, setMaxPrice, setMinMaxId, isPrice, categoryName = null } = props;
  const { setAppliedFilter, appliedFilters, filterData, setOpenFilterDrawer, setDelayLoading } = useFilterContext();
  const dispatch = useDispatch();

  const handleDelete = () => {
    const _appliedFilter = [...appliedFilters];
    const index = _appliedFilter.findIndex(item => item.value === value);

    if (index !== -1) {
      // if user is at category/sub category page
      // and user is trying to remove category/sub category filter
      // redirect user accordingly
      if (categoryName) {
        const filterItem = filterData.find(filter => filter._id === _appliedFilter[index]._id);
        if (filterItem) {
          let drawerState;
          if (filterItem.fieldName === FILTER_FIELD_NAMES.CATEGORY) {
            drawerState = true;
            setDelayLoading(true);
            history.push('/catalog', { replace: true });
          } else if (filterItem.fieldName === FILTER_FIELD_NAMES.SUB_CATEGORY) {
            drawerState = true;
            setDelayLoading(true);
            history.push(`/catalog/${categoryName}`, { replace: true });
          }
          setOpenFilterDrawer(drawerState);
        }
        return;
      }

      _appliedFilter.splice(index, 1);
    }

    dispatch(setAppliedFilter(_appliedFilter));

    if (!isPrice) {
      setMinMaxId(null);
      setMaxPrice(null);
      setMinPrice(null);
    }
  };

  return (
    <Chip
      deleteIcon={<CloseIcon style={{ marginRight: 4 }} />}
      label={
        <Typography variant="body4MediumInter" style={{ padding: '12px 0px' }}>
          {label}
        </Typography>
      }
      onDelete={handleDelete}
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
        borderRadius: 4,
        background: '#E5E7E8',
        padding: '4px 12px',
        width: 'fit-content',
        marginRight: 12,
        marginTop: 12
      }}
    />
  );
};

export default Chips;
