import * as React from 'react';
import { Box, Grid, Link } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Typography } from '@swagup-com/components';
import { LocalShippingOutlined } from '@material-ui/icons';
import { useOrder } from '../../OrderContext';
import { ProductProvider } from '../../common/ProductContext';

import { Header, OrderProduct } from './common';
import UsOnlyWarningBox from '../../../../../global/UsOnlyWarningBox';

const ReviewOrder = () => {
  const order = useOrder();
  const products = order?.products ?? [];

  const usOnlyShippingProducts = products.map(p => p.product).filter(p => !p.can_ship_international);

  const hasWarning = !isEmpty(usOnlyShippingProducts);

  return (
    <Box>
      <Header header="Review your order" />
      {hasWarning && <UsOnlyWarningBox names={usOnlyShippingProducts.map(p => p.name)} />}
      <Box>
        {products.map(product => (
          <ProductProvider key={product.id} product={product}>
            <OrderProduct />
          </ProductProvider>
        ))}
      </Box>
    </Box>
  );
};

export default ReviewOrder;
