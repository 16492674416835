export const getMinimumPriceForQuantity = pricingMatrix => {
  if (!Array.isArray(pricingMatrix) || pricingMatrix.length === 0) return null;
  const result = pricingMatrix.reduce((minObj, currentObj) => {
    const minQuantityCurrent = currentObj.parameters.find(param => param.name === 'quantity')?.minimum ?? Infinity;
    const maxQuantityCurrent = currentObj.parameters.find(param => param.name === 'quantity')?.maximum ?? Infinity;

    const minQuantityMinObj = minObj.parameters.find(param => param.name === 'quantity')?.minimum ?? Infinity;
    const maxQuantityMinObj = minObj.parameters.find(param => param.name === 'quantity')?.maximum ?? Infinity;

    // Compare based on minimum and then maximum
    if (
      minQuantityCurrent < minQuantityMinObj ||
      (minQuantityCurrent === minQuantityMinObj && maxQuantityCurrent < maxQuantityMinObj)
    ) {
      return currentObj;
    }
    return minObj;
  });

  return result?.price?.[0]?.value || null;
};

export const getPriceForQuantity = (pricingMatrix, quantity) => {
  if (!Array.isArray(pricingMatrix) || pricingMatrix?.length === 0) return null;
  const matchingPrice = pricingMatrix.find(matrix => {
    const parameter = matrix.parameters?.[0];
    const min = parameter?.minimum || 0;
    const max = parameter?.maximum || Infinity;

    return quantity >= min && quantity <= max;
  });

  return matchingPrice?.price?.[0]?.value || getMinimumPriceForQuantity(pricingMatrix);
};
