import React from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import { shoppingExperienceColors } from '../constants';

const useStyles = makeStyles(() => ({
  textField: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontSize: '16px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      fontFamily: 'Inter !important',
      '& fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&:hover fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&.Mui-focused fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&.Mui-focusedVisible fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      }
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '5px'
    },
    '& .Mui-error .MuiOutlinedInput-notchedOutline': {
      borderColor: shoppingExperienceColors.error
    },
    '& .MuiOutlinedInput-input::placeholder': {
      color: shoppingExperienceColors.textPrimary,
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px',
      opacity: 1
    },
    '& .MuiFormHelperText-root.Mui-error': {
      textAlign: 'left',
      margin: '0px'
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 30px 12px 16px',
      height: '35px',
      fontFamily: 'Inter',
      fontWeight: 400,
      fontSize: '16px'
    },
    '& .MuiFormHelperText-contained': {
      margin: '0px'
    }
  }
}));

const StyledTextField = ({ width = '360px', type = 'text', placeholder, onChange, ...props }) => {
  const classes = useStyles();

  const handleChange = event => {
    const { value } = event.target;
    const updatedValue = type === 'email' ? value.toLowerCase() : value;
    if (onChange) onChange({ ...event, target: { ...event.target, value: updatedValue } });
  };

  return (
    <TextField
      variant="outlined"
      type={type}
      className={classes.textField}
      style={{ width }}
      placeholder={placeholder}
      onChange={handleChange}
      {...props}
    />
  );
};

export default StyledTextField;
